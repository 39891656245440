import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MoreHoriz } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../redux/actions/global/global.actions";
import { AddTeam } from "../../pages/Admin/AdminSettings/components/ManageTeams/components";
import adminService from "../../services/admin/admin.service";
import { getToken } from "../../utils/helpers";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MoreMenu({
  id,
  firstText,
  secondText,
  user,
  getUsers,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const paths = location.pathname.split("/");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getToken();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFirstText = () => {
    if (paths.includes("teams")) {
      dispatch(
        setPopupChildren(
          <AddTeam
            headerText={"Edit Team Member"}
            btnText={"Update"}
            data={user}
          />
        )
      );
      dispatch(togglePopup(true));
      handleClose();
    } else {
      navigate(`${firstText.toLowerCase()}/${id}`);
    }
  };
  const toggleActiveUser = (userId, isActive) => {
    adminService
      .toggleActiveUser(token, userId, isActive)
      .then(({ success }) => {
        if (success) getUsers();
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };
  const handleSecondText = () => {
    if (secondText === "activity") {
      toggleActiveUser(user.id, !user.is_active);
      console.log("activity", user.is_active);
      handleClose();
    }
  };


  return (
    <div>
      <Box
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{ cursor: "pointer" }}
      >
        <MoreHoriz />
      </Box>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleFirstText} disableRipple>
          {firstText}
        </MenuItem>
        <MenuItem onClick={handleSecondText} disableRipple>
          {secondText === "activity" &&
            (user.is_active ? "Set Inactive" : "Set Active")}
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
