import { makeStyles } from "@mui/styles";

export const MembershipsStyles = makeStyles(({ theme }) => ({
  memberWrap: {
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 10,
    display: "flex !important",
    cursor: "pointer",
    border: "0.5px solid #aaa",
    justifyContent: "space-between",
    gap: 32,
  },
  memberInfo: {
    width: "80%",
    whiteSpace: "wrap",
  },
  amountText: {
    fontWeight: "600",
    color: "#000",
    fontSize: "40px !important",
  },
  durationText: {
    fontSize: "12px !important",
    marginBottom: "-15px !important",
  },
  titleText: {
    fontSize: "15px !important",
    fontWeight: "600 !important",
  },
  descriptionText: {
    fontSize: "13px !important",
  },
  planNameRow: {
    display: "flex !important",
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },
  activePlan: {
    border: "1px solid #5AB400",
    backgroundColor: "#E3FDEB",
    color: "#5AB400",
    fontSize: 10,
    padding: "2px 5px",
    borderRadius: 10,
  },
}));
