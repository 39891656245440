import {
  Box,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import React from "react";
import { ChatsHeaderStyle } from "./ChatsHeader.style";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import ChatsFilter from "../../../../components/ChatsFIlter";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import useChats from "../../hooks/useChats";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
const ChatsHeader = ({ togglePopup, setPopupChildren }) => {
  const classes = ChatsHeaderStyle();
  const { setSearch, search, onSearch } = useChats();
  const { t } = useTranslation();
  return (
    <Box className={classes.chatsHeaderWrapper} sx={{ display: "flex" }}>
      <Box>
        <Typography className={classes.text}>{t("chatMessaging")}</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "70%",
          justifyContent: "flex-end",
        }}
      >
        {search ? (
          <Input
            onChange={({ target }) => onSearch(target.value)}
            variant="filled"
            style={{
              width: "100%",
              backgroundColor: "#F7F7F7",
              height: 25,
              borderRadius: 3,
            }}
            type={"text"}
            // value={textMessage}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearch(false);
                  }}
                >
                  <CancelIcon
                    style={{
                      width: 15,
                      height: 15,
                      color: "#00000029",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
        ) : (
          <IconButton onClick={() => setSearch(true)}>
            <SearchIcon
              style={{
                padding: 5,
                borderRadius: 3,
                backgroundColor: "#F7F7F7",
                width: 15,
                height: 15,
                color: "#000",
              }}
            />
          </IconButton>
        )}

        <IconButton
          onClick={() => {
            setPopupChildren(<ChatsFilter />);
            togglePopup(true);
          }}
        >
          <FilterIcon
            style={{
              padding: 5,
              borderRadius: 3,
              backgroundColor: "#F7F7F7",
              width: 15,
              height: 15,
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatsHeader;
