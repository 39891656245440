import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setChats,
  setMessagesInView,
  setMessagingUser,
} from "../../../redux/actions/chats/chats.actions";
import { setCaller } from "../../../redux/actions/global/global.actions";
import {
  setGettingPreview,
  setPreviewUser,
} from "../../../redux/actions/users/users.actions";
import callService from "../../../services/call.service";
import chatsService from "../../../services/chats.service";
import socketService from "../../../services/socket.service";
import usersService from "../../../services/users.service";
import { filteredChat, getToken } from "../../../utils/helpers";

const useChats = () => {
  const { chats, chatsFilterVal, messagingUser } = useSelector(
    (state) => state.chats
  );

  const me = useSelector((state) => state.users.myData);
  const dispatch = useDispatch();
  const token = getToken();
  const [search, setSearch] = useState(false);
  const [isFav, setIsFav] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const onTyping = (res) => {
      dispatch(
        setChats(
          chats?.map((chat) =>
            chat?.user?.id == res?.sender
              ? {
                  ...chat,
                  typing: res?.status,
                }
              : chat
          )
        )
      );
    };
    const onReceiveMessage = (res) => {
      const newChats = chats?.map((chat) =>
        chat?.user?.id == res?.sender
          ? {
              ...chat,
              last_message: res?.message,
              chat: {
                ...chat?.chat,
                unseen_messages: chat?.chat?.unseen_messages + 1,
              },
            }
          : chat
      );

      const chatToMoveTop = newChats?.filter(
        (chat) => chat?.user?.id == res?.sender
      );

      const otherChats = newChats?.filter(
        (chat) => chat?.user?.id != res?.sender
      );
      dispatch(setChats([...chatToMoveTop, ...otherChats]));
    };

    const onSeenMyMessage = (res) => {
      if (res?.status) {
        dispatch(
          setChats(
            chats?.map((chat) =>
              chat?.user?.id == res?.sender
                ? {
                    ...chat,
                    last_message: { ...chat?.last_message, seen: true },
                  }
                : chat
            )
          )
        );
      }
    };

    const userOpenedOurDm = (res) => {
      dispatch(
        setChats(
          chats?.map((chat) =>
            chat?.user?.id == res?.id
              ? {
                  ...chat,
                  last_message: { ...chat?.last_message, seen: true },
                }
              : chat
          )
        )
      );
    };

    socketService?.on("typing", onTyping);
    socketService?.on("message", onReceiveMessage);
    socketService?.on("seen", onSeenMyMessage);
    socketService?.on("open_dm", userOpenedOurDm);

    return () => {
      socketService?.off("typing", onTyping);
      socketService?.off("message", onReceiveMessage);
      socketService?.off("seen", onSeenMyMessage);
      socketService?.off("open_dm", userOpenedOurDm);
    };
  }, [chats]);

  const original = useMemo(() => {
    if (search) {
      return chats;
    }
  }, [search]);

  const toggleChatFavourite = (chat) => {
    setIsFav(!isFav);
    chatsService
      .toggleFavouriteChat(token, chat)
      .then((res) => {
        if (res?.success) {
          getChats();
        }
      })
      .catch((err) => console.log("err for fav", err));
  };

  const getChats = () => {
    chatsService
      .getChats(token)
      .then(async (res) => {
        if (res?.success === true) {
          dispatch(setChats(filteredChat(res?.data?.data, chatsFilterVal)));
        }
      })
      .catch((err) => console.log(err.errors || err.message))
      .finally(() => {});
  };

  const getMessagesWithUser = (userId) => {
    dispatch(setMessagesInView([]));
    // dispatch(setGettingMessage(true));
    chatsService
      .getMessagesWithUser(token, userId, 1, 25)
      .then((res) => {
        if (res?.success === true) {
          dispatch(setMessagesInView(res?.data?.data));
          dispatch(
            setChats(
              chats?.map((chat) =>
                chat?.user?.id === userId
                  ? {
                      ...chat,
                      chat: {
                        ...chat?.chat,
                        unseen_messages: 0,
                      },
                    }
                  : chat
              )
            )
          );
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {});
  };

  const handleUserMessage = (user) => {
    dispatch(setMessagingUser(user));
    getMessagesWithUser(user?.id);
  };

  const onSearch = (text) => {
    const searchText = text;

    if (searchText.trim().length > 0) {
      let filteredSearch;
      filteredSearch = original?.filter((chat) => {
        if (
          chat?.user?.first_name
            ?.toLowerCase()
            .includes(searchText.toString().toLowerCase())
        ) {
          return chat;
        }
        if (
          chat?.user?.last_name
            ?.toLowerCase()
            .includes(searchText.toString().toLowerCase())
        ) {
          return chat;
        }
      });
      dispatch(setChats(filteredSearch));
    } else {
      dispatch(setChats(original));
    }
  };

  const getUser = (userId) => {
    dispatch(setGettingPreview(true));
    usersService
      .getUser(token, userId)
      .then(({ data }) => {
        dispatch(setPreviewUser(data));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingPreview(false)));
  };

  const checkPermissions = (type) => {
    if (type === "voice") {
      const microphonePermissionPromise = navigator.permissions.query({
        name: "microphone",
      });
      microphonePermissionPromise.then((permissionStatus) => {
        if (permissionStatus.state === "granted") {
          handleCallUser(type);
        } else {
          requestPermissions(type);
          return toast.info(t("enableMic"));
        }
      });
    } else {
      const microphonePermissionPromise = navigator.permissions.query({
        name: "microphone",
      });
      microphonePermissionPromise.then((permissionStatus) => {
        if (permissionStatus.state !== "granted") {
          requestPermissions(type);
          return toast.info(t("enableMic"));
        }
      });

      const cameraPermissionPromise = navigator.permissions.query({
        name: "camera",
      });
      cameraPermissionPromise.then((permissionStatus) => {
        if (permissionStatus.state === "granted") {
          handleCallUser(type);
        } else {
          requestPermissions(type);
          return toast.info(t("enableCameraMic"));
        }
      });
    }
  };

  const requestPermissions = async (type) => {
    try {
      if (type === "voice") {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      } else {
        await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
      }
    } catch (error) {
      console.error("Error requesting media permissions:", error);
    }
  };
  const handleCallUser = (type) => {
    if (type == "voice") {
      if (me?.membership?.name == "ELITE" || me?.membership?.name == "CLUB") {
        if (
          messagingUser?.membership?.name == "ELITE" ||
          messagingUser?.membership?.name == "CLUB"
        ) {
          callService.zp
            .sendCallInvitation({
              callees: [
                {
                  userID: `${messagingUser?.id}`,
                  userName: `${messagingUser?.username}`,
                },
              ],
              callType: ZegoUIKitPrebuilt.InvitationTypeVideoCall,
              timeout: 60,
            })
            .then((res) => {
              console.warn(res);
            })
            .catch((err) => {
              console.warn(err);
            });
          dispatch(setCaller(true));
        } else {
          toast.info(t("receiverIneligible"));
        }
      } else {
        toast.info(t("callerIneligible"));
      }
    }

    if (type == "video") {
      if (me?.membership?.name == "ELITE") {
        if (messagingUser?.membership?.name == "ELITE") {
          callService.zp
            .sendCallInvitation({
              callees: [
                {
                  userID: `${messagingUser?.id}`,
                  userName: `${messagingUser?.username}`,
                },
              ],
              callType: ZegoUIKitPrebuilt.InvitationTypeVideoCall,
              timeout: 60,
            })
            .then((res) => {
              console.warn(res);
            })
            .catch((err) => {
              console.warn(err);
            });
          dispatch(setCaller(true));
        } else {
          toast.info(t("receiverIneligible"));
        }
      } else {
        toast.info(t("callerIneligible"));
      }
    }
  };
  const handleStartCall = (type, user) => {
    checkPermissions(type);
  };
  return {
    getChats,
    dispatch,
    handleUserMessage,
    onSearch,
    search,
    setSearch,
    isFav,
    toggleChatFavourite,
    setIsFav,
    getUser,
    handleStartCall,
  };
};

export default useChats;
