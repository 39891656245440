import { makeStyles } from "@mui/styles";

export const styles = makeStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    width: "100%",
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#ccc",
    marginBottom: 25,
  },
  rowView: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  view1: {
    width: "100%",
    padding: 15,
    backgroundColor: "#fff",
  },
  view11: {
    // width: "100%",
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    whiteSpace: "nowrap",
    gap: 16,
    padding: "0px 15px",
  },
  colView: {
    marginLeft: 20,
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 10,
    width: "65%",
    padding: 20,
    border: "solid",
  },
  boldTxt: {
    fontWeight: "600",
    fontSize: 14,
  },
  boldTxt1: {
    fontWeight: "600",
    fontSize: 38,
  },
  img: {
    width: 38,
    height: 38,
    objectFit: "contain",
  },
  upgradeText: {
    textDecoration: "underline",
    margin: "10px auto 0px !important",
    fontSize: "14px !important",
    color: "#011B60",
    cursor: "pointer",
  },
  memberDetailsBox: {
    backgroundColor: "#fff",
    marginTop: 15,
    height: "50vh",
    "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
    },

    "& .MuiTypography-root": {
      fontSize: "13px !important",
    },
  },
}));
