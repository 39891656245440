import env from "../env";
import { request } from "./api";

const API_URL = `${env.API_URL}`;

const URL = {
  getChats: `${API_URL}/users/chats`,
  getMessagesWithUser: (userId, limit, batch) =>
    `${API_URL}/users/${userId}/message?batch=${batch}&limit=${limit}`,
  toggleFavouriteChat: `${API_URL}/users/chats/favorite`,
};

const getChats = (token) => {
  return request(URL.getChats, {}, "GET", `Bearer ${token}`);
};

const getMessagesWithUser = (token, userId, batch = 1, limit = 500) => {
  return request(
    URL.getMessagesWithUser(userId, limit, batch),
    {},
    "GET",

    `Bearer ${token}`
  );
};

const toggleFavouriteChat = (token, chat) => {
  return request(
    URL.toggleFavouriteChat,
    { chat },
    "PATCH",

    `Bearer ${token}`
  );
};

const chatsService = { getChats, getMessagesWithUser, toggleFavouriteChat };
export default chatsService;
