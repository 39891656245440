import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import socketService from "../../../../services/socket.service";
import MessageBody from "./components/MessageBody";
import MessageFooter from "./components/MessageFooter";
import MessageHeader from "./components/MessageHeader";
import { MessageSectionStyle } from "./MessageSection.style";

const MessageSection = ({
  messagingUser,
  messagesInView,
  chats,
  setMessagesInView,
  setChats,
}) => {
  const classes = MessageSectionStyle();
  const [isTyping, setIsTyping] = useState(false);
  const [messageRef, setMessageRef] = useState(null);
  const messageInputRef = useRef(null);
  useEffect(() => {
    const onTyping = (res) => {
      if (messagingUser?.id === res.sender) {
        setIsTyping(res.status);
      } else {
        setIsTyping(false);
      }
    };
    const onReceiveMessage = (res) => {
      if (messagingUser?.id === res?.sender) {
        setMessagesInView([res?.message, ...messagesInView]);
        const newChats = chats?.map((chat) =>
          chat?.user?.id === messagingUser?.id
            ? {
                ...chat,
                last_message: res?.message,
                chat: {
                  ...chat?.chat,
                  unseen_messages: 0,
                },
              }
            : chat
        );
        const chatToMoveTop = newChats?.filter(
          (chat) => chat?.user?.id === messagingUser?.id
        );

        const otherChats = newChats?.filter(
          (chat) => chat?.user?.id !== messagingUser?.id
        );
        setChats([...chatToMoveTop, ...otherChats]);

        socketService.emit(
          "seen",
          {
            id: res?.message?.id,
          },
          (res) => {}
        );
      }
    };
    const onSeenMyMessage = (res) => {
      if (res?.status) {
        setMessagesInView(
          messagesInView?.map((message) =>
            message?.sender === true ? { ...message, seen: true } : message
          )
        );

        setChats(
          chats?.map((chat) =>
            chat?.user?.id === messagingUser?.id
              ? {
                  ...chat,
                  last_message: { ...chat?.last_message, seen: true },
                }
              : chat
          )
        );
      }
    };

    const userOpenedOurDm = (res) => {
      if (res?.id === messagingUser?.id) {
        setMessagesInView(
          messagesInView?.map((message) =>
            message?.sender === true ? { ...message, seen: true } : message
          )
        );

        setChats(
          chats?.map((chat) =>
            chat?.user?.id === messagingUser?.id
              ? {
                  ...chat,
                  last_message: { ...chat?.last_message, seen: true },
                }
              : chat
          )
        );
      }
    };

    socketService?.on("typing", onTyping);
    socketService?.on("message", onReceiveMessage);
    socketService?.on("seen", onSeenMyMessage);
    socketService?.on("open_dm", userOpenedOurDm);

    return () => {
      socketService?.off("typing", onTyping);
      socketService?.off("message", onReceiveMessage);
      socketService?.off("seen", onSeenMyMessage);
      socketService?.off("open_dm", userOpenedOurDm);
    };
  }, [messagesInView, chats]);

  return (
    <Box
      sx={{
        width: "70%",
        position: "relative",
      }}
    >
      {messagingUser && Object.keys(messagingUser).length > 0 && (
        <>
          <MessageHeader />
          <MessageBody
            setMessageRef={setMessageRef}
            messageInputRef={messageInputRef}
            isTyping={isTyping}
          />
          <MessageFooter
            messageRef={messageRef}
            messageInputRef={messageInputRef}
            setMessageRef={setMessageRef}
          />
        </>
      )}
    </Box>
  );
};

export default MessageSection;
