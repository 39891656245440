import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageContainer: {
    width: 420,
    padding: 25,
    backgroundColor: "#fff",
    borderRadius: 15,
  },
  pageBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  formBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    marginTop: 20,
  },
  header: {
    fontSize: "18px !important",
    fontWeight: "600 !important",
    color: "#0A0A12",
  },
  headerSub: {
    fontSize: "16px !important",
    fontWeight: "400 !important",
    color: "#0A0A12",
  },

  count: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    color: "#0A0A12",
    textAlign: "center",
    marginTop: "15px !important",
  },
  intWrap: {
    border: "1px solid #707070",
    width: "fit-content",
    padding: "5px 10px",
    borderRadius: 15,
    cursor: "pointer",
  },
  intText: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
  },
  intCon: {
    display: "flex",
    flexWrap: "wrap",
    gap: 15,
    justifyContent: "flex-start",
  },
}));

export default useStyles;
