import { makeStyles } from "@mui/styles";

export const DashboardStyles = makeStyles(({ theme }) => ({
  pageWrapper: {
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: "#FFEBEB",
    position: "relative",
    overflow: "hidden",
    margin: "20px 0px",
    gap: "1em",
    display: "flex",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  greetDateRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardsRow: {
    display: "grid",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    gap: "1.5em",
    gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
  },
  chartsContainer: {
    display: "grid",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 350,
    position: "relative",
    gap: "1.5em",
    gridTemplateColumns: "minmax(0, 2fr) minmax(0, 1fr)",
  },
  graphContainer: {
    backgroundColor: "#fff",
    borderRadius: 15,
    height: 350,
  },
  pieRightContainer: {
    backgroundColor: "#fff",
    borderRadius: 15,
    height: 320,
    padding: 15,
  },
  onlineRow: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    marginBottom: 10,
  },
  usersInfoRow: {
    display: "flex",
    flexDirection: "row",
  },
}));
