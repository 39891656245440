import { connect } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../redux/actions/global/global.actions";
import {
  setMyData,
  setPreviewUser,
} from "../../../../redux/actions/users/users.actions";
import LeftSideBar from "./LeftSideBar";

const mapState = ({ users }) => ({
  myData: users.myData,
});

const mapDispatchToProps = (dispatch) => ({
  setMyData: (data) => dispatch(setMyData(data)),
  setPreviewUser: (data) => dispatch(setPreviewUser(data)),
  setPopupChildren: (data) => dispatch(setPopupChildren(data)),
  togglePopup: (data) => dispatch(togglePopup(data)),
});
export default connect(mapState, mapDispatchToProps)(LeftSideBar);
