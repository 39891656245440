import { Box } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import useMyPostsEvents from "../../hoooks/useMyPostsEvents";
import EventCard from "./components/EventCard";
import { MyEventsStyles } from "./MyEvents.styles";

const MyEvents = () => {
  const classes = MyEventsStyles();
  const postEvents = useSelector((state) => state.feeds.myEvents);
  // const myData = useSelector((state) => state.users.myData);
  const { getPostEvents, gettingPostEvents } = useMyPostsEvents();
  useEffect(() => {
    getPostEvents();
  }, []);

  return (
    <Box className={classes.container}>
      {!gettingPostEvents ? (
        postEvents.length > 0 ? (
          postEvents?.map((event, i) => {
            return <EventCard key={i} event={event} />;
          })
        ) : (
          <Box sx={{ margin: "auto", width: "100%", textAlign: "center" }}>
            No Events Found
          </Box>
        )
      ) : (
        <Box sx={{ margin: "auto", width: "100%" }}>
          <Skeleton
            count={3}
            height={150}
            style={{ margin: "20px auto", width: "100%" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default MyEvents;
