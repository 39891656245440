import { makeStyles } from "@mui/styles";

export const AddTeamStyles = makeStyles(({ theme }) => ({
    Wrapper: {
        display: "flex !important",
        flexDirection: "column",
        backgroundColor: "#fff",
        width: 400,
        padding: "2rem",
        borderRadius: 15,
        border: "none",
        maxHeight: "80vh",
        overflow: "auto",
        gap:"2rem",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems:"center",
    },
    label: {
        fontSize: "13px !important",
        fontWeight: "600 !important",
        paddingLeft: 20,
        color: "#293146"
    },
    inputField: {
        backgroundColor: "#F4F5F9",
        border: "none",
        color: "#293146",
        padding:"1rem"
    },
    textFieldsWrapper: {
        display: "flex !important",
        flexDirection: "column",
        margin: "10px 0px",
        border: "none",

        "& .MuiTextField-root": {
            marginTop: "5px",
            border:"none"
        },
    },
    textFieldsContainer: {
        display: "flex",
        flexDirection: "column",
        gap:"1rem"
    },
    selectRole: {
        backgroundColor: "#F4F5F9",
        border: "none",
        color: "#293146",
        padding: "1rem",
    },
    roleOption: {
        backgroundColor: "#F4F5F9",
        padding:"1rem",
        color: "#293146",
    },
    submitButton: {
        width: "100%",
        border: "none",
        color: "white",
        padding: "1rem",
        borderRadius: "5px",
        cursor: "pointer",
        backgroundColor: "#EA0F08"
    }

}))