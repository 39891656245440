import { makeStyles } from "@mui/styles";

export const MyPostsEventsStyles = makeStyles(() => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    //   justifyContent: "space-between",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    width: "100%",
    alignSelf: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#ccc",
    marginBottom: 25,
  },
  headerItem: {
    width: "50%",
    padding: 5,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textAlign: "center",
    fontSize: 13,
    text: "#000",
  },
  headerItemSel: {
    width: "50%",
    padding: 5,
    backgroundColor: "#EA0F08",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textAlign: "center",
    fontSize: 13,
    fontWeight: "500",
  },
  headerText: {
    color: "#000",
  },
  headerText1: {
    color: "#fff",
    fontWeight: "600",
  },
  postCardStyle: {
    width: "100%",
    marginTop: 20,
  },
  postCardHeaderStyle: {
    width: "100%",
  },
  postCont: {
    overflowY: "auto",
    backgroundColor: "#fff",
    position: "relative",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  cardEvents: {
    width: "100%",
    // height: 300,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
    justifyContent: "space-between",
  },
  eventsView: {
    // width: "100%",
    // height: 300,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  imgStyle: {
    height: "100%",
    width: "30%",
  },
  imgStyle2: {
    height: 15,
    width: 15,
  },
  eventsView1: {
    marginLeft: 25,
  },
  text1: {
    fontSize: 11,
  },
  boldTxt: {
    fontWeight: "600",
  },
  rowView: {
    display: "flex",
    marginTop: 20,
    marginBottom: 10,
    alignItems: "center",
    flexDirection: "row",
  },
}));
