import { makeStyles } from "@mui/styles";

export const CustomTableStyles = makeStyles((theme) => ({
  tableWrapper: {
    position: "relative",
    width: "100%",
    overflow: "auto",
    backgroundColor: "#fff",
    // height: "50vh",
    "& .MuiTableRow-head": {
      borderBottom: "none",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  table: {
    width: "100%",
    fontSize: 12,
    color: "blue",
    "& th": {
      fontSize: 12,
      padding: 15,
      paddingLeft: 20,
      paddingRight: 12,
      lineHeight: "15px",
      fontWeigth: "400",
      border: 0,
      backgroundColor: "#F4F5F9",
    },
    "& .MuiTableRow-root.MuiTableRow-hover:hover": {},
  },
  tableHeadCell: {
    fontWeight: 500,
    paddingLeft: 17,
    paddingRight: 20,
    backgroundColor: "#105FFB",
    "& .sortBtn": {
      marginLeft: 10,
      marginTop: -2,
      borderRadius: 2,
      opacity: 0.15,
      transition: "all 200ms linear",
    },
    "&:hover .sortBtn": {
      opacity: 1,
    },
  },
  tableCell: {
    padding: "0px !important",
    fontSize: "1em !important",
    justifyContent: "flex-start",
    paddingLeft: "0px !important",
    fontWeight: 100,
    position: "relative",
    border: 0,
  },
  tableHeadName: {
    display: "flex",
    color: "#fff",
  },
  divider: {
    height: 10,
    backgroundColor: "red",
  },
  tbRow: {
    backgroundColor: "green",
    boxShadow: "0px 0px 17px rgba(0,0,0,0.01)",

    "&.cursor-pointer": {
      cursor: "pointer",
    },
  },
  rowButton: {
    width: "100%",
  },
  tableBody: {
    "&.MuiTableBody-root": {
      overflowY: "scroll !important",
    },
  },
}));
