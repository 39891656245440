import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { Avatar, Box, IconButton } from "@mui/material";
import UserImgComponent from "../../../../../../../../../components/UserImgComponent";
import useAccountProfile from "../../../../../../../../Settings/components/AccountProfile/hooks/useAccountProfile";
import { RotatingLines } from "react-loader-spinner";
import { PlayCircle, VideoCall } from "@mui/icons-material";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../../../../../../redux/actions/global/global.actions";
import MediaPreview from "../../../../../../../../../components/MediaPreview";
import { styles } from "./ImageSection.style";

export const ImageSection = (props) => {
  const classes = styles();
  const { previewUser } = useSelector((state) => state.users);
  console.log(previewUser);
  const [data, setData] = useState({});
  const { fileToAccept, setFileToAccept, setImgType, open, loading, dispatch } =
    useAccountProfile();

  useEffect(() => {
    setData({
      images: previewUser?.images,
      vid: previewUser?.vid,
    });
  }, [previewUser]);

  useEffect(() => {
    fileToAccept && open();
    return () => {
      setFileToAccept(null);
      setImgType("");
    };
  }, [fileToAccept]);

  // if (!data) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className={classes.view1}>
      <Box className={classes.otherImagesWrap}>
        {loading && (
          <Box className={classes.loaderContainer}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </Box>
        )}

        {data?.vid ? (
          <Box
            className={classes.imgBox}
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                setPopupChildren(
                  <MediaPreview file={{ url: data?.vid }} fileType="video" />
                )
              );
              dispatch(togglePopup(true));
            }}
          >
            <PlayCircle
              style={{
                position: "absolute",
                top: "40%",
                bottom: "40%",
                left: " 40%",
                right: "40%",
                width: 50,
                height: 50,
                opacity: 0.7,
              }}
            />
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: 5,
              }}
              controls={false}
              onClick={() => console.log("clicked")}
            >
              <source src={data?.vid} />
            </video>
          </Box>
        ) : (
          <IconButton
            onClick={() => {
              setFileToAccept("video");
            }}
            className={classes.imgBox}
            sx={{
              border: "1px dashed",
              borderRadius: 1,
            }}
          >
            <VideoCall
              sx={{
                width: "50px",
                height: "50px",
                transform: "translateY(10px)",
                // color: "#bdbdbd",
                color: "#707070",
                opacity: 0.7,
              }}
            />
          </IconButton>
        )}

        {data?.images?.filter((image, i) => image?.isMain).length > 0 ? (
          <Box
            className={classes.imgBox}
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                setPopupChildren(
                  <MediaPreview
                    file={data?.images?.filter((image) => image?.isMain)[0]}
                    fileType="image"
                  />
                )
              );
              dispatch(togglePopup(true));
            }}
          >
            <UserImgComponent
              src={data?.images?.filter((image) => image?.isMain)[0]?.url}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: 5,
                border: "2px solid red",
              }}
            />
          </Box>
        ) : (
          <IconButton
            onClick={() => {
              setFileToAccept("image");
              setImgType("main");
            }}
            className={classes.imgBox}
            sx={{
              border: "1px dashed",
              borderRadius: 1,
            }}
          >
            <Avatar src="" className={classes.secImage} />
          </IconButton>
        )}
        {data?.images
          ?.filter((image) => !image?.isMain)
          ?.map((item, i) => (
            <Box
              key={i}
              className={classes.imgBox}
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(
                  setPopupChildren(
                    <MediaPreview file={item} fileType="image" />
                  )
                );
                dispatch(togglePopup(true));
              }}
            >
              <UserImgComponent
                src={item?.url}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 5,
                }}
              />
            </Box>
          ))}
        {!data.images ||
        data?.images?.filter((image) => !image?.isMain)?.length < 4 ? (
          <IconButton
            className={classes.imgBox}
            sx={{
              borderWidth: 1,
              borderStyle: "dashed",
              borderRadius: 1,
            }}
            onClick={() => {
              setFileToAccept("image");
              setImgType("others");
            }}
          >
            <AddIcon size={16} />
          </IconButton>
        ) : null}
      </Box>
    </div>
  );
};


