import { createTheme, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import AppLayout from "./layout/AppLayout";
import AuthLayout from "./layout/AuthLayout";
import SplashScreen from "./layout/AuthLayout/components/SplashScreen";
import Feeds from "./pages/Feeds";
import configureStore from "./redux/store";
import Authentication from "./pages/Authentication";
import Settings from "./pages/Settings";
import Discover from "./pages/Discover";
import "react-loading-skeleton/dist/skeleton.css";
import Nearby from "./pages/Nearby";
import Chats from "./pages/Chats";
import "react-dropdown/style.css";
import { AccountProfile } from "./pages/Settings/components/AccountProfile";
import { Wallet, Membership } from "./pages/Settings/components";
import Matches from "./pages/Matches";
import Activities from "./pages/Activities";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ConfirmProvider } from "material-ui-confirm";
import MyPostsEvents from "./pages/Settings/components/MyPostsEvents/MyPostsEvents";
import MyPosts from "./pages/Settings/components/MyPostsEvents/components/MyPosts/MyPosts";
import MyEvents from "./pages/Settings/components/MyPostsEvents/components/MyEvents";
import ManageLocation from "./pages/Settings/components/ManageLocation";
import Security from "./pages/Settings/components/Security";
import BlockedAccounts from "./pages/Settings/components/Security/components/BlockedAccounts";
import Onboarding from "./pages/Onboarding";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ReferEarn from "./pages/Settings/components/ReferEarn";
import PaymentCheckout from "./pages/PaymentCheckout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Notification from "./components/Notification/Notification";
import { Suspense, useEffect } from "react";
import { getLocalLang, getUser, setLocalLang } from "./utils/helpers";
import i18next from "i18next";
import Dashboard from "./pages/Admin/Dasboard";
import Revenue from "./pages/Admin/Revenue";
import Users from "./pages/Admin/Users";
import Referrals from "./pages/Admin/Referrals";
import AdminSettings from "./pages/Admin/AdminSettings";
import ManageProfile from "./pages/Admin/AdminSettings/components/ManageProfile";
import ManageTeams from "./pages/Admin/AdminSettings/components/ManageTeams";
import { Edit } from "./pages/Admin/Users/components";
import {
  Posts,
  Events,
  AdminWallet,
  Profile,
  PostsEvents,
} from "./pages/Admin/Users/components/Edit";

function App() {
  const store = configureStore();
  const theme = createTheme({
    typography: {
      fontFamily: "Avenir",
    },
  });

  useEffect(() => {
    window.addEventListener("resize", () => {
      // console.log("width >>", window.innerWidth);
      // console.log("height >>", window.innerHeight);
    });
  }, []);

  const promise = loadStripe(
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_STRIPE_PUB_KEY_LIVE
      : process.env.REACT_APP_STRIPE_PUB_KEY_LIVE
  );

  const lang = getLocalLang();
  const browserLangs = navigator.languages;
  if (!lang) {
    browserLangs.forEach((lang) => {
      if (["en", "it", "pt", "es", "fr", "de"].includes(lang)) {
        // setLocalLang(lang)
        i18next.changeLanguage(lang);
      } else {
        i18next.changeLanguage("en");
      }
    });
  } else {
    i18next.changeLanguage(lang);
  }

  const user = getUser();

  return (
    <Suspense fallback={null}>
      <Provider store={store}>
        <Notification />
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Elements stripe={promise}>
              <ConfirmProvider>
                <Router>
                  <Routes>
                    <Route path="/" element={<SplashScreen />} />
                    <Route path="/admin" element={<SplashScreen />} />
                    <Route
                      path={"auth"}
                      element={
                        <AuthLayout>
                          <Outlet />
                        </AuthLayout>
                      }
                    >
                      <Route exact path=":page" element={<Authentication />} />
                    </Route>
                    <Route
                      path={"admin/auth"}
                      element={
                        <AuthLayout>
                          <Outlet />
                        </AuthLayout>
                      }
                    >
                      <Route exact path=":page" element={<Authentication />} />
                    </Route>
                    <Route path="/onboarding/:page" element={<Onboarding />} />
                    <Route
                      element={
                        <AppLayout>
                          <Outlet />
                        </AppLayout>
                      }
                    >
                      {/* {user?.role == "superadmin" && ( */}
                      <Route path="/admin">
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="revenue" element={<Revenue />} />
                        <Route>
                          <Route path="users" element={<Users />} />
                          <Route
                            element={
                              <Edit>
                                <Outlet />
                              </Edit>
                            }
                          >
                            <Route
                              path="users/edit/:userId"
                              element={<Profile />}
                            />
                            <Route
                              path="users/edit/:userId/profile"
                              element={<Profile />}
                            />
                            <Route element={<PostsEvents />}>
                              <Route
                                path="users/edit/:userId/posts-events/"
                                element={<Posts />}
                              />
                              <Route
                                path="users/edit/:userId/posts-events/posts"
                                element={<Posts />}
                              />
                              <Route
                                path="users/edit/:userId/posts-events/events"
                                element={<Events />}
                              />
                            </Route>
                            <Route
                              path="users/edit/:userId/admin-wallet"
                              element={<AdminWallet />}
                            />
                          </Route>
                        </Route>
                        <Route path="referrals" element={<Referrals />} />
                        <Route
                          element={
                            <AdminSettings>
                              <Outlet />
                            </AdminSettings>
                          }
                        >
                          <Route path="settings" element={<ManageProfile />} />
                          <Route
                            path="settings/profile"
                            element={<ManageProfile />}
                          />
                          <Route
                            path="settings/teams"
                            element={<ManageTeams />}
                          />
                        </Route>
                      </Route>
                      {/* )} */}

                      <Route exact path="/feeds" element={<Feeds />} />
                      <Route exact path="/discover" element={<Discover />} />
                      <Route exact path="/nearby" element={<Nearby />} />
                      <Route exact path="/matches" element={<Matches />} />
                      <Route exact path="/chats" element={<Chats />} />
                      <Route
                        exact
                        path="/activities"
                        element={<Activities />}
                      />

                      <Route
                        element={
                          <Settings>
                            <Outlet />
                          </Settings>
                        }
                      >
                        <Route path="/settings" element={<AccountProfile />} />
                        <Route
                          path="/settings/posts-events"
                          element={
                            <MyPostsEvents>
                              <Outlet />
                            </MyPostsEvents>
                          }
                        >
                          <Route index element={<MyPosts />} />

                          <Route
                            path="/settings/posts-events/posts"
                            element={<MyPosts />}
                          />
                          <Route
                            path="/settings/posts-events/events"
                            element={<MyEvents />}
                          />
                        </Route>
                        <Route path="/settings/wallet" element={<Wallet />} />
                        <Route
                          path="/settings/membership"
                          element={<Membership />}
                        />
                        <Route path="/settings/refer" element={<ReferEarn />} />
                        <Route
                          path="/settings/manage_location"
                          element={<ManageLocation />}
                        />
                        <Route
                          path="/settings/security"
                          element={<Security />}
                        />
                        <Route
                          path="/settings/security/blocked_accounts"
                          element={<BlockedAccounts />}
                        />
                        {/* <Route path="/wallet" element={<AccountProfile/> }/> */}
                      </Route>
                    </Route>
                    <Route
                      path="/payment_checkout"
                      element={<PaymentCheckout />}
                    />
                    {/* <Route path="*" element={<NotFound />} /> */}
                  </Routes>
                </Router>
              </ConfirmProvider>
            </Elements>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </Suspense>
  );
}

export default App;
