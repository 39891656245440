import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onMessageListener } from "../../firebase";
import { ToastContainer } from "react-toastify";

const Notification = () => {
  const [notification, setNotification] = useState({ title: "", body: "" });

  function ToastDisplay() {
    return (
      <div>
        <p>
          <b>{notification?.title}</b>
        </p>
        <p>{notification?.body}</p>
      </div>
    );
  }

  useEffect(() => {
    if (notification?.title) {
      toast.info(<ToastDisplay />);
    }
  }, [notification]);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  return <ToastContainer autoClose={2500} pauseOnHover={true} />;
};

export default Notification;
