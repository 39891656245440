import { makeStyles } from "@mui/styles";

export const EventsCardStyles = makeStyles(() => ({
  container: {
    display: "flex !important",
    flexDirection: "row",
    marginTop: 5,
    height: 120,
    backgroundColor: "#fff",
  },
  eventImgContainer: {
    width: "20%",
    height: 120,
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  eventDetails: {
    display: "flex !important",
    flexDirection: "column",

    padding: 10,
    width: "80%",
    justifyContent: "space-between",
  },
  infoTopTitle: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex !important",
    flexDirection: "row",
  },
  statusInfo: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  locationInfo: {
    flexDirection: "row",
    alignItems: "center",
  },
  locationText: {
    fontSize: "13px !important",
    width: "90%",
  },
  eventDetailsWrapper: {
    alignItems: "center",
    fontStyle: "italic",
    marginBottom: 20,
    display: "flex !important",
  },
  dropdown: {
    cursor: "pointer",
    position: "relative",
    height: 20,
    transform: "translateY(0px)",
    marginLeft: 15,
    marginRight: 5,

    "& img ": {
      height: "100%",
    },
  },
  noDisplay: {
    display: "none",
  },
  dropdownContent: {
    position: "absolute",
    top: 30,
    right: -15,
    width: 120,
    overflow: "auto",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    backgroundColor: "#fff",
    zIndex: 100,
  },
  dropContent: {
    fontWeight: " 500 !important",
    fontSize: "12px !important",
    padding: "15px 25px 15px 35px",

    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",

    "&: hover": {
      backgroundColor: "#dadde1",
    },
  },
  cardImage: {
    width: "100%",
    height: 120,
    objectFit: "contain",
    background: "#f3f3f3",
  },
  file: {
    width: "100%",
    height: 120,
    objectFit: "contain",
    background: "#f3f3f3",
  },
}));
