import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Body, Header } from "./components";
import useSettings from "./hooks/useSettings";
import { styles } from "./style";

const Settings = ({ myData, children }) => {
  const classes = styles();

  const { getMe } = useSettings();
  // useEffect(() => {
  //   !myData && getMe();
  // }, [myData]);

  return (
    <div className={classes.container}>
      <div className={classes.view1}>
        <Header />
        <Body>{children}</Body>
      </div>
    </div>
  );
};

export default Settings;
