import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useStyles } from "./ModeCard.styles";

function ModeCard({ mode, selectedMode, setSelectedMode, pad }) {
  const classes = useStyles();

  return (
    <Box
      className={classes.modeWrap}
      style={{
        backgroundColor: selectedMode === mode?.value ? "#FFE7E6" : "#E8E8E8",
        padding: pad,
      }}
      onClick={() => setSelectedMode(mode?.value)}
    >
      {mode?.logo}
      <Box className={classes.modeInfo}>
        <Typography className={classes.titleText}>{mode?.title}</Typography>
        <Typography className={classes.descriptionText}>
          {mode?.description}
        </Typography>
      </Box>
    </Box>
  );
}

export default ModeCard;
