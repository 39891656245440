import React from "react";
import { usePremiumStyle } from "./Premium.style";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { ReactComponent as CheckedIcon } from "../../../../../../assets/icons/checked-white.svg";
import { ReactComponent as Coins } from "../../../.././../../assets/icons/coins.svg";
import { ReactComponent as PremiumImg } from "../../../.././../../assets/icons/premium.svg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useConstants from "../../../../../../utils/useConstants";
import useMembershipConstants from "../../../../../Settings/components/Membership/useMembershipConstants";
import clubBadge from "../../../.././../../assets/images/club.png";
import eliteBadge from "../../../.././../../assets/images/elite.png";
import liteBadge from "../../../.././../../assets/images/lite.png";
import { useDispatch } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../../../redux/actions/global/global.actions";
import Memberships from "../../../../../../components/Memberships";

export default function Premium() {
  const classes = usePremiumStyle();
  const [currentPrice, setCurrentPrice] = useState(0);
  const { t } = useTranslation();
  const { memberships } = useConstants();
  const [currentPlan, setCurrentPlan] = useState(null);

  const [selected, setSelected] = useState(1);
  const { litePlanList, elitePlanList, clubPlanList } =
    useMembershipConstants();
  const dispatch = useDispatch();

  const pricing = [
    {
      period: t("lite"),
      price: "2 M8S Doins",
      benefits: [
        t("smartMatch50"),
        t("instantMessaging"),
        t("oneCountryLocation"),
        t("voiceAllowed"),
      ],
    },
    {
      period: "3 Day",
      price: "5 M8S Doins",
      benefits: [
        t("smartMatch80"),
        t("seeLikes"),
        t("instantMessaging"),

        t("unlimitedMatch"),
        t("voiceAudioEnabled"),
        t("twoCountryLocation"),
      ],
    },
    {
      period: "1 Week",
      price: "2 M8S Doins",
      benefits: [
        t("smartMatch80"),
        t("seeLikeDiscovery"),
        t("instantMessaging"),

        t("unlimitedMatch"),
        t("voiceAudioEnabled"),
        t("videoAllowed"),
        t("accessAll"),
      ],
    },
  ];
  return (
    <Box className={classes.Wrapper}>
      <Box className={classes.header}>
        <Box>
          <Box className={classes.headerImg}>
            <PremiumImg />
          </Box>
          <Box className={classes.headerContent}>
            <Typography className={classes.headerTitle}>
              {t("goPremium")}
            </Typography>
            <Typography className={classes.headerBody}>
              {t("selectPlanMonth")}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.headerBtnContainer}>
          <Button
            onClick={() => {
              dispatch(togglePopup(true));
              dispatch(setPopupChildren(<Memberships />));
            }}
            className={classes.UpgradeBtn}
          >
            {t("upgradePlan")}
          </Button>
        </Box>
      </Box>
      <Box className={classes.pricing}>
        {memberships.map((membership, index) => {
          return (
            <Box
              className={
                index === currentPrice ? classes.active : classes.inactive
              }
              onClick={() => setCurrentPrice(index)}
            >
              <Box>
                <Typography className={classes.period}>
                  {membership.name}
                </Typography>
                <Typography className={classes.price}>
                  {"\u0024"}
                  {membership?.price?.unit_amount} /{" "}
                  {membership?.price?.recurring?.interval}
                </Typography>
              </Box>
              <Box>
                {membership?.id === 3 && (
                  <img
                    alt={"elite"}
                    src={eliteBadge}
                    style={{ width: 35, height: 45 }}
                  />
                )}
                {membership?.id === 2 && (
                  <img
                    alt="club"
                    src={clubBadge}
                    style={{ width: 35, height: 45 }}
                  />
                )}
                {membership?.id === 1 && (
                  <img
                    alt="lite"
                    src={liteBadge}
                    style={{ width: 35, height: 45 }}
                  />
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box className={classes.Footer}>
        <Typography className={classes.FooterTitle}>
          Why you get with this plan
        </Typography>
        <Box className={classes.benefits}>
          {pricing[currentPrice].benefits.map((benefit) => (
            <Box className={classes.benefit}>
              <CheckedIcon />
              <Box component={"span"} style={{ marginLeft: 8, fontSize: 14 }}>
                {benefit}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
