import { makeStyles } from "@mui/styles";

export const SecurityStyles = makeStyles((theme) => ({
  pageWrap: {
    display: "flex !important",
    flexDirection: "column",
    gap: 8,
  },
  section: {
    height: 30,
    backgroundColor: "#fff",
    padding: 20,
    cursor: "pointer",
  },
  subText: {
    fontSize: "13px !important",
  },
}));
