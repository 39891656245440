import React from "react";
import { Avatar, Typography } from "@mui/material";
import useStyles from "./YourStoryNode.styles";
import useFeeds from "../../hooks/useFeeds";
import { Circles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { setStoryModal } from "../../../../redux/actions/global/global.actions";
import Poster from "../../../../assets/images/vidPost.png";
import { videoExts } from "../../../../utils/constants";
import { useTranslation } from "react-i18next";

function YourStoryNode({ story, avatar }) {
  const classes = useStyles();
  const reduxDispatch = useDispatch();
  const { removeStory, state, onMakeStory } = useFeeds();
  const { isDeleting } = state;

  const showModal = useSelector((state) => state.global.storyModal);
  const { t } = useTranslation();
  return (
    <div className={classes.storyNode}>
      <div
        className={story?.length > 0 ? classes.node : classes.noNode}
        onClick={() => reduxDispatch(setStoryModal(!showModal))}
        style={{ position: "relative" }}
      >
        <Avatar src={avatar} alt="story" className={classes.nodeAvatar} />
      </div>
      <div className={classes.outer} onClick={onMakeStory}>
        <div className={classes.inner}>
          <span>&#43;</span>
        </div>
      </div>
      {!!showModal && story?.length > 0 && (
        <div className={classes.nodeCon}>
          <div className={classes.nodeWrap}>
            {story?.map((item) => {
              const isType = videoExts.includes(
                `.${
                  item?.media.split("/").pop().split(".").pop().split("?")[0]
                }`
              );

              return (
                <div className={classes.noNode}>
                  <Avatar
                    src={isType ? Poster : item?.media}
                    alt="story"
                    className={classes.nodeAvatar}
                  />
                  <span
                    className="deleteIcon"
                    onClick={() => {
                      removeStory(item?.id);
                      // setIsShow(false);
                    }}
                  >
                    x
                  </span>
                  {isDeleting && (
                    <Circles
                      color="red"
                      ariaLabel="circles-loading"
                      wrapperClass={classes.topSpinner}
                      visible={true}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <Typography className={classes.title}>{t("yourStory")}</Typography>
    </div>
  );
}

export default YourStoryNode;
