import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  storyNode: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },

  node: {
    height: 58,
    width: 58,
    borderRadius: "50%",
    overflow: "hidden",
    outline: "2px solid #EA0F07",
    border: "4px solid #fff",
    cursor: "pointer",
  },
  nodeCon: {
    maxWidth: "calc(100vw - 580px)",
    background: "#e74c3c",
    padding: "10px 10px 20px 10px",
    position: "absolute",
    top: -10,
    left: 80,
    zIndex: 500,
    borderRadius: 10,
    transition: "all .5s ease",
    overflowX: "scroll",
    overflow: "hidden",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },
  nodeWrap: {
    height: "100%",
    display: "inline-flex",
    gap: 20,
    alignItems: "center",
    overflow: "hidden",
  },
  noShow: {
    display: "none",
    opacity: 0,
    transition: "all .5s ease",
  },
  noNode: {
    height: 68,
    width: 68,
    borderRadius: "50%",
    position: "relative",

    "& .deleteIcon": {
      background: "#fff",
      width: 25,
      height: 20,
      paddingBottom: 4,
      borderRadius: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 22,
      fontWeight: 500,
      position: "absolute",
      right: 0,
      bottom: -10,
      transform: "translateY(-10px)",
      color: "#e74c3c",
      cursor: "pointer",
    },
  },
  seen: {
    height: 58,
    width: 58,
    borderRadius: "50%",
    overflow: "hidden",
    outline: "2px solid #d0d0d0",
    border: "4px solid #fff",
    cursor: "pointer",
  },
  nodeAvatar: {
    height: "100% !important",
    width: "100% !important",
    objectFit: "cover !important",
  },

  addStory: {
    position: "absolute",
    right: 0,
    top: 47,
    height: 25,
    width: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: 22,
    fontWeight: 500,
    borderRadius: "50%",
    overflow: "hidden",
    backgroundColor: "#FFAC09",

    "& span": {
      transform: "translateY(-.5px)",
      cursor: "pointer",
    },
  },
  title: {
    fontWeight: " 500 !important",
    fontSize: "14px !important",
    color: "#000",
    textAlign: "center",
  },
  topSpinner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#000",
    opacity: 0.5,
    borderRadius: 50,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  outer: {
    width: 25,
    height: 25,
    borderRadius: "50%",
    backgroundColor: "#FFD112",
    position: "absolute",
    top: "50%",
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  inner: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    backgroundColor: "#FFA000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& span": {
      color: "#fff",
      fontSize: 15,
      fontWeight: 500,
    },
  },
}));

export default useStyles;
