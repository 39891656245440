import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import UserImgComponent from "../../components/UserImgComponent";
import { ActivitiesStyles } from "./Activities.styles";
import ActivitiesHeader from "./components/ActivitiesHeader";
import useActivities from "./hooks/useActivities";
import CustomButton from "../../components/CustomButton";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { usernameStringCheck } from "../../utils/helpers";
import activitiesBanner from "../../assets/images/activities_banner.jpg";
import { useTranslation } from "react-i18next";

const Activities = ({ myActivities, myData }) => {
  const classes = ActivitiesStyles();
  const {
    getMyActivities,
    loadingActivities,
    wantsToAttendEvent,
    onAcceptRequest,
    removeActivity,
  } = useActivities();
  const { t } = useTranslation();
  const message = (item) => {
    const splitString = item?.message?.split(" ");
    let newWord = [];

    for (var i = 0; i < splitString?.length; i++) {
      if (splitString[i]?.charAt(0) === "@") {
        newWord?.push(
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: 12,
              color: "#000",
              marginLeft: 3,
            }}
          >
            {" "}
            <span style={{ color: "#ADABAB" }}>@</span>
            {usernameStringCheck(splitString[i])}
          </Typography>
        );
      } else {
        newWord?.push(` ${splitString[i]}`);
      }
    }

    return newWord;
  };

  const [selectedActivity, setSelectedActivity] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onOpenMenu = (event, id) => {
    setSelectedActivity(id);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSelectedActivity(null);
    setAnchorEl(null);
  };

  useEffect(() => {
    !myActivities?.length && getMyActivities();
  }, []);

  return (
    <Box className={classes.pageWrapper}>
      <ActivitiesHeader />
      <Box className={classes.activitiesMainWrap} sx={{ display: "flex" }}>
        <Box className={classes.activitiesLeft}>
          <img
            src={activitiesBanner}
            alt="banner"
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        </Box>
        <Box className={classes.activitiesRight}>
          {loadingActivities ? (
            <Box>
              <Skeleton
                count={10}
                height={70}
                style={{ margin: "5px 15px", width: "97%" }}
              />
            </Box>
          ) : myActivities?.length > 0 ? (
            myActivities?.map((item, i) => (
              <Box
                key={i}
                style={{
                  backgroundColor: "#fff",
                  width: "99%",
                  borderBottom: "1px solid #D7E2F2",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  style={{
                    width: "100%",
                    padding: "10px 0px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Box
                    // onPress={() => {
                    //   getUser(item?.source?.id);
                    //   navigation.navigate('preview-user-screen');
                    // }}
                    sx={{ display: "flex" }}
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: "100%",
                      justifyContent: "center",
                      //overflow: "hidden",
                      alignItems: "center",
                      marginRight: 10,
                      zIndex: 0,
                      position: "relative",
                    }}
                  >
                    <UserImgComponent
                      src={
                        item?.source?.id ? item?.source?.avatar : myData?.avatar
                      }
                      className={classes.userImg}
                    />
                    <Box
                      sx={{ dispaly: "flex", zIndex: 5 }}
                      style={{
                        width: 15,
                        height: 15,
                        borderRadius: 15,
                        backgroundColor: "red",
                        borderColor: "#fff",
                        borderWidth: 2,
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        zIndex: 5,
                        border: "0.5px solid #fff",
                      }}
                    />
                  </Box>
                  <Box
                    style={{
                      width: "43%",
                      flex: 1,
                      bottom: 3,
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                        margin: "5px 0px",
                      }}
                    >
                      {item?.source?.username ? (
                        <span style={{ color: "#ADABAB" }}>@</span>
                      ) : null}
                      {usernameStringCheck(item?.source?.username)}
                    </Typography>
                    <Typography wrap className={classes.message}>
                      {message(item)}
                    </Typography>
                    <Typography noWrap className={classes.message}>
                      {" "}
                      {moment(item.createdAt).fromNow()}
                    </Typography>
                  </Box>
                  {wantsToAttendEvent(item) && (
                    <CustomButton
                      style={{
                        backgroundColor: "#06C439",
                        color: "#fff",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingHorizontal: 10,
                        paddingVertical: 5,
                        margin: "auto",
                        width: 100,
                        height: 30,
                        borderRadius: 1,
                        borderStyle: "none",
                      }}
                      onClick={() =>
                        item?.reference === "post" &&
                        item?.referenceId &&
                        onAcceptRequest(
                          item?.source?.id,
                          item.referenceId,
                          item.id
                        )
                      }
                    >
                      <Typography style={{ fontSize: 12, color: "#fff" }}>
                        {t("accept")}
                      </Typography>
                    </CustomButton>
                  )}
                  <IconButton
                    id="demo-positioned-button"
                    aria-controls={"demo-positioned-menu"}
                    aria-haspopup="true"
                    aria-expanded={"true"}
                    onClick={(e) => onOpenMenu(e, item?.id)}
                  >
                    <MoreVertOutlinedIcon style={{ color: "#00000045" }} />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    open={selectedActivity === item?.id ? true : false}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem onClick={() => removeActivity(item?.id)}>
                      {t("delete")}
                    </MenuItem>
                  </Menu>

                  {/* <Popover
                    offset={9}
                    trigger={(triggerProps) => {
                      return (
                        <IconButton
                          {...triggerProps}
                          icon={
                            <Icon
                              as={MaterialIcons}
                              name="more-vert"
                              color="#00000045"
                              size={25}
                              fontWeight="100"
                            />
                          }
                        />
                      );
                    }}
                  >
                    <Popover.Content
                      width="32"
                      rounded="xl"
                      mr="3"
                      accessibilityLabel="Remove"
                    >
                      <Popover.Body p="0">
                        <VStack
                          py="3"
                          space="3"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <TouchableOpacity
                            onPress={() => removeActivity(item?.id)}
                          >
                            <Text>Remove</Text>
                          </TouchableOpacity>
                        </VStack>
                      </Popover.Body>
                    </Popover.Content>
                  </Popover> */}
                </Box>
              </Box>
            ))
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "75vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>No activity found</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Activities;
