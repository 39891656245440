import env from "../env";
import { request } from "./api";

const API_URL = `${env.API_URL}`;

const URL = {
  profile: `${API_URL}/users/profile`,
  mode: `${API_URL}/users/mode`,
  interest: `${API_URL}/users/interest`,
  membership: (id) => `${API_URL}/users/membership/${id}`,
};

const setMembership = (token, id) => {
  return request(URL.membership(id), {}, "POST", `Bearer ${token}`);
};
const setProfile = (
  token,
  first_name,
  last_name,
  username,
  gender,
  sexuality,
  birthday
) => {
  return request(
    URL.profile,
    { first_name, last_name, username, gender, sexuality, birthday },
    "POST",
    `Bearer ${token}`
  );
};
const setMode = (token, mode) => {
  return request(URL.mode, { mode }, "POST", `Bearer ${token}`);
};
const setInterests = (token, interests) => {
  return request(URL.interest, { interests }, "POST", `Bearer ${token}`);
};

const onboardingService = {
  setProfile,
  setMembership,
  setMode,
  setInterests,
};
export default onboardingService;
