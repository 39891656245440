import { AccountCircle, ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const ChartCard = ({
  icon,
  title,
  description,
  figure,
  trend,
  trendValue,
  data,
  dataKey,
  leftSide,
  rightSide,
  middleSide,
}) => {
  // const data = [
  //   {
  //     name: "Page A",
  //     uv: 4000,
  //     pv: 2400,
  //     amt: 2400,
  //   },
  //   {
  //     name: "Page B",
  //     uv: 3000,
  //     pv: 1398,
  //     amt: 2210,
  //   },
  //   {
  //     name: "Page C",
  //     uv: 2000,
  //     pv: 9800,
  //     amt: 2290,
  //   },
  //   {
  //     name: "Page D",
  //     uv: 2780,
  //     pv: 3908,
  //     amt: 2000,
  //   },
  //   {
  //     name: "Page E",
  //     uv: 1890,
  //     pv: 4800,
  //     amt: 2181,
  //   },
  //   {
  //     name: "Page F",
  //     uv: 2390,
  //     pv: 3800,
  //     amt: 2500,
  //   },
  //   {
  //     name: "Page G",
  //     uv: 3490,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  // ];
  return (
    <Box
      style={{
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 15,
        gap: 10,
        display: "flex",
        flexDirection: "column",
        width: "220px",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        {icon}
        <Typography style={{ fontWeight: 500, fontSize: 16 }}>
          {title}
        </Typography>
      </Box>
      {leftSide && rightSide ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box style={{ gap: 10, textAlign: "center" }}>
            {leftSide?.description}
            {leftSide?.figure}
          </Box>
          <Box style={{ gap: 10, textAlign: "center" }}>
            {middleSide?.description}
            {middleSide?.figure}
          </Box>
          <Box style={{ gap: 10, textAlign: "center" }}>
            {rightSide?.description}
            {rightSide?.figure}
          </Box>
        </Box>
      ) : (
        <>
          <Typography style={{ fontSize: 13, color: "#ADABAB" }}>
            {description}
          </Typography>
          <Box
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            <Typography style={{ fontSize: 22, fontWeight: 500 }}>
              {figure}
            </Typography>
            {/* {trend ? (
          trend == "positive" ? (
            <ArrowDropUp style={{ color: "#00E38C" }} />
          ) : (
            <ArrowDropDown style={{ color: "#EA0F08" }} />
          )
        ) : null} */}

            {/* <Typography
          style={{
            color: trend == "positive" ? "#00E38C" : "#EA0F08",
            fontSize: 11,
          }}
        >
          {trend == "positive" ? "+" : trend == "negative" ? "-" : ""}
          {trendValue}
        </Typography> */}
          </Box>
        </>
      )}

      <Box
        style={{
          height: "100%",
          width: "100%",
          minHeight: 50,
        }}
      >
        <ResponsiveContainer width="100%" height={70}>
          <LineChart
            data={data}
            //   width={210}
            //   height={100}
            margin={{ top: 55, right: 0, left: 0, bottom: 0 }}
          >
            <XAxis dataKey={"month"} hide={true} />
            <Tooltip />
            {leftSide && rightSide ? (
              <>
                <Line
                  type="linear"
                  dataKey={"males"}
                  stroke="#000"
                  dot={false}
                />
                <Line
                  type="linear"
                  dataKey={"females"}
                  stroke="#EA0F08"
                  dot={false}
                />
              </>
            ) : trend != "positive" ? (
              <Line
                type="linear"
                dataKey={dataKey ? dataKey : "count"}
                stroke="#00E38C"
                yAxisId={0}
                dot={false}
              />
            ) : (
              <Line
                type="linear"
                dataKey={dataKey ? dataKey : "count"}
                stroke="#EA0F08"
                yAxisId={1}
                dot={false}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default ChartCard;
