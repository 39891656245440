import { Box, Typography } from "@mui/material";
import React from "react";
import { DashboardStyles } from "./Dashboard.style";
import { AccountCircle, PriceChange, Report } from "@mui/icons-material";
import ChartCard from "../../../components/ChartCard";
import {
  Area,
  AreaChart,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import AdminGlobalHeader from "../../../components/AdminGlobalHeader";
import useAdminDashboard from "./hook/useAdminDashboard";
import useAdmin from "../hooks/useAdmin";

const Dashboard = () => {
  const classes = DashboardStyles();

  const { dashboardData, isLoading } = useAdminDashboard();
  const { sortDataByMonthAndYear } = useAdmin();
 

  if (isLoading) {
    return <div>Loading</div>;
  }
  const usersData = [
    {
      name: "Men",
      value: dashboardData.online.totalMalesCount,
      color: "#FFA6A3",
    },
    {
      name: "Women",
      value: dashboardData.online.totalFemalesCount,
      color: "#EA0F08",
    },
  ];

  const data = [...dashboardData.users.groupedByDateRange];
  const sortedData = sortDataByMonthAndYear(data);
  const sortedUsersData = sortDataByMonthAndYear(
    dashboardData.users.groupedByDateRange
  );
  const sortedReportData = sortDataByMonthAndYear(
    dashboardData.reports.groupedByDateRange
  );
  const sortedRevenuesData = sortDataByMonthAndYear(
    dashboardData.revenue.groupedByDateRange
  );
 

  return (
    <Box className={classes.pageWrapper}>
      <AdminGlobalHeader />
      <Box className={classes.cardsRow}>
        <ChartCard
          icon={<AccountCircle style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Users"
          description="Free Users"
          figure={dashboardData.users.totalCount}
          trend="negative"
          trendValue="7.6"
          dataKey={"count"}
          data={[...sortedUsersData]}
        />

        <ChartCard
          icon={<Report style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Reports"
          figure={dashboardData.reports.totalCount}
          description="Total Reports"
          data={[...sortedReportData]}
          dataKey={"count"}
        />
        <ChartCard
          icon={<PriceChange style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Total Revenue"
          description="Subscription Purchase"
          figure={dashboardData.revenue.total}
          trend="positive"
          trendValue="78"
          data={[...sortedRevenuesData]}
          dataKey={"count"}
        />
      </Box>
      <Box className={classes.chartsContainer}>
        <Box className={classes.graphContainer}>
          <Box style={{ margin: 10 }}>
            <Typography style={{ fontSize: 13, fontWeight: 600 }}>
              Total Users
            </Typography>
          </Box>

          <ResponsiveContainer width="100%" height="95%">
            <AreaChart
              data={sortedData?.map((item) => ({
                ...item,
                period: item?.day
                  ? `${item?.day} ${item?.month} ${item?.year}`
                  : `${item?.month} ${item?.year}`,
              }))}
              width={500}
              height={400}
              margin={{ bottom: 40, left: 10, right: 10 }}
            >
              <XAxis
                axisLine={false}
                tickLine={false}
                dataKey="period"
                fontSize={10}
                height={10}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                fontSize={10}
                width={30}
              />

              <Tooltip />
              <Area
                type="linear"
                dataKey="males"
                stroke="#EA0F08"
                fillOpacity={0.5}
                fill="#EA0F08"
                stackId="1"
              />
              <Area
                type="linear"
                dataKey="females"
                stroke="#FFA6A3"
                fillOpacity={0.5}
                fill="#FFEBEB"
                stackId="1"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
        <Box className={classes.pieRightContainer}>
          <Box className={classes.onlineRow}>
            <Box
              style={{
                backgroundColor: "#1DC107",
                height: 10,
                width: 10,
                borderRadius: 10,
              }}
            />
            <Typography fontSize="13px" fontWeight={600}>
              Online Now
            </Typography>
          </Box>
          <Box className={classes.usersInfoRow}>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box>
                <Typography fontSize={22} fontWeight={500}>
                  {dashboardData.online.totalCount}
                </Typography>
                <Typography fontSize={12}>Users Online Now</Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <Box
                    style={{
                      height: 12,
                      width: 12,
                      backgroundColor: "#FFA6A3",
                    }}
                  />
                  <Typography fontSize="11px">
                    Male - {dashboardData.online.totalMalesCount}
                  </Typography>
                </Box>
                <Box style={{ display: "flex", alignItems: "center", gap: 5 }}>
                  <Box
                    style={{
                      height: 12,
                      width: 12,
                      backgroundColor: "#EA0F08",
                    }}
                  />
                  <Typography fontSize="11px">
                    Female - {dashboardData.online.totalFemalesCount}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              height: 220,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ResponsiveContainer width="100%" height="80%">
              <PieChart>
                <Pie
                  dataKey="value"
                  data={usersData}
                  innerRadius={30}
                  outerRadius={60}
                >
                  {usersData.map((entry, index) => (
                    <Cell key={index} fill={entry.color} stroke="transparent" />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
