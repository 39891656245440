import React, { useRef, useState } from "react";
import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import useStyles from "./PostCards.styles";
import GiftIcon from "../../../../resources/img/gift.svg";
import LikeIcon from "../../../../resources/img/like_user.svg";
import LocationIcon from "../../../../resources/img/location-pin.svg";
import UnlikeIcon from "../../../../resources/img/unlike_user.svg";
import DotsIcon from "../../../../resources/img/Icon material-more-vert.svg";
import usePostCards from "./hooks/usePostCards";
import { fileType, usernameStringCheck } from "../../../../utils/helpers";
import PostImgComponent from "../../../../components/PostImgComponent";
import { useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import { useEffect } from "react";
import { setPopupChildren } from "../../../../redux/actions/global/global.actions";
import { togglePopup } from "./../../../../redux/actions/global/global.actions";
import { useDispatch, useSelector } from "react-redux";
import MakePost from "./../MakePost/MakePost";
import UserPreview from "../../../../components/UserPreview";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";

function PostCards({
  i,
  profileImg,
  profileName,
  profileLocation,
  post,
  containerStyle,
  headerStyle,
  onOpenReport,
  cardImage,
}) {
  const { t } = useTranslation();
  const {
    getCaption,
    likePost,
    handleHidePost,
    handleOpenGifts,
    removePost,
    getUser,
    handleEventRequest,
    sendingRequest,
  } = usePostCards();
  console.log(post);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const splitPath = location.pathname.split("/");
  const path = splitPath[1];
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [time, setTime] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const reduxDispatch = useDispatch();
  const myData = useSelector((state) => state.users.myData);

  const onOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const videoRef = useRef(null);
  const cVideoRef = useRef(null);

  const checkVidInViewport = (e) => {
    if (e === 1) {
      cVideoRef.current && cVideoRef.current.play();
    } else {
      cVideoRef.current && cVideoRef.current.pause();
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.play();
          } else {
            entry.target.pause();
          }
        });
      },
      { threshold: 0.5 }
    );
    if (cVideoRef.current) {
      observer.observe(cVideoRef.current);
    }

    return () => {
      if (cVideoRef?.current) {
        observer.unobserve(cVideoRef.current);
      }
    };
  }, [cVideoRef]);

  const handleEvent = () => {
    if (post?.is_event) {
      const date = moment(post?.event_date);
      const now = moment();

      const formatedDate = date.format("DD/MMM/YYYY HH:MM A");
      const dateTime = formatedDate.split(" ");
      setTime(`${dateTime[1]} ${dateTime[2]}`);
      const date_components = dateTime[0].split("/");
      setDay(date_components[0]);
      setMonth(date_components[1]);
      setYear(date_components[2]);
      setIsExpired(date?.isBefore(now));
    }
  };

  useEffect(() => {
    handleEvent();
  }, []);

  const handleRequest = () => {
    let attend = post?.attendees || [
      { status: "" },
      { status: "" },
      { status: "" },
    ];
    let status = null;

    for (let i = 0; i < attend.length; i++) {
      let attendee = attend[i];
      if (attendee?.user?.id === myData?.id) {
        status = attend?.status;
        break;
      }
    }
    return status;
  };

  const status = handleRequest();

  return (
    <Box key={i} className={[classes.cardRoot, containerStyle]}>
      <Box className={[classes.cardHeader, headerStyle]}>
        <Box className={classes.cardProfile}>
          <Box className={classes.cardProfileImg}>
            <Avatar
              src={profileImg}
              alt="profile Img"
              className={classes.cardAvatar}
            />
          </Box>
          <Box className={classes.cardProfileSum}>
            <Typography
              className={classes.cardProfileName}
              onClick={() => {
                getUser(post?.user?.id);
                reduxDispatch(setPopupChildren(<UserPreview />));
                reduxDispatch(togglePopup(true));
              }}
            >
              <span style={{ color: "#ADABAB" }}>@</span>
              {usernameStringCheck(profileName)}
            </Typography>
            <Typography noWrap className={classes.cardProfileLocation}>
              {profileLocation?.length > 0 && (
                <img
                  className={classes.locationIcon}
                  src={LocationIcon}
                  alt="icon"
                />
              )}{" "}
              {profileLocation}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.cardIcons}>
          {post?.is_event === false && (
            <Box className={classes.cardIcons}>
              <span
                onClick={() => handleOpenGifts(post?.user)}
                className={classes.giftIcon}
              >
                {path === "settings" ? (
                  <span
                    style={{
                      color: "#EA0F08",
                      fontSize: 18,
                      fontWeight: 500,
                      transform: "translateY(-4px)",
                      display: "flex",
                    }}
                  >
                    {post?.likes}
                  </span>
                ) : (
                  myData?.id !== post?.user?.id && (
                    <img src={GiftIcon} alt="icon" />
                  )
                )}
              </span>
              <span
                onClick={() => likePost(post?.id)}
                className={classes.likeIcon}
              >
                {post?.liked ? (
                  <img src={LikeIcon} alt="icon" />
                ) : (
                  <img
                    src={UnlikeIcon}
                    alt="icon"
                    className={classes.likeAnime}
                  />
                )}
              </span>
              <span
                className={classes.dropdown}
                id="demo-positioned-button"
                aria-controls={"demo-positioned-menu"}
                aria-haspopup="true"
                aria-expanded={"true"}
                onClick={(e) => onOpenMenu(e, post?.id)}
              >
                <img src={DotsIcon} alt="icon" />
              </span>
            </Box>
          )}
          {post?.is_event === true && (
            <Box className={classes.cardIcons}>
              {myData?.id !== post?.user?.id && status === null && (
                <span
                  className={classes.requestIv}
                  onClick={() => handleEventRequest(post?.id)}
                >
                  {t("requestInvitation")}
                </span>
              )}
              {status === "pending" && (
                <span
                  className={classes.pendingIv}
                  onClick={() => handleEventRequest(post?.id)}
                >
                  {t("invitationSent")}
                </span>
              )}
              {status === "accepted" && (
                <span
                  className={classes.approvedIv}
                  onClick={() => handleEventRequest(post?.id)}
                >
                  {t("invitationApproved")}
                </span>
              )}
              <span
                className={classes.dropdown}
                id="demo-positioned-button"
                aria-controls={"demo-positioned-menu"}
                aria-haspopup="true"
                aria-expanded={"true"}
                onClick={(e) => onOpenMenu(e, post?.id)}
              >
                <img
                  src={DotsIcon}
                  alt="icon"
                  style={{ transform: "translateY(3px)" }}
                />
              </span>
            </Box>
          )}

          {path === "settings" ? (
            <Menu
              anchorEl={anchorEl}
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={() => {
                  reduxDispatch(setPopupChildren(<MakePost isEdit={post} />));
                  reduxDispatch(togglePopup(true));
                  handleClose();
                }}
              >
                {t("edit")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  removePost(post?.id);
                  handleClose();
                }}
              >
                {t("delete")}
              </MenuItem>
            </Menu>
          ) : (
            <Menu
              anchorEl={anchorEl}
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={() => onOpenReport(post?.id)}>
                {t("report")}
              </MenuItem>

              <MenuItem onClick={() => handleHidePost(post?.id)}>
                {t("hide")}
              </MenuItem>
            </Menu>
          )}
        </Box>
      </Box>

      <Box className={classes.cardImageBox}>
        {Array.isArray(post?.files) ? (
          <Carousel
            onChange={(e) => checkVidInViewport(e)}
            sx={{
              display: "flex !important",
              justifyContent: "center !important",
              alignItems: "center !important",

              // height: 400,
              width: "100%",
            }}
            className={classes.cardImage}
            indicators={false}
            navButtonsAlwaysVisible={true}
            autoPlay={false}
            animation="slide"
            navButtonsProps={{
              style: {
                backgroundColor: "#000",
                opacity: 0.1,
                display: post?.files?.length <= 1 ? "none" : undefined,
              },
            }}
          >
            {post?.files?.map((item, i) =>
              fileType(item) === "video" ? (
                <video
                  key={i}
                  src={item}
                  className={classes.cardImage}
                  ref={cVideoRef}
                  // loop={false}
                  controls
                  style={{ width: "100%" }}
                  loop={false}
                />
              ) : (
                <PostImgComponent
                  key={i}
                  className={classes.file}
                  src={item}
                  alt="card Img"
                />
              )
            )}
          </Carousel>
        ) : fileType(post?.image) === "video" ? (
          <video
            // autoPlay
            loop={false}
            src={post?.image}
            className={classes.cardImage}
            controls
            ref={cVideoRef}
          />
        ) : (
          <PostImgComponent
            className={classes.cardImage}
            src={post?.image}
            alt="card Img"
          />
        )}
      </Box>
      <Box className={classes.cardCaption}>
        <Typography className={classes.cardCaptionText}>
          {getCaption(post)}
        </Typography>
        {post?.is_event && (
          <Typography className={classes.eventDate}>
            <InfoIcon
              fontSize="small"
              className={!isExpired ? "iconGo" : "iconStop"}
            />{" "}
            <span style={{ fontWeight: "600", fontSize: 12 }}>
              {moment(post?.event_date).format("D MMM. YYYY.  h:mm a")}
            </span>
            {moment(post?.event_date).isAfter() ? null : (
              <span style={{ fontStyle: "italic", fontSize: 12, color: "red" }}>
                {" "}
                ({t("expiredEvent")})
              </span>
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default PostCards;
