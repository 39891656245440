import env from "../env";
import { request } from "./api";

const API_URL = `${env.API_URL}`;

const URL = {
  login: `${API_URL}/users/login`,
  create: `${API_URL}/users`,
  verifyOtp: `${API_URL}/users/otp`,
  forgotPassword: `${API_URL}/users/password`,
  resetPassword: `${API_URL}/users/password-otp`,
  changePassword: `${API_URL}/users/password`,
};

const login = (email, password) => {
  return request(URL.login, { email, password }, "POST");
};
const create = (email, password, cPassword, lang, ref) => {
  return request(
    URL.create,
    { email, password, cPassword, lang, ...(ref ? { ref } : {}) },
    "POST"
  );
};
const verifyOtp = (email, otp) => {
  return request(URL.verifyOtp, { email, otp }, "POST");
};
const resendOtp = (email) => {
  return request(URL.verifyOtp, { email }, "GET");
};
const forgotPassword = (email) => {
  return request(URL.forgotPassword, { email }, "POST");
};
const resetPassword = (email, otp) => {
  return request(URL.resetPassword, { email, otp }, "POST");
};
const changePassword = (type, password, token) => {
  return request(
    URL.changePassword,
    { type, password },
    "PATCH",
    `Bearer ${token}`
  );
};

const resetNewPassword = (token, data) => {
  return request(URL.changePassword, data, "PATCH", `Bearer ${token}`);
};
const authService = {
  login,
  create,
  verifyOtp,
  resendOtp,
  forgotPassword,
  resetPassword,
  changePassword,
  resetNewPassword,
};
export default authService;
