import React, { useReducer, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { imageExts, videoExts } from "../../../utils/constants";

const useMediaPreview = () => {
  const reduxDispatch = useDispatch();

  const initState = {
    fileExt: "",
    fileToPreview: null,
    fileToUpload: null,
    fileType: "",
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initState
  );

  const { open } = useDropzone({
    accept: { "image/*": imageExts, "video/*": videoExts },
    multiple: false,
    maxSize: 15728640,
    minSize: 0,
    onDrop: (acceptedFiles) => {
      dispatch({ fileExt: `.${acceptedFiles[0].name.split(".").pop()}` });
      const file = URL.createObjectURL(acceptedFiles[0]);
      dispatch({ fileToPreview: file });
    },
  });
  return { reduxDispatch, state, dispatch, open, reduxDispatch };
};

export default useMediaPreview;
