import { connect } from "react-redux";
import { setCaller } from "../../../../../../redux/actions/global/global.actions";
import MessageHeader from "./MessageHeader";

const mapState = ({ chats }) => ({
  messagingUser: chats.messagingUser,
  chats: chats.chats,
});
const mapDispatchToProps = (dispatch) => ({
  setCaller: (data) => dispatch(setCaller(data)),
});
export default connect(mapState, mapDispatchToProps)(MessageHeader);
