import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import UserImgComponent from "../../../../components/UserImgComponent/UserImgComponent";
import { ActivitiesHeaderStyle } from "./ActivitiesHeader.styles";
import { ReactComponent as LikeUserIcon } from "../../../../assets/icons/like_user.svg";
import useActivities from "../../hooks/useActivities";
import UserPreview from "../../../../components/UserPreview";

const ActivitiesHeader = ({
  myData,
  whoLikesMe,
  setPreviewUser,
  setPopupChildren,
  togglePopup,
}) => {
  const classes = ActivitiesHeaderStyle();
  const { getWhoILike, getUser } = useActivities();
  useEffect(() => {
    getWhoILike();
  }, []);

  return (
    <Box sx={{ display: "flex" }} className={classes.wrapper}>
      <Box
        onClick={() => {
          setPreviewUser(myData);
          setPopupChildren(<UserPreview />);
          togglePopup(true);
        }}
        className={classes.userNode}
      >
        <UserImgComponent className={classes.img} src={myData.avatar} />
        <Box className={classes.likeCountWrap}>
          <Typography style={{ fontSize: 10, marginRight: 3 }}>
            {whoLikesMe?.length}
          </Typography>
          <LikeUserIcon height={10} width={10} />
        </Box>
      </Box>
      {whoLikesMe.length
        ? whoLikesMe.map((user, i) => (
            <Box
              onClick={() => {
                getUser(user?.owner?.id);
                setPopupChildren(<UserPreview />);
                togglePopup(true);
              }}
              key={i}
              className={classes.userNode}
            >
              <UserImgComponent
                className={classes.img}
                src={user?.owner?.avatar}
              />
              <Box
                style={{
                  backgroundColor: "red",
                  height: 10,
                  width: 10,
                  borderRadius: "100%",
                  zIndex: 2,
                  position: "absolute",
                  bottom: 1,
                  right: 0,
                  border: "1px solid #fff",
                }}
              />
            </Box>
          ))
        : null}
    </Box>
  );
};

export default ActivitiesHeader;
