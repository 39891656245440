import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useStyles from "./PersonalDetails.styles";
import FormField from "../../../components/FormField";
import Dropdown from "react-dropdown";
import CustomButton from "../../../components/CustomButton";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useOnboarding from "../hooks/useOnboarding";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import useConstants from "../../../utils/useConstants";

function PersonalDetails() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { sexOptions } = useConstants();
  const { createProfile, state, dispatch, handleUsername } = useOnboarding();
  const {
    name,
    user,
    gender,
    sexuality,
    birthday,
    isLoading,
    isChecking,
    isExist,
  } = state;
  const moment = require("moment");
  const date = moment(birthday);
  const formatedDate = date.format("DD/MM/YYYY");

  const date_components = formatedDate.split("/");
  const day = date_components[0];
  const month = date_components[1];
  const year = date_components[2];

  const handleDatePickerClose = () => {
    setIsOpen(false);
  };
  const { t } = useTranslation();
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageBox}>
        <form className={classes.formBox} onSubmit={createProfile}>
          <Box className={classes.headClass}>
            <Box className={classes.headerText}>
              <span className={classes.header}>{t("welcomeToM8s")}</span>
              <span className={classes.sub}>{t("knowMore")}</span>
            </Box>
            <Box
              sx={{
                width: 52,
                height: 52,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CircularProgressbar
                value={25}
                text={`25%`}
                strokeWidth={15}
                styles={buildStyles({
                  textColor: "#0A0A12",
                  pathColor: "#EA0F08",
                  trailColor: "#f4f5f9",
                  textSize: "25px",
                })}
              />
            </Box>
          </Box>
          <Box>
            <FormField
              value={name}
              handleChange={(e) => dispatch({ name: e.target.value })}
              label={t("whatyourname")}
              type="text"
              placeholder={t("firstlastname")}
            />
          </Box>
          <Box sx={{ position: "relative" }}>
            <FormField
              label={t("selectUsername")}
              value={user}
              handleChange={(e) => handleUsername(e.target.value)}
              type="text"
              placeholder=""
              startIcon={"@"}
            />
            <Box
              sx={{
                position: "absolute",
                right: 10,
                top: 26,
                display: "flex",
              }}
            >
              {!!isChecking ? (
                <CircularProgress size={23} />
              ) : user.length > 0 ? (
                isExist === null ? (
                  ""
                ) : !isExist ? (
                  <ErrorIcon sx={{ color: "red" }} />
                ) : (
                  <CheckCircleIcon color="success" />
                )
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Box className={classes.target}>
            <Typography className={classes.targetText}>
              {t("gender")}
            </Typography>

            <Box className={classes.btnBox}>
              <span
                className={gender === "male" ? classes.btnActive : classes.btn}
                onClick={() => dispatch({ gender: "male" })}
              >
                {t("male")}
              </span>
              <span
                onClick={() => dispatch({ gender: "female" })}
                className={
                  gender === "female" ? classes.btnActive : classes.btn
                }
              >
                {t("female")}
              </span>
            </Box>
          </Box>
          <Box className={classes.target}>
            <Typography className={classes.targetText}>{t("sex")}</Typography>
            <Dropdown
              controlClassName={classes.dropDown}
              arrowClassName={classes.dropDownArrow}
              options={sexOptions}
              onChange={(e) => dispatch({ sexuality: e.value })}
              value={sexuality?.toLowerCase()}
              placeholder={t("selectSex")}
            />
          </Box>
          <Box className={classes.target}>
            <Typography className={classes.targetText}>{t("dob")}</Typography>
            <Box className={classes.btnBox}>
              <span className={classes.day} onClick={() => setIsOpen(!isOpen)}>
                {day === "Invalid date" ? "DD" : day}
              </span>
              <span
                className={classes.month}
                onClick={() => setIsOpen(!isOpen)}
              >
                {month || "MM"}
              </span>
              <span className={classes.year} onClick={() => setIsOpen(!isOpen)}>
                {year || "YYYY"}
              </span>
            </Box>
          </Box>
          <Box sx={{ display: "none" }}>
            <MobileDatePicker
              open={isOpen}
              onClose={handleDatePickerClose}
              label="Date mobile"
              inputFormat="MM/DD/YYYY"
              value={
                birthday ||
                new Date(new Date().setFullYear(new Date().getFullYear() - 18))
              }
              onChange={(val) => dispatch({ birthday: val })}
              renderInput={(params) => <TextField {...params} />}
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear() - 18))
              }
            />
          </Box>

          <Box className={classes.continueBtn}>
            <CustomButton type="submit" loading={!!isLoading}>
              {t("continue")}
            </CustomButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default PersonalDetails;
