import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import UserImgComponent from "../../../../../../components/UserImgComponent/UserImgComponent";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";

import useChats from "../../../../hooks/useChats";
import { useNavigate } from "react-router-dom";
import { usernameStringCheck } from "../../../../../../utils/helpers";
import { useDispatch } from "react-redux";
import { setPreviewUser } from "../../../../../../redux/actions/users/users.actions";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../../../redux/actions/global/global.actions";
import UserPreview from "../../../../../../components/UserPreview";

const MessageHeader = ({ messagingUser, chats, setCaller }) => {
  const chat = chats?.filter((chat) => messagingUser?.id === chat?.user?.id)[0];
  const { isFav, toggleChatFavourite, setIsFav, getUser, handleStartCall } =
    useChats();
  useEffect(() => {
    chat && setIsFav(chat?.is_favorite);
  }, [chats]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#fff",
        height: 80,
        padding: "0px 10px",
      }}
    >
      <Box
        onClick={() => {
          getUser(messagingUser?.id);
          dispatch(setPopupChildren(<UserPreview />));
          dispatch(togglePopup(true));
        }}
        sx={{
          display: "flex",
          gap: 2,
          cursor: "pointer",
        }}
      >
        <Box
          style={{
            width: 50,
            height: 50,
            borderRadius: "100%",
            overflow: "hidden",
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UserImgComponent
            src={messagingUser?.avatar}
            style={{ width: 50, height: "auto" }}
          />
        </Box>
        <Typography style={{ fontWeight: 600, fontSize: 15 }}>
          {" "}
          {/* {messagingUser?.first_name && messagingUser?.first_name}{" "}
          {messagingUser?.last_name && messagingUser?.last_name} */}
          <span style={{ color: "#ADABAB" }}>@</span>
          {usernameStringCheck(messagingUser?.username)}
        </Typography>
        {messagingUser?.status && messagingUser?.status === "online" && (
          <Box
            style={{
              borderRadius: "100%",
              backgroundColor: "#06C439",
              height: 10,
              width: 10,
              marginTop: 7,
            }}
          />
        )}
      </Box>
      <Box style={{ gap: 1, display: "flex" }}>
        <IconButton onClick={() => handleStartCall("voice")}>
          <PhoneOutlinedIcon
            style={{
              padding: 5,
              borderRadius: 5,
              backgroundColor: "#F7F7F7",
              width: 25,
              height: 25,
              color: "#000",
            }}
          />
        </IconButton>
        <IconButton onClick={() => handleStartCall("video")}>
          <VideocamOutlinedIcon
            style={{
              padding: 5,
              borderRadius: 5,
              backgroundColor: "#F7F7F7",
              width: 25,
              height: 25,
              color: "#000",
            }}
          />
        </IconButton>
        <IconButton onClick={() => toggleChatFavourite([chat?.chat?.id])}>
          <StarOutlinedIcon
            style={{
              padding: 5,
              borderRadius: 5,
              backgroundColor: "#F7F7F7",
              width: 25,
              height: 25,
              color: isFav ? "#FFD37C" : "#fff",
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default MessageHeader;
