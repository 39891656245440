import {
  SET_CHATS,
  SET_CHATS_FILTER_VAL,
  SET_MESSAGES_IN_VIEW,
  SET_MESSAGING_USER,
} from "./chats.types";

export const setMessagingUser = (payload) => ({
  type: SET_MESSAGING_USER,
  payload,
});

export const setMessagesInView = (payload) => ({
  type: SET_MESSAGES_IN_VIEW,
  payload,
});

export const setChats = (payload) => ({
  type: SET_CHATS,
  payload,
});

export const setChatsFilterVal = (payload) => ({
  type: SET_CHATS_FILTER_VAL,
  payload,
});
