import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageContainer: {
    width: 480,
    padding: 25,
    backgroundColor: "#fff",
    borderRadius: 15,
    maxHeight: "95vh",
    overflow: "auto",
  },
  pageContainer2: {
    width: 480,
    padding: 25,
    borderRadius: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 25,
  },
  pageBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  formBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  target: {
    marginTop: 0,
    width: "100%",
  },
  targetText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    paddingLeft: 10,
    color: "#293146",
    marginBottom: 5,
  },

  dropDown: {
    height: 35,
    width: "100%",
    borderRadius: 0,
    color: "#293146",
    fontSize: 14,
    fontWeight: "400 !important",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& .Dropdown-arrow": {
      top: 15,
    },
    "& .Dropdown-placeholder": {
      fontSize: "14px important",
      color: "#293146",
    },

    "&:focus": {
      border: "1px solid #2f3138",
    },
  },
  btnBox: {
    display: "flex",
    gap: 20,
  },
  btn: {
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    border: "1px solid #00000029",
    background: "#FFFFFF",
    width: "100%",
    textAlign: "center",
    fontSize: "14px !important",
  },
  btnActive: {
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "100%",
    textAlign: "center",
    fontSize: "14px !important",
    boxSizing: "border-box",
  },
  day: {
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "20%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  month: {
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "30%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  year: {
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "50%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  continueBtn: {
    marginTop: 0,
  },
  header: {
    color: "#EA0F08",
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  headerBlack: {
    color: "#0A0707",
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  sub: {
    color: "#2F3C47",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  subSub: {
    color: "#969696",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    width: "80%",
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  headClass: {
    display: "flex",
    justifyContent: "space-between",
    height: 60,
  },
  intWrap: {
    width: "fit-content",
    padding: "1px 10px",
    borderRadius: 5,
    cursor: "pointer",
  },
  intText: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
  },
  intCon: {
    display: "flex",
    flexWrap: "wrap",
    gap: 5,
    justifyContent: "flex-start",
  },
  mainImage: {
    height: "180px !important",
    width: "180px !important",
    objectFit: "cover !important",
    position: "relative",
    borderRadius: "100%",
  },
  avatarAdd: {
    height: "30px !important",
    width: "30px !important",
    borderRadius: "50px",
    position: "absolute",
    bottom: 30,
    right: 0,
    background: "#FFD112",
    cursor: "pointer",
    color: "#fff",
    fontSize: "25px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  secImage: {
    height: "120px !important",
    width: "120px !important",
    objectFit: "cover !important",
    borderRadius: "3px !important",
    border: "1px dashed #bdbdbd",
    fontSize: 80,
    textAlign: "center",
    cursor: "pointer",
  },
  subImages: {
    display: "flex",
    flexWrap: "wrap",
    gap: 15,
    justifyContent: "space-around",
    position: "relative",
  },
  newBox: {
    height: 38,
    width: "100%",
    border: " 1px solid #ccc",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    paddingLeft: 12,
    boxSizing: "border-box",
  },
  conBody: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  conMain: {
    display: "flex",
    gap: 20,
    justifyContent: "space-between",
  },
  conLeft: {
    fontWeight: 500,
    color: "#EA0F08",
    fontSize: 14,
  },
  conRight: {
    justifyContent: "flex-end",
    textAlign: "right",
    fontWeight: 500,
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    gap: 10,
    width: "60%",

    "& img": {
      height: "25px !important",
      width: "25px !important",
    },
  },
  intCon2: {
    display: "flex",
    flexWrap: "wrap",
    gap: 5,
    justifyContent: "flex-end",
  },

  footing: {
    width: "100%",
    height: 150,
    overflow: "hidden",
    transform: "translateY(10px)",

    "& img": {
      transform: "translate(0, -53px)",
      height: 270,
      width: "100%",
      objectFit: "contain",
      zIndex: -1,
    },
  },
}));

export default useStyles;
