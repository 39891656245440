import React from "react";
import { useState } from "react";
import adminService from "../../../../../../../services/admin/admin.service";
import { getToken } from "../../../../../../../utils/helpers";
import { useParams } from "react-router-dom";

export default function useAdminWallet() {
  const [walletTrans, setWalletTrans] = useState([]);
  const [gettingTrans, setGettingTrans] = useState(false);
  const { userId } = useParams();
  const token = getToken();
  const getWalletTrans = () => {
    setGettingTrans(true);
    adminService
      .getTransactions(token, userId)
      .then((res) => {
        console.log(res);
        setWalletTrans(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setGettingTrans(false);
      });
  };
  return {
    getWalletTrans,
    gettingTrans,
    walletTrans,
  };
}
