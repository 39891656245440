import { makeStyles } from "@mui/styles";

export const MessageFooterStyle = makeStyles(({ theme }) => ({
  messageFooterContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "#fff",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0px",
    zIndex: 3,
  },
  footerInner: {
    backgroundColor: "#F7F7F7",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    width: "95%",
    padding: 8,
    position: "relative",
  },
  inputBox: { width: "80%", backgroundColor: "#fff", position: "relative" },
  actionButtons: {
    width: 35,
    height: 35,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 40,
    marginHorizontal: 7,
    elevation: 2,
    borderWidth: 0.2,
    borderColor: "#fff",
    zIndex: 1000,
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
  },
  recordingInfo: {
    display: "flex !important",
    flexDirection: "row",
    alignItems: "center",
  },
  recordingRight: {
    width: 150,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  mediaMessageBox: {
    display: "flex !important",
    borderRadius: 5,
    position: "absolute",
    left: 0,
    top: -50,
    justifyContent: "center",
    alignItems: "center",
  },
  removeBtn: {
    position: "absolute",
    top: -10,
    right: -10,
    zIndex: 10,
  },
  suggestiveTextsContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 12,
    position: "absolute",
    top: -25,
    overflow: "auto",
    width: "90%",
    paddingLeft: 30,

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  suggestiveText: {
    fontSize: "13px !important",
    padding: 5,
    // backgroundColor: "#E00E08",
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
    color: "grey",
    cursor: "pointer",
    whiteSpace: "nowrap",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -15px",
    border: "0.5px solid #E00E08",
  },
  messageRefWrapper: {
    position: "absolute",
    top: -70,
    left: 0,
    right: 0,
    padding: 5,
    backgroundColor: "#fff",
  },
  messageRefInner: {
    borderRadius: 5,
    padding: 5,
    display: "flex !important",
    flexDirection: "column",
  },
}));
