import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminSettingsStyles } from "./AdminSettings.style";
import AdminSettingsHeader from "./components/AdminSettingsHeader/AdminSettingsHeader";
import { adminSettingsNav } from "./constants";
import AdminNavigation from "./components/AdminNavigation/AdminNavigation";

const AdminSettings = ({ children }) => {
  const [selected, setSelected] = useState(1);
  useEffect(() => {
    if (selected === null) {
      _id();
    }
  }, []);
  const classes = AdminSettingsStyles();
  const location = useLocation();
  const defaultLocation = location.pathname.split("/");
  const navigate = useNavigate();

  const _id = () => {
    const val = sessionStorage.getItem("$settingsId");
    if (val) {
      setSelected(val);
      // return sessionStorage.getItem("$settingsId");
    } else {
      setSelected(1);
      // return 1;
    }
  };
  const _onClick = (val, id) => {
    // e.prevent
    navigate(`/${val}`);
    setSelected(id);
  };

  return (
    <Box className={classes.pageWrapper}>
      <AdminSettingsHeader />
      <Box className={classes.pageMainWrap} sx={{ display: "flex" }}>
        <Box className={classes.pageListWrap}>
          <AdminNavigation />
        </Box>
        <Box
          sx={{
            width: "70%",
            position: "relative",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminSettings;
