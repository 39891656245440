import { Box, Typography } from "@mui/material";
import { ManageTeamsStyles } from "./ManageTeams.styles";
import { MoreHoriz } from "@mui/icons-material";
import { useState } from "react";
// import { usersData } from "../../../Users/constants";
import CustomTable from "../../../../../components/CustomTable";
import { useDispatch } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../../redux/actions/global/global.actions";
import useManageTeams from "./hooks/useManageTeams";
import { AddTeam, MoreOptionsPopover } from "./components";
import MoreMenu from "../../../../../components/MoreMenu/MoreMenu";

export default function ManageTeams() {
  const classes = ManageTeamsStyles();
  const dispatch = useDispatch();

  const { usersData, isLoading, getUsers } = useManageTeams();
  if (isLoading) return <div>Loading...</div>;
  return (
    <Box className={classes.Wrapper}>
      <Box className={classes.header}>
        <Typography variant="h6" color="initial">
          Team Members
        </Typography>
        <Box>
          <button
            className={classes.addTeams}
            onClick={() => {
              dispatch(setPopupChildren(<AddTeam />));
              dispatch(togglePopup(true));
            }}
          >
            Add Team Member
          </button>
        </Box>
      </Box>
      <CustomTable
        noSort
        data={{
          th: [
            <span key={1}>User</span>,
            <span key={2}>Status</span>,
            <span key={3}>Email</span>,
            <span key={4}>Actions</span>,
          ],
          rows: usersData.map((user, index) => {
            const { first_name, last_name, is_active, email, id } = user;
            return [
              <Box key={id} className={classes.tableRow}>
                <Box className={classes.userCell}>
                  <Box className={classes.greyBg} />
                  {`${first_name + " " + last_name || "--"}`}
                </Box>
              </Box>,
              <Box key={id} className={classes.tableRow}>
                <Box
                  className={classes.statusBg}
                  style={{
                    backgroundColor: is_active ? "#D0F3D3" : "#FFEBEB",
                  }}
                >
                  {is_active ? "Active" : "Inactive"}
                </Box>
              </Box>,
              <Box key={id} className={classes.tableRow}>
                {email}
              </Box>,
              <Box key={index} className={classes.tableRow}>
                <MoreMenu
                  firstText={"Edit"}
                  user={user}
                  secondText={"activity"}
                  className={classes.dropdownIcon}
                  getUsers={getUsers}
                />
              </Box>,
            ];
          }),
        }}
      />
    </Box>
  );
}
