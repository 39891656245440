import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { NavData } from "../../../constants/data";
import { COLOR } from "../../../constants/theme";
import { AccountProfile } from "./AccountProfile";
import { DetailsComp } from "./DetailsComp";
import { ImageUpload } from "./ImageUpload";
import { InfoRenderComp } from "./InfoRenderComp";
import { Nav } from "./Nav";

export const Body = (props) => {
  // const idCheck = useMemo(() => _id(), []);
  const classes = styles();

  return (
    <div className={classes.container}>
      <Nav />

      <div className={classes.contentContainer}>
        {/* {
            selectedNav === "Account Profile" ? 
            <AccountProfile />
            : null
          } */}
        {props.children}
        {/* <Outlet/> */}
      </div>
      {/* <Typography>{`My name is ${myData.first_name}`}</Typography> */}
      {/* <Typography>I am on Settings Page</Typography> */}
    </div>
  );
};

export const styles = makeStyles((theme) => ({
  container: {
    marginTop: 15,
    display: "flex !important",
    // alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    gap: 24,
    height: "80vh",
  },
  contentContainer: {
    width: "75%",
    height: "80vh",
    display: "flex",
    flexDirection: "column",
    overflowX: "scroll",
  },
}));
