import {
  SET_DISCOVER_USERS,
  SET_GETTING_DISCOVER,
  SET_GETTING_MATES,
  SET_GETTING_NEARBY,
  SET_GETTING_PREVIEW,
  SET_MATES,
  SET_MY_ACTIVITIES,
  SET_MY_COORDS,
  SET_MY_DATA,
  SET_MY_REFERRALS,
  SET_NEARBY_USERS,
  SET_PREVIEW_USER,
  SET_USERS_DEFAULT_FILTER,
  SET_WHO_I_LIKE,
  SET_WHO_LIKES_ME,
} from "../../actions/users/users.types";

const defaultState = {
  myData: {},
  discoverUsers: [],
  gettingDiscover: false,
  gettingNearby: false,
  nearbyUsers: [],
  myCoords: null,
  previewUser: {},
  gettingPreview: false,
  usersDefaultFilter: {
    interests: [],
    gender: "both",
    state: "",
    country: "",
    min_age: 18,
    max_age: 65,
    category: "all",
    mode: "",
  },
  whoLikesMe: [],
  mates: [],
  gettingMates: false,
  whoILike: [],
  myActivities: [],
  myReferrals: [],
};

export default function usersReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_MY_DATA:
      return { ...state, myData: action.payload };
    case SET_DISCOVER_USERS:
      return { ...state, discoverUsers: action.payload };
    case SET_GETTING_DISCOVER:
      return { ...state, gettingDiscover: action.payload };
    case SET_GETTING_NEARBY:
      return { ...state, gettingNearby: action.payload };
    case SET_NEARBY_USERS:
      return { ...state, nearbyUsers: action.payload };
    case SET_MY_COORDS:
      return { ...state, myCoords: action.payload };
    case SET_PREVIEW_USER:
      return { ...state, previewUser: action.payload };
    case SET_GETTING_PREVIEW:
      return { ...state, gettingPreview: action.payload };
    case SET_USERS_DEFAULT_FILTER:
      return { ...state, usersDefaultFilter: action.payload };
    case SET_WHO_LIKES_ME:
      return { ...state, whoLikesMe: action.payload };
    case SET_MATES:
      return { ...state, mates: action.payload };
    case SET_GETTING_MATES:
      return { ...state, gettingMates: action.payload };
    case SET_WHO_I_LIKE:
      return { ...state, whoILike: action.payload };
    case SET_MY_ACTIVITIES:
      return { ...state, myActivities: action.payload };
    case SET_MY_REFERRALS:
      return { ...state, myReferrals: action.payload };
    default:
      return state;
  }
}
