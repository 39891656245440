import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../../../components/CustomButton";
import { useStyles } from "./ReferEarn.styles";
import Banner from "../../../../assets/icons/11.svg";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useDispatch, useSelector } from "react-redux";
import Withdraw from "./component/Withdraw/Withdraw";
import {
  setPopupChildren,
  togglePopup,
} from "./../../../../redux/actions/global/global.actions";
import useReferral from "./hooks/useReferral";
import Skeleton from "react-loading-skeleton";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function ReferEarn() {
  const classes = useStyles();
  const { getReferral, isGettingReferrals } = useReferral();
  const myData = useSelector((state) => state.users.myData);
  const myRefs = useSelector((state) => state.users.myReferrals);
  const reduxDispatch = useDispatch();
  const [isCopy, setIsCopy] = useState(false);

  useEffect(() => {
    getReferral();
  }, []);
  const { t } = useTranslation();
  return (
    <Box className={classes.pageWrap}>
      <Box className={classes.pageHeader}>
        <Box className={classes.headerTop}>
          <Box className={classes.headerIcon}>
            <img src={Banner} alt="banner" />
          </Box>
          <Box className={classes.summary}>
            {/* <Typography className={classes.total}>
              {t("totalM8sEarned")}
            </Typography>
            <Typography className={classes.count}>{myData?.coins}</Typography> */}
            {/* <CustomButton
              onClick={() => {
                reduxDispatch(setPopupChildren(<Withdraw />));
                reduxDispatch(togglePopup(true));
              }}
            >
              Withdraw Coins
            </CustomButton> */}
          </Box>
        </Box>
        <Box className={classes.headerLink}>
          <div className={classes.linkField}>
            <span className={classes.default}>
              {`${window.location.origin}/auth/signup?ref=${myData?.ref}`}
            </span>
            <span
              className={classes.getLink}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/auth/signup?ref=${myData?.ref}`
                );
                setIsCopy(true);
              }}
            >
              {isCopy ? "Copied!!" : `${t("copyLink")}`}
            </span>
          </div>
        </Box>
      </Box>
      <Box className={classes.pageDetails}>
        {!!isGettingReferrals ? (
          <Box sx={{ margin: "0 auto" }}>
            <Skeleton
              count={5}
              height={50}
              style={{ margin: "10px auto", width: "600px" }}
            />
          </Box>
        ) : (
          myRefs
            ?.filter((r) => r?.isReferral)
            ?.map((ref) => {
              return (
                <Box className={classes.pageLine}>
                  {ref?.type === "CREDIT" ? (
                    <span className="upward">
                      <ArrowDownwardIcon fontSize="small" />
                    </span>
                  ) : (
                    <span className="downward">
                      <ArrowUpwardIcon fontSize="small" />
                    </span>
                  )}

                  <Typography>
                    {/* <span style={{ fontWeight: 500 }}>30 M8S</span> received from */}
                    {ref?.description}
                  </Typography>
                </Box>
              );
            })
        )}
      </Box>
    </Box>
  );
}

export default ReferEarn;
