import { makeStyles } from "@mui/styles";

export const useEditStyles = makeStyles((theme) => ({
  Wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: ".8rem",
    height: "100%",
    overflow: "hidden",
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
    gap: "1.5rem",
  },
  navigation: {
    height: "fit-content",
    // flex: 1,
    boxShadow: "8px 0 10px rgba(0, 0, 0, 0.1)",
  },
  content: {
    // flex: 2,
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
}));
