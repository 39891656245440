import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { Avatar } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Logo from "../../../resources/img/M8s social white background.png";
import { Typography } from "@mui/material";
import CustomButton from "../../../components/CustomButton";
import useStyles from "../Verify/Verify.styles";
import Poster from "../../../assets/images/VideoPoster.jpg";
import useVerification from "./../hooks/useVerification";
import Footing from "../../../resources/img/footerImg.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useConstants from "../../../utils/useConstants";
import { useTranslation } from "react-i18next";
import useSettingConstants from "../../../constants/data/useSettingConstants";
import { VideoCall } from "@mui/icons-material";

function Confirmation() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { modes, sexOptions, genderOptions } = useConstants();
  const { state, getMe, myData, getRandomColor } = useVerification();
  const { isLoading } = state;
  const { interestsArray } = useSettingConstants();

  const interestList = myData?.interests;
  const { t } = useTranslation();
  useEffect(() => {
    getMe();
  }, []);

  const moment = require("moment");
  const date = moment(myData?.birthday);
  const formatedDate = date.format("DD/MM/YYYY");

  function findObjectById(array, id) {
    const foundObject = array.find((obj) => obj.value === id);
    if (foundObject) {
      return { logo: foundObject.logo, title: foundObject.title };
    } else {
      return null;
    }
  }

  const selectedMode = findObjectById(modes, myData?.mode);

  const vid = null;

  return (
    <Grid container padding={5} spacing={5}>
      <Grid item md={6} display="flex" justifyContent="center">
        <form className={classes.pageContainer2}>
          <Box>
            <Avatar
              src={!!myData ? myData.avatar : ""}
              className={classes.mainImage}
            />
          </Box>
          <Box className={classes.subImages}>
            {!!vid ? (
              <video poster={Poster} className={classes.secImage}>
                <source src={vid} />
              </video>
            ) : (
              <span className={classes.secImage}>
                <VideoCall
                  sx={{
                    width: "80px",
                    height: "60px",
                    transform: "translateY(10px)",
                    // color: "#bdbdbd",
                    color: "#707070",
                    opacity: 0.7,
                  }}
                />
              </span>
            )}
            {myData &&
              myData?.images?.map((item) => {
                return !!item ? (
                  <img src={item.url} className={classes.secImage} alt="pic" />
                ) : (
                  <span className={classes.secImage}>&#x2b;</span>
                );
              })}
          </Box>
        </form>
      </Grid>
      <Grid item md={6}>
        <Box className={classes.pageContainer}>
          <Box className={classes.pageBox}>
            <Box className={classes.formBox} sx={{ gap: 5 }}>
              <Box className={classes.headClass}>
                <Box
                  sx={{
                    position: "relative",
                    height: 300,
                    display: "inline-flex",
                    transform: "translate(90px, -120px)",
                  }}
                >
                  <img src={Logo} alt="m8t logo" />
                </Box>
              </Box>
              <Box className={classes.conBody}>
                <Box className={classes.conMain}>
                  <Box className={classes.conLeft}>{t("whatyourname")}</Box>
                  <Box className={classes.conRight}>
                    {myData
                      ? `${myData?.first_name || ""} ${myData?.last_name || ""}`
                      : ""}
                  </Box>
                </Box>
                <Box className={classes.conMain}>
                  <Box className={classes.conLeft}>{t("username")}</Box>
                  <Box className={classes.conRight}>
                    {myData ? myData.username : ""}
                  </Box>
                </Box>
                <Box className={classes.conMain}>
                  <Box className={classes.conLeft}>{t("sex")}</Box>
                  <Box className={classes.conRight}>
                    {sexOptions.filter(
                      (item) =>
                        item.value?.toLowerCase() ===
                        myData?.sexuality?.toLowerCase()
                    )[0]?.title || ""}
                  </Box>
                </Box>
                <Box className={classes.conMain}>
                  <Box className={classes.conLeft}>{t("dob")}</Box>
                  <Box className={classes.conRight}>{formatedDate}</Box>
                </Box>
                <Box className={classes.conMain}>
                  <Box className={classes.conLeft}>{t("gender")}</Box>
                  <Box
                    className={classes.conRight}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {genderOptions.filter(
                      (item) =>
                        item.value?.toLowerCase() ===
                        myData.gender?.toLowerCase()
                    )[0]?.label || ""}
                  </Box>
                </Box>
                <Box className={classes.conMain}>
                  <Box className={classes.conLeft}>{t("desiredMode")}</Box>
                  <Box className={classes.conRight}>
                    {selectedMode?.logo} {selectedMode?.title}
                  </Box>
                </Box>
                <Box className={classes.conMain}>
                  <Box className={classes.conLeft}>{t("interests")}</Box>
                  <Box className={classes.conRight}>
                    <Box className={classes.intCon2}>
                      {!!interestList &&
                        interestList.map((i) => (
                          <Box
                            key={i.id}
                            className={classes.intWrap}
                            style={{
                              backgroundColor: getRandomColor(),
                            }}
                          >
                            <Typography className={classes.intText}>
                              #
                              {
                                interestsArray.filter(
                                  (int) => int?.id === i?.id
                                )[0]?.name
                              }
                            </Typography>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.continueBtn}>
                <CustomButton
                  loading={!!isLoading}
                  onClick={() => navigate("/feeds")}
                >
                  {t("proceedFeeds")}
                </CustomButton>
              </Box>
              <Box className={classes.footing}>
                <img src={Footing} alt="m8t footing" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Confirmation;
