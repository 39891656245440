import { Box } from "@mui/material";
import React from "react";
import { Boost, Premium, PlanHeader } from "./components/";
import { useState } from "react";
import { usePlan } from "./Plan.style";

export default function Plans({ setShowPremiumBoost }) {
  const [currentTab, setCurrentTab] = useState(0);
  const classes = usePlan(currentTab);
  return (
    <Box className={classes.Wrapper}>
      <PlanHeader
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        setShowPremiumBoost={setShowPremiumBoost}
      />
      <Box className={classes.body}>
        {!currentTab ? (
          <Boost setShowPremiumBoost={setShowPremiumBoost} />
        ) : (
          <Premium />
        )}
      </Box>
    </Box>
  );
}
