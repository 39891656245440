import { makeStyles } from "@mui/styles";

const drawerWidth = "330px";
export const AppLayoutStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: "0px",
    backgroundColor: "#F7F7F7",
    height: "100vh",
    width: "100vw",
    position: "fixed",
  },
  mainSection: {
    width: `calc(100vw - ${drawerWidth} - 30px - 80px)`,
    position: "relative",
    marginLeft: "80px",
    marginRight: "40px",
    height: "100vh",
  },

  noInternet: {
    display: "flex",
    position: "absolute",
    top: 0,
    height: "23px",
    width: `calc(100vw)`,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 187, 87)",
    fontSize: 11,
    zIndex: 10,
  },
}));
