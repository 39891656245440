import {
  Avatar,
  Box,
  ButtonBase,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LeftSideBarStyle } from "./LeftSideBar.style";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";
import { ReactComponent as LogoutIcon } from "../../../../assets/icons/logout.svg";
import { removeUserSession } from "../../../../utils/helpers";
import UserPreview from "../../../../components/UserPreview";
import UsersFilter from "../../../../components/UsersFilter/UsersFilter";
import Memberships from "../../../../components/Memberships";
import BuyCoins from "../../../../components/BuyCoins";
import useAppLayout from "./../../hooks/useAppLayout";
import { useTranslation } from "react-i18next";
import useNavConstants from "./useNavConstants";

const LeftSideBar = ({
  myData,
  setPreviewUser,
  setPopupChildren,
  togglePopup,
}) => {
  const classes = LeftSideBarStyle();
  const navigate = useNavigate();
  const location = useLocation();
  const splitLocation = location.pathname.split("/");
  const onOpenFilter = (page) => {
    setPopupChildren(<UsersFilter page={page} />);
    togglePopup(true);
  };

  const { onLogout } = useAppLayout();

  const { t } = useTranslation();

  const { sideBarNavigations, newMessage, setNewMessage, adminNavigations } =
    useNavConstants();
  const navigations =
    splitLocation[1] === "admin" ? adminNavigations : sideBarNavigations;
  return (
    <nav className={classes.navDrawerWrapper}>
      <Drawer variant="permanent" open={true} className={classes.drawerStyle}>
        <Box
          className={classes.topLeftDiv}
          style={
            splitLocation[1] === "admin"
              ? { marginBottom: 30, marginTop: 20 }
              : {}
          }
        >
          <Box className={classes.imgWrapper}>
            <img
              alt="m8s-logo-white"
              className={classes.img}
              src={require("../../../../assets/images/m8s-logo-white.png")}
            />
          </Box>
          <Box style={{ marginTop: 15 }}>
            {splitLocation[1] === "admin" ? (
              <Typography
                style={{
                  fontWeight: 700,
                  marginBottom: -20,
                  fontSize: 14,
                  marginLeft: -15,
                }}
              >
                ADMIN
              </Typography>
            ) : (
              <>
                <Typography fontSize={12} width={150} fontWeight={500}>
                  {t("borderless")}
                </Typography>
                <Typography fontSize={12} width={150} fontWeight={500}>
                  {t("datingExperience")}
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {splitLocation[1] !== "admin" && (
          <Box className={classes.menuHeader}>
            <IconButton
              onClick={() => {
                setPreviewUser(myData);
                setPopupChildren(<UserPreview isMe />);
                togglePopup(true);
              }}
              className={classes.avatarWrapper}
              style={{
                border: splitLocation[1] === "settings" && "1px solid #EA0F08",
                borderRadius: "100%",
              }}
            >
              <Avatar src={myData.avatar} className={classes.avatar} />
            </IconButton>
            <Box className={classes.userNameText}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: 170 }}
              >
                <Typography
                  fontSize={14}
                  style={{
                    color: splitLocation[1] === "settings" && "#EA0F08",
                    fontWeight: splitLocation[1] === "settings" && 600,
                  }}
                >
                  {myData?.first_name || "--/--"}
                </Typography>
                <IconButton
                  className={classes.settingsBtn}
                  onClick={() => navigate("/settings")}
                >
                  <SettingsIcon
                    style={{
                      width: 15,
                      height: 15,
                    }}
                  />{" "}
                </IconButton>
              </Box>

              <Box className={classes.coinRow}>
                <Box className={classes.userIconWrap}>
                  <img
                    alt="m8s-coins"
                    className={classes.m8sIcon}
                    src={require("../../../../assets/images/coin.png")}
                  />
                  <Typography fontWeight={800} fontSize={12} marginLeft={1}>
                    {myData?.coins || 0}
                  </Typography>
                </Box>
                <Typography
                  onClick={() => {
                    setPopupChildren(<BuyCoins />);
                    togglePopup(true);
                  }}
                  className={classes.buyMore}
                >
                  {t("buyMore")}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {navigations.map((item, i) => (
          <ListItem
            button
            key={item.id}
            onClick={() => {
              navigate(item.path);
              item.value == "chats" && setNewMessage(false);
            }}
            className={
              location.pathname === item.path
                ? classes.active
                : classes.inActive
            }
            style={
              splitLocation[1] === "admin"
                ? { marginBottom: 10, marginTop: 10 }
                : {}
            }
          >
            <ListItemIcon>
              {location.pathname === item.path ? item.activeIcon : item.icon}
            </ListItemIcon>

            <ListItemText>
              <Typography
                style={{
                  fontSize: "13px",
                  fontWeight: location.pathname === item.path ? 800 : 400,
                  color: location.pathname === item.path ? "#EA0F08" : "#000",
                }}
              >
                {item.title}
              </Typography>
            </ListItemText>
            {(item.value === "discover" || item.value === "nearby") && (
              <IconButton
                onClick={(e) => {
                  // e.stopPropagation();
                  onOpenFilter(item.value);
                }}
                sx={{ "&:hover": { color: "red" } }}
                className={classes.filterBtn}
              >
                <FilterIcon
                  style={{
                    padding: 5,
                    borderRadius: 3,
                    backgroundColor: "#F7F7F7",
                    opacity: 0.5,
                    width: 15,
                    height: 15,
                  }}
                />
              </IconButton>
            )}
            {item.value === "chats" && newMessage && (
              <Box className={classes.newMessageDot} />
            )}
          </ListItem>
        ))}
        <ListItem
          className={classes.inActive}
          style={
            splitLocation[1] === "admin"
              ? { marginBottom: 10, marginTop: 10, cursor: "pointer" }
              : { cursor: "pointer" }
          }
          onClick={() => {
            onLogout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon style={{ width: 20, height: 20 }} />
          </ListItemIcon>
          <ListItemText>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              {t("logout")}
            </Typography>
          </ListItemText>
        </ListItem>
        {splitLocation[1] == "admin" ? (
          <Box
            style={{
              flex: 1,
              alignItems: "flex-end",
              display: "flex",
              padding: 20,
              marginBottom: 30,
            }}
          >
            <Typography style={{ fontSize: 13 }}>© 2023 M8S SRL</Typography>
          </Box>
        ) : (
          <Box className={classes.bottomBoxWrapper}>
            <Box className={classes.bottomBox}>
              <Typography fontWeight={600} fontSize={15}>
                {t("findYour")}
              </Typography>
              <Typography fontWeight={600} fontSize={15}>
                {t("perfectMatch")}
              </Typography>

              <Typography fontSize={12}>{t("unlimitedS")}</Typography>
              <Typography fontSize={12}>{t("instantChats")}</Typography>
              <ButtonBase
                style={{
                  backgroundColor: "#EA0F08",
                  marginTop: 10,
                  height: 50,
                  borderRadius: 5,
                }}
                onClick={() => {
                  togglePopup(true);
                  setPopupChildren(<Memberships />);
                }}
              >
                <Typography color="#fff" fontWeight={600} fontSize={14}>
                  {t("changeMembership")}
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        )}
      </Drawer>
    </nav>
  );
};

export default LeftSideBar;
