import { makeStyles } from "@mui/styles";

export const usePlanHeader = makeStyles(({ theme }) => ({
  Wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "left",
    fontSize: "14px !important",
  },
  active: {
    background: "#EA0F08",
    color: "white",
    cursor: "pointer",
    padding: ".5rem",
    textAlign: "center",
    width: "300px",
    "& p": {
      fontSize: "14px !important",
      fontWeight: "500 !important",
    },
  },
  inactive: {
    background: "#FFF",
    color: "black",
    cursor: "pointer",
    padding: ".3rem",
    textAlign: "center",
    width: "300px",
    "& p": {
      fontSize: "14px !important",
      fontWeight: "500 !important",
    },
  },
}));
