import { makeStyles } from '@mui/styles';

export const RightMenuBarStyle = makeStyles((theme) => ({
  menubarWrapper: {
    width: '30px',
    margin: '0 0 0 auto',
    backgroundColor: '#EA0F08',
  },
  menubarInnerContainer: {
    width: '300px',
    minHeight: '500px',
    position: 'relative',
    padding: '15px',
    display: 'inline-block',
  },
  copyrightText: {
    position: 'absolute',
    bottom: '0px',
    marginLeft: '-142px',
    transform: 'rotate(270deg)',
    fontSize: '13px',
    color: '#fff',
  },
}));
