import { makeStyles } from "@mui/styles";

export const ChatsFilterStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex !important",
    backgroundColor: "#fff",
    width: 350,
    padding: 20,
    borderRadius: 10,
  },
  radioWrap: {
    width: 300,
    display: "flex !important",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
    borderRadius: 5,
    backgroundColor: "#E3E5FF",
    fontSize: "13px !important",
  },
  radio: {
    color: "#000 !important",
  },
}));
