import React from "react";
import { Box } from "@mui/system";
import FormField from "../../../../components/FormField";
import { Typography } from "@mui/material";
import useStyles from "./Login.styles";
import Logo from "../../../../resources/img/M8s social white background.png";
import Footing from "../../../../resources/img/footerImg.png";
import CustomButton from "../../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../../../../pages/Authentication/hooks/useAuthentication";
import { useTranslation } from "react-i18next";

function Login() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { state, dispatch, handleLogin } = useAuthentication();
  const { email, password, isLoading } = state;
  const isDisabled = (email && password) === "";
  const { t } = useTranslation();
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageBox}>
        <Box className={classes.logo}>
          <img src={Logo} alt="m8t logo" />
        </Box>
        <form className={classes.formBox} onSubmit={handleLogin}>
          <Box>
            <FormField
              value={email}
              label={t("email")}
              type="email"
              handleChange={(e) => dispatch({ email: e.target.value })}
            />
          </Box>
          <Box>
            <FormField
              value={password}
              label={t("password")}
              isPassword
              handleChange={(e) => dispatch({ password: e.target.value })}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography
              className={classes.formForgot}
              onClick={() => navigate("/auth/forgot")}
            >
              {t("forgotPassword")}
            </Typography>
          </Box>

          <Box>
            <CustomButton
              type="submit"
              disabled={!!isDisabled}
              loading={!!isLoading}
              style={{ borderStyle: "none" }}
            >
              {t("login")}
            </CustomButton>
          </Box>
          <Box>
            <Typography className={classes.formText}>
              {t("agree1")}{" "}
              <a
                href="https://m8s.online/terms"
                target={"__blank"}
                className={classes.formLink}
              >
                {t("terms")}
              </a>{" "}
              {t("and")}{" "}
              <a
                href="https://m8s.online/privacy-policy"
                target={"__blank"}
                className={classes.formLink}
              >
                {t("privacy")}
              </a>
            </Typography>
          </Box>
          <Box marginTop="0px">
            <Typography className={classes.formText}>
              {t("newUser")}{" "}
              <span
                onClick={() => navigate("/auth/signup")}
                className={classes.formLink}
              >
                {t("createAcct")}
              </span>
            </Typography>
          </Box>
        </form>
        <Box className={classes.footing}>
          <img src={Footing} alt="m8t footing" />
        </Box>
      </Box>
    </Box>
  );
}

export default Login;
