const apk = false;

const enVars = {
  environment: apk ? "apk" : process.env.NODE_ENV, // values => development | production

  production: {
    API_URL: "https://m8s-backend-server-c54c2b672054.herokuapp.com/api/v1",
  },
  development: {
    API_URL: "https://m8s-backend-server-c54c2b672054.herokuapp.com/api/v1",
  },
};

const env = {
  ENVIRONMENT: enVars.development,
  ...(enVars.environment === "production"
    ? process.env.REACT_APP_ENV === "development"
      ? enVars.development
      : enVars.production
    : enVars.development),
};

export default env;
