import { makeStyles } from "@mui/styles";

export const ChatsHeaderStyle = makeStyles(({ theme }) => ({
  chatsHeaderWrapper: {
    backgroundColor: "#fff",
    marginTop: 40,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
    dispaly: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row !important",
    padding: "10px 20px",
  },

  text: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
}));
