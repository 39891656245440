import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useUsersFilter from "./hooks/useUsersFilter";
import { USersFilterStyle } from "./UsersFilter.style";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Select from "react-select";
import CustomButton from "../CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { togglePopup } from "../../redux/actions/global/global.actions";
import { setUsersDefaultFilter } from "../../redux/actions/users/users.actions";
import useConstants from "../../utils/useConstants";
import { useTranslation } from "react-i18next";
import useSettingConstants from "../../constants/data/useSettingConstants";
import json from "country-region-data/data.json";
const UsersFilter = ({ page }) => {
  const classes = USersFilterStyle();
  const { getInterests, filter, setFilter, getUsers } = useUsersFilter();
  const { t } = useTranslation();
  const countriesArray = json.map((country) => ({
    value: country?.countryShortCode,
    label: country?.countryName,
  }));
  const [states, setStates] = useState([]);
  const usersDefaultFilter = useSelector(
    (state) => state.users.usersDefaultFilter
  );
  const myData = useSelector((state) => state.users.myData);
  const dispatch = useDispatch();
  const { modes, genderOptions, usersCategoryOptions } = useConstants();
  const { interestsArray } = useSettingConstants();
  useEffect(() => {
    getInterests();
    setStates(
      json
        .filter(
          (country) => country?.countryShortCode === usersDefaultFilter?.country
        )[0]
        ?.regions.map((item) => ({
          value: `${item?.name},${item?.shortCode}`,
          label: item?.name,
        }))
    );
  }, [usersDefaultFilter?.country]);
  const onChangeCountry = (v) => {
    dispatch(
      setUsersDefaultFilter({
        ...usersDefaultFilter,
        country: v?.value,
        state: "",
      })
    );
  };

  const onSlideAge = (event, newValue) => {
    dispatch(
      setUsersDefaultFilter({
        ...usersDefaultFilter,
        min_age: newValue[0],
        max_age: newValue[1],
      })
    );
  };

  return (
    <Box className={classes.filterModalWrapper}>
      <Box className={classes.top}>
        <Typography className={classes.topText}>{t("setPrefer")}</Typography>

        <IconButton onClick={() => dispatch(togglePopup(false))}>
          <HighlightOffIcon color="#000" fontSize="small" />
        </IconButton>
      </Box>
      <Box className={classes.dropdownWrapper}>
        <Typography className={classes.dropdownTitle}>
          {t("location")} ({t("country")})
        </Typography>

        <Select
          // defaultValue={
          //   countries.filter(
          //     (item) => item?.iso2 === usersDefaultFilter?.country
          //   )[0]?.name
          // }
          isDisabled={
            myData?.membership?.name === "ELITE" ||
            myData?.membership?.name === "CLUB"
              ? false
              : true
          }
          options={
            myData?.membership?.name === "ELITE"
              ? countriesArray
              : json
                  ?.filter(
                    (country) =>
                      country?.countryShortCode === myData?.country ||
                      country?.countryShortCode === myData?.club_country
                  )
                  .map((country) => ({
                    value: country?.countryShortCode,
                    label: country?.countryName,
                  }))
          }
          placeholder={
            countriesArray.filter(
              (item) => item?.value === usersDefaultFilter?.country
            )[0]?.name
          }
          onChange={(v) => onChangeCountry(v)}
        />
      </Box>
      <Box className={classes.dropdownWrapper}>
        <Typography className={classes.dropdownTitle}>
          {t("state")} /{t("city")}
        </Typography>
        <Select
          options={states}
          placeholder={`${t("selectRegionIn")} ${
            json.filter(
              (item) => item?.countryShortCode === usersDefaultFilter?.country
            )[0]?.countryName || "..."
          }`}
          onChange={(v) =>
            dispatch(
              setUsersDefaultFilter({ ...usersDefaultFilter, state: v.value })
            )
          }
        />
      </Box>
      <Box className={classes.dropdownWrapper}>
        <Typography className={classes.dropdownTitle}>
          {t("interestedIn")}:
        </Typography>
        <Box
          style={{
            display: "flex",
            border: "0.5px solid #C6C6C6",
            width: "100%",
            borderRadius: 3,
          }}
        >
          {genderOptions.map((item, i) => (
            <CustomButton
              key={i}
              onClick={() =>
                dispatch(
                  setUsersDefaultFilter({
                    ...usersDefaultFilter,
                    gender: item.value,
                  })
                )
              }
              style={{
                borderStyle: "none",
                marginLeft: -1,
                marginRight: -1,
                backgroundColor:
                  usersDefaultFilter?.gender === item.value
                    ? "#ea0f08"
                    : "#fff",
                color:
                  usersDefaultFilter?.gender === item.value
                    ? "#fff"
                    : "#ea0f08",
                border: "0.5px solid #C6C6C6",
              }}
            >
              {item.label}
            </CustomButton>
          ))}
        </Box>
      </Box>
      {page === "discover" ? (
        <Box className={classes.dropdownWrapper}>
          <Typography className={classes.dropdownTitle}>
            {t("selectMode")}
          </Typography>
          <Select
            options={modes}
            placeholder={t("selectMode")}
            onChange={(v) =>
              dispatch(
                setUsersDefaultFilter({ ...usersDefaultFilter, mode: v?.value })
              )
            }
            defaultValue={
              modes?.filter(
                (mode) => usersDefaultFilter?.mode === mode?.value.toUpperCase()
              )[0]?.title || ""
            }
          />
        </Box>
      ) : (
        <Box className={classes.dropdownWrapper}>
          <Typography className={classes.dropdownTitle}>
            {t("interestedIn")}:
          </Typography>
          <Box
            style={{
              display: "flex",
              border: "0.5px solid #C6C6C6",
              width: "100%",
              borderRadius: 3,
            }}
          >
            {usersCategoryOptions.map((item, i) => (
              <CustomButton
                key={i}
                style={{
                  borderStyle: "none",
                  marginLeft: -1,
                  marginRight: -1,
                  backgroundColor:
                    usersDefaultFilter?.category === item.value
                      ? "#ea0f08"
                      : "#fff",
                  color:
                    usersDefaultFilter?.category === item.value
                      ? "#fff"
                      : "#ea0f08",
                  border: "0.5px solid #C6C6C6",
                }}
                onClick={() =>
                  dispatch(
                    setUsersDefaultFilter({
                      ...usersDefaultFilter,
                      category: item.value,
                    })
                  )
                }
              >
                {item.label}
              </CustomButton>
            ))}
          </Box>
        </Box>
      )}

      <Box className={classes.dropdownWrapper}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className={classes.dropdownTitle}>
            {t("byAge")}
          </Typography>
          <Typography
            className={classes.dropdownTitle}
          >{`${usersDefaultFilter?.min_age} - ${usersDefaultFilter?.max_age}`}</Typography>
        </Box>

        <Slider
          getAriaLabel={() => t("ageRange")}
          value={[usersDefaultFilter?.min_age, usersDefaultFilter?.max_age]}
          onChange={onSlideAge}
          valueLabelDisplay="auto"
          color="error"
          min={Number(filter?.min_age)}
          max={Number(filter?.max_age)}
        />
      </Box>
      <Box className={classes.dropdownWrapper}>
        <Typography className={classes.dropdownTitle}>
          {t("interests")}
        </Typography>
        <Select
          options={interestsArray.map((item) => ({
            ...item,
            label: item?.name,
            value: item?.id,
          }))}
          isMulti={true}
          onChange={(value) =>
            dispatch(
              setUsersDefaultFilter({ ...usersDefaultFilter, interests: value })
            )
          }
          defaultValue={usersDefaultFilter?.interests}
        />
      </Box>
      <Box className={classes.dropdownWrapper}>
        <CustomButton
          onClick={() => getUsers(page)}
          style={{ fontWeight: 600, borderStyle: "none" }}
        >
          {t("continue")}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default UsersFilter;
