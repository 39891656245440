import { combineReducers, legacy_createStore } from "redux";
import chatsReducer from "../reducers/chats";
import feedsReducer from "../reducers/feeds";
import globalReducer from "../reducers/global";
import usersReducer from "../reducers/users";

const reducers = combineReducers({
  feeds: feedsReducer,
  users: usersReducer,
  global: globalReducer,
  chats: chatsReducer,
});

const configureStore = () => {
  return legacy_createStore(reducers);
};

export default configureStore;
