import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMyEvents,
  setMyPosts,
  setPosts,
} from "../../../../../redux/actions/feeds/feeds.actions";

import {
  setPopupChildren,
  togglePopup,
} from "../../../../../redux/actions/global/global.actions";
import feedsService from "../../../../../services/feeds.service";
import { getToken } from "../../../../../utils/helpers";
import { useConfirm } from "material-ui-confirm";
import Gifts from "../../../../../components/Gifts";
import {
  setGettingPreview,
  setPreviewUser,
} from "../../../../../redux/actions/users/users.actions";
import usersService from "../../../../../services/users.service";
import UserPreview from "../../../../../components/UserPreview";
import { toast } from "react-toastify";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function usePostCards() {
  const reduxDispatch = useDispatch();
  const token = getToken();
  const posts = useSelector((state) => state.feeds.posts);
  const myPosts = useSelector((state) => state.feeds.myPosts);
  const confirm = useConfirm();
  const [isLoading, setIsLoading] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const { t } = useTranslation();
  const getCaption = (post) => {
    const splitString = post?.caption?.split(" ");
    let newWord = [];

    for (var i = 0; i < splitString?.length; i++) {
      if (post?.tags?.map((tag) => tag?.name)?.includes(splitString[i])) {
        const tagIndex = post?.tags?.findIndex(
          (tag) => tag?.name == splitString[i]
        );
        newWord?.push(
          <span
            key={i}
            onClick={() => {
              getUser(post?.tags[tagIndex]?.user?.id);
              reduxDispatch(setPopupChildren(<UserPreview />));
              reduxDispatch(togglePopup(true));
            }}
            style={{
              color: "#EA0F07",
              fontFamily: "Avenir",
              fontWeight: "bold",
              fontSize: 14,
              cursor: "pointer",
            }}
          >
            <span
              style={{
                fontSize: 12,
                color: "#EA0F07",
                fontFamily: "Avenir",
              }}
            >
              @
            </span>
            {post?.tags[tagIndex]?.user?.username}{" "}
          </span>
        );
      } else {
        newWord?.push(`${splitString[i]} `);
      }
    }

    return newWord;
  };

  const getUser = (userId) => {
    reduxDispatch(setGettingPreview(true));
    usersService
      .getUser(token, userId)
      .then(({ data }) => {
        reduxDispatch(setPreviewUser(data));
      })
      .catch((err) => console.log(err))
      .finally(() => reduxDispatch(setGettingPreview(false)));
  };

  const getPostEvents = () => {
    feedsService
      .getPostEvents(token)
      .then((res) => {
        reduxDispatch(setMyEvents(res?.data.posts));
      })
      .catch((err) => console.log(err));
  };
  const likePost = (postId) => {
    reduxDispatch(
      setPosts(
        posts?.map((post) =>
          post?.id === postId ? { ...post, liked: !post?.liked } : post
        )
      )
    );
    reduxDispatch(
      setMyPosts(
        myPosts?.map((post) =>
          post?.id === postId ? { ...post, liked: !post?.liked } : post
        )
      )
    );
    feedsService
      .likePosts(token, postId)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const getPosts = () => {
    feedsService
      .getPosts(token)
      .then((res) => {
        reduxDispatch(setPosts(res.data.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };
  const handleEventRequest = (id) => {
    setSendingRequest(true);
    feedsService
      .eventRequest(token, id)
      .then((res) => {
        getPosts();
        getPostEvents();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setSendingRequest(false);
      });
  };

  const getMyPosts = () => {
    setIsLoading(true);
    feedsService
      .getMyPosts(token, 1, 1000)
      .then((res) => {
        reduxDispatch(setMyPosts(res?.data?.data));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const hidePost = (postId) => {
    reduxDispatch(setPosts(posts?.filter((post) => post?.id != postId)));
  };

  const handleHidePost = (postId) => {
    confirm({
      title: `${t("hide")} ${t("post")}`,
      description: t("willNotSeePost"),
      cancellationText: t("cancel"),
      confirmationText: t("proceed"),
    })
      .then(() => hidePost(postId))
      .catch(() => console.log("Cancelled hide post."));
  };

  const handleOpenGifts = (user) => {
    reduxDispatch(setPopupChildren(<Gifts reciever={user} />));
    reduxDispatch(togglePopup(true));
  };

  const removePost = (postId) => {
    feedsService
      .removePost(token, postId)
      .then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          getMyPosts();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {});
  };

  return {
    getCaption,
    likePost,
    handleHidePost,
    handleOpenGifts,
    getMyPosts,
    removePost,
    isLoading,
    getUser,
    handleEventRequest,
    sendingRequest,
  };
}

export default usePostCards;
