import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  searchBox: {
    height: 40,

    "& .MuiOutlinedInput-root ": {
      height: 45,
      padding: "0px 30px 0px 10px !important",
      borderRadius: 0,
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
      outline: "none !important",

      "& fieldset": {
        border: "1px solid #ccc !important",
        outline: "none !important",
      },
    },
    "& .Mui-focused": {
      border: "1px solid #ccc !important",
      outline: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #ccc !important",
      outline: "none !important",
    },
    "& input,": {
      height: 45,
      padding: 0,
      fontSize: 14,
      fontWeight: "500 !important",
    },
    "& .MuiAutocomplete-input": {
      height: 45,
      padding: "0px !important",
      fontSize: 14,
      fontWeight: "500 !important",

      "& fieldset:focus": {
        border: "1px solid #ccc !important",
        outline: "none !important",
      },
    },
  },
  fieldLabel: {
    fontFamily: "Avenir",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    paddingLeft: 10,
    color: "#293146",
    marginBottom: 5,
  },
}));
