import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setGifts,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import usersService from "../../../services/users.service";
import globalService from "../../../services/global.service";
import chatsService from "../../../services/chats.service";
import { getToken, filteredChat, setUser } from "../../../utils/helpers";
import { toast } from "react-toastify";
import { setMyData } from "../../../redux/actions/users/users.actions";
import {
  setMessagesInView,
  setMessagingUser,
  setChats,
} from "../../../redux/actions/chats/chats.actions";
import socketService from "../../../services/socket.service";

const useGifts = () => {
  const dispatch = useDispatch();
  const [gettingGifts, setGettingGifts] = useState(false);
  const [sendingGifts, setSendingGifts] = useState(false);
  const chatsFilterVal = useSelector((state) => state.chats.chatsFilterVal);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGift, setSelectedGift] = useState(null);
  const token = getToken();
  const navigate = useNavigate();

  const getGifts = () => {
    setGettingGifts(true);
    globalService
      .getGifts(token)
      .then(({ data }) => {
        dispatch(setGifts(data));
      })
      .catch((err) => console.log(err))
      .finally(() => setGettingGifts(false));
  };
  const onSendGift = (giftId, userId, goTo) => {
    setSendingGifts(true);
    if (goTo === "messages") {
      socketService.emit(
        "message",
        {
          gift: Number(giftId),
          receiver: Number(userId),
          type: "gift",
          text: "sent a gift",
        },
        (res) => {
          if (res.status) {
            getUser(userId);

            getMe();
            toast.success("Successfully sent gift");
            setSendingGifts(false);
            dispatch(togglePopup(false));
            getMessagesWithUser(userId);
          } else {
            toast.error("Unable to send gift");
            setSendingGifts(false);
          }
        }
      );
    } else {
      globalService
        .sendGift(token, userId, giftId)
        .then((res) => {
          if (res.success === true) {
            getMe();
            toast.success("Successfully sent gift");
          } else {
            toast.error(res?.errors || "Unable to send gift");
          }
        })
        .catch((err) => {
          toast.error("Unable to send gift");
        })
        .finally(() => {
          setSendingGifts(false);
          setOpenDialog(false);
          dispatch(togglePopup(false));
        });
    }
  };

  const getMessagesWithUser = (userId) => {
    dispatch(setMessagesInView([]));
    chatsService
      .getMessagesWithUser(token, userId, 1, 25)
      .then((res) => {
        if (res?.success === true) {
          dispatch(setMessagesInView(res?.data?.data));
          getChats();
        }
      })
      .catch((err) => toast.error(err));
  };

  const getUser = (id) => {
    usersService
      .getUser(token, id)
      .then((res) => {
        if (res?.success === true) {
          dispatch(setMessagingUser(res?.data));
          navigate("/chats");
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => toast.error(err))
      .finally(() => {});
  };

  const getMe = () => {
    usersService.getMe(token).then(({ data }) => {
      dispatch(setMyData(data));
      setUser(data);
    });
  };

  const getChats = () => {
    chatsService
      .getChats(token)
      .then((res) => {
        if (res?.success === true) {
          dispatch(setChats(filteredChat(res?.data?.data, chatsFilterVal)));
        }
      })
      .catch((err) => console.log("err are ", err))
      .finally(() => {});
  };

  return {
    getGifts,
    gettingGifts,
    onSendGift,
    sendingGifts,
    setOpenDialog,
    openDialog,
    selectedGift,
    setSelectedGift,
  };
};

export default useGifts;
