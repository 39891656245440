import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setMyReferrals } from "../../../../../redux/actions/users/users.actions";
import usersService from "../../../../../services/users.service";
import { getToken } from "../../../../../utils/helpers";

function useReferral() {
  const token = getToken();
  const reduxDispatch = useDispatch();
  const [isGettingReferrals, setIsGettingReferrals] = useState(false);

  const getReferral = () => {
    setIsGettingReferrals(true);
    usersService
      .getReferral(token)
      .then((res) => {
        reduxDispatch(setMyReferrals(res?.data?.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsGettingReferrals(false);
      });
  };
  return {
    getReferral,
    isGettingReferrals,
  };
}

export default useReferral;
