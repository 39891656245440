import { makeStyles } from "@mui/styles";

export const ActivitiesStyles = makeStyles(({ theme }) => ({
  pageWrapper: {
    flexDirection: "column",
    width: "100%",
    // height: "100%",
    backgroundColor: "#F7F7F7",
    position: "relative",

    overflow: "hidden",
  },
  activitiesMainWrap: {
    backgroundColor: "#F7F7F7",
    marginTop: 15,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
    // justifyContent: "space-between",
    height: "calc(100vh - 145px)",
    // overflow: "auto",
    gap: 24,
    dispaly: "flex !important",
    flexDirection: "row !important",
  },

  activitiesLeft: {
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -5px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    width: "25%",
    backgroundColor: "#fff !important",
  },
  activitiesRight: {
    display: "flex",
    flexDirection: "column !important",
    flex: 1,
    backgroundColor: "#fff",
    padding: 15,
    overflow: "auto",
  },
  userImg: {
    height: "50px",
    width: "50px",
    objectFit: "cover",
    clipPath: "circle(50% at 50% 50%)",
  },
  message: {
    fontSize: "12px !important",
    color: "#bbb",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));
