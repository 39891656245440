import React from "react";
import { useDispatch } from "react-redux";
import useStyles from "./MakeEvent.styles";
import useFeeds from "./../../hooks/useFeeds";
import { useEffect } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { togglePopup } from "../../../../redux/actions/global/global.actions";
import CloseModal from "../../../../resources/img/close_modal.svg";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { ReactComponent as LocationIcon } from "../../../../resources/img/location-pin.svg";
import CustomButton from "../../../../components/CustomButton";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { imageExts } from "../../../../utils/constants";
import { Circles } from "react-loader-spinner";

function MakeEvent() {
  const classes = useStyles();
  const reduxDispatch = useDispatch();
  const { state, dispatch, getGeoLocation, createPost,files,setFiles } = useFeeds();
  const { caption, location, eventDate, isLoading } = state;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getGeoLocation();
  }, []);


  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": imageExts },
    multiple: false,
    maxSize: 15728640,
    minSize: 0,
    onDrop: (acceptedFiles) => {
      setFiles([...acceptedFiles]);
      dispatch({ media: acceptedFiles });
    },
  });

  const moment = require("moment");
  const date = moment(eventDate);
  const formatedDate = date.format("DD/MMM/YYYY HH:MM A");

  const dateTime = formatedDate.split(" ");
  const time = `${dateTime[1]} ${dateTime[2]}`;
  const date_components = dateTime[0].split("/");
  const day = date_components[0];
  const month = date_components[1];
  const year = date_components[2];

  const handleDatePickerClose = () => {
    setIsOpen(false);
  };

  const [value, setValue] = useState("");
  // const [isSavingAddress, setIsSavingAddress] = useState(false);
  const isEvent = true;

  const handleChange = (address) => {
    dispatch({ location: address });
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  return (
    <form
      className={classes.postModal}
      onSubmit={(e) => createPost(e, isEvent)}
    >
      <div className={classes.topHalf}>
        <div className={classes.topHead}>
          <div
            className={classes.close}
            onClick={() => reduxDispatch(togglePopup(false))}
          >
            <img src={CloseModal} alt="close modal" />
          </div>
          <Typography className={classes.headerText}>New Event</Typography>
          <button type="submit" className={classes.headerText2}>
            Create Event
          </button>
        </div>
        <div className={classes.postImage} {...getRootProps()}>
          {isLoading && (
            <Circles
              color="red"
              ariaLabel="circles-loading"
              wrapperClass={classes.topSpinner}
              visible={true}
            />
          )}
          <input {...getInputProps()} />
          {!files?.length && (
            <div className={classes.uploaderIcon}>
              <span>Drag and drop or browse to choose file</span>
            </div>
          )}
          {files?.map((file, index) => {
            return (
              <div key={index} style={{ width: "100%", height: "100%" }}>
                <img src={URL.createObjectURL(file)} alt={file.name} />
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.lowerHalf}>
        <div>
          <Typography className={classes.captionHead}>
            Write a caption
          </Typography>
          <TextField
            multiline
            value={caption}
            onChange={(e) => dispatch({ caption: e.target.value })}
            className={classes.captionField}
          />
        </div>

        <div>
          <Typography className={classes.captionHead}>
            Add a location
          </Typography>
          <Grid container spacing={1} className={classes.locationBox}>
            <Grid item sm={12}>
              <PlacesAutocomplete
                value={location}
                onChange={handleChange}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className={classes.wowo}>
                    <input
                      {...getInputProps({
                        placeholder: "",
                        className: "location-search-input",
                      })}
                    />
                    <span className="locationIcon">
                      <LocationIcon />
                    </span>
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#d0d0d0", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span
                              onClick={() =>
                                dispatch({ location: suggestion.description })
                              }
                            >
                              {suggestion.description}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Grid>
            {/* <Grid item sm={3}>
              <CustomButton onClick={() => onUseMaps()}>Use Map</CustomButton>
            </Grid> */}
          </Grid>
        </div>
        <div>
          <Grid container spacing={2} className={classes.locationBox}>
            <Grid item sm={8}>
              <Typography className={classes.captionHead}>
                Event Date
              </Typography>
              <Box className={classes.btnBox}>
                <span
                  className={classes.day}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {day === "Invalid" ? "DD" : day}
                </span>
                <span
                  className={classes.month}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {month || "MM"}
                </span>
                <span
                  className={classes.year}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {year || "YYYY"}
                </span>
              </Box>
            </Grid>
            <Grid item sm={4}>
              <Typography className={classes.captionHead}>Time</Typography>
              <Grid item sm={12}>
                <Box className={classes.btnBox}>
                  <span
                    className={classes.time}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {time === "date undefined" ? "HH/MM" : time}
                  </span>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ display: "none" }}>
            <DateTimePicker
              open={isOpen}
              onClose={handleDatePickerClose}
              label="Date&Time picker"
              value={value}
              minDate={new Date(Date.now())}
              onChange={(val) => dispatch({ eventDate: val })}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </div>
        <div style={{ marginTop: "20px" }}>
          <CustomButton>Apply</CustomButton>
        </div>
      </div>
    </form>
  );
}

export default MakeEvent;
