import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStyles from "./SplashScreen.style";
import Logo from "../../../../assets/images/m8s-white-transparent.png";
import { Box } from "@mui/material";

function SplashScreen() {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const splitLocation = location.pathname?.split("/");
  useEffect(() => {
    setTimeout(() => {
      if (splitLocation[1] == "admin") {
        navigate("/admin/auth/login");
      } else {
        navigate("/auth/login");
      }
    }, 3000);
  }, [navigate]);

  return (
    <Box className={classes.root}>
      <Box className={classes.iconWrapper}>
        <img src={Logo} alt="m8t logo" />
      </Box>
    </Box>
  );
}

export default SplashScreen;
