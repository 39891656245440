import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { usernameStringCheck } from "../../utils/helpers";
import { GiftsStyles } from "./Gifts.styles";
import useGifts from "./hooks/useGifts";
const Gifts = ({ gifts, myData, togglePopup, reciever, goTo }) => {
  const classes = GiftsStyles();
  const {
    getGifts,
    openDialog,
    setOpenDialog,
    sendingGifts,
    onSendGift,
    setSelectedGift,
    selectedGift,
  } = useGifts();
  useEffect(() => !gifts.length && getGifts(), []);

  return (
    <Box className={classes.giftModalWrapper}>
      <Box className={classes.top}>
        <Box>
          <Typography className={classes.topText}>Share a Gift</Typography>
        </Box>
        <Box
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
            gap: 10,
          }}
        >
          <img
            height={20}
            width={20}
            alt="m8s_coins"
            src={require("../../assets/images/coin.png")}
          />
          <Typography className={classes.topText}>
            {myData?.coins} coins
          </Typography>
          <IconButton onClick={() => togglePopup(false)}>
            <HighlightOffIcon color="#000" fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.giftsContainer}>
        {gifts?.map((gift, i) => (
          <IconButton
            onClick={() => {
              setSelectedGift(gift);
              setOpenDialog(true);
            }}
            className={classes.giftButton}
          >
            <Box>
              <LazyLoadImage
                effect="blur"
                src={gift?.icon}
                alt={gift?.name}
                height={100}
                width={100}
              />
            </Box>
            <Box className={classes.giftDetail}>
              <Typography className={classes.giftText}>
                {gift?.value}
              </Typography>
              <img
                alt="m8s"
                height={20}
                width={20}
                style={{ marginTop: -5 }}
                src={require("../../assets/images/logo.png")}
              />
              <Typography className={classes.giftText}>coins</Typography>
            </Box>
          </IconButton>
        ))}
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Send Gift</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to send gift to @
            {usernameStringCheck(reciever?.username)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            disabled={sendingGifts}
            onClick={() => {
              onSendGift(selectedGift?.id, reciever?.id, goTo);
            }}
            autoFocus
          >
            {sendingGifts ? "Please wait..." : "Yes"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Gifts;
