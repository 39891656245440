export const SET_SHOW_POPUP = Symbol();
export const SET_POPUP_CHILDREN = Symbol();
export const SET_CONFIRM_MODAL = Symbol();
export const SET_GIFTS = Symbol();
export const SET_INTERESTS = "SET_INTERESTS";
export const SET_SOCKET = "SET_SOCKET";
export const SET_PEER = "SET_PEER";
export const SET_CALLING = "SET_CALLING";
export const SET_ON_CALL = "SET_ON_CALL";
export const SET_OUTGOING_CALL = "SET_OUTGOING_CALL";
export const SET_INCOMING_CALL = "SET_INCOMING_CALL";
export const SET_CALLER = "SET_CALLER";
export const SET_REMOTE_STREAM = "SET_REMOTE_STREAM";
export const SET_LOCAL_STREAM = "SET_LOCAL_STREAM";
export const SET_MEMBERSHIPS = "SET_MEMBERSHIPS";
export const SET_GETTING_MEMBERSHIPS = "SET_GETTING_MEMBERSHIPS";
export const SET_STORY_MODAL = "SET_STORY_MODAL";
export const SET_RINGTONE = "SET_RINGTONE";
export const SET_FIRST_COMPARABLE = "SET_FIRST_COMPARABLE";
export const SET_SECOND_COMPARABLE = "SET_SECOND_COMPARABLE";
export const SET_THIRD_COMPARABLE = "SET_THIRD_COMPARABLE";
export const SET_PERIODS = "SET_PERIODS";
export const SET_DATES = "SET_DATES";
