import { makeStyles } from '@mui/styles';

export const BlockedAccountsStyles = makeStyles((theme) => ({
  pageWrap: {
    display: 'flex !important',
    flexDirection: 'column',
    gap: 8,
  },
  section: {
    height: 30,
    backgroundColor: '#fff',
    padding: 20,

    display: 'flex !important',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subText: {
    fontSize: '13px !important',
  },
  unblockBtn: {
    borderStyle: 'none',
    backgroundColor: '#EA0F08',
    width: 100,
    padding: 10,
    color: '#fff',
    cursor: 'pointer',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
