import { makeStyles } from "@mui/styles";

export const usePlan = makeStyles(({ theme }) => ({
  Wrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    gap: ".5rem",
  },
  body: {
    height: "100%",
  },
}));
