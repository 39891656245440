import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useUpgradeModal } from "./Upgrade.style";
import { CloseOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import { ReactComponent as Profile } from "../../.././assets/icons/upgradeIcons/profile.svg";
import { ReactComponent as Heart } from "../../.././assets/icons/upgradeIcons/heart.svg";
import { ReactComponent as VideoCall } from "../../.././assets/icons/upgradeIcons/video_calls.svg";
import { ReactComponent as VideoAudio } from "../../.././assets/icons/upgradeIcons/video&audio.svg";
import { ReactComponent as DailyMatch } from "../../.././assets/icons/upgradeIcons/dailymatch.svg";
import { ReactComponent as Chatbubble } from "../../.././assets/icons/upgradeIcons/chatbubble.svg";
import { ReactComponent as Globe } from "../../.././assets/icons/upgradeIcons/globe.svg";
import { useTranslation } from "react-i18next";
import Memberships from "../../Memberships";

export default function Upgrade() {
  const classes = useUpgradeModal();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Box className={classes.Wrapper}>
      <CloseOutlined
        onClick={() => dispatch(togglePopup(false))}
        className={classes.closeBtn}
      />
      <Typography className={classes.title}>{t("makeEasy")}</Typography>
      <Box className={classes.listContainer}>
        <Box className={classes.list}>
          <Box className={classes.IconContainer}>
            <Profile />
          </Box>
          <Typography>{t("smartMatch50")}</Typography>
        </Box>
        <Box className={classes.list}>
          <Box className={classes.IconContainer}>
            <Heart />
          </Box>
          <Typography>{t("seeLikeDiscovery")}</Typography>
        </Box>
        <Box className={classes.list}>
          <Box className={classes.IconContainer}>
            <VideoCall />
          </Box>
          <Typography>{t("instantMessaging")}</Typography>
        </Box>
        <Box className={classes.list}>
          <Box className={classes.IconContainer}>
            <VideoAudio />
          </Box>
          <Typography>{t("unlimitedMatch")}</Typography>
        </Box>
        <Box className={classes.list}>
          <Box className={classes.IconContainer}>
            <DailyMatch />
          </Box>
          <Typography>{t("voiceAudioEnabled")}</Typography>
        </Box>
        <Box className={classes.list}>
          <Box className={classes.IconContainer}>
            <Chatbubble />
          </Box>
          <Typography>{t("videoAllowed")}</Typography>
        </Box>
        <Box className={classes.list}>
          <Box className={classes.IconContainer}>
            <Globe />
          </Box>
          <Typography>{t("accessAll")}</Typography>
        </Box>
      </Box>
      <Button
        onClick={() => {
          dispatch(togglePopup(true));
          dispatch(setPopupChildren(<Memberships />));
        }}
        className={classes.mainBtn}
      >
        {t("premium")}
      </Button>
    </Box>
  );
}
