import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import PostCards from "../../../../../Feeds/components/PostCards";
import usePostCards from "../../../../../Feeds/components/PostCards/hooks/usePostCards";
import { MyPostsStyles } from "./MyPosts.styles";

const MyPosts = () => {
  const classes = MyPostsStyles();

  const { getMyPosts, isLoading } = usePostCards();
  useEffect(() => {
    getMyPosts();
  }, []);
  const myPosts = useSelector((state) => state.feeds.myPosts);

  const justPost = myPosts?.filter((post) => post.is_event === false);
  return (
    <Box className={classes.container}>
      {!isLoading ? (
        justPost.length > 0 ? (
          justPost?.map((post, i) => {
            return (
              <PostCards
                key={i}
                i={i}
                profileName={post.user.username}
                profileLocation={post.post_location}
                cardCaption={post.caption}
                profileImg={post.user.avatar}
                cardImage={post.image}
                post={post}
                containerStyle={{
                  border: "1px solid red",
                  backgroundColor: "red !important",
                  width: 400,
                }}
              />
            );
          })
        ) : (
          <Box sx={{ margin: "auto", width: "100%", textAlign: "center" }}>
            No Posts Found
          </Box>
        )
      ) : (
        <Box sx={{ margin: "0 auto" }}>
          <Skeleton
            count={3}
            height={400}
            style={{ margin: "20px auto", width: "600px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default MyPosts;
