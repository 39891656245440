import PopupModal from "./PopupModal";
import {
  togglePopup,
  setPopupChildren,
} from "../../redux/actions/global/global.actions";
import { connect } from "react-redux";
const mapState = ({ global }) => ({
  showPopup: global.showPopup,
  popupChildren: global.popupChildren,
  confirmModal: global.confirmModal,
  onModalDismiss: global.onModalDismiss,
  calling: global.calling,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopup: (data) => dispatch(togglePopup(data)),
  setPopupChildren: (data) => dispatch(setPopupChildren(data)),
});
export default connect(mapState, mapDispatchToProps)(PopupModal);
