import { makeStyles } from "@mui/styles";

export const GiftsStyles = makeStyles((theme) => ({
  giftModalWrapper: {
    backgroundColor: "#fff",
    width: 450,
    padding: 20,
    borderRadius: 15,
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 10px",
  },
  topText: { fontWeight: "500 !important", fontSize: "20px !important" },
  giftsContainer: {
    flexDirection: "row",
    alignItems: "center",
    display: "grid",
    gap: 20,
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
  },
  giftButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  giftDetail: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  giftText: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    color: "#000",
  },
}));
