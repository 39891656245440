import {
  Box,
  IconButton,
  Input,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { ReactComponent as GiftIcon } from "../../../../../../assets/icons/gift_white.svg";
import { MessageFooterStyle } from "./MessageFooter.style";
import useMessageFooter from "./hooks/useMessageFooter";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import MovieIcon from "@mui/icons-material/Movie";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Circles, ThreeDots } from "react-loader-spinner";
import { videoExts } from "../../../../../../utils/constants";
import DeleteIcon from "@mui/icons-material/Delete";
import StopIcon from "@mui/icons-material/Stop";
import { AttachFile, Close, HighlightOff } from "@mui/icons-material";
import { fileType, usernameStringCheck } from "../../../../../../utils/helpers";
import { useTranslation } from "react-i18next";
const MessageFooter = ({
  messagingUser,
  messageRef,
  messageInputRef,
  setMessageRef,
  myData,
}) => {
  const classes = MessageFooterStyle();
  const {
    onOpenGifts,
    onEmitTypingAction,
    textMessage,
    onTyping,
    onSubmitMessage,
    sendingText,

    open,
    fileToAccept,
    setFileToAccept,
    mediaFile,

    onSendMedia,
    mediaMessage,
    sendingMedia,
    fileExt,
    onRemoveMedia,
    startRecording,
    stopRecording,
    recorded,
    recording,
    seconds,
    minutes,
    hours,
    handleKeyDown,
    greetings,
  } = useMessageFooter({ messageRef, setMessageRef });
  const [openPlus, setOpenPlus] = useState(null);
  const openIcon = Boolean(openPlus);
  const handleClick = (event) => {
    setOpenPlus(event.currentTarget);
  };
  const handleClose = () => {
    setOpenPlus(null);
  };
  const { t } = useTranslation();
  useEffect(() => {
    return () => onEmitTypingAction(messagingUser.id, false);
  }, []);
  return (
    <Box className={classes.messageFooterContainer}>
      {recording || recorded ? (
        <Box
          className={classes.footerInner}
          style={{
            backgroundColor: "#E00E08",
            height: 30,
            justifyContent: "space-between",
            borderRadius: 50,
          }}
        >
          <Box />
          {recording && (
            <Box className={classes.recordingInfo}>
              <ThreeDots
                height="20"
                width="20"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ marginRight: 5 }}
                wrapperClassName=""
                visible={true}
              />
              <Typography color="#fff">Recording</Typography>
              <ThreeDots
                height="20"
                width="20"
                radius="9"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ marginLeft: 5 }}
                wrapperClassName=""
                visible={true}
              />
            </Box>
          )}

          <Box className={classes.recordingRight}>
            {hours !== 0 && (
              <Typography color={"#fff"}>{`${hours} : `}</Typography>
            )}
            <Typography color={"#fff"}>{`${minutes} : ${seconds}`}</Typography>
            {!recording && recorded ? (
              <>
                <IconButton
                  onClick={onRemoveMedia}
                  className={classes.actionButtons}
                  style={{ backgroundColor: "#fff" }}
                >
                  <DeleteIcon style={{ color: "#EA0F08" }} />
                </IconButton>
                <IconButton
                  onClick={onSendMedia}
                  disabled={sendingMedia}
                  className={classes.actionButtons}
                  style={{ backgroundColor: "#fff" }}
                >
                  {sendingMedia ? (
                    <Circles
                      height="20"
                      width="20"
                      color="#02BC3C"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    <SendIcon style={{ color: "#02BC3C" }} />
                  )}
                </IconButton>
              </>
            ) : (
              <IconButton
                onClick={() => stopRecording()}
                className={classes.actionButtons}
                style={{ backgroundColor: "#fff" }}
              >
                <StopIcon style={{ color: "#000" }} width={18} height={18} />
              </IconButton>
            )}
          </Box>
        </Box>
      ) : (
        <Box className={classes.footerInner}>
          {mediaMessage && (
            <Box className={classes.mediaMessageBox}>
              {!sendingMedia && (
                <IconButton
                  size={"xs"}
                  className={classes.removeBtn}
                  disabled={sendingMedia}
                  onClick={() => onRemoveMedia()}
                >
                  <HighlightOffIcon
                    size={10}
                    style={{
                      color: "red",
                      borderRadius: "100%",
                      padding: 0,
                      width: 15,
                      height: 15,
                    }}
                  />
                </IconButton>
              )}
              {sendingMedia && (
                <Circles
                  height="50"
                  width="50"
                  color="red"
                  ariaLabel="circles-loading"
                  wrapperStyle={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    backgroundColor: "#000",
                    opacity: 0.5,
                    borderRadius: 5,
                  }}
                  wrapperClass=""
                  visible={true}
                  style={{}}
                />
              )}

              {videoExts.includes(fileExt) ? (
                <video
                  src={mediaFile}
                  controls
                  style={{ objectFit: "contain", width: 100, height: 50 }}
                />
              ) : (
                <img
                  src={mediaFile}
                  style={{
                    width: 50,
                    height: 50,
                    objectFit: "contain",
                    borderRadius: 5,
                  }}
                  alt="img"
                />
              )}
            </Box>
          )}

          <Box className={classes.inputBox}>
            {messageRef && (
              <Box className={classes.messageRefWrapper}>
                <Box
                  className={classes.messageRefInner}
                  style={{
                    backgroundColor: messageRef?.sender ? "#E00E08" : "#F4F5F9",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {messageRef?.sender ? (
                      <Typography
                        style={{
                          color: "#fff",
                          fontWeight: "700",
                          opacity: 0.5,
                          textDecorationLine: "underline",
                          fontSize: 13,
                        }}
                      >
                        {t("you")}
                      </Typography>
                    ) : (
                      <Typography
                        style={{
                          color: "#000",
                          fontWeight: "700",
                          opacity: 0.5,
                          textDecorationLine: "underline",
                          fontSize: 13,
                          display: "flex",
                        }}
                      >
                        <Typography style={{ color: "#ADABAB", fontSize: 13 }}>
                          @
                        </Typography>
                        {usernameStringCheck(messagingUser?.username)}
                      </Typography>
                    )}
                    <IconButton onClick={() => setMessageRef(null)}>
                      <HighlightOff
                        style={{
                          color: messageRef?.sender ? "#fff" : "#000",
                          width: 16,
                          height: 16,
                        }}
                      />
                    </IconButton>
                  </Box>

                  <Typography
                    style={{ color: messageRef?.sender ? "#fff" : "#000" }}
                    numberOfLines={1}
                  >
                    {messageRef?.text
                      ? messageRef?.text
                      : fileType(messageRef?.media) == "video"
                      ? `...🎥${t("video")}...`
                      : fileType(messageRef?.media) == "image"
                      ? `...📸${t("image")}...`
                      : fileType(messageRef?.media) == "audio"
                      ? `...🎞${t("audio")}...`
                      : `...📎${t("file")}...`}
                  </Typography>
                </Box>
              </Box>
            )}
            <Input
              style={{ width: "100%" }}
              type={"text"}
              multiline={true}
              maxRows={5}
              value={textMessage}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={sendingText || sendingMedia}
                    onClick={(e) => {
                      e.preventDefault();
                      mediaMessage
                        ? onSendMedia()
                        : onSubmitMessage(messagingUser?.id);
                    }}
                  >
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
              onFocus={() => onEmitTypingAction(messagingUser.id, true)}
              onBlur={() => onEmitTypingAction(messagingUser.id, false)}
              onChange={(e) => onTyping(e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, messagingUser?.id)}
              ref={messageInputRef}
            />
          </Box>
          <Box
            style={{
              width: "20%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <IconButton
              className={classes.actionButtons}
              style={{ backgroundColor: "#E00E08" }}
              onClick={() => onOpenGifts(messagingUser)}
            >
              <GiftIcon
                style={{ stroke: "#fff", fill: "#fff", color: "fff" }}
                stroke={"#fff"}
                width={18}
                color="#fff"
                height={18}
                fill={"#fff"}
              />
            </IconButton>

            <IconButton
              onClick={() => startRecording()}
              className={classes.actionButtons}
              style={{ backgroundColor: "#000" }}
            >
              <MicNoneOutlinedIcon
                style={{ color: "#fff" }}
                width={18}
                height={18}
              />
            </IconButton>

            <IconButton
              id="basic-button"
              aria-controls={openIcon ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openIcon ? "true" : undefined}
              onClick={open}
              className={classes.actionButtons}
              style={{ backgroundColor: "#000" }}
            >
              <AttachFile style={{ color: "#fff" }} width={18} height={18} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={openPlus}
              open={openIcon}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={() => setFileToAccept("video")}>
                <ListItemIcon>
                  <MovieIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Video</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => setFileToAccept("image")}>
                <ListItemIcon>
                  <InsertPhotoIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Image</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default MessageFooter;
