import {
  SET_GETTING_POSTS,
  SET_MY_EVENTS,
  SET_MY_POSTS,
  SET_POSTS,
  SET_STORIES,
} from "../../actions/feeds/feeds.types";

const defaultState = {
  posts: [],
  stories: [],
  gettingPosts: false,
  myPosts: [],
  myEvents: [],
};

export default function feedsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_POSTS:
      return { ...state, posts: action.payload };
    case SET_STORIES:
      return { ...state, stories: action.payload };
    case SET_GETTING_POSTS:
      return { ...state, gettingPosts: action.payload };
    case SET_MY_POSTS:
      return { ...state, myPosts: action.payload };
    case SET_MY_EVENTS:
      return { ...state, myEvents: action.payload };
    default:
      return state;
  }
}
