import React, { useEffect, Component } from "react";
import { useParams } from "react-router";
import "./Onboarding.styles.css";
import Mode from "./Mode/Mode";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import Plans from "./Plans/Plans";
import useOnboarding from "./hooks/useOnboarding";
import Interest from "./Interests/Interest";
import Verify from "./Verify";
import Confirmation from "./Confirmation";
import PopupModal from "../../components/PopupModal";

function Onboarding() {
  const { getMemberships, getInterests } = useOnboarding();

  useEffect(() => {
    getMemberships();
    getInterests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { page } = useParams();

  const pages = ["personal", "plans", "mode", "interest"];
  const isImageBack = pages.includes(page);

  switch (page) {
    case "personal":
      Component = <PersonalDetails />;
      break;
    case "plans":
      Component = <Plans />;
      break;
    case "mode":
      Component = <Mode />;
      break;
    case "interest":
      Component = <Interest />;
      break;
    case "verification":
      Component = <Verify />;
      break;
    case "confirmation":
      Component = <Confirmation />;
      break;
    default:
      break;
  }

  return (
    <div className={isImageBack ? "onboardingPage" : "verificationPage"}>
      <PopupModal />
      {Component}
    </div>
  );
}

export default Onboarding;
