import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import globalService from "../../../services/global.service";
import { getToken, shuffledArray } from "../../../utils/helpers";
import {
  setInterests,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import {
  setDiscoverUsers,
  setGettingDiscover,
  setGettingNearby,
  setNearbyUsers,
} from "../../../redux/actions/users/users.actions";
import usersService from "../../../services/users.service";
import { toast } from "react-toastify";

const useUsersFilter = () => {
  const token = getToken();
  const dispatch = useDispatch();
  const usersDefaultFilter = useSelector(
    (state) => state.users.usersDefaultFilter
  );
  const [filter, setFilter] = useState(usersDefaultFilter);

  const getInterests = () => {
    globalService
      .getInterests(token)
      .then((res) => {
        if (res?.success) {
          dispatch(setInterests(res?.data));
        }
      })
      .catch((err) => console.log(err));
  };

  const getUsers = (page) => {
    const usersIntString = String(
      usersDefaultFilter.interests.map((selected) => selected?.id)
    );

    page === "nearby"
      ? dispatch(setGettingNearby(true))
      : dispatch(setGettingDiscover(true));

    usersService
      .getUsers(
        token,
        100,
        1,
        usersIntString,
        usersDefaultFilter?.gender,
        usersDefaultFilter?.state || "",
        usersDefaultFilter?.country || "",
        usersDefaultFilter?.min_age,
        usersDefaultFilter?.max_age,
        usersDefaultFilter?.category,
        usersDefaultFilter?.mode
      )
      .then((res) => {
        if (res.success === true) {
          if (page === "discover") {
            dispatch(setDiscoverUsers(shuffledArray(res.data.data)));
          } else {
            dispatch(setNearbyUsers(shuffledArray(res.data.data)));
          }

          dispatch(togglePopup(false));
        } else {
          toast.error(res?.errors || res?.message || "");
        }
      })
      .catch((error) => toast.error(error?.message))
      .finally(() => {
        dispatch(setGettingNearby(false));
        dispatch(setGettingDiscover(false));
      });
  };

  return { getInterests, filter, setFilter, getUsers };
};

export default useUsersFilter;
