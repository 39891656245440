import { makeStyles } from "@mui/styles";

export const ChangePasswordStyles = makeStyles((theme) => ({
  changePassContainer: {
    display: "flex !important",
    flexDirection: "column",
    backgroundColor: "#fff",
    width: 400,
    padding: 30,
    borderRadius: 15,
    border:"none",
    maxHeight: "80vh",
    overflow: "auto",
  },
  top: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 10px",
  },
  topText: {
    fontWeight: "600 !important",
    fontSize: "18px !important",
  },
  textFieldsWrapper: {
    display: "flex !important",
    flexDirection: "column",
    margin: "10px 0px",
    border:"none",

    "& .MuiTextField-root": {
      marginTop: "5px",
    },
  },
  label: {
    fontSize: "13px !important",
    fontWeight: "600 !important",
    paddingLeft: 20,
    color:"#293146"
  },
  inputField: {
    backgroundColor: "#F4F5F9",
    border: "none",
    color: "#293146"
  },
  confirmBtn: {
    borderStyle: "none",
    backgroundColor: "#EA0F08",
    color: "#fff",
    padding: 12,
    fontWeight: 600,
    borderRadius: "4px",
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  textFieldsContainer: {
    display: "flex",
    flexDirection: "column",
    gap:"2rem"
  }
}));
