import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  setGettingPreview,
  setMyActivities,
  setPreviewUser,
  setWhoILike,
} from "../../../redux/actions/users/users.actions";
import feedsService from "../../../services/feeds.service";
import usersService from "../../../services/users.service";
import { getToken } from "../../../utils/helpers";

const useActivities = () => {
  const token = getToken();
  const dispatch = useDispatch();
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [removing, setRemoving] = useState(false);

  const getWhoILike = () => {
    usersService
      .getUsersWhoLikesMe(token)
      .then(({ data }) => {
        dispatch(setWhoILike(data?.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const getUser = (userId) => {
    dispatch(setGettingPreview(true));
    usersService
      .getUser(token, userId)
      .then(({ data }) => {
        dispatch(setPreviewUser(data));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingPreview(false)));
  };

  const getMyActivities = () => {
    setLoadingActivities(true);
    usersService
      .getMyActivities(token)
      .then(({ data }) => dispatch(setMyActivities(data.data)))
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingActivities(false);
      });
  };

  const wantsToAttendEvent = (item) => {
    return ["want", "to", "attend"].every((word) =>
      item?.message?.includes(word)
    );
  };

  const onAcceptRequest = (userId, postEvent, activityId) => {
    feedsService
      .acceptEventRequest(token, postEvent, userId)
      .then((res) => {
        if (res?.success == true) {
          toast.success(res?.message);
          removeActivity(activityId);
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => toast.error(err.message));
  };

  const removeActivity = (id) => {
    setRemoving(true);
    usersService
      .deleteActivity(token, [id])
      .then((res) => {
        getMyActivities();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setRemoving(false);
      });
  };
  return {
    getWhoILike,
    getUser,
    getMyActivities,
    loadingActivities,
    wantsToAttendEvent,
    onAcceptRequest,
    removeActivity,
  };
};

export default useActivities;
