import { useMemo, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setMyPosts,
  setPosts,
  setStories,
} from "../../../redux/actions/feeds/feeds.actions";
import {
  setPopupChildren,
  setStoryModal,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import feedsService from "../../../services/feeds.service";
import {
  base64ToFile,
  getFileExtFromBase64,
  getToken,
} from "../../../utils/helpers";
import MakePost from "../components/MakePost";
import Stories from "react-insta-stories";
import globalService from "../../../services/global.service";
import MakeEvent from "../components/MakeEvent/MakeEvent";
import { setMyEvents } from "./../../../redux/actions/feeds/feeds.actions";
import useStyles from "./../components/PostCards/PostCards.styles";
import moment from "moment";
import { videoExts } from "../../../utils/constants";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useTranslation } from "react-i18next";

const useFeeds = () => {
  const reduxDispatch = useDispatch();
  const posts = useSelector((state) => state.feeds.posts);
  const { t } = useTranslation();
  const token = getToken();
  const classes = useStyles();
  const [openReport, setOpenReport] = useState(false);
  const [postToReport, setPostToReport] = useState(null);
  const [reasonForReport, setReasonForReport] = useState("");
  const [loadingPost, setLoadingPost] = useState(false);
  const [loadingStory, setLoadingStory] = useState(false);
  const [gettingMore, setGettingMore] = useState(false);
  const [isLastBatch, setIsLastBatch] = useState(false);
  const [files, setFiles] = useState([]);

  const [crop, setCrop] = useState({
    unit: "px",
    width: 40,
    height: 50,
    aspect: 4 / 5,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [fileToPreview, setFileToPreview] = useState(null);
  const [fileName, setFileName] = useState("");

  const imgPreviewRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const onOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onOpenReport = (id) => {
    setPostToReport(id);
    setOpenReport(true);
  };

  const onCancelReport = () => {
    setPostToReport(null);
    setReasonForReport("");
    setOpenReport(false);
  };

  const onSubmitReport = () => {
    if (reasonForReport.trim().length === 0) {
      toast.error(t("stateReport"));
      return;
    }

    globalService
      .submitReport(token, postToReport, "post", reasonForReport)
      .then((res) => {
        if (res?.success) {
          toast.success(t("reportSubmitSuccess"));
          setPostToReport(null);
          setReasonForReport("");
          setOpenReport(false);
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => toast.error(err?.mesage));
  };
  const initState = {
    caption: "",
    location: "",
    lat: "",
    long: "",
    eventDate: "",
    tag: "",
    isLoading: false,
    isDeleting: false,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initState
  );

  const { caption, location, lat, long, eventDate, tag } = state;

  const form_data = new FormData();

  const getPosts = (batch = 1) => {
    if (batch > 1) {
      setGettingMore(true);
    } else {
      setLoadingPost(true);
    }

    feedsService
      .getPosts(token, batch)
      .then((res) => {
        if (res.data.data?.length < 25) {
          setIsLastBatch(true);
        }
        batch == 1
          ? reduxDispatch(setPosts(res?.data?.data))
          : reduxDispatch(setPosts([...posts, ...res?.data?.data]));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoadingPost(false);
        setGettingMore(false);
      });
  };

  const getStories = () => {
    setLoadingStory(true);
    feedsService
      .getStories(token)
      .then((res) => {
        reduxDispatch(setStories(res.data));
      })
      .finally(() => {
        setLoadingStory(false);
      });
  };

  const createPost = (e, isEvent) => {
    e.preventDefault();
    if (isEvent && !eventDate) {
      return toast.error(t("selectDateTime"));
    }

    if (!files.length) {
      return toast.error(t("uploadFile"));
    }
    // if (!fileToUpload) {
    //   return toast.error(t("uploadFile"));
    // }
    else {
      const form_data = new FormData();
      for (var i = 0; i < files?.length; i++) {
        var singleFile = files[i];
        form_data.append("file", singleFile);
      }
      // form_data.append("file", fileToUpload);
      form_data.append("caption", caption);
      form_data.append("location", location);
      form_data.append("tags", tag);
      form_data.append("lat", lat);
      form_data.append("lng", long);
      isEvent && form_data.append("is_event", "true");
      isEvent && form_data.append("event_date", `${eventDate.toISOString()}`);

      dispatch({ isLoading: true });
      feedsService
        .createPost(token, form_data)
        .then((res) => {
          if (res?.data?.success) {
            getPosts();
            toast.success(res?.data?.message);
            reduxDispatch(togglePopup(false));
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((error) => {
          toast.error(
            error.response.data.errors || error.response.data.message
          );
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  };
  const getMyPosts = () => {
    feedsService
      .getMyPosts(token, 1, 1000)
      .then((res) => {
        reduxDispatch(setMyPosts(res?.data?.data));
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const getPostEvents = () => {
    feedsService
      .getPostEvents(token)
      .then((res) => {
        reduxDispatch(setMyEvents(res?.data.posts));
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const updatePost = (e, id) => {
    e.preventDefault();
    if (!fileToUpload) {
      return toast.error(t("uploadFile"));
    } else {
      form_data.append("file", fileToUpload);
      form_data.append("caption", caption);
      form_data.append("location", location);
      form_data.append("tags", tag);
      form_data.append("lat", lat);
      form_data.append("lng", long);
      dispatch({ isLoading: true });
      feedsService
        .updatePost(token, id, form_data)
        .then((res) => {
          getMyPosts();
          getPostEvents();
          toast.success("Post Updated");
          reduxDispatch(togglePopup(false));
        })
        .catch((error) => {
          console.log(error);
          toast.error("Error");
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  };

  const createStory = (e) => {
    e.preventDefault();

    if (!fileToUpload) {
      return toast.error(t("uploadFile"));
    } else {
      form_data.append("file", fileToUpload);
      form_data.append("caption", caption);

      dispatch({ isLoading: true });
      feedsService
        .createStories(token, form_data)
        .then((res) => {
          if (res?.data.success) {
            getStories();
            toast.success(res?.data?.message);
            reduxDispatch(togglePopup(false));
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((error) => {
          toast.error(
            error.response.data.errors || error.response.data.message
          );
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  };

  const getGeoLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      dispatch({
        lat: position.coords.latitude,
        long: position.coords.longitude,
      });
    });
  };

  const handleChange = (address) => {
    dispatch({ location: address });
  };
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log("Success", latLng))
      .catch((error) => console.error("Error", error));
  };

  const onMakePosts = () => {
    reduxDispatch(setPopupChildren(<MakePost />));
    reduxDispatch(togglePopup(true));
  };
  const onMakeStory = () => {
    reduxDispatch(setPopupChildren(<MakePost story />));
    reduxDispatch(togglePopup(true));
  };
  const onMakeEvent = () => {
    reduxDispatch(setPopupChildren(<MakeEvent />));
    reduxDispatch(togglePopup(true));
  };

  const viewedStory = (id) => {
    feedsService
      .viewedStory(token, id)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };
  const removeStory = (id) => {
    dispatch({ isDeleting: true });
    feedsService
      .removeStory(token, id)
      .then((res) => {
        toast.success("story deleted");
        getStories();
        reduxDispatch(setStoryModal(false));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch({ isDeleting: false });
      });
  };

  const handleSeen = async (arr) => {
    for (const id of arr) {
      await viewedStory(id);
    }

    getStories();
  };

  const onShowStory = (storiesArr, story) => {
    const stories = storiesArr?.map((item) => ({
      ...item,
      url: item.media,
      header: {
        heading: story?.username,
        subheading: `posted ${moment(item?.createdAt).fromNow()}`,
        profileImage: story?.avatar,
      },
      type: videoExts.includes(
        `.${item?.media.split("/").pop().split(".").pop().split("?")[0]}`
      )
        ? "video"
        : "",
    }));

    const stId = storiesArr.map((item) => {
      return item.id;
    });

    const StoryId = stId.reverse();
    const newArr = stories.reverse();

    reduxDispatch(
      setPopupChildren(
        <Stories
          stories={newArr}
          defaultInterval={4500}
          width={432}
          height={668}
          storyContainerStyles={{ border: "none" }}
          onStoryEnd={(s, st) => console.log(s, st)}
          onAllStoriesEnd={(s, st) => {
            handleSeen(StoryId);
            reduxDispatch(togglePopup(false));
          }}
          onStoryStart={(s, st) => console.log("story started")}
          keyboardNavigation={true}
          onDelete={(story) => console.log("delete", story)}
          seeMore={true}
        />
      )
    );
    reduxDispatch(togglePopup(true));
  };

  const handleCropChange = (pixelCrop, crop) => {
    setCrop(crop);
  };

  const handleCropComplete = (pixelCrop, crop) => {
    setCompletedCrop(crop);
  };

  const getCroppedImage = (e) => {
    e.preventDefault();
    const canvas = imgPreviewRef.current;

    const ctx = canvas.getContext("2d");
    const image = new Image();

    image.src = fileToPreview;
    canvas.width = completedCrop.width * (image.naturalWidth / 100);
    canvas.height = completedCrop.height * (image.naturalHeight / 100);

    ctx.drawImage(
      image,
      completedCrop.x * (image.naturalWidth / 100),
      completedCrop.y * (image.naturalHeight / 100),
      completedCrop.width * (image.naturalWidth / 100),
      completedCrop.height * (image.naturalHeight / 100),
      0,
      0,
      canvas.width,
      canvas.height
    );
    const fileExt = getFileExtFromBase64(fileToPreview);
    const imgData64 = canvas.toDataURL("image/" + fileExt);
    const f = base64ToFile(imgData64, fileName);
    // const f = base64ToFile(fileToPreview, fileName);
    setFileToUpload(f);

    // const reader = new FileReader();
    // canvas.toBlob((blob) => {
    //   reader.readAsDataURL(blob);
    //   reader.onloadend = () => {
    //     const f = base64ToFile(reader.result, fileName);
    //     setFileToUpload(f);
    //   };
    // });
  };

  return {
    getPosts,
    getStories,
    onMakePosts,
    state,
    dispatch,
    getGeoLocation,
    createPost,
    onShowStory,
    onOpenReport,
    openReport,
    setOpenReport,
    postToReport,
    reasonForReport,
    setReasonForReport,
    onCancelReport,
    onSubmitReport,
    onMakeStory,
    createStory,
    onMakeEvent,
    updatePost,
    loadingPost,
    loadingStory,
    removeStory,
    handleChange,
    handleSelect,
    handleCropChange,
    handleCropComplete,
    getCroppedImage,
    crop,
    completedCrop,
    imgPreviewRef,
    fileToPreview,
    fileToUpload,
    setFileToPreview,
    setFileToUpload,
    fileName,
    setFileName,
    gettingMore,
    isLastBatch,
    files,
    setFiles,
  };
};

export default useFeeds;
