import React, { useState } from "react";
import { useBoostStyle } from "./Boost.style";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { ReactComponent as CheckedIcon } from "../../../../../../assets/icons/checked.svg";
import { ReactComponent as Coins } from "../../../.././../../assets/icons/coins.svg";
import { ReactComponent as BoostImg } from "../../../.././../../assets/icons/boost.svg";
import { useTranslation } from "react-i18next";
import otherServices from "../../../../../../services/others.service";
import { getToken } from "../../../../../../utils/helpers";
import { toast } from "react-toastify";
import usersService from "../../../../../../services/users.service";
import { useDispatch } from "react-redux";
import { setMyData } from "../../../../../../redux/actions/users/users.actions";

export default function Boost({ setShowPremiumBoost }) {
  const classes = useBoostStyle();
  const [currentPrice, setCurrentPrice] = useState(0);
  const { t } = useTranslation();
  const [proceeding, setProceeding] = useState(false);
  const token = getToken();
  const dispatch = useDispatch();
  const [openDialogue, setOpenDialogue] = useState(false);
  const pricing = [
    {
      period: `1 ${t("day")}`,
      price: "2 m8s " + t("coins"),
      coins: 2,
      benefits: [
        t("avail1Day"),
        t("exposeProfile"),
        t("unlimitedMatch"),
        t("backtrackInstant"),
      ],
      value: 1,
    },
    {
      period: `3 ${t("days")}`,
      price: "5 m8s " + t("coins"),
      coins: 5,
      benefits: [
        t("avail3Days"),
        t("exposeProfile"),
        t("unlimitedMatch"),
        t("backtrackInstant"),
      ],
      value: 3,
    },
    {
      period: `1 ${t("week")}`,
      price: "12 m8s " + t("coins"),
      coins: 12,
      benefits: [
        t("avail7Days"),
        t("exposeProfile"),
        t("unlimitedMatch"),
        t("backtrackInstant"),
      ],
      value: 7,
    },
  ];
  const handleActivateBooster = () => {
    setProceeding(true);
    otherServices
      .activateBooster(token, {
        value: pricing[currentPrice]?.coins,
        duration: pricing[currentPrice]?.value,
      })
      .then(async ({ data, success, message, errors }) => {
        if (success == true) {
          toast.success(message);
          setShowPremiumBoost(false);
        } else {
          toast.error(message || errors || null);
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setProceeding(false);
      });
  };

  const getMe = () => {
    usersService
      .getMe(token)
      .then(({ data }) => {
        dispatch(setMyData(data));
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box className={classes.Wrapper}>
      <Box className={classes.header}>
        <Box className={classes.headerCaptions}>
          <Box className={classes.headerImg}>
            <BoostImg />
          </Box>
          <Box className={classes.headerContent}>
            <Typography className={classes.headerTitle}>
              {t("getBoosted")}
            </Typography>
            <Typography className={classes.headerBody}>
              {t("increaseChance")}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.headerBtnContainer}>
          <Button
            className={classes.ActivateBtn}
            onClick={() => setOpenDialogue(true)}
          >
            {t("activateBoost")}
          </Button>
        </Box>
      </Box>
      <Box className={classes.pricing}>
        {pricing.map((booster, index) => {
          return (
            <Box
              className={
                index === currentPrice ? classes.active : classes.inactive
              }
              onClick={() => setCurrentPrice(index)}
            >
              <Box>
                <Typography className={classes.period}>
                  {booster.period}
                </Typography>
                <Typography className={classes.price}>
                  {booster.price}
                </Typography>
              </Box>
              <Box>
                <Coins />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box className={classes.Footer}>
        <Typography className={classes.FooterTitle}>
          What you get with this plan
        </Typography>
        <Box className={classes.benefits}>
          {pricing[currentPrice].benefits.map((benefit) => (
            <Box className={classes.benefit}>
              <CheckedIcon />
              <Box component={"span"} style={{ marginLeft: 8, fontSize: 14 }}>
                {benefit}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Dialog
        open={openDialogue}
        onClose={() => setOpenDialogue(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("activateBoost")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("activeBoostFor")} {pricing[currentPrice].period}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialogue(false)}>{t("cancel")}</Button>
          <Button disabled={proceeding} onClick={handleActivateBooster}>
            {proceeding ? t("wait") + "..." : t("proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
