import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import usersService from "../../../../../../services/users.service";
import { getToken } from "../../../../../../utils/helpers";
import { useParams } from "react-router-dom";
import { setPreviewUser } from "../../../../../../redux/actions/users/users.actions";
import { useDispatch, useSelector } from "react-redux";

export default function useEditUser() {
  const token = getToken();
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { previewUser } = useSelector((state) => state.users);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    async function getCurrentUser() {
      try {
        const user = await usersService.getUser(token, userId);
        // console.log("images", user.data.images);
        // console.log("video", user.data.vid);
        dispatch(setPreviewUser(user.data));
        // console.log("preUser",previewUser);
        // console.log("images", previewUser.images);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    getCurrentUser();
  }, [token]);
  return { isLoading };
}
