import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { usePlanHeader } from "./PlanHeader.style";

export default function PlanHeader({
  currentTab,
  setCurrentTab,
  setShowPremiumBoost,
}) {
  const tabs = ["Boost", "Premium"];
  const classes = usePlanHeader();
const {t} = useTranslation()
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box className={classes.Wrapper}>
        {tabs.map((tab, index) => (
          <Box
            className={currentTab === index ? classes.active : classes.inactive}
            onClick={() => setCurrentTab(index)}
          >
            <Typography>{tab}</Typography>
          </Box>
        ))}
      </Box>
      <Typography
        onClick={() => setShowPremiumBoost(false)}
        style={{
          textDecorationLine: "underline",
          fontSize: 13,
          cursor: "pointer",
        }}
      >
        {t("continueSwipe")}
      </Typography>
    </Box>
  );
}
