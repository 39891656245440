import {
  AccountCircle,
  KeyboardArrowDown,
  MoreHoriz,
  PriceChange,
  Report,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AdminGlobalHeader from "../../../components/AdminGlobalHeader";
import ChartCard from "../../../components/ChartCard";
import CustomTable from "../../../components/CustomTable";
import { UsersStyles } from "./Users.style";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import useAdminUser from "./hooks/useAdminUser";
import MoreMenu from "../../../components/MoreMenu/MoreMenu";
import CustomDropDown from "../../../components/CustomDropDown/CustomDropDown";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { RotatingLines } from "react-loader-spinner";

function DefaultDropdownIcon() {
  const classes = UsersStyles();
  return <KeyboardArrowDown className={classes.arrowDownIcon} />;
}

const Users = () => {
  const classes = UsersStyles();
  const {
    usersStatistics,
    isLoading,
    users,
    toggleActiveUser,
    handleClickMenu,
    handleCloseMenu,
    opens,
    anchorEls,
    handleEdit,
    statusOptions,
    selectedStatus,
    handleSelectStatus,
    handleSearchUser,
    searchText,
    genderOptions,
    selectedGender,
    handleSelectGender,
    gettingUsers,
    modeOptions,
    selectedMode,
    handleSelectMode,
  } = useAdminUser();

  console.log(usersStatistics);

  return (
    <Box className={classes.pageWrapper}>
      <AdminGlobalHeader />
      <Box className={classes.cardsRow}>
        <ChartCard
          icon={<AccountCircle style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Total Users"
          description="General"
          figure={usersStatistics?.totalCount}
          trend="positive"
          data={[]}
          trendValue="34.9"
        />

        <ChartCard
          icon={<Report style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Users"
          description="Male Users"
          trend="negative"
          trendValue="8.7"
          data={usersStatistics?.groupedByDateRange}
          leftSide={{
            description: (
              <Typography style={{ fontSize: 13, color: "#ADABAB" }}>
                Male
              </Typography>
            ),
            figure: (
              <Typography style={{ fontSize: 22, fontWeight: 500 }}>
                {usersStatistics?.totalMalesCount}
              </Typography>
            ),
          }}
          rightSide={{
            description: (
              <Typography style={{ fontSize: 13, color: "#FFA6A3" }}>
                Female
              </Typography>
            ),
            figure: (
              <Typography
                style={{
                  fontSize: 22,
                  fontWeight: 500,
                  color: "#EA0F08",
                  textAlign: "right",
                }}
              >
                {usersStatistics?.totalFemalesCount}
              </Typography>
            ),
          }}
          middleSide={{
            description: (
              <Typography style={{ fontSize: 13, color: "#C6C6C6" }}>
                Others
              </Typography>
            ),
            figure: (
              <Typography
                style={{ fontSize: 22, fontWeight: 500, color: "#C6C6C6" }}
              >
                {usersStatistics?.totalOthersCount}
              </Typography>
            ),
          }}
        />
        <ChartCard
          icon={<PriceChange style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Active Users"
          description="Total Active Users"
          figure={usersStatistics?.totalActiveUsersCount}
          trend="negative"
          trendValue="4.3"
          data={[]}
        />
      </Box>
      <Box className={classes.tableSection} sx={classes.tableSection}>
        {gettingUsers && (
          <Box
            style={{
              position: "absolute",
              zIndex: 2,
              top: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              opacity: 0.3,
              backgroundColor: "#fff",
              borderRadius: 3,
            }}
          >
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </Box>
        )}

        <Typography fontWeight={500}>Users Summary</Typography>
        <Box className={classes.revenueTableHeader}>
          <Box style={{ display: "flex", gap: 20 }}>
            <Box>
              <Typography style={{ fontSize: 13 }}>Status</Typography>
              <CustomDropDown
                data={statusOptions}
                value={selectedStatus}
                handleChange={handleSelectStatus}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 13 }}>Gender</Typography>
              <CustomDropDown
                data={genderOptions}
                value={selectedGender}
                handleChange={handleSelectGender}
              />
            </Box>

            <Box>
              <Typography style={{ fontSize: 13 }}>Mode</Typography>
              <CustomDropDown
                data={modeOptions}
                value={selectedMode}
                handleChange={handleSelectMode}
              />
            </Box>
          </Box>

          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "40%",
              backgroundColor: "#F4F5F9",
              height: 35,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: 13 }}
              placeholder="Search by first name, last name or email"
              onChange={({ target }) => handleSearchUser(target.value)}
            />

            <SearchIcon
              style={{
                color: "#95A4B3",
                width: 10,
                height: 10,
                marginRight: 10,
              }}
            />
          </Paper>
        </Box>
        <CustomTable
          noSort
          data={{
            th: [
              <span key={1}>User</span>,
              <span key={2}>Status</span>,
              <span key={3}>Email</span>,
              <span key={2}>Gender</span>,
              <span key={2}>Created</span>,
              <span key={4}>Actions</span>,
            ],
            rows: users.map(
              (
                {
                  first_name,
                  last_name,
                  status,
                  email,
                  id,
                  is_active,
                  avatar,
                  gender,
                  createdAt,
                },
                index
              ) => [
                <Box key={id} className={classes.tableRow}>
                  <Box className={classes.userCell}>
                    <Avatar src={avatar} className={classes.userAvatar} />
                    {`${first_name || "--"} ${last_name || "/--"}`}
                  </Box>
                </Box>,
                <Box
                  className={classes.statusBg}
                  style={{
                    backgroundColor: is_active ? "#D0F3D3" : "#FFEBEB",
                  }}
                >
                  {is_active ? "Active" : "Inactive"}
                </Box>,
                // <Box key={id} className={classes.tableRow}>
                //   <Box
                //     className={classes.statusBg}
                //     style={{
                //       backgroundColor:
                //         status === "offline"
                //           ? "#FFEBEB"
                //           : status === "away"
                //           ? "#F1F3D0"
                //           : "#D0F3D3",
                //     }}
                //   >
                //     {status?.toSentenceCase()}
                //   </Box>
                // </Box>,
                <Box key={id} className={classes.tableRow}>
                  {email}
                </Box>,
                <Box key={id} className={classes.tableRow}>
                  <Box className={classes.statusBg}>
                    {gender ? gender?.toSentenceCase() : "--/--"}
                  </Box>
                </Box>,
                <Box key={id} className={classes.tableRow}>
                  <Box className={classes.statusBg}>
                    {moment(createdAt).format("DD MMM YYYY")}
                  </Box>
                </Box>,

                <Box key={index} className={classes.tableRow}>
                  <MoreHoriz
                    id={id}
                    aria-controls={`menu-${index}`}
                    aria-haspopup="true"
                    onClick={(event) => handleClickMenu(event, index)}
                    style={{ cursor: "pointer" }}
                  />

                  <Menu
                    id={`menu-${index}`}
                    anchorEl={anchorEls[index]}
                    open={opens[index]}
                    onClose={() => handleCloseMenu(index)}
                  >
                    <MenuItem onClick={() => handleEdit(id, index)}>
                      Edit
                    </MenuItem>
                    <MenuItem
                      onClick={() => toggleActiveUser(id, !is_active, index)}
                    >
                      {is_active ? "Make Inactive" : "Make Active"}
                    </MenuItem>
                  </Menu>
                </Box>,
              ]
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Users;
