import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import UserImgComponent from "../../components/UserImgComponent";
import UserPreview from "../../components/UserPreview";
import {
  distanceBetweenCoords,
  getAge,
  usernameStringCheck,
} from "../../utils/helpers";
import MatchesHeader from "./components/MatchesHeader";
import Mate from "./components/Mate";
import useMatches from "./hooks/useMatches";
import { MatchesStyle } from "./Matches.styles";

const Matches = ({
  mates,
  gettingMates,
  setPreviewUser,
  setPopupChildren,
  togglePopup,
  myCoords,
}) => {
  const classes = MatchesStyle();
  const { getMates, getUserLocation } = useMatches();
  useEffect(() => {
    getMates();
  }, []);
  const { t } = useTranslation();
  return (
    <Box className={classes.matchesWrapper}>
      <MatchesHeader />
      <Box className={classes.matesMainContainer}>
        <Box className={classes.matesTopTextBox}>
          <Typography className={classes.matesTopText}>
            {t("yourMatchList")}
          </Typography>
        </Box>
        <Grid container className={classes.matesGrid}>
          {gettingMates ? (
            <Box className={classes.skeletonContainer}>
              <Skeleton
                count={20}
                width={160}
                height={210}
                inline={true}
                style={{ margin: "10px 15px" }}
              />
            </Box>
          ) : mates?.length > 0 ? (
            mates?.map((user, i) => <Mate user={user} index={i} />)
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "75vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>{t("noUser")}</Typography>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Matches;
