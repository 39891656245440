import { ReactComponent as ChatLogo } from "../assets/icons/chat.svg";
import { ReactComponent as LikeIcon } from "../assets/icons/like_user.svg";
import { useTranslation } from "react-i18next";

const useConstants = () => {
  const { t } = useTranslation();
  const modes = [
    {
      id: 1,
      title: t("friends"),
      description: t("fDescription"),
      value: "FRIEND",
      logo: <ChatLogo />,
      label: t("fLabel"),
    },
    {
      id: 2,
      title: t("date"),
      description: t("dDescription"),
      value: "DATE",
      logo: (
        <img
          alt="relationship"
          src={require("../assets/images/relationship.png")}
          style={{ height: 30, width: 30 }}
        />
      ),
      label: t("dLabel"),
    },
    {
      id: 3,
      title: t("relationship"),
      description: t("rDescription"),
      value: "RELATIONSHIP",
      logo: <LikeIcon />,
      label: t("rLabel"),
    },
    {
      id: 4,
      title: t("marriage"),
      description: t("mDescription"),
      value: "MARRIAGE",
      logo: (
        <img
          alt="marriage"
          src={require("../assets/images/rings.png")}
          style={{ height: 30, width: 30 }}
        />
      ),
      label: t("mLabel"),
    },
  ];

  const childrenOptions = [
    { title: t("noKids"), value: "NO, NEVER" },

    { title: t("maybeKids"), value: "SOMEDAY, MAYBE" },
    { title: t("expectingKids"), value: "EXPECTING" },
    { title: t("haveKids"), value: "I ALREADY HAVE KIDS" },
    {
      title: t("noMoreKids"),
      value: "I HAVE KIDS AND DONT WANT MORE",
    },
  ];

  const petOptions = [
    { title: t("noPets"), value: "NONE" },
    { title: t("havePets"), value: "HAVE PETS" },
  ];

  const drinkOptions = [
    { title: t("never"), value: "NEVER" },
    { title: t("drink"), value: "I DRINK SOMETIMES" },
  ];

  const smokeOptions = [
    { title: t("never"), value: "NEVER" },
    { title: t("smoke"), value: "I SMOKE SOMETIMES" },
    { title: t("chainSmoker"), value: "CHAIN SMOKER" },
  ];

  const personalityOptions = [
    { title: t("adventorous"), value: "Adventorous" },
    { title: t("careless"), value: "Careless" },
    { title: t("cheerful"), value: "Cheerful" },
    { title: t("demanding"), value: "Demanding" },
    { title: t("extroverted"), value: "Extroverted" },
    { title: t("honest"), value: "Honest" },
    { title: t("generous"), value: "Generous" },
    { title: t("liberal"), value: "liberal" },
    { title: t("introverted"), value: "Introverted" },
    { title: t("possessive"), value: "Possessive" },
    { title: t("reserved"), value: "Reserved" },
    { title: t("sensitive"), value: "Sensitive" },
    { title: t("spontaneous"), value: "Spontaneous" },
    { title: t("proud"), value: "Proud" },
    { title: t("considerate"), value: "Considerate" },
    { title: t("helpful"), value: "Helpful" },
    { title: t("optimistic"), value: "Optimistic" },
  ];

  const religionOptions = [
    { title: t("agnostic"), value: "Agnostic" },
    { title: t("atheist"), value: "ATHEIST" },
    { title: t("buddhist"), value: "BUDDHIST" },
    { title: t("catholic"), value: "CATHOLIC" },
    { title: t("christian"), value: "CHRISTIAN" },
    { title: t("hindu"), value: "HINDU" },
    { title: t("jain"), value: "JAIN" },
    { title: t("jewish"), value: "JEWISH" },
    { title: t("mormon"), value: "MORMON" },
    { title: t("muslim"), value: "MUSLIM" },
    { title: t("zoroastrian"), value: "ZOROASTRIAN" },
    { title: t("sikh"), value: "SIKH" },
    { title: t("spiritual"), value: "SPIRITUAL" },
    { title: t("other"), value: "OTHER" },
    { title: t("ratherNotSay"), value: "NOT_SAY" },
  ];

  const zodiacSignOptions = [
    { title: t("aries"), value: "ARIES" },
    { title: t("taurus"), value: "TAURUS" },
    { title: t("gemini"), value: "GEMINI" },
    { title: t("cancer"), value: "CANCER" },
    { title: t("leo"), value: "LEO" },
    { title: t("virgo"), value: "VIRGO" },
    { title: t("libra"), value: "LIBRA" },
    { title: t("scorpio"), value: "SCORPIO" },
    { title: t("sagittarius"), value: "SAGITTARIUS" },
    { title: t("capricon"), value: "CAPRICON" },
    { title: t("aquarius"), value: "AQUARIUS" },
    { title: t("pisces"), value: "PISCES" },
  ];

  const genderOptions = [
    { value: "male", label: t("male") },
    { value: "both", label: t("both") },
    { value: "female", label: t("female") },
  ];

  const usersCategoryOptions = [
    { value: "all", label: t("all") },
    { value: "online", label: t("online") },
    { value: "new", label: t("new") },
  ];

  const sexOptions = [
    { value: "straight", title: t("straight") },
    { value: "gay", title: t("gay") },
    { value: "bi", title: t("bi") },
  ];

  const memberships = [
    {
      id: 1,
      name: t("lite"),
      description: t("tasteOfDating"),
      price: {
        recurring: {
          interval: t("month"),
          interval_count: 1,
        },
        unit_amount: 10,
      },
    },
    {
      id: 2,
      name: t("club"),
      description: t("enjoyM8s"),
      price: {
        recurring: {
          interval: t("month"),
          interval_count: 1,
        },
        unit_amount: 50,
      },
    },
    {
      id: 3,
      name: t("elite"),
      description: t("eliteDating"),
      price: {
        recurring: {
          interval: t("month"),
          interval_count: 1,
        },
        unit_amount: 250,
      },
    },
  ];

  return {
    modes,
    childrenOptions,
    petOptions,
    drinkOptions,
    smokeOptions,
    personalityOptions,
    religionOptions,
    zodiacSignOptions,
    genderOptions,
    usersCategoryOptions,
    sexOptions,
    memberships,
  };
};

export default useConstants;
