import {
  SET_CHATS,
  SET_CHATS_FILTER_VAL,
  SET_MESSAGES_IN_VIEW,
  SET_MESSAGING_USER,
} from "../../actions/chats/chats.types";

const defaultState = {
  messagesInView: [],
  messagingUser: {},
  chats: [],
  chatsFilterVal: "most_recent",
};

export default function chatsReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_MESSAGING_USER:
      return { ...state, messagingUser: action.payload };
    case SET_MESSAGES_IN_VIEW:
      return { ...state, messagesInView: action.payload };
    case SET_CHATS:
      return { ...state, chats: action.payload };
    case SET_CHATS_FILTER_VAL:
      return { ...state, chatsFilterVal: action.payload };
    default:
      return state;
  }
}
