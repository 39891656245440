import axios from "axios";
import env from "../env";
import { request } from "./api";

const API_URL = `${env.API_URL}`;

const URL = {
  getMe: `${API_URL}/users/me`,
  getUsers: (
    limit,
    batch,
    interests,
    gender,
    state,
    country,
    min_age,
    max_age,
    category,
    mode,
    usersQuery
  ) =>
    `${API_URL}/users?limit=${limit}&batch=${batch}&interests=${interests}&gender=${gender}&state=${state}&country=${country}&age_down=${min_age}&age_up=${max_age}&category=${category}&mode=${mode}&usersQuery=${usersQuery}`,

  toggleUserLike: (userId) => `${API_URL}/users/like/${userId}`,
  updateMyLocation: `${API_URL}/users/location`,
  updateStatus: `${API_URL}/users/active`,
  logoutOffline: `${API_URL}/users/logout`,
  getUsersWhoLikesMe: `${API_URL}/users/likes?batch=1&limit=1000`,
  getUser: (userId) => `${API_URL}/users/${userId}`,
  getMates: (batch, limit, search) =>
    `${API_URL}/users/mates?batch=${batch}&limit=${limit}&search=${search}`,
  getMyActivities: (batch, limit) =>
    `${API_URL}/activities?batch=${batch}&limit=${limit}`,
  deleteActivity: `${API_URL}/activities`,
  mode: `${API_URL}/users/mode`,
  uploadImage: `${API_URL}/users/pic`,
  uploadMainImage: `${API_URL}/users/profile-pic`,
  deleteImg: (imageId) => `${API_URL}/users/pic/${imageId}`,
  addImage: `${API_URL}/users/pic`,
  uploadVidProfile: `${API_URL}/users/profile-vid`,
  delVidProfile: `${API_URL}/users/profile-vid`,
  updateProfileInfo: `${API_URL}/users/profile`,
  saveInterests: `${API_URL}/users/interest`,
  checkUsername: `${API_URL}/users/username`,
  getReferral: `${API_URL}/users/referral`,
  setFCMToken: `${API_URL}/activities/fcm`,
  removeFCMToken: `${API_URL}/activities/fcm/web`,
  deleteAccount: `${API_URL}/users`,
  saveClubCountry: `${API_URL}/users/club`,
  swipeUser: (userId) => `${API_URL}/users/swipe/${userId}`,
  resetSwipes: `${API_URL}/users/swipe`,
  seeActivities: `${API_URL}/activities`,
};

const getMe = (token) => {
  return request(URL.getMe, {}, "GET", `Bearer ${token}`);
};

const getUsers = (
  token,
  limit = 25,
  batch = 1,
  interests = "",
  gender = "both",
  state = "",
  country = "",
  min_age = 18,
  max_age = 150,
  category = "",
  mode = "",
  usersQuery = ""
) => {
  return request(
    URL.getUsers(
      limit,
      batch,
      interests,
      gender,
      state,
      country,
      min_age,
      max_age,
      category,
      mode,
      usersQuery
    ),
    {},
    "GET",
    `Bearer ${token}`
  );
};

const toggleUserLike = (token, userId) => {
  return request(
    URL.toggleUserLike(userId),
    { id: userId },
    "POST",
    `Bearer ${token}`
  );
};

const updateMyLocation = (token, country, state, lat, lng) => {
  return request(
    URL.updateMyLocation,
    { country, state, lat, lng },
    "PATCH",

    `Bearer ${token}`
  );
};
const updateStatus = (token, status) => {
  return request(URL.updateStatus, { status }, "POST", `Bearer ${token}`);
};

const logoutOffline = (token) => {
  return request(URL.logoutOffline, {}, "POST", `Bearer ${token}`);
};

const getUsersWhoLikesMe = (token) => {
  return request(URL.getUsersWhoLikesMe, {}, "GET", `Bearer ${token}`);
};

const getUser = (token, userId) => {
  return request(URL.getUser(userId), {}, "GET", `Bearer ${token}`);
};
const getMates = (token, batch = 1, limit = 1000, search = "") => {
  return request(
    URL.getMates(batch, limit, search),

    {},
    "GET",
    `Bearer ${token}`
  );
};
const getMyActivities = (token, batch = 1, limit = 1000) => {
  return request(
    URL.getMyActivities(batch, limit),

    {},
    "GET",
    `Bearer ${token}`
  );
};

const deleteActivity = (token, ids) => {
  return request(URL.deleteActivity, { ids }, "DELETE", `Bearer ${token}`);
};
const saveMode = (token, mode) => {
  return request(
    URL.mode,
    {
      mode,
    },
    "POST",

    `Bearer ${token}`
  );
};

const uploadPhotos = (token, formData) => {
  return axios({
    method: "POST",
    url: URL.uploadImage,
    data: formData,
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
    },
    mode: "no-cors",
    cache: "no-cache",
  });
};
const uploadMainImage = (token, formData) => {
  return axios({
    method: "POST",
    url: URL.uploadMainImage,
    data: formData,
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
    },
    mode: "no-cors",
    cache: "no-cache",
  });
};

const deleteImage = (token, imageId) => {
  return request(
    URL.deleteImg(imageId),
    {},
    "DELETE",

    `Bearer ${token}`
  );
};

const setAsMain = (token, imageId) => {
  return request(
    URL.deleteImg(imageId),
    {},
    "PATCH",

    `Bearer ${token}`
  );
};

const addImage = (token, formData) => {
  return axios({
    method: "PUT",
    url: URL.addImage,
    data: formData,
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
    },
    mode: "no-cors",
    cache: "no-cache",
  });
};

const uploadVidProfile = (token, formData) => {
  return axios({
    method: "POST",
    url: URL.uploadVidProfile,
    data: formData,
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
    },
    mode: "no-cors",
    cache: "no-cache",
  });
};

const delVidProfile = (token) => {
  return request(
    URL.delVidProfile,
    {},
    "DELETE",

    `Bearer ${token}`
  );
};

const updateProfile = (token, data) => {
  return request(
    URL.updateProfileInfo,
    data,
    "PATCH",

    `Bearer ` + token
  );
};

const saveInterests = (token, data) => {
  return request(
    URL.saveInterests,
    { interests: data },
    "POST",
    `Bearer ${token}`
  );
};

const checkUserName = (token, username) => {
  return request(URL.checkUsername, { username }, "GET", `Bearer ${token}`);
};
const setFCMToken = (BearerToken, token, platform = "web") => {
  return request(
    URL.setFCMToken,
    { token, platform },
    "POST",
    `Bearer ${BearerToken}`
  );
};
const removeFCMToken = (BearerToken) => {
  return request(URL.removeFCMToken, {}, "DELETE", `Bearer ${BearerToken}`);
};

const getReferral = (token, batch = 1, limit = 50) => {
  return request(URL.getReferral, { batch, limit }, "GET", `Bearer ${token}`);
};
const deleteAccount = (token) => {
  return request(URL.deleteAccount, {}, "DELETE", `Bearer ${token}`);
};

const saveClubCountry = (token, country) => {
  return request(URL.saveClubCountry, { country }, "PATCH", `Bearer ${token}`);
};

const swipeUser = (token, userId) => {
  return request(URL.swipeUser(userId), {}, "POST", `Bearer ${token}`);
};

const resetSwipes = (token) => {
  return request(URL.resetSwipes, {}, "DELETE", `Bearer ${token}`);
};

const seeActivities = (token, ids) => {
  return request(URL.deleteActivity, { ids }, "POST", `Bearer ${token}`);
};
const usersService = {
  getMe,
  getUsers,
  toggleUserLike,
  updateMyLocation,
  updateStatus,
  logoutOffline,
  getUsersWhoLikesMe,
  getUser,
  getMates,
  getMyActivities,
  deleteActivity,
  saveMode,
  uploadPhotos,
  uploadMainImage,
  deleteImage,
  addImage,
  uploadVidProfile,
  delVidProfile,
  updateProfile,
  saveInterests,
  checkUserName,
  setFCMToken,
  removeFCMToken,
  getReferral,
  setAsMain,
  deleteAccount,
  saveClubCountry,
  swipeUser,
  resetSwipes,
  seeActivities,
};
export default usersService;
