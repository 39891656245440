import { makeStyles } from "@mui/styles";

export const UserPreviewStyle = makeStyles((theme) => ({
  discoverMainWrap: {
    backgroundColor: "#fff",
    // height: "calc(100vh - 145px)",
    width: "70vw",
    marginTop: 15,
    padding: 10,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
  },
  swiperWrapper: {
    height: "calc(100vh - 175px)",
    margin: "10px 20px",
    borderRadius: 10,
    display: "flex",
    position: "relative",
    justifyContent: "center",
    // background:
    //   "linear-gradient(to right bottom, transparent, rgba(0,0,0,0.2))",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px",
    alignItems: "center",
  },
  imgContainer: {
    width: "100%",
    justifyContent: "center",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    height: "calc(100vh - 175px)",
  },
  carousel: {
    height: "calc(100vh - 175px) !important",
    width: "100%",
    borderRadius: "10px",
    justifyContent: "center !important",
    alignItems: "center !important",
    display: "flex",

    "& .css-1f8sh1y": {
      height: "calc(100vh - 175px) !important",
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "center !important",
    },
  },
  img: {
    width: "100%",
    display: "block",
    height: "auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "80px",
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
  },
  rightSideContainer: {
    height: "calc(100vh - 175px)",
    overflow: "auto",
  },
  userDetailsContainer: {
    flexDirection: "column !important",
    gap: 32,
  },
  modeBox: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 15px 10px 15px",
    borderRadius: 5,
    backgroundColor: "#FFCECC",
    marginTop: 5,
  },
  memberBox: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 15px 10px 15px",
    borderRadius: 5,
    marginTop: 5,
  },
  detailTitle: {
    fontWeight: "700 !important",
    marginBottom: "5px !important",
  },
  infoInterestGrid: {
    flexDirection: "row",
    alignItems: "center",
    display: "inline-flex",
    gap: 12,
    flexWrap: "wrap",
  },
  infoBox: {
    display: "flex",
    alignItems: "center",
    height: 20,
    padding: 10,
    borderRadius: 5,
    backgroundColor: "#F4F5F9",
  },
  infoIcon: {
    color: "#EA0F08",
  },
  infoText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    marginLeft: "5px !important",
  },
  actionButtons: {
    width: 70,
    height: 70,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 40,
    marginHorizontal: 7,
    elevation: 2,
    borderWidth: 0.2,
    borderColor: "#fff",
    zIndex: 1000,
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
  },
}));
