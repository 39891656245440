import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import ReportDialog from "../../../../components/ReportDialog";
import useFeeds from "../../hooks/useFeeds";
import PostCards from "../PostCards";
import useStyles from "./Posts.styles";
import { useDispatch, useSelector } from "react-redux";
import { setStoryModal } from "../../../../redux/actions/global/global.actions";
import { useTranslation } from "react-i18next";
import { Oval } from "react-loader-spinner";

const Posts = ({ posts }) => {
  const {
    getPosts,
    onMakePosts,
    onOpenReport,
    openReport,
    setOpenReport,
    onMakeStory,
    onMakeEvent,
    setReasonForReport,
    reasonForReport,
    onSubmitReport,
    onCancelReport,
    loadingPost,
    gettingMore,
    isLastBatch,
  } = useFeeds();
  const [isDisplay, setIsDisplay] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  useEffect(() => {
    getPosts();
  }, []);

  const observer = useRef();
  const lastItemRef = useCallback(
    (node) => {
      if (posts?.length >= 25) {
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            getPosts(Math.ceil(posts?.length / 25) + 1);
            console.log("End reached", Math.ceil(posts?.length / 25) + 1);
          }
        });
        if (node) observer.current.observe(node);
      }
    },
    [posts]
  );
  return (
    <div className={classes.postRoot}>
      {!loadingPost ? (
        posts
          ?.map((post, i) => {
            return (
              <PostCards
                i={i}
                profileName={post.user.username}
                profileLocation={post.post_location}
                cardCaption={post.caption}
                profileImg={post.user.avatar}
                post={post}
                onOpenReport={onOpenReport}
              />
            );
          })
          .concat(
            ["1"].map((button) =>
              gettingMore ? (
                <Box className={classes.loaderContainer}>
                  <Oval
                    height={50}
                    width={50}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#C3C3C3"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                </Box>
              ) : isLastBatch ? (
                <Box className={classes.loaderContainer}>
                  <Typography color="#C3C3C3">No more posts</Typography>
                </Box>
              ) : (
                <Box ref={lastItemRef} />
              )
            )
          )
      ) : (
        <Box sx={{ margin: "0 auto" }}>
          <Skeleton
            count={3}
            height={400}
            style={{ margin: "20px auto", width: "600px" }}
          />
        </Box>
      )}
      {!loadingPost && (
        <div className={classes.outer} onClick={() => setIsDisplay(!isDisplay)}>
          <div className={classes.inner}>
            <span>&#43;</span>
          </div>
        </div>
      )}
      <div className={isDisplay ? classes.dropdownContent : classes.noDisplay}>
        <span
          className={classes.dropContent}
          onClick={() => {
            onMakePosts();
            setIsDisplay(false);
          }}
        >
          {t("makeaPost")}
        </span>
        <span
          className={classes.dropContent}
          onClick={() => {
            onMakeStory();
            setIsDisplay(false);
          }}
        >
          {t("shareaPost")}
        </span>
        <span
          className={classes.dropContent}
          onClick={() => {
            onMakeEvent();
            setIsDisplay(false);
          }}
        >
          {t("createEvent")}
        </span>
      </div>
      <ReportDialog
        open={openReport}
        handleClose={() => setOpenReport(false)}
        title={t("report")}
        description={t("whyReporting")}
        value={reasonForReport}
        onChange={({ target }) => setReasonForReport(target.value)}
        onCancel={onCancelReport}
        onSubmit={onSubmitReport}
      />
    </div>
  );
};

export default Posts;
