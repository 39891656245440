import { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  setGettingMemberships,
  setInterests,
  setMemberships,
  setPopupChildren,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import globalService from "../../../services/global.service";
import onboardingService from "../../../services/onboarding.service";
import usersService from "../../../services/users.service";
import { getToken } from "../../../utils/helpers";
import PaymentCheckout from "../../PaymentCheckout";
import { useTranslation } from "react-i18next";
import useSettingConstants from "../../../constants/data/useSettingConstants";
import { setMyData } from "../../../redux/actions/users/users.actions";

function useOnboarding() {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();
  const { memberships } = useSettingConstants();
  const initState = {
    first_name: "",
    last_name: "",
    user: "",
    username: "",
    gender: "male",
    sexuality: "",
    birthday: "",
    mode: "",
    interests: [],
    Membership: "",
    isLoading: false,
    isExist: null,
    isChecking: false,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initState
  );
  const { t } = useTranslation();

  const {
    name,
    username,
    gender,
    sexuality,
    birthday,
    mode,
    interests,
    membership,
    user,
    isExist,
  } = state;

  useEffect(() => {
    dispatch({ isExist: null });
    const debounceHandler = setTimeout(() => {
      if (user) {
        dispatch({ isChecking: true });
        usersService
          .checkUserName(token, user)
          .then(({ data }) => {
            dispatch({ isExist: data.status });
          })
          .finally(() => dispatch({ isChecking: false }));
      }
    }, 800);
    return () => clearTimeout(debounceHandler);
  }, [user]);

  const handleUsername = (value) => {
    if (value.startsWith("@")) {
      dispatch({ user: value.substring(1) });
    } else {
      dispatch({ user: value });
    }
  };

  const createProfile = (e) => {
    e.preventDefault();

    if (isExist !== true) {
      toast.error(t("selectUniqueUser"));
    } else {
      let nameSplit = name.trim().split(" ");
      const [first_name, last_name] = nameSplit;

      dispatch({ isLoading: true });
      onboardingService
        .setProfile(
          token,
          first_name,
          last_name,
          user,
          gender,
          sexuality,
          birthday
        )
        .then((response) => {
          if (response.success === true) {
            toast.success(response?.message);
            navigate("/onboarding/plans");
          }
        })
        .catch((error) => {
          toast.error(error?.errors || error?.message);
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  };

  const createMembership = (e) => {
    e.preventDefault();
    dispatch({ isLoading: true });
    console.log(membership);
    onboardingService
      .setMembership(token, membership)
      .then(({ data, success, message, errors }) => {
        // navigate("/onboarding/mode");
        if (success === true) {
          reduxDispatch(
            setPopupChildren(
              <PaymentCheckout
                clientSecret={data?.client_secret}
                isMembership={true}
                membership={
                  memberships.filter((coin) => coin?.id === membership)[0]
                }
              />
            )
          );
          reduxDispatch(togglePopup(true));
        } else {
          toast.error(message || errors || null);
        }
      })
      .catch((error) => {
        toast.error(error?.errors || error?.message);
      })
      .finally(() => dispatch({ isLoading: false }));
  };

  const createMode = (e) => {
    e.preventDefault();
    dispatch({ isLoading: true });
    onboardingService
      .setMode(token, mode)
      .then((response) => {
        if (response.success) {
          toast.success(response?.message);
          getMe();
        }
      })
      .catch((error) => {
        toast.error(error?.errors || error?.message);
      })
      .finally(() => dispatch({ isLoading: false }));
  };

  const createInterests = (e) => {
    e.preventDefault();
    dispatch({ isLoading: true });

    onboardingService
      .setInterests(token, interests)
      .then((response) => {
        navigate("/onboarding/verification");
      })
      .catch((error) => {
        toast.error(error?.errors || error?.message);
      })
      .finally(() => dispatch({ isLoading: false }));
  };

  const getMemberships = () => {
    reduxDispatch(setGettingMemberships(true));
    globalService
      .getMembership()
      .then(({ data }) => {
        reduxDispatch(setMemberships(data));
      })
      .catch((err) => console.log(err))
      .finally(() => reduxDispatch(setGettingMemberships(false)));
  };

  const getInterests = () => {
    globalService
      .getInterests(token)
      .then((res) => {
        if (res?.success) {
          reduxDispatch(setInterests(res?.data));
        }
      })
      .catch((err) => console.log(err));
  };

  const getMe = () => {
    usersService
      .getMe(token)
      .then(({ data }) => {
        reduxDispatch(setMyData(data));
        if (data?.interests?.length === 0) {
          navigate("/onboarding/interest");
        } else if (data?.avatar === null || !data?.avatar) {
          navigate("/onboarding/verification");
        } else {
          navigate("/feeds");
        }
      })
      .catch((err) => console.log(err));
  };

  return {
    getMemberships,
    getInterests,
    createProfile,
    createMembership,
    createMode,
    createInterests,
    state,
    dispatch,
    handleUsername,
    navigate,
  };
}

export default useOnboarding;
