import React from "react";

export default function useAdmin() {
  function sortDataByMonthAndYear(data) {
    const monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return data
      .sort((a, b) => {
        const yearComparison = a.year.localeCompare(b.year);
        if (yearComparison !== 0) {
          return yearComparison;
        }

        const aMonthIndex = monthShortNames.indexOf(a.month.substring(0, 3));
        const bMonthIndex = monthShortNames.indexOf(b.month.substring(0, 3));

        return aMonthIndex - bMonthIndex;
      })
      .map((item) => ({
        ...item,
        month:
          monthShortNames[monthShortNames.indexOf(item.month.substring(0, 3))],
      }));
  }

  return { sortDataByMonthAndYear };
}
