import { DoneAllOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserImgComponent from "../../components/UserImgComponent/UserImgComponent";
import { fileType, usernameStringCheck } from "../../utils/helpers";
import { ChatsStyle } from "./Chats.style";
import ChatsHeader from "./components/ChatsHeader";
import MessageSection from "./components/MessageSection";
import useChats from "./hooks/useChats";
import Lottie from "lottie-react";
import review from "../../assets/animations/review.json";
import {
  setMessagesInView,
  setMessagingUser,
} from "../../redux/actions/chats/chats.actions";
import {
  setPopupChildren,
  togglePopup,
} from "../../redux/actions/global/global.actions";
import UserPreview from "../../components/UserPreview";

const Chats = ({ chats, messagingUser, socket, chatsFilterVal }) => {
  const classes = ChatsStyle();
  const { getChats, handleUserMessage, dispatch, getUser } = useChats();
  const { t } = useTranslation();
  const yesterday = moment().subtract(1, "days").startOf("day");
  useEffect(() => {
    getChats();
    return () => {
      dispatch(setMessagesInView(null));
      dispatch(setMessagingUser(null));
    };
  }, [chatsFilterVal]);
  return (
    <Box className={classes.chatsWrapper}>
      <ChatsHeader />

      <Box className={classes.chatsMainWrap} sx={{ display: "flex" }}>
        <Box className={classes.chatsListWrap}>
          {chats.length > 0 ? (
            chats.map((chat, i) => (
              <Box
                onClick={() => handleUserMessage(chat?.user)}
                key={i}
                className={classes.chatNodeContainer}
                style={{
                  backgroundColor:
                    messagingUser?.id === chat?.user?.id ? "#F7F7F7" : "#fff",
                }}
              >
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    getUser(chat?.user?.id);
                    dispatch(setPopupChildren(<UserPreview />));
                    dispatch(togglePopup(true));
                  }}
                  className={classes.imgBox}
                >
                  {messagingUser?.id !== chat?.user?.id &&
                  chat?.chat?.unseen_messages != 0 ? (
                    <Box className={classes.unread}>
                      <Typography fontSize={10} color="#fff">
                        {chat?.chat?.unseen_messages}
                      </Typography>
                    </Box>
                  ) : null}

                  <UserImgComponent
                    src={chat?.user?.avatar}
                    className={classes.avatarImg}
                  />
                </Box>
                <Box
                  style={{ width: "calc(100% - 58px)", position: "relative" }}
                >
                  {!chat?.last_message?.sender && (
                    <Typography className={classes.yourTurnText}>
                      {t("yourMove")}
                    </Typography>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{ fontSize: 14, fontWeight: 500, width: "100px" }}
                      noWrap
                    >
                      {/* {chat?.user?.first_name && chat?.user?.first_name}{" "}
                      {chat?.user?.last_name && chat?.user?.last_name}{" "} */}
                      <span style={{ color: "#ADABAB" }}>@</span>
                      {usernameStringCheck(chat?.user?.username)}
                    </Typography>
                    {chat?.user?.status && chat?.user?.status === "online" && (
                      <Box className={classes.onlineDot} />
                    )}
                    <Typography fontSize={9} color="gray">
                      {moment(chat?.last_message?.createdAt).isSame(
                        moment().startOf("day"),
                        "d"
                      )
                        ? moment(chat?.last_message?.createdAt).format("h:mm a")
                        : moment(chat?.last_message?.createdAt).isSame(
                            yesterday,
                            "d"
                          )
                        ? "yesterday"
                        : moment(chat?.last_message?.createdAt).format(
                            "DD/MM/YY"
                          )}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: 11,
                        width: "100px",
                        color: "#2F4D6C7E",
                      }}
                      noWrap
                    >
                      {chat?.typing ? (
                        <Typography fontSize={11} color="#1EC05E">
                          {t("typing")}
                        </Typography>
                      ) : chat?.last_message?.media ? (
                        fileType(chat?.last_message?.media) == "video" ? (
                          `...🎥${t("video")}...`
                        ) : fileType(chat?.last_message?.media) == "image" ? (
                          `...📸${t("image")}...`
                        ) : fileType(chat?.last_message?.media) == "audio" ? (
                          `...🎞${t("audio")}...`
                        ) : (
                          `...📎${t("file")}...`
                        )
                      ) : (
                        chat?.last_message?.text
                      )}
                    </Typography>
                    <Box>
                      {chat?.last_message?.sender ? (
                        chat?.last_message?.seen ? (
                          <DoneAllOutlined
                            style={{ color: "#5ced73", width: 10, height: 10 }}
                          />
                        ) : (
                          <DoneAllOutlined
                            style={{
                              color: "#C0C0C0",
                              width: 10,
                              height: 10,
                            }}
                          />
                        )
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Box className={classes.emptyBox}>
              <Lottie animationData={review} loop={true} />
              <Typography>{t("noChatFound")}</Typography>
            </Box>
          )}
        </Box>
        <MessageSection />
      </Box>
    </Box>
  );
};

export default Chats;
