import React, { useEffect, useState } from "react";
import adminService from "../../../../services/admin/admin.service";
import { debounceHandler, getToken } from "../../../../utils/helpers";
import useAdmin from "../../hooks/useAdmin";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function useAdminUser() {
  const [usersStatistics, setUsersStatics] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = getToken();
  const [batch, setBatch] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [gettingUsers, setGettingUsers] = useState(false);
  const [anchorEls, setAnchorEls] = useState([]);
  const { startDate, endDate } = useSelector((state) => state.global);
  const [date, setDate] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    name: "All",
    value: "all",
  });
  const [selectedGender, setSelectedGender] = useState({
    name: "Both",
    value: "both",
  });

  const [selectedMode, setSelectedMode] = useState({
    name: "All",
    value: "all",
  });

  const [searchText, setSearchText] = useState("");
  const opens = anchorEls.map((anchorEl) => Boolean(anchorEl));
  const navigate = useNavigate();

  const statusOptions = [
    { name: "All", value: "all" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" },
  ];

  const genderOptions = [
    { name: "Both", value: "both" },
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
  ];

  const modeOptions = [
    { name: "All", value: "all" },
    { name: "Chat with Friends", value: "FRIEND" },
    { name: "Find a Date", value: "DATE" },
    { name: "Start a Relationship", value: "RELATIONSHIP" },
    { name: "Looking for marriage", value: "MARRIAGE" },
  ];

  const handleClickMenu = (event, index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleCloseMenu = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const handleEdit = (userId, index) => {
    navigate(`edit/${userId}`);
    handleCloseMenu(index);
  };

  useEffect(() => {
    console.log("changed here");
    setDate({
      startDate,
      endDate,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    getUsersData();
  }, [token, startDate, endDate]);

  useEffect(() => {
    getUsers();
  }, [selectedStatus, searchText, date, token, selectedGender, selectedMode]);

  const handleSearchUser = (val) => {
    debounceHandler(() => setSearchText(val), 500);
  };

  const handleSelectStatus = (val) => {
    setSelectedStatus(val.target);
  };

  const handleSelectGender = (val) => {
    setSelectedGender(val.target);
  };

  const handleSelectMode = (val) => {
    setSelectedMode(val.target);
  };

  const getUsers = () => {
    setGettingUsers(true);
    adminService
      .adminGetUsers(token, {
        batch,
        limit,
        ...(selectedStatus?.value !== "all" && {
          active: selectedStatus?.value === "inactive" ? false : true,
        }),
        ...(searchText?.trim().length && {
          search: searchText,
        }),
        ...(date && {
          startDate: date?.startDate,
          endDate: date?.endDate,
        }),
        ...(selectedMode.value !== "all" && {
          mode: selectedMode.value,
        }),
        ...(selectedGender.value !== "both" && {
          gender: selectedGender.value,
        }),
      })
      .then((res) => {
        setUsers([...res.data.data]);
        setAnchorEls(new Array(res?.data?.length).fill(null));
        setIsLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setGettingUsers(false));
  };

  const getUsersData = () => {
    adminService
      .getUsersData(token)
      .then(({ data }) => setUsersStatics(data))
      .catch((err) => console.log(err));
  };

  const toggleActiveUser = (userId, isActive, index) => {
    adminService
      .toggleActiveUser(token, userId, isActive)
      .then(({ success }) => {
        if (success) getUsers();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        handleCloseMenu(index);
      });
  };

  return {
    usersStatistics,
    users,
    isLoading,
    toggleActiveUser,
    handleClickMenu,
    handleCloseMenu,
    opens,
    anchorEls,
    handleEdit,
    statusOptions,
    selectedStatus,
    handleSelectStatus,
    handleSearchUser,
    searchText,
    genderOptions,
    selectedGender,
    handleSelectGender,
    gettingUsers,
    modeOptions,
    selectedMode,
    handleSelectMode,
  };
}
