import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PaymentCheckout from "../../../pages/PaymentCheckout";
import {
  setGettingMemberships,
  setMemberships,
  setPopupChildren,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import globalService from "../../../services/global.service";
import otherServices from "../../../services/others.service";
import { getToken } from "../../../utils/helpers";

const useBuyCoins = ({ coins }) => {
  const [buying, setBuying] = useState(false);
  const [selectedCoins, setSelectedCoins] = useState(null);
  const dispatch = useDispatch();
  const token = getToken();
  const navigate = useNavigate();

  const buyCoins = (e) => {
    // navigate("/payment_checkout");

    e.preventDefault();
    if (!selectedCoins) {
      toast.error("Select amount you wish to purchase");
      return false;
    }
    setBuying(true);
    otherServices
      .buyCoins(token, selectedCoins)
      .then(({ data, success }) => {
        if (success === true) {
          dispatch(
            setPopupChildren(
              <PaymentCheckout
                clientSecret={data?.client_secret}
                isCoins={true}
                coin={coins.filter((coin) => coin?.id === selectedCoins)[0]}
              />
            )
          );
          dispatch(togglePopup(true));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBuying(false));
  };
  return { buyCoins, selectedCoins, setSelectedCoins, buying };
};

export default useBuyCoins;
