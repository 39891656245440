import { makeStyles } from "@mui/styles";

export const USersFilterStyle = makeStyles(({ theme }) => ({
  filterModalWrapper: {
    backgroundColor: "#fff",
    width: 450,
    padding: 20,
    borderRadius: 15,
    maxHeight: "80vh",
    overflow: "auto",
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 10px",
  },
  topText: { fontWeight: "500 !important", fontSize: "18px !important" },
  dropdownWrapper: {
    marginBottom: 20,
  },
  dropdownTitle: {
    marginBottom: "5px !important",
    fontSize: "14px !important",
  },
}));
