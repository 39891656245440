import { makeStyles } from "@mui/styles";

const drawerWidth = 240;

export const styles = makeStyles((theme) => ({
  container: {
    alignSelf: "center",
    justifyContent: "center",
    // alignItems: "center",
    position: "relative",

    display: "flex",
    overflow: "hidden",
    width: "100%",
  },
  view1: {
    width: "100%",
    // height: "100%",
    // backgroundColor: "#dcdcdc",
    // display: "flex",
    // alignSelf: "flex-start",
    // justifyContent: "center"
  },
  header: {
    padding: "20px",
    backgroundColor: "#fff",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "4px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
    marginTop: 40,
  },
  headerTxt: {
    margin: "5px",
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dividerV: {
    backgroundColor: "#000",
    width: "1px",
    height: "15px",
    margin: "5px 10px",
  },
  online: {
    width: "8px",
    height: "8px",
    backgroundColor: "#06C439",
    borderRadius: 100,
    margin: "auto 5px",
  },
  headerBtn: {
    display: "flex !important",
    flexDirection: "row !important",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFCECC",
    borderRadius: 5,
    padding: "10px 20px",
    cursor: "pointer",
    borderStyle: "none",
  },
  backBtn: {
    color: "red",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
