import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  postModal: {
    paddingTop: "30px",
    background: "#0F0704",
    width: 400,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    border: "none",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
  },
  topHalf: {
    backgroundColor: "#0F0704",
    display: "flex",
    flexDirection: "column",
    padding: "0 15px 15px 15px",
    gap: 10,
  },
  close: {
    width: "14px",
    height: "14px",
    borderRadius: "50%",
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    "& img": {
      width: "60%",
      height: "60%",
    },
  },
  topHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    fontSize: "14px !important",
    fontWeight: "200 !important",
    color: "#FFFFFF",
    transform: "translateX(23px)",
  },
  headerText2: {
    fontSize: "12px !important",
    fontWeight: "100 !important",
    color: "#FFFFFF",
    cursor: "pointer",
    background: "none",
    border: "none",
  },
  postImage: {
    height: 250,
    width: "100%",
    background: "#252525",
    position: "relative",

    "& div > img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  lowerHalf: {
    background: "#FFFFFF",
    padding: "15px 15px 30px 15px",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  captionHead: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    paddingLeft: 14,
  },
  captionField: {
    background: "#F4F5F9 !important",
    minHeight: "50px !important",
    width: "100%",
    border: "none",
    wordBreak: "break-word",

    "& fieldset": {
      border: "none",
    },
    "&:focus": {
      border: "none",
    },
  },
  captionField2: {
    background: "#F4F5F9 !important",
    height: "45px !important",
    width: "100%",
    border: "none",
    wordBreak: "break-word",
    display: "flex",
    justifyContent: "center",

    "& fieldset": {
      border: "none",
    },
    "&:focus": {
      border: "none",
    },
    "& > .css-1r02wuc-MuiInputBase-root-MuiOutlinedInput-root": {
      padding: 0,
      width: "100%",
    },
  },
  wowo: {
    position: "relative",

    "& .location-search-input ": {
      background: "#F4F5F9 !important",
      height: "45px !important",
      width: "100%",
      border: "none",
      wordBreak: "break-word",
      display: "flex",
      justifyContent: "center",
      padding: "0 15px",
      paddingLeft: 20,
      boxSizing: "border-box",
    },
    "& .location-search-input:focus,.location-search-input:active,  ": {
      border: "none",
      outline: "none",
    },
    "& .locationIcon": {
      position: "absolute",
      top: 12,
      left: 5,
    },
    "& .autocomplete-dropdown-container": {
      marginTop: 10,
      background: "#d0d0d0",
      padding: 0,
      position: "absolute",
      maxHeight: 150,
      zIndex: 200,
      overflowY: "scroll",
    },
  },
  uploaderIcon: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& span": {
      color: "#fff",
    },
  },
  btnBox: {
    display: "flex",
    gap: 15,
  },
  day: {
    height: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#F4F5F9 ",
    width: "20%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  month: {
    height: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#F4F5F9 ",
    width: "30%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  year: {
    height: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#F4F5F9 ",
    width: "50%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  time: {
    height: 45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#F4F5F9 ",
    width: "100%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  topSpinner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#000",
    opacity: 0.5,
    borderRadius: 5,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
