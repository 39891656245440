import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MembershipCard from "../../../../components/Memberships/components/MembershipCard";
import { styles } from "./style";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../redux/actions/global/global.actions";
import Memberships from "../../../../components/Memberships";
import { useTranslation } from "react-i18next";
import useConstants from "../../../../utils/useConstants";
import useMembershipConstants from "./useMembershipConstants";

const Membership = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const { memberships } = useConstants();

  const myData = useSelector((state) => state.users.myData);

  const [currentPlan, setCurrentPlan] = useState(null);

  const [selected, setSelected] = useState(1);
  const { litePlanList, elitePlanList, clubPlanList } =
    useMembershipConstants();

  useEffect(() => {
    setCurrentPlan(myData?.membership?.id);
  }, [myData]);

  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.view1}>
        <div className={classes.view11}>
          {memberships.map((membership, i) => (
            <Box sx={{ maxWidth: "400px", flex: "1 1 0px" }}>
              <MembershipCard
                membership={membership}
                i={i}
                key={i}
                currentPlan={currentPlan}
                onClick={() => {
                  setSelected(membership?.id);
                }}
                selected={selected}
              />
            </Box>
          ))}
        </div>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            onClick={() => {
              dispatch(togglePopup(true));
              dispatch(setPopupChildren(<Memberships />));
            }}
            className={classes.upgradeText}
          >
            {t("changeMembership")}
            {/* CHANGE MEMBERSHIP */}
          </Typography>
        </Box>
      </div>
      <Box className={classes.memberDetailsBox}>
        {selected === 1 && (
          <>
            <Typography padding={2} fontWeight={600}>
              {t("whatLiteGet")}
            </Typography>
            <List>
              {litePlanList.map((item, i) => (
                <ListItem key={i}>
                  <ListItemIcon>
                    <TaskAltIcon
                      style={{ height: 15, width: 15, color: "#14AA00" }}
                    />
                  </ListItemIcon>
                  <ListItemText>{item}</ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {selected === 2 && (
          <>
            <Typography padding={2} fontWeight={600}>
              {t("whatClubGet")}
            </Typography>
            <List>
              {clubPlanList.map((item, i) => (
                <ListItem key={i}>
                  <ListItemIcon>
                    <TaskAltIcon
                      style={{ height: 15, width: 15, color: "#14AA00" }}
                    />
                  </ListItemIcon>
                  <ListItemText>{item}</ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        )}
        {selected === 3 && (
          <>
            <Typography padding={2} fontWeight={600}>
              {t("whatEliteGet")}
            </Typography>
            <List>
              {elitePlanList.map((item, i) => (
                <ListItem key={i}>
                  <ListItemIcon>
                    <TaskAltIcon
                      style={{ height: 15, width: 15, color: "#14AA00" }}
                    />
                  </ListItemIcon>
                  <ListItemText>{item}</ListItemText>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
    </div>
  );
};

export default Membership;
