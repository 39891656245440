import { useReducer, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import usersService from "../../../services/users.service";
import { colors, imageExts, videoExts } from "../../../utils/constants";
import {
  getOnboarding,
  getToken,
  setOnboarding,
  setUser,
} from "../../../utils/helpers";
import { setMyData } from "./../../../redux/actions/users/users.actions";

function useVerification() {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const token = getToken();

  const [fileToAccept, setFileToAccept] = useState(null);
  const [imgType, setImgType] = useState("");
  const [imgIndex, setImgIndex] = useState(0);
  const [vid, setVid] = useState(null);
  const [files, setFiles] = useState([]);
  const [others, setOthers] = useState([null, null, null, null, null]);
  const [main, setMain] = useState(null);
  const { t } = useTranslation();
  const initState = {
    isLoading: false,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initState
  );

  const { open } = useDropzone({
    accept: {
      ...(fileToAccept === "video"
        ? { "video/*": videoExts }
        : { "image/*": imageExts }),
    },
    multiple: false,
    maxSize: 15728640,
    minSize: 0,

    onDrop: (acceptedFiles) => {
      if (fileToAccept === "video") {
        setVid(acceptedFiles[0]);
      }
      if (fileToAccept === "image") {
        if (files.length > 5) {
          setFileToAccept(null);

          return toast.error(t("cantHave5"));
        }

        if (imgType === "main") {
          setMain(acceptedFiles[0]);
          setFiles([...files, acceptedFiles[0]]);
        }

        if (imgType === "others") {
          setOthers(
            others.map((item, i) => (i === imgIndex ? acceptedFiles[0] : item))
          );
          setFiles([...files, acceptedFiles[0]]);
        }
      }
    },
  });

  const uploadAll = (e) => {
    e.preventDefault();

    if (!others[0]) {
      return toast.error(t("selectProfPic"));
    } else if (others.filter((item) => item !== null).length < 3) {
      return toast.error(t("selectGalImg"));
    } else {
      let formData = new FormData();

      formData.append("main", others[0]);

      if (others.length > 1) {
        for (let i = 1; i < others.length; i++) {
          if (others[i]) {
            formData.append("others", others[i]);
          }
        }
      }

      dispatch({ isLoading: true });
      usersService
        .uploadPhotos(token, formData)
        .then((res) => {
          if (res?.data?.success === true) {
            toast.success(res?.data?.message);
            navigate("/onboarding/confirmation");
            if (vid) {
              uploadVideo();
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data.errors || err.response.data.message);
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  };
  const getMe = () => {
    usersService.getMe(token).then(({ data }) => {
      reduxDispatch(setMyData(data));
      setUser(data);
    });
  };

  const uploadVideo = () => {
    let formData = new FormData();
    formData.append("file", vid);
    usersService
      .uploadVideoProfile(token, formData)
      .then((res) => {
        if (res?.success) {
          getMe();
        } else {
          console.log(res?.errors || res?.message);
        }
      })
      .catch((err) => {
        console.log(err?.message);
      })
      .finally(() => {});
  };

  const myData = useSelector((state) => state.users.myData);

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };
  return {
    setImgType,
    open,
    files,
    setFileToAccept,
    fileToAccept,
    main,
    others,
    vid,
    state,
    uploadAll,
    getMe,
    myData,
    getRandomColor,
    setImgIndex,
    imgIndex,
  };
}

export default useVerification;
