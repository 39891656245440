import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { togglePopup } from "../../../redux/actions/global/global.actions";
import authService from "../../../services/auth.service";
import { getToken } from "../../../utils/helpers";

const useChangePassword = () => {
  const [pass, setPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [cNewPass, setCNewPass] = useState("");
  const [showPass, setShowPass] = useState("");
  const [showNewPass, setShowNewPass] = useState("");
  const [showCNewPass, setShowCNewPass] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const token = getToken();
  const dispatch = useDispatch();
  const onSubmitPass = () => {
    if (newPass !== cNewPass) {
      return toast.error("Passwords do not match");
    }
    setSubmitting(true);

    const data = {
      type: "RESET",
      password: cNewPass,
      old_password: pass,
    };

    authService
      .resetNewPassword(token, data)
      .then((res) => {
        if (res?.success) {
          toast.success("Successfully changed password");
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => toast.error(err?.errors || err?.message))
      .finally(() => {
        setSubmitting(false);
        dispatch(togglePopup(false));
      });
  };

  return {
    pass,
    setPass,
    newPass,
    setNewPass,
    cNewPass,
    setCNewPass,
    submitting,
    onSubmitPass,
    showPass,
    setShowPass,
    showNewPass,
    setShowNewPass,
    showCNewPass,
    setShowCNewPass,
  };
};

export default useChangePassword;
