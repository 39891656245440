import { useTranslation } from "react-i18next";

const useCoinsConstants = () => {
  const { t } = useTranslation();
  const coins = [
    { id: 1, title: t("basic"), amount: 4.99, quantity: 6 },
    { id: 4, title: t("bronze"), amount: 9.99, quantity: 15 },

    { id: 5, title: t("silverPack"), amount: 49.99, quantity: 70 },
    { id: 2, title: t("goldPack"), amount: 99.99, quantity: 180 },
  ];
  return { coins };
};

export default useCoinsConstants;
