import { makeStyles } from "@mui/styles";

export const MyPostsStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "80vh",
    overflowY: "scroll",
    display: "flex !important",
    flexDirection: "column",
    gap: 30,
    backgroundColor: "#fff",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    scrollBehavior: "smooth",
    padding: "20px 0px",
    boxSizing: "border-box",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
