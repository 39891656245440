import { ReactComponent as FeedsActiveIcon } from "../../../../assets/icons/nav-icons/ACTIVE/feeds-active.svg";
import { ReactComponent as FeedsIcon } from "../../../../assets/icons/nav-icons/INACTIVE/feeds.svg";
import { ReactComponent as DiscoverActiveIcon } from "../../../../assets/icons/nav-icons/ACTIVE/discover-active.svg";
import { ReactComponent as DiscoverIcon } from "../../../../assets/icons/nav-icons/INACTIVE/discover.svg";
import { ReactComponent as MatchesActiveIcon } from "../../../../assets/icons/nav-icons/ACTIVE/matches-active.svg";
import { ReactComponent as MatchesIcon } from "../../../../assets/icons/nav-icons/INACTIVE/matches.svg";
import { ReactComponent as ChatsActiveIcon } from "../../../../assets/icons/nav-icons/ACTIVE/chats-active.svg";
import { ReactComponent as ChatsIcon } from "../../../../assets/icons/nav-icons/INACTIVE/chats.svg";
import { ReactComponent as NearbyIcon } from "../../../../assets/icons/nav-icons/INACTIVE/nearby.svg";
import { ReactComponent as ActivityIcon } from "../../../../assets/icons/nav-icons/INACTIVE/thumbs-up.svg";
import { ReactComponent as NearbyActiveIcon } from "../../../../assets/icons/nav-icons/ACTIVE/nearby-active.svg";
import { ReactComponent as ActivityActiveIcon } from "../../../../assets/icons/nav-icons/ACTIVE/thumbs-up-active.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import socketService from "../../../../services/socket.service";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setChats } from "../../../../redux/actions/chats/chats.actions";

const useNavConstants = () => {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = useState(false);
  const chats = useSelector((state) => state.chats.chats);
  const dispatch = useDispatch();
  const location = useLocation();
  const sideBarNavigations = [
    {
      id: 1,
      title: t("m8sFeeds"),
      path: "/feeds",
      value: "feeds",
      icon: <FeedsIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <FeedsActiveIcon style={{ width: 20, height: 20 }} />,
    },
    {
      id: 2,
      title: t("discover"),
      path: "/discover",
      value: "discover",
      icon: <DiscoverIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <DiscoverActiveIcon style={{ width: 20, height: 20 }} />,
    },
    {
      id: 3,
      title: t("nearby"),
      path: "/nearby",
      value: "nearby",
      icon: <NearbyIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <NearbyActiveIcon style={{ width: 20, height: 20 }} />,
    },
    {
      id: 4,
      title: t("matches"),
      path: "/matches",
      value: "matches",
      icon: <MatchesIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <MatchesActiveIcon style={{ width: 20, height: 20 }} />,
    },
    {
      id: 5,
      title: t("chats"),
      path: "/chats",
      value: "chats",
      icon: <ChatsIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <ChatsActiveIcon style={{ width: 20, height: 20 }} />,
    },
    {
      id: 6,
      title: t("activity"),
      path: "/activities",
      value: "activities",
      icon: <ActivityIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <ActivityActiveIcon style={{ width: 20, height: 20 }} />,
    },
  ];

  const adminNavigations = [
    {
      id: 1,
      title: "Dashboard",
      path: "/admin/dashboard",
      value: "dashboard",
      icon: <FeedsIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <FeedsActiveIcon style={{ width: 20, height: 20 }} />,
    },
    {
      id: 2,
      title: "Revenue",
      path: "/admin/revenue",
      value: "revenue",
      icon: <DiscoverIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <DiscoverActiveIcon style={{ width: 20, height: 20 }} />,
    },
    {
      id: 3,
      title: "Users",
      path: "/admin/users",
      value: "users",
      icon: <NearbyIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <NearbyActiveIcon style={{ width: 20, height: 20 }} />,
    },
    {
      id: 4,
      title: "Referrals",
      path: "/admin/referrals",
      value: "referrals",
      icon: <MatchesIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <MatchesActiveIcon style={{ width: 20, height: 20 }} />,
    },
    {
      id: 5,
      title: "Settings",
      path: "/admin/settings",
      value: "settings",
      icon: <SettingsIcon style={{ width: 20, height: 20 }} />,
      activeIcon: <SettingsIcon style={{ width: 20, height: 20 }} />,
    },
  ];
  socketService.on("message", (res) => {
    if (res && location.pathname !== "/chats") {
      setNewMessage(true);
      const newChats = chats?.map((chat) =>
        chat?.user?.id == res?.sender
          ? {
              ...chat,
              last_message: res?.message,
              chat: {
                ...chat?.chat,
                unseen_messages: chat?.chat?.unseen_messages + 1,
              },
            }
          : chat
      );
      const chatToMoveTop = newChats?.filter(
        (chat) => chat?.user?.id == res?.sender
      );

      const otherChats = newChats?.filter(
        (chat) => chat?.user?.id !== res?.sender
      );
      dispatch(setChats([...chatToMoveTop, ...otherChats]));
    }
  });
  return { sideBarNavigations, newMessage, setNewMessage, adminNavigations };
};

export default useNavConstants;
