import { makeStyles } from "@mui/styles";
import { COLOR } from "../../../../../../../../../constants/theme";
export const styles = makeStyles((theme) => ({
  view2: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    alignSelf: "center",
    height: "100vh",
    // overflow: "auto",
    // overflowX: "hidden",
    // alignItems: "flex-start",
    // backgroundColor: "blue"
  },
  title: {
    fontWeight: "700",
    fontSize: 13,
  },
  title1: {
    fontWeight: "600",
    fontSize: 15,
  },
  input: {
    width: "100%",
    backgroundColor: "#fff",
    padding: 20,
    borderStyle: "none !important",
    fontSize: 12,
    borderWidth: "0px",
    borderColor: "transparent !important",
    outline: "none",
  },
  inputView: {
    marginTop: 20,
    position: "relative",
  },
  wrapView: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "90%",
  },
  input1: {
    width: "90%",
    backgroundColor: "#fff",
    fontSize: 14,
    alignSelf: "center",
    border: "none",
    outline: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  sexuality: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    alignSelf: "flex-start",
    marginTop: 20,
    // backgroundColor: "blue"
  },
  sexBtn: {
    // border: "1px solid #707070",
    backgroundColor: COLOR.light,
    width: "45%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 18,
    cursor: "pointer",
    border: "none",
  },

  sexText: {
    fontWeight: "500",
    fontSize: 13,
  },
  sexBtnNone: {
    // border: "1px solid #707070",
    backgroundColor: "#fff",
    width: "40%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    cursor: "pointer",
    border: "none",
  },
  content: {
    fontWeight: "400",
    fontSize: 13,
    textAlign: "center",
  },
  closeBtn: {
    borderRadius: 100,
    padding: 5,
    width: 15,
    height: 15,
    backgroundColor: "#fff",
    border: "1px solid",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    position: "absolute",
    right: -12,
    top: -12,
  },
  moreInfoRow: {
    display: "flex !important",
    justifyContent: "space-between",
    marginBottom: 10,
    padding: "10px 0px",
    borderBottom: "1px solid #70707014",
    cursor: "pointer",
  },
  infoTitle: {
    display: "flex !important",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  infoIcon: {
    color: "#EA0F08",
    height: "20px !important",
    width: "20px !important",
  },
  saveProfileBtn: {
    borderStyle: "none",
    backgroundColor: "#EA0F08",
    padding: 15,
    width: "100%",
    color: "#fff",
    borderRadius: 3,
    cursor: "pointer",
    fontWeight: "600",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },
  interestBox: {
    padding: "0px 10px",
    borderRadius: 5,
    position: "relative",
    margin: 10,
    cursor: "pointer",
  },
  day: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "20%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  month: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "30%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  year: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "50%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  btnBox: {
    display: "flex",
    gap: 20,
  },
}));
