import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ReportDialog = ({
  open,
  handleClose,
  title,
  description,
  value,
  onChange,
  onCancel,
  onSubmit,
  submitting,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="report"
          type="text"
          fullWidth
          variant="standard"
          multiline={true}
          maxRows={5}
          value={value}
          onChange={onChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("cancel")}</Button>
        <Button disabled={submitting} onClick={onSubmit}>
          {" "}
          {submitting ? t("wait") : t("submit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportDialog;
