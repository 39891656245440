import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: 550,
    margin: "auto",
    display: "flex !important",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 5px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    marginBottom: "2rem",
  },
  cardHeader: {
    width: "100%",
    height: 80,
    display: "flex",
    gap: 20,
    alignItems: "center",
    padding: "10px 15px",
    boxSizing: "border-box",
    justifyContent: "space-between",
  },
  cardProfile: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  cardProfileImg: {
    height: 55,
    width: 55,
    borderRadius: "50%",
    overflow: "hidden",
  },
  cardAvatar: {
    height: "100% !important",
    width: "100% !important",
    objectFit: "cover !important",
  },

  cardProfileName: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    cursor: "pointer",
  },
  cardProfileLocation: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    display: "block",
    alignItems: "center",
    gap: 8,
    width: 300,
  },
  locationIcon: {
    height: 14,
  },
  cardIcons: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  cardCaption: {
    minHeight: 50,
    padding: "10px 15px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "flex-Start",
    // justifyContent: "space-between",
    flexDirection: "column",
    gap: 15,
  },
  cardCaptionText: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
  },

  cardImageBox: {
    width: "100%",
    height: 400,
    // background: "#f3f3f3",
    background: "#fff",
    // overflow: "hidden",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },

  cardImage: {
    maxWidth: 550,
    //maxHeight: 400,
    // width: "auto",
    height: "100%",
    objectFit: "contain",
    background: "#f3f3f3",
  },
  dropdown: {
    cursor: "pointer",
    position: "relative",
    height: 20,
    transform: "translateY(-3px)",

    "& img ": {
      height: "100%",
    },
  },
  noDisplay: {
    display: "none",
  },
  dropdownContent: {
    position: "absolute",
    top: 30,
    right: -15,
    width: 120,
    overflow: "auto",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    backgroundColor: "#fff",
    zIndex: 100,
  },
  dropContent: {
    fontWeight: " 500 !important",
    fontSize: "12px !important",
    padding: "15px 25px 15px 35px",

    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",

    "&: hover": {
      backgroundColor: "#dadde1",
    },
  },
  "@keyframes likeIcon": {
    "0%": {
      transform: "scale(1)",
    },
    "40%": {
      transform: "scale(.4)",
    },
    "80%": {
      transform: "scale(1.5)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  "@keyframes likeAnime": {
    to: { transform: " scale(1.2)" },
  },
  likeIcon: {
    cursor: "pointer",
    width: 24,

    "&:active": {
      animationName: "$likeIcon",
      animationDuration: "1s",
      animationTimingFunction: "cubic-bezier(0.27, 0.35, 0.71, 1.68)",
    },
  },
  likeAnime: {
    animation: "$likeAnime .5s infinite alternate",
  },
  giftIcon: {
    cursor: "pointer",
  },
  captionTag: {
    fontWeight: " 600 !important",
    fontSize: "14px !important",
    color: "#EA0F07",
  },
  eventDate: {
    fontSize: "12px !important",
    display: "flex !important",
    alignItems: "center",
    gap: 10,

    "& .iconGo": {
      color: "green",
    },
    "& .iconStop": {
      color: "#EA0F07",
      fontStyle: "italic",
    },
  },

  requestIv: {
    padding: "5px 10px",
    background: "#FFB9B7",
    fontSize: 11,
    cursor: "pointer",
  },
  pendingIv: {
    padding: "5px 10px",
    background: "#FFD37C",
    fontSize: 11,
    cursor: "pointer",
  },
  approvedIv: {
    padding: "5px 10px",
    background: "#06C439",
    fontSize: 11,
    cursor: "pointer",
  },
  file: {
    maxWidth: 600,
    width: 600,
    height: 400,
    objectFit: "contain",
    background: "#f3f3f3",
  },
}));

export default useStyles;
