import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getToken } from "../../../../../../utils/helpers";
import adminService from "../../../../../../services/admin/admin.service";

export default function useManageTeams() {
  const [usersData, setUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const token = getToken();
  const getUsers = () => {
    setIsLoading(true);
    adminService
      .getAdminUsers(token)
      .then((res) => {
        setIsLoading(false);
        setUsersData(res.data.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  useEffect(() => {
    getUsers();
  }, [token]);
  useEffect(() => {}, [usersData]);

  // console.log("User Data", usersData);
  return { usersData, isLoading, getUsers };
}
