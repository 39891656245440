import React from "react";
import { useTranslation } from "react-i18next";

const useMembershipConstants = () => {
  const { t } = useTranslation();

  const litePlanList = [
    t("smartMatch50"),
    // t('limit20Match'),
    // t('cantSeeLikes'),
    // t("earnToCash"),
    t("instantMessaging"),
    // t('noAudioVideo'),
    t("oneCountryLocation"),
    t("voiceAllowed"),
  ];

  const clubPlanList = [
    t("smartMatch80"),
    t("seeLikes"),
    t("instantMessaging"),

    t("unlimitedMatch"),
    // t("earnToCash"),
    t("voiceAudioEnabled"),
    // t('noVideo'),
    t("twoCountryLocation"),
  ];

  const elitePlanList = [
    t("smartMatch80"),
    t("seeLikeDiscovery"),
    t("instantMessaging"),

    t("unlimitedMatch"),
    // t("earnToCash"),
    t("voiceAudioEnabled"),
    t("videoAllowed"),
    t("accessAll"),
  ];

  return { litePlanList, clubPlanList, elitePlanList };
};

export default useMembershipConstants;
