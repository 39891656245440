import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Circles } from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton';
import CustomButton from '../../../../../../components/CustomButton';
import UserImgComponent from '../../../../../../components/UserImgComponent';
import useSettings from '../../../../hooks/useSettings';
import { BlockedAccountsStyles } from './BlockedAccounts.styles';

const BlockedAccounts = () => {
  const classes = BlockedAccountsStyles();

  const {
    getBlockedAccts,
    gettingBlocked,
    unBlockUser,
    unblocking,
    setGettingBlocked,
    blockedUsers,
  } = useSettings();

  useEffect(() => {
    getBlockedAccts();
  }, []);
  console.log(blockedUsers);
  return (
    <Box className={classes.pageWrap}>
      {gettingBlocked ? (
        <Skeleton count={10} height={50} style={{ marginBottom: 10 }} />
      ) : (
        blockedUsers?.map((user, i) => (
          <Box key={i} className={classes.section}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 50,
                height: 50,
                borderRadius: '100%',
                overflow: 'hidden',
              }}
            >
              <UserImgComponent
                src={user?.avatar}
                style={{ width: 50, height: 'auto' }}
              />
            </Box>

            <CustomButton
              onClick={() => unBlockUser(user?.id)}
              disable={unblocking}
              className={classes.unblockBtn}
            >
              {unblocking ? (
                <Circles color="#fff" width={15} height={15} />
              ) : (
                'Unblock'
              )}
            </CustomButton>
          </Box>
        ))
      )}
    </Box>
  );
};

export default BlockedAccounts;
