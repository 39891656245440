import { connect } from "react-redux";
import { setMessagingUser } from "../../redux/actions/chats/chats.actions";
import { togglePopup } from "../../redux/actions/global/global.actions";
import UserPreview from "./UserPreview";

const mapState = ({ users, global }) => ({
  previewUser: users.previewUser,
  gettingPreview: users.gettingPreview,
});

const mapDispatchToProps = (dispatch) => ({
  setMessagingUser: (data) => dispatch(setMessagingUser(data)),
  togglePopup: (data) => dispatch(togglePopup(data)),
});
export default connect(mapState, mapDispatchToProps)(UserPreview);
