import { Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PostImgComponent from "../../../../../../../../../../components/PostImgComponent";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { ReactComponent as LocationIcon } from "../../../../../../../../../../resources/img/location-pin.svg";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import moment from "moment";
import {
  fileType,
  usernameStringCheck,
} from "../../../../../../../../../../utils/helpers";
import DotsIcon from "../../../../../../../../../../resources/img/Icon material-more-vert.svg";

import { EventsCardStyles } from "./EventsCard.style";
import Carousel from "react-material-ui-carousel";
import usePostsEvents from "../../../hooks/usePostsEvents";

const EventsCard = ({ event }) => {
  const classes = EventsCardStyles();
  const { previewUser } = useSelector((state) => state.users);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const reduxDispatch = useDispatch();

  const { removeUserEvent } = usePostsEvents();

  const onOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const cVideoRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.play();
          } else {
            entry.target.pause();
          }
        });
      },
      { threshold: 0.5 }
    );
    if (cVideoRef.current) {
      observer.observe(cVideoRef.current);
    }

    return () => {
      if (cVideoRef?.current) {
        observer.unobserve(cVideoRef.current);
      }
    };
  }, [cVideoRef]);

  const checkVidInViewport = (e) => {
    if (e === 1) {
      cVideoRef.current && cVideoRef.current.play();
    } else {
      cVideoRef.current && cVideoRef.current.pause();
    }
  };
  return (
    <Box className={classes.container}>
      <Box className={classes.eventImgContainer}>
        {/* <PostImgComponent
          src={event?.files[0] || event?.image}
          style={{
            height: 120,
            width: "100%",
            objectFit: "contain",
          }}
        /> */}
        {Array.isArray(event?.files) ? (
          <Carousel
            onChange={(e) => checkVidInViewport(e)}
            sx={{
              display: "flex !important",
              justifyContent: "center !important",
              alignItems: "center !important",

              height: 120,
              width: "100%",
            }}
            className={classes.cardImage}
            indicators={false}
            navButtonsAlwaysVisible={true}
            autoPlay={false}
            animation="slide"
            navButtonsProps={{
              style: {
                backgroundColor: "#000",
                opacity: 0.1,
                display: event?.files?.length <= 1 ? "none" : undefined,
              },
            }}
          >
            {event?.files?.map((item, i) =>
              fileType(item) === "video" ? (
                <video
                  key={i}
                  src={item}
                  className={classes.cardImage}
                  ref={cVideoRef}
                  // loop={false}
                  controls
                  style={{ width: "100%" }}
                  loop={false}
                />
              ) : (
                <PostImgComponent
                  key={i}
                  className={classes.file}
                  src={item}
                  alt="card Img"
                />
              )
            )}
          </Carousel>
        ) : fileType(event?.image) === "video" ? (
          <video
            // autoPlay
            loop={false}
            src={event?.image}
            className={classes.cardImage}
            controls
            ref={cVideoRef}
          />
        ) : (
          <PostImgComponent
            className={classes.cardImage}
            src={event?.image}
            alt="card Img"
          />
        )}
      </Box>
      <Box className={classes.eventDetails}>
        <Box className={classes.infoTopTitle}>
          <Box>
            <Typography style={{ fontSize: 14, fontWeight: "500" }}>
              {event?.caption}
            </Typography>
          </Box>

          <Box className={classes.statusInfo}>
            <Typography
              style={{ color: "red", fontWeight: "500", fontSize: 13 }}
            >
              {
                event?.attendees?.filter(
                  (attender) => attender?.status === "accepted"
                ).length
                // event?.attendees
              }
            </Typography>
            <PeopleAltOutlinedIcon
              name="people"
              size="md"
              style={{ marginLeft: 1 }}
            />
            <span
              className={classes.dropdown}
              id="demo-positioned-button"
              aria-controls={"demo-positioned-menu"}
              aria-haspopup="true"
              aria-expanded={"true"}
              onClick={(e) => onOpenMenu(e, event?.id)}
            >
              <img src={DotsIcon} alt="icon" />
            </span>
            <Menu
              anchorEl={anchorEl}
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={() => {
                  removeUserEvent(event?.id);
                  handleClose();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Box
          className={classes.locationInfo}
          sx={{
            display: event?.post_location ? "flex" : "none",
            gap: 1,
            width: "100%",
          }}
        >
          <Box paddingY={1}>
            <LocationIcon style={{ color: "#000" }} />
          </Box>

          <Typography className={classes.locationText}>
            {event?.post_location}
          </Typography>
        </Box>
        {event?.is_event && event?.event_date && (
          <Box className={classes.eventDetailsWrapper}>
            <ErrorOutlineIcon
              style={{
                color: moment(event?.event_date).isAfter() ? "green" : "red",
                marginRight: 2,
                width: 15,
                height: 15,
              }}
              size="xs"
            />
            <Typography style={{ fontSize: 12, fontStyle: "italic" }}>
              {moment(event?.event_date).format("D MMM. YYYY.")}
            </Typography>
            <Typography
              style={{
                fontWeight: "600",
                fontSize: 12,
                fontStyle: "italic",
              }}
            >
              {moment(event?.event_date).format(" -  h:mm a")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EventsCard;
