import { Slide } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import socketService from "../../../services/socket.service";
import { getToken } from "../../../utils/helpers";

const useSocketPeer = () => {
  const token = getToken();

  const location = useLocation();
  const [newMessage, setNewMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [transition, setTransition] = React.useState(undefined);
  const handleShowMessage = (Transition) => {
    console.log(Transition);
    setTransition(() => Transition);
    setOpen(true);
  };
  function TransitionUp(props) {
    return <Slide {...props} direction="down" />;
  }
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (token) {
      socketService.connect(token);
    }
  }, [token]);

  useEffect(() => {
    socketService.on("message", (res) => {
      if (res && location.pathname !== "/chats") {
        setNewMessage(res?.message);
        handleShowMessage(TransitionUp);
      }
    });
  }, [location, token]);
  return { open, handleClose, transition, newMessage };
};

export default useSocketPeer;
