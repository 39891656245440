import { Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModesModal from "../../../components/ModesModal";
import {
  setPopupChildren,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import { getAge, usernameStringCheck } from "../../../utils/helpers";
import useConstants from "../../../utils/useConstants";
import { styles } from "../style";
import clubBadge from "../../../assets/images/club.png";
import eliteBadge from "../../../assets/images/elite.png";
import liteBadge from "../../../assets/images/lite.png";

export const Header = () => {
  const classes = styles();
  const myData = useSelector((state) => state.users.myData);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { modes } = useConstants();

  return (
    <div className={classes.header}>
      <div className={classes.rowView}>
        {myData?.membership?.id === 3 && (
          <img
            alt={"elite"}
            src={eliteBadge}
            style={{ width: 26, height: 32, marginRight: 8 }}
          />
        )}
        {myData?.membership?.id === 2 && (
          <img
            alt="club"
            src={clubBadge}
            style={{ width: 26, height: 32, marginRight: 8 }}
          />
        )}
        {myData?.membership?.id === 1 && (
          <img
            alt="lite"
            src={liteBadge}
            style={{ width: 26, height: 32, marginRight: 8 }}
          />
        )}
        <Typography
          fontSize={14}
          fontWeight={"500"}
          className={classes.headerTxt}
        >
          {myData?.first_name || myData?.last_name || myData?.username},{" "}
          {myData?.birthday && getAge(myData?.birthday)}
        </Typography>
        {myData?.status && myData?.status === "online" && (
          <>
            <div className={classes.dividerV} />

            <Typography
              fontSize={14}
              fontWeight={"500"}
              className={classes.headerTxt}
            >
              Online
            </Typography>
            <div className={classes.online} />
          </>
        )}

        <div className={classes.dividerV} />

        <Typography
          fontSize={14}
          fontWeight={"500"}
          className={classes.headerTxt}
        >
          @{usernameStringCheck(myData?.username)}
        </Typography>
      </div>
      {location?.pathname === "/settings/security/blocked_accounts" ? (
        <Typography onClick={() => navigate(-1)} className={classes.backBtn}>
          Back
        </Typography>
      ) : (
        <div
          className={classes.headerBtn}
          onClick={() => {
            dispatch(togglePopup(true));
            dispatch(setPopupChildren(<ModesModal />));
          }}
        >
          {modes.filter((mode) => mode?.value === myData?.mode)[0]?.logo}

          <Typography
            marginLeft={2}
            fontSize={15}
            fontWeight={"500"}
            className={classes.headerTxt}
          >
            {modes.filter((mode) => mode?.value === myData?.mode)[0]?.title}
          </Typography>
        </div>
      )}
    </div>
  );
};
