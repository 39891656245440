import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { ChatsFilterStyles } from "./ChatsFilter.styles";
import useChatsFilter from "./hooks/useChatsFilter";

const ChatsFilter = ({ chatsFilterVal }) => {
  const classes = ChatsFilterStyles();

  const { onSelectFilterBy } = useChatsFilter();
  return (
    <Box className={classes.wrapper}>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={chatsFilterVal}
          onChange={({ target }) => onSelectFilterBy(target.value)}
        >
          <FormControlLabel
            value="most_recent"
            control={<Radio className={classes.radio} />}
            label="Most Recent"
            labelPlacement="start"
            className={classes.radioWrap}
            componentsProps={{
              typography: { fontSize: "14px", fontWeight: 500 },
            }}
          />
          <FormControlLabel
            value="unread"
            control={<Radio className={classes.radio} />}
            label="Unread"
            labelPlacement="start"
            className={classes.radioWrap}
            componentsProps={{
              typography: { fontSize: "14px", fontWeight: 500 },
            }}
          />
          <FormControlLabel
            value="favourites"
            control={<Radio className={classes.radio} />}
            label="Favourites"
            labelPlacement="start"
            className={classes.radioWrap}
            componentsProps={{
              typography: { fontSize: "14px", fontWeight: 500 },
            }}
          />
          <FormControlLabel
            value="online"
            control={<Radio className={classes.radio} />}
            label="Online"
            labelPlacement="start"
            className={classes.radioWrap}
            componentsProps={{
              typography: { fontSize: "14px", fontWeight: 500 },
            }}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default ChatsFilter;
