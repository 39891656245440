import i18next from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { togglePopup } from "../../../redux/actions/global/global.actions";
import {
  setMyCoords,
  setMyData,
} from "../../../redux/actions/users/users.actions";
import otherServices from "../../../services/others.service";
import usersService from "../../../services/users.service";
import { getToken, setLocalLang } from "../../../utils/helpers";
import Geocode from "react-geocode";
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from "react-i18next";
const useSettings = () => {
  const [walletTrans, setWalletTrans] = useState([]);
  const [gettingTrans, setGettingTrans] = useState(false);
  const [gettingBlocked, setGettingBlocked] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [unblocking, setUnblocking] = useState(false);
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const [submittingInt, setSubmittingInt] = useState(false);
  const myData = useSelector((state) => state.users.myData);
  const [locationEnabled, setLocationEnabled] = useState(
    !myData?.disable_location
  );
  const dispatch = useDispatch();
  const token = getToken();
  const confirm = useConfirm();
  const { t } = useTranslation();
  useEffect(() => {
    setLocationEnabled(!myData?.disable_location);
  }, [myData]);

  const getMe = () => {
    usersService
      .getMe(token)
      .then((res) => {
        dispatch(setMyData(res?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWalletTrans = () => {
    setGettingTrans(true);
    otherServices
      .getWalletTrans(token)
      .then((res) => {
        if (res.status === true || res.success) {
          setWalletTrans(res.data.data);
        } else {
          toast.error(res.message || res.errors);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setGettingTrans(false);
      });
  };

  const onSwitchIncognito = () => {
    otherServices
      .switchIncognito(token)
      .then((res) => {
        if (res?.success) {
          getMe();
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => toast.error(err?.message));
  };

  const handleLangChange = (e) => {
    otherServices
      .updateLang(token, e.target.value)
      .then((res) => {
        if (res?.success) {
          setLocalLang(e.target.value);
          i18next.changeLanguage(e.target.value);
          dispatch(togglePopup(false));
          toast.success("Successfully updated language");
          getMe();
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const getBlockedAccts = () => {
    setGettingBlocked(true);
    otherServices
      .getBlockedAccts(token)
      .then((res) => {
        if (res.status === true || res.success) {
          setBlockedUsers(res.data.data);
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.errors || err?.message);
      })
      .finally(() => {
        setGettingBlocked(false);
      });
  };

  const unBlockUser = (userId) => {
    setUnblocking(true);
    otherServices
      .unblockUser(token, userId)
      .then(async (res) => {
        if (res.status === true || res.success) {
          toast.success("Successfully unblocked user");
          getBlockedAccts();
        } else {
          toast.error(res.errors || res.message);
          return false;
        }
      })
      .catch((err) => {
        toast.error(err.errors || err.message);
      })
      .finally(() => {
        setUnblocking(false);
      });
  };

  const updateProfileInfo = (data) => {
    setUpdatingProfile(true);
    usersService
      .updateProfile(token, data)
      .then((res) => {
        if (res.status === true || res.success) {
          toast.success(res.message);
          getMe();
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.errors || err?.message);
      })
      .finally(() => {
        setUpdatingProfile(false);
      });
  };

  const onSubmitInterests = (interests) => {
    setSubmittingInt(true);
    usersService
      .saveInterests(token, interests)
      .then((res) => {
        if (res.status === true || res.success) {
          toast.success(res.message);
          getMe();
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.errors || err?.message);
      })
      .finally(() => {
        setSubmittingInt(false);
      });
  };

  const openLocationSettings = (isEnabled) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (!isEnabled) {
            dispatch(
              setMyCoords({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            );
            getCoordsInfo(position.coords.latitude, position.coords.longitude);
            onToggleEnabledLocation();
          } else {
            return toast.info(
              "Disable device location permission on your device"
            );
          }
        },
        (error) => {
          return toast.info(
            "Check your device settings to grant location permission"
          );
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  const getCoordsInfo = (lat, lng) => {
    try {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          let city, state, country;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[0]) {
                case "locality":
                  city = response.results[0].address_components[i].long_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].short_name;
                  break;
                case "country":
                  country =
                    response.results[0].address_components[i].short_name;
                  break;
                default:
              }
            }
          }

          updateMyCoords(country, state, lat, lng);
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (error) {}
  };
  const updateMyCoords = (country, state, lat, lng) => {
    usersService
      .updateMyLocation(token, country, state, Number(lat), Number(lng))
      .then(({ success }) => {
        if (success === true) {
          getMe();
        }
      })
      .catch((err) => console.log("Err res :: ", err));
  };

  const onToggleEnabledLocation = () => {
    otherServices
      .toggleEnabledLocation(token)
      .then((res) => {
        if (res?.success) {
          getMe();
        }
      })
      .catch((err) => console.log(err, "is err"))
      .finally(() => {});
  };

  const onSaveClubCountry = (value) => {
    usersService
      .saveClubCountry(token, value)
      .then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          getMe();
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleSelectClubCountry = (country) => {
    confirm({
      title: t("country"),
      description: t("sureSelectClubCountry"),
      cancellationText: t("cancel"),
      confirmationText: t("proceed"),
    })
      .then(() => onSaveClubCountry(country))
      .catch(() => console.log("Cancelled select club country"));
  };

  return {
    getMe,
    getWalletTrans,
    gettingTrans,
    walletTrans,
    onSwitchIncognito,
    handleLangChange,
    getBlockedAccts,
    gettingBlocked,
    unBlockUser,
    unblocking,
    setGettingBlocked,
    blockedUsers,
    updateProfileInfo,
    updatingProfile,
    onSubmitInterests,
    submittingInt,
    onToggleEnabledLocation,
    locationEnabled,
    openLocationSettings,
    onSaveClubCountry,
    handleSelectClubCountry,
  };
};

export default useSettings;
