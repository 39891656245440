import React from "react";
import { Box } from "@mui/system";
import FormField from "../../../../components/FormField";
import { Typography } from "@mui/material";
import useStyles from "../Login/Login.styles";
import Logo from "../../../../resources/img/M8s social white background.png";
import CustomButton from "../../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../../../../pages/Authentication/hooks/useAuthentication";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { state, dispatch, handleForgotPassword } = useAuthentication();

  const { resetEmail, isLoading } = state;

  const isDisabled = resetEmail === "";
  const { t } = useTranslation();
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageBox}>
        <Box className={classes.logo}>
          <img src={Logo} alt="m8t logo" />
        </Box>
        <Box marginTop="-20px" marginBottom="20px">
          <Typography className={classes.headerRed}>
            {t("forgotPass")}
          </Typography>
        </Box>

        <form className={classes.formBox} onSubmit={handleForgotPassword}>
          <Box>
            <FormField
              label="Email Address"
              type="email"
              value={resetEmail}
              handleChange={(e) => dispatch({ resetEmail: e.target.value })}
            />
          </Box>
          <Box marginTop="15px">
            <CustomButton
              type="submit"
              loading={!!isLoading}
              disabled={!!isDisabled}
            >
              {t("resetPass")}
            </CustomButton>
          </Box>

          <Box marginBottom="20px">
            <Typography className={classes.formText}>
              {t("alreadyMember")}{" "}
              <span
                onClick={() => navigate("/auth/login")}
                className={classes.formLink}
              >
                {t("signIn")}
              </span>
            </Typography>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
