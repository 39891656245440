export const languageGroup = [
  {
    value: "EN",
    label: "English",
  },
  { value: "FR", label: "French" },
  { value: "ES", label: "Spanish" },
  { value: "PT", label: "Portuguese" },
  { value: "DE", label: "Dutch" },
  { value: "IT", label: "Italian" },
];
