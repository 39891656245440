import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  postModal: {
    paddingTop: "30px",
    background: "#0F0704",
    width: 400,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    border: "none",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
  },
  topHalf: {
    backgroundColor: "#0F0704",
    display: "flex",
    flexDirection: "column",
    padding: "0 15px 15px 15px",
    gap: 10,
  },
  close: {
    width: "14px",
    height: "14px",
    borderRadius: "50%",
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    "& img": {
      width: "60%",
      height: "60%",
    },
  },
  topHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    fontSize: "14px !important",
    fontWeight: "200 !important",
    color: "#FFFFFF",
    transform: "translateX(10px)",
  },
  headerText2: {
    fontSize: "12px !important",
    fontWeight: "200 !important",
    color: "#FFFFFF",
    cursor: "pointer",
    background: "none",
    border: "none",
  },
  postImage: {
    height: 250,
    width: "100%",
    background: "#252525",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& div > img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    "& div > video": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  lowerHalf: {
    background: "#FFFFFF",
    padding: "15px 15px 30px 15px",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  captionHead: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    paddingLeft: 14,
  },
  captionField: {
    background: "#F4F5F9 !important",
    minHeight: "50px !important",
    width: "100%",
    border: "none",
    wordBreak: "break-word",

    "& fieldset": {
      border: "none",
    },
    "&:focus": {
      border: "none",
    },
  },
  captionField2: {
    background: "#F4F5F9 !important",
    minHeight: "30px !important",
    width: "100%",
    border: "none",
    wordBreak: "break-word",
    "& fieldset": {
      border: "none",
    },
    "&:focus": {
      border: "none",
    },
  },
  uploaderIcon: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& span": {
      color: "#fff",
    },
  },

  topSpinner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#000",
    opacity: 0.5,
    borderRadius: 5,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 5,
  },
  saveBtn: {
    color: "#fff",
    backgroundColor: "#06C439",
    borderStyle: "none",
    padding: "5px 20px",
    borderRadius: 3,
    cursor: "pointer",
  },
  cropBtn: {
    color: "#fff",
    backgroundColor: "blue",
    borderStyle: "none",
    padding: "5px 20px",
    borderRadius: 3,
    cursor: "pointer",
  },
}));

export default useStyles;
