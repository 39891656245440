import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import Carousel from "react-material-ui-carousel";
import { colors } from "../../utils/constants";
import { toFeetInch, usernameStringCheck } from "../../utils/helpers";
import UserImgComponent from "../UserImgComponent";
import { UserPreviewStyle } from "./UserPreview.style";
import PetsIcon from "@mui/icons-material/Pets";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import HeightIcon from "@mui/icons-material/Height";
import LiquorIcon from "@mui/icons-material/Liquor";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import { ReactComponent as TimesIcon } from "../../assets/icons/close.svg";
import { ReactComponent as GiftIcon } from "../../assets/icons/gift.svg";
import { ReactComponent as LikeUserIcon } from "../../assets/icons/like_white.svg";
import useUserPreview from "./hooks/useUserPreview";
import useConstants from "../../utils/useConstants";
import { FaStarOfDavid, FaBrain } from "react-icons/fa";
import { ReactComponent as ReligionIcon } from "../../assets/icons/religion.svg";
import { useTranslation } from "react-i18next";
import useSettingConstants from "../../constants/data/useSettingConstants";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ChatsIcon } from "../../assets/icons/nav-icons/INACTIVE/chats.svg";
const UserPreview = ({
  previewUser,
  gettingPreview,
  isMe,
  setMessagingUser,
  togglePopup,
}) => {
  const classes = UserPreviewStyle();
  const [userGallery, setUserGallery] = useState([]);
  const vidRef = useRef(null);
  const {
    modes,
    memberships,
    sexOptions,
    childrenOptions,
    drinkOptions,
    smokeOptions,
    religionOptions,
    personalityOptions,
    zodiacSignOptions,
    petOptions,
  } = useConstants();
  const { interestsArray } = useSettingConstants();
  const { t } = useTranslation();
  useEffect(() => {
    let gallery = [];
    if (previewUser?.images?.length) {
      gallery = previewUser?.images
        ?.filter((img) => !img?.isMain && img?.url)
        .map((i) => i?.url);
    }
    setUserGallery([
      previewUser?.avatar && previewUser?.avatar,
      previewUser?.vid && previewUser?.vid,
      ...gallery,
    ]);
  }, [previewUser]);
  const navigate = useNavigate();
  const checkVidInViewport = (e) => {
    if (e === 1) {
      vidRef.current && vidRef.current.play();
    } else {
      vidRef.current && vidRef.current.pause();
    }
  };

  const { getMessagesWithUser, onOpenGifts, toggleUserLike } = useUserPreview();

  return (
    <Box className={classes.discoverMainWrap}>
      <Grid container>
        <Grid item md={6}>
          {gettingPreview ? (
            <Skeleton
              style={{
                borderRadius: 10,
                height: "calc(100vh - 175px)",
              }}
            />
          ) : (
            <Box className={classes.swiperWrapper}>
              <Box className={classes.imgContainer}>
                <Carousel
                  onChange={(e) => checkVidInViewport(e)}
                  sx={{
                    display: "flex",
                    justifyContent: "center !important",
                    alignItems: "center !important",
                    height: "calc(100vh - 175px)",
                  }}
                  className={classes.carousel}
                  indicators={false}
                  navButtonsAlwaysVisible={true}
                  autoPlay={false}
                  animation="slide"
                  navButtonsProps={{
                    style: {
                      backgroundColor: "#000",
                      opacity: 0.1,
                    },
                  }}
                >
                  {userGallery.map((item, i) =>
                    i === 1 && item ? (
                      <video
                        src={item}
                        ref={vidRef}
                        className={classes.img}
                        muted={true}
                      />
                    ) : (
                      <UserImgComponent
                        key={i}
                        className={classes.img}
                        alt="user_image"
                        src={item}
                        height="calc(100vh - 175px)"
                        width="100%"
                      />
                    )
                  )}
                </Carousel>
              </Box>
              {!isMe && (
                <Box className={classes.buttonsContainer}>
                  {previewUser?.mate ? (
                    <IconButton
                      onClick={() => {
                        getMessagesWithUser(previewUser?.id);

                        setMessagingUser(previewUser);

                        navigate("/chats");
                        togglePopup(false);
                      }}
                      className={classes.actionButtons}
                      style={{ backgroundColor: "#fff" }}
                    >
                      <ChatsIcon style={{ width: 20, height: 20 }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={classes.actionButtons}
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => onOpenGifts(previewUser)}
                    >
                      <GiftIcon
                        stroke={"#000"}
                        width={18}
                        height={18}
                        fill={"#000"}
                      />
                    </IconButton>
                  )}
                  {previewUser?.liked ? (
                    <IconButton
                      className={classes.actionButtons}
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => toggleUserLike(previewUser?.id)}
                    >
                      <TimesIcon
                        stroke={"#000"}
                        width={18}
                        height={18}
                        fill={"#000"}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      className={classes.actionButtons}
                      style={{ backgroundColor: "red" }}
                      onClick={() => toggleUserLike(previewUser?.id)}
                    >
                      <LikeUserIcon
                        stroke={"#fff"}
                        width={18}
                        height={18}
                        fill={"#fff"}
                      />
                    </IconButton>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Grid>
        <Grid item md={6} className={classes.rightSideContainer}>
          {gettingPreview ? (
            <Skeleton count={10} height={40} style={{ marginBottom: 15 }} />
          ) : (
            <Grid container className={classes.userDetailsContainer}>
              <Grid>
                <Typography fontSize={32}>
                  <span style={{ color: "#ADABAB" }}>@</span>
                  {usernameStringCheck(previewUser?.username)}
                </Typography>
              </Grid>
              <Grid>
                <Typography fontSize={13}>
                  {previewUser?.first_name ||
                    previewUser?.first_name ||
                    previewUser?.username ||
                    "--/--"}{" "}
                  {` ${t("isHere")} `}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
                  {previewUser?.mode && (
                    <Box className={classes.modeBox}>
                      {
                        modes.filter(
                          (mode) => mode?.value === previewUser?.mode
                        )[0]?.logo
                      }
                      <Typography style={{ marginLeft: 5 }}>
                        {
                          modes.filter(
                            (mode) => mode?.value === previewUser?.mode
                          )[0]?.title
                        }
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              {previewUser?.bio && (
                <Grid>
                  <Typography className={classes.detailTitle}>
                    {t("aboutMe")}
                  </Typography>
                  <Typography fontSize={13}>{previewUser?.bio}</Typography>
                </Grid>
              )}
              <Grid>
                <Typography className={classes.detailTitle}>
                  {t("moreInfo")}
                </Typography>
                <Box>
                  <Box className={classes.infoInterestGrid}>
                    {previewUser?.sexuality && (
                      <Box className={classes.infoBox}>
                        <Typography className={classes.infoText}>
                          {
                            sexOptions.filter(
                              (s) =>
                                s.value ===
                                previewUser?.sexuality?.toLowerCase()
                            )[0]?.title
                          }
                        </Typography>
                      </Box>
                    )}
                    {previewUser?.children && (
                      <Box className={classes.infoBox}>
                        <ChildCareIcon className={classes.infoIcon} />
                        <Typography className={classes.infoText}>
                          {
                            childrenOptions.filter(
                              (s) => s.value === previewUser?.children
                            )[0]?.title
                          }
                        </Typography>
                      </Box>
                    )}
                    {previewUser?.height && (
                      <Box className={classes.infoBox}>
                        <HeightIcon className={classes.infoIcon} />
                        <Typography className={classes.infoText}>
                          {`${toFeetInch(previewUser?.height)} (${
                            (previewUser?.height || 0) / 100
                          }m)`}
                        </Typography>
                      </Box>
                    )}
                    {previewUser?.personality && (
                      <Box className={classes.infoBox}>
                        <FaBrain className={classes.infoIcon} />
                        <Typography className={classes.infoText}>
                          {
                            personalityOptions.filter(
                              (s) =>
                                s.value?.toUpperCase() ===
                                previewUser?.personality?.toUpperCase()
                            )[0]?.title
                          }
                        </Typography>
                      </Box>
                    )}
                    {previewUser?.pet && (
                      <Box className={classes.infoBox}>
                        <PetsIcon className={classes.infoIcon} />
                        <Typography className={classes.infoText}>
                          {
                            petOptions.filter(
                              (s) => s.value === previewUser?.pet
                            )[0]?.title
                          }
                        </Typography>
                      </Box>
                    )}
                    {previewUser?.religion && (
                      <Box className={classes.infoBox}>
                        <ReligionIcon
                          style={{ width: 20, height: 20 }}
                          className={classes.infoIcon}
                        />
                        <Typography className={classes.infoText}>
                          {
                            religionOptions.filter(
                              (s) => s.value === previewUser?.religion
                            )[0]?.title
                          }
                        </Typography>
                      </Box>
                    )}
                    {previewUser?.drink && (
                      <Box className={classes.infoBox}>
                        <LiquorIcon className={classes.infoIcon} />
                        <Typography className={classes.infoText}>
                          {
                            drinkOptions.filter(
                              (s) => s.value === previewUser?.drink
                            )[0]?.title
                          }
                        </Typography>
                      </Box>
                    )}
                    {previewUser?.smoke && (
                      <Box className={classes.infoBox}>
                        <SmokingRoomsIcon className={classes.infoIcon} />
                        <Typography className={classes.infoText}>
                          {
                            smokeOptions.filter(
                              (s) => s.value === previewUser?.smoke
                            )[0]?.title
                          }
                        </Typography>
                      </Box>
                    )}
                    {previewUser?.zodiac_sign && (
                      <Box className={classes.infoBox}>
                        <FaStarOfDavid className={classes.infoIcon} />
                        <Typography className={classes.infoText}>
                          {
                            zodiacSignOptions.filter(
                              (s) => s.value === previewUser?.zodiac_sign
                            )[0]?.title
                          }
                        </Typography>
                      </Box>
                    )}
                    {/* <Box>{capitalizeFirst(d)}</Box> */}
                  </Box>
                </Box>
              </Grid>
              {previewUser?.interests?.length > 0 &&
                previewUser?.interests !== null && (
                  <Grid>
                    <Typography className={classes.detailTitle}>
                      {t("interests")}
                    </Typography>
                    <Box>
                      <Box className={classes.infoInterestGrid}>
                        {previewUser?.interests.map((item, i) => {
                          return (
                            <Box
                              key={i}
                              className={classes.infoBox}
                              style={{
                                backgroundColor:
                                  colors[
                                    Math.floor(Math.random() * colors.length)
                                  ],
                              }}
                            >
                              <Typography className={classes.infoText}>
                                #
                                {
                                  interestsArray?.filter(
                                    (int) => int?.id === item?.id
                                  )[0]?.name
                                }
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserPreview;
