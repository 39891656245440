import { connect } from "react-redux";
import { setChatsFilterVal } from "../../redux/actions/chats/chats.actions";
import {
  setCaller,
  setCalling,
  setIncomingCall,
  setPeer,
  setSocket,
} from "../../redux/actions/global/global.actions";
import { setMyData } from "../../redux/actions/users/users.actions";
import AppLayout from "./AppLayout";

const mapState = ({ global, users }) => ({
  popupChildren: global.popupChildren,
  socket: global.socket,
  myData: users.myData,
  peer: global.peer,
});
const mapDispatchToProps = (dispatch) => ({
  setMyData: (data) => dispatch(setMyData(data)),
  setSocket: (data) => dispatch(setSocket(data)),
  setChatsFilterVal: (data) => dispatch(setChatsFilterVal(data)),
  setPeer: (data) => dispatch(setPeer(data)),
  setCalling: (data) => dispatch(setCalling(data)),
  setIncomingCall: (data) => dispatch(setIncomingCall(data)),
  setCaller: (data) => dispatch(setCaller(data)),
});
export default connect(mapState, mapDispatchToProps)(AppLayout);
