import { makeStyles } from "@mui/styles";

export const ManageLocationStyles = makeStyles((theme) => ({
  locationsWrapper: {
    display: "flex !important",
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
    height: "100%",
    gap: 16,
  },
  row: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
    "& .Mui-checked": {
      color: "#EA0F08 !important",
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#EA0F08 !important",
    },
  },
  text: {
    fontSize: "14px !important",
  },
}));
