import { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  CardExpiryElement,
  CardCvcElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import usersService from "../../../services/users.service";
import { getToken } from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { setMyData } from "../../../redux/actions/users/users.actions";
import { togglePopup } from "../../../redux/actions/global/global.actions";
import { useLocation, useNavigate } from "react-router-dom";

const usePaymentCheckout = () => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const splitLocation = location.pathname.split("/");

  const stripe = useStripe();
  const elements = useElements();
  const token = getToken();
  const dispatch = useDispatch();
  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev, clientSecret) => {
    try {
      ev.preventDefault();
      setProcessing(true);

      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(
            CardNumberElement,
            CardCvcElement,
            CardExpiryElement
          ),
        },
      });

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        toast.success("Successfully made Payment");
        getMe();
        if (splitLocation[1] === "onboarding") {
          navigate("/onboarding/mode");
        }
        setTimeout(() => {
          dispatch(togglePopup(false));
        }, 3000);
      }
    } catch (error) {
      toast.error("Unable to process payment");
      setProcessing(false);
    }
  };
  const getMe = () => {
    usersService
      .getMe(token)
      .then(({ data }) => {
        dispatch(setMyData(data));
      })
      .catch((err) => console.log(err));
  };

  return {
    handleChange,
    handleSubmit,
    succeeded,
    error,
    processing,
    disabled,
  };
};

export default usePaymentCheckout;
