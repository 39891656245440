import { connect } from "react-redux";
import { togglePopup } from "../../redux/actions/global/global.actions";
import Memberships from "./Memberships";

const mapState = ({ users, global }) => ({
  myData: users.myData,
  memberships: global.memberships
});
const mapDispatchToProps = (dispatch) => ({
  togglePopup: (data) => dispatch(togglePopup(data)),
});
export default connect(mapState, mapDispatchToProps)(Memberships);
