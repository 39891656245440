import { makeStyles } from "@mui/styles";

export const ChatsStyle = makeStyles(({ theme }) => ({
  chatsWrapper: {
    flexDirection: "column",
    width: "100%",
    // height: "100%",
    backgroundColor: "#F7F7F7",
    position: "relative",

    overflow: "hidden",
  },
  chatsMainWrap: {
    backgroundColor: "#F7F7F7",
    marginTop: 15,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
    // justifyContent: "space-between",
    height: "calc(100vh - 145px)",
    // overflow: "auto",
    gap: 24,
    dispaly: "flex !important",
    flexDirection: "row !important",
  },
  chatsTop: {
    backgroundColor: "#fff",
    marginTop: 40,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
    dispaly: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row !important",
    padding: "10px 20px",
  },
  chatsListWrap: {
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -5px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    width: "30%",
    backgroundColor: "#fff !important",
    padding: 10,
    overflow: "auto",
  },
  text: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  emptyBox: {
    width: "100%",
    height: "100%",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  chatNodeContainer: {
    display: "flex !important",
    alignItems: "center",
    marginBottom: 10,
    padding: "10px 5px",
    borderBottom: "1px solid #D7E2F2",
    gap: 8,
    cursor: "pointer",
  },
  imgBox: {
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",

    overflow: "hidden",
    position: "relative",
  },
  unread: {
    width: 18,
    height: 18,
    backgroundColor: "#1EC05E",
    borderRadius: 15,
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarImg: {
    width: "50px",
    height: "50px",
    borderRadius: "50px",
    objectFit: "contain",
    display: "block",
  },
  onlineDot: {
    borderRadius: "100%",
    backgroundColor: "#1EC05E",
    height: 7,
    width: 7,
  },
  yourTurnText: {
    position: "absolute",
    backgroundColor: "rgba(235, 15, 8, 0.8)",
    color: "#fff",
    borderRadius: "5px",
    top: "60%",
    right: "0%",
    fontSize: "9px !important",
    padding: 2,
    whiteSpace: "nowrap",
  },
}));
