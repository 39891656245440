export const SET_MY_DATA = "SET_MY_DATA";
export const SET_DISCOVER_USERS = "SET_DISCOVER_USERS";
export const SET_GETTING_DISCOVER = "SET_GETTING_DISCOVER";

export const SET_GETTING_NEARBY = "SET_GETTING_NEARBY";
export const SET_NEARBY_USERS = "SET_NEARBY_USERS";
export const SET_MY_COORDS = "SET_MY_COORDS";
export const SET_PREVIEW_USER = "SET_PREVIEW_USER";
export const SET_GETTING_PREVIEW = "SET_GETTING_PREVIEW";
export const SET_USERS_DEFAULT_FILTER = "SET_USERS_DEFAULT_FILTER";
export const SET_WHO_LIKES_ME = "SET_WHO_LIKES_ME";
export const SET_GETTING_MATES = "SET_GETTING_MATES";
export const SET_MATES = "SET_MATES";
export const SET_WHO_I_LIKE = "SET_WHO_I_LIKE";
export const SET_MY_ACTIVITIES = "SET_MY_ACTIVITIES";
export const SET_MY_REFERRALS = "SET_MY_REFERRALS";
