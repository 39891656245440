import React, { useState } from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import "./FormField.styles.css";

function FormField({
  startIcon,
  label,
  handleChange,
  isPassword,
  type,
  value,
  class2,
  label2,
  ...otherProps
}) {
  const [showPassword, setShowPassword] = useState(isPassword);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const startIconStyle = {
    paddingLeft: "25px",
  };

  return (
    <Box className="fieldRoot">
      <Typography className={label2 ? label2 : "fieldLabel"}>
        {label}
      </Typography>
      <input
        className={class2 ? `fieldInput ${class2}` : "fieldInput"}
        type={showPassword ? "password" : type}
        onChange={handleChange}
        value={value}
        rows="4"
        cols="1"
        wrap="soft"
        required
        style={startIcon && startIconStyle}
        {...otherProps}
      />
      {isPassword && (
        <span className="eye" onClick={() => handleClickShowPassword()}>
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </span>
      )}
      {startIcon && <span className="startIcon">{startIcon}</span>}
    </Box>
  );
}

export default FormField;
