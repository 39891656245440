import { Box, IconButton, InputAdornment, Typography } from "@mui/material";
import React, { useState } from "react";
import { AddTeamStyles } from "./AddTeam.styles";
import { HighlightOff } from "@mui/icons-material";
import { togglePopup } from "../../../../../../../redux/actions/global/global.actions";
import { useDispatch } from "react-redux";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useAddTeamMember from "./hooks/useAddTeamMember";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function AddTeam({ headerText, btnText, data }) {
  const classes = AddTeamStyles();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const { createUser, updateTeamMember } = useAddTeamMember();
  const [userData, setUserData] = useState({
    fullName: "",
    username: "",
    email: "",
    phone: "",
    role: "admin",
    password: "",
    cPassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const [first_name, last_name] = userData.fullName.split(" ");
    const { email, role, password, cPassword, username, phone } = userData;
    if (
      !first_name ||
      !last_name ||
      !email ||
      !role ||
      !password ||
      !cPassword ||
      !username ||
      (!phone && password !== cPassword)
    ) {
      console.log(userData.id);
      toast.error("Please fill all fields Correctly");
      return;
    }
    if (!data) {
      createUser({
        first_name,
        last_name,
        email,
        role,
        password,
        cPassword,
        username,
        phone,
      });
      
      return;
    }
    updateTeamMember(userData.id, {
      first_name,
      last_name,
      email,
      role,
      cPassword,
      username,
      phone,
    });
    
    return;
  };

  useEffect(() => {
    if (data) {
      console.log(data);
      setUserData((prevUserData) => ({
        ...prevUserData,
        id: data.id,
        fullName: `${data.first_name} ${data.last_name}`,
        username: data.username,
        email: data.email,
        phone: data.phone,
        role: data.role,
      }));
    }
  }, [data]);

  return (
    <Box className={classes.Wrapper}>
      <Box className={classes.header}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {headerText || "Add Team Member"}
        </Typography>
        <IconButton onClick={() => dispatch(togglePopup(false))}>
          <HighlightOff color="#000" fontSize="small" />
        </IconButton>
      </Box>
      <form className={classes.textFieldsContainer} onSubmit={handleSubmit}>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Full Name</Typography>
          <input
            className={classes.inputField}
            size="small"
            name="fullName"
            type="text"
            value={userData.fullName}
            onChange={handleInputChange}
          />
        </Box>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Username</Typography>
          <input
            className={classes.inputField}
            size="small"
            name="username"
            type="text"
            value={userData.username}
            onChange={handleInputChange}
          />
        </Box>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Email</Typography>
          <input
            className={classes.inputField}
            size="small"
            name="email"
            type="email"
            value={userData.email}
            onChange={handleInputChange}
          />
        </Box>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Phone</Typography>
          <input
            className={classes.inputField}
            size="small"
            name="phone"
            type="text"
            value={userData.phone}
            onChange={handleInputChange}
          />
        </Box>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Role</Typography>
          <select
            name="role"
            id=""
            className={classes.selectRole}
            value={userData.role}
            onChange={handleInputChange}
          >
            <option className={classes.roleOption} value="admin">
              admin
            </option>
            <option className={classes.roleOption} value="operations">
              operations
            </option>
            <option className={classes.roleOption} value="superadmin">
              superadmin
            </option>
          </select>
        </Box>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Password</Typography>
          <input
            type={isVisible ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  {isVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            className={classes.inputField}
            size="small"
            name="password"
            value={userData.password}
            onChange={handleInputChange}
          />
        </Box>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Confirm Password</Typography>
          <input
            type={isVisible ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setIsVisible(!isVisible)}
                >
                  {isVisible ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            className={classes.inputField}
            size="small"
            name="cPassword"
            value={userData.cPassword}
            onChange={handleInputChange}
          />
        </Box>
        <button type="submit" className={classes.submitButton}>
          {btnText || "Submit"}
        </button>
      </form>
    </Box>
  );
}
