import { Box, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BoosterIcon } from "../../assets/icons/boosters.svg";
import BuyCoins from "../BuyCoins";
import Memberships from "../Memberships";
import { CoinsAndMoreStyles } from "./CoinsAndMore.styles";
const CoinsAndMore = ({ togglePopup, setPopupChildren }) => {
  const classes = CoinsAndMoreStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.wrapper}>
      <Box>
        <BoosterIcon width={80} height={80} stroke="#000" fill="#000" />
      </Box>
      <Typography className={classes.titleText}>Do More on M8S</Typography>
      <Typography className={classes.descriptionText} textAlign={"center"}>
        Purchase M8S credits or upgrade your membership as you share more
        experience on our community.
      </Typography>

      <Grid container justifyContent={"space-between"} xs={12}>
        <Grid item xs={6}>
          <Box
            className={classes.box}
            onClick={() => {
              setPopupChildren(<BuyCoins />);
              togglePopup(true);
            }}
          >
            <Box className={classes.boxContent}>
              <img
                alt="m8s-coins"
                className={classes.coinLogo}
                src={require("../../assets/images/coin.png")}
              />
              <Typography className={classes.boxText}>{t("buyM8s")}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={classes.box}
            onClick={() => {
              togglePopup(true);
              setPopupChildren(<Memberships />);
            }}
          >
            <Box className={classes.boxContent}>
              <img
                alt="m8s-coins"
                className={classes.membershipLogo}
                src={require("../../assets/images/elite.png")}
              />
              <Typography className={classes.boxText}>
                Upgrade Membership
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CoinsAndMore;
