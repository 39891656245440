import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  FormHelperText,
  InputLabel,
  Input,
  TextField,
  Stack,
  Button,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ManageProfileStyles } from "./ManageProfile.styles";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../../redux/actions/global/global.actions";
import ChangePassword from "../../../../../components/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useManageProfile from "./hooks/useManageProfile";
import { gender } from "../../../../../utils/constants";
import { toast } from "react-toastify";

const ManageProfile = () => {
  const classes = ManageProfileStyles();
  const dispatch = useDispatch();
  const { myData } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const { editProfileInfo } = useManageProfile();
  const [data, setData] = useState({
    fullName: ``,
    username: "",
    gender: "",
  });

  useEffect(() => {
    setData({
      fullName: `${myData.first_name} ${myData.last_name}`,
      username: myData.username,
      gender: myData.gender,
    });
  }, [myData]);


  function handleChange(e) {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    const { fullName, username, gender } = data;
    const [first_name, last_name] = fullName.split(" ");
    if (!first_name || !last_name || !username || !gender) {
      toast.error("Please fill in all fields");
    }
    editProfileInfo(data);
  }

  return (
    <Box className={classes.Wrapper}>
      <Box className={classes.header}>
        <Typography sx={{ fontWeight: "bold" }}>Edit your profile</Typography>
        <Box
          onClick={() => {
            dispatch(setPopupChildren(<ChangePassword />));
            dispatch(togglePopup(true));
          }}
          className={classes.changePassword}
        >
          <Typography sx={{ fontWeight: "bold" }}>
            {t("Change Password")}
          </Typography>
        </Box>
      </Box>
      <Box>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Stack className={classes.inputContainer}>
            <label className={classes.formLabel}>Full Name</label>
            <input
              name="fullName"
              size="small"
              className={classes.formInput}
              type="text"
              value={data.fullName}
              onChange={handleChange}
            />
          </Stack>

          <Stack className={classes.inputContainer}>
            <label className={classes.formLabel}>Username</label>
            <input
              name="username"
              size="small"
              className={classes.formInput}
              type="text"
              value={data.username}
              onChange={handleChange}
            />
          </Stack>
          <Stack className={classes.inputContainer}>
            <label className={classes.formLabel}>Gender</label>
            <select
              name="gender"
              className={classes.formInput}
              id=""
              onChange={handleChange}
            >
              <option value="male">male</option>
              <option value="female">female</option>
            </select>
            {/* <input size="small" className={classes.formInput} type="email" /> */}
          </Stack>

          <button type="submit" className={classes.submitButton}>
            Submit
          </button>
        </form>
      </Box>
    </Box>
  );
};

export default ManageProfile;
