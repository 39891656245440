export const colors = [
  "#FFC9D8",
  "#DDCCF8",
  "#F7EED5",
  "#F4F5F9",
  "#CCF8D6",
  "#FFCECC",
  "#E0CBFC",
  "#FFDF8E",
  "#F4BCB9",
];

export const gender = [
  {
    value: "male",
    title: "Male",
  },
  {
    value: "both",
    title: "Both",
  },
  {
    value: "female",
    title: "Female",
  },
];

export const categories = [
  { title: "All", value: "all" },
  { title: "Online", value: "online" },
  { title: "New", value: "new" },
];

export const imageExts = [
  ".png",
  ".jpg",
  ".jpeg",
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".webp",
  ".jpe",
  ".jif",
  ".jfi",
  ".gif",
  ".svg",
  ".avif",
];
export const videoExts = [
  ".webm",
  ".mp2",
  ".mp4",
  ".m4v",
  ".avi",
  ".flv",
  ".mov",
  ".mpg",
  ".mpeg",
  ".mpe",
  ".mpv",
  ".ogg",
  ".m4p",
  ".wmv",
  ".qt",
  ".swf",
];
export const audioExts = [
  ".dsd",
  ".wma",
  ".alac",
  ".mp3",
  ".aac",
  ".flac",
  ".aiff",
  ".wav",
  ".m4a",
  ".opus",
  ".ogg",
  ".pcm",
  ".ec3",
  ".mtm",
  ".efs",
  ".abc",
];
