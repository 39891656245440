import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  pageWrap: {
    display: "flex",
    flexDirection: "column",
    gap: 25,
    background: "#fff",
    padding: 20,
    width: 480,
    borderRadius: 15,
    position: "relative",

    "& .withdraw": {
      fontWeight: "500 !important",
      fontSize: "16px !important",
    },
  },
  close: {
    border: "1px solid #000000",
    height: 18,
    width: 18,
    borderRadius: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 20,
    right: 20,
    cursor: "pointer",

    "& img": {
      width: "60%",
      height: "60%",
    },
  },
  review: {
    height: 120,
    background: "#f4f5f9",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    justifyContent: "center",
    padding: 15,
    boxSizing: "border-box",

    "& :nth-child(1)": {
      fontWeight: "500 !important",
    },
    "& :nth-child(3)": {
      fontWeight: "500 !important",
    },
  },
  target: {
    marginTop: 0,
    width: "100%",
  },
  targetText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    paddingLeft: 10,
    color: "#293146",
    marginBottom: 5,
  },

  dropDown: {
    height: 45,
    width: "100%",
    borderRadius: 0,
    color: "#293146",
    fontSize: 14,
    fontWeight: "500 !important",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& .Dropdown-arrow": {
      top: 18,
    },
    "& .Dropdown-placeholder": {
      fontSize: "14px important",
      color: "#293146",
    },

    "&:focus": {
      border: "1px solid #2f3138",
    },
  },
}));
