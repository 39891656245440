import { connect } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../redux/actions/global/global.actions";
import CoinsAndMore from "./CoinsAndMore";

const mapDispatchToProps = (dispatch) => ({
  togglePopup: (data) => dispatch(togglePopup(data)),
  setPopupChildren: (data) => dispatch(setPopupChildren(data)),
});
export default connect(null, mapDispatchToProps)(CoinsAndMore);
