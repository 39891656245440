import React, { useCallback, useEffect, useState } from "react";
import LeftSideBar from "./components/LeftSideBar";
import { AppLayoutStyle } from "./AppLayout.style";
import RightMenuBar from "./components/RightMenuBar";
import { fileType, getChatsFilter, getToken } from "../../utils/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import PopupModal from "../../components/PopupModal";
import useAppLayout from "./hooks/useAppLayout";
import { useDispatch, useSelector } from "react-redux";
import { setStoryModal } from "../../redux/actions/global/global.actions";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import EnableLocationPop from "../../components/EnableLocationPop";
import useSocketPeer from "./hooks/useSocketPeer";
import { IconButton, Snackbar } from "@mui/material";
import { Close } from "@mui/icons-material";
const AppLayout = ({ children, myData, setChatsFilterVal }) => {
  const classes = AppLayoutStyle();
  const token = getToken();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    getGifts,
    getMe,
    getMyCoords,
    onUpdateUserStatus,
    getMemberships,
    getInterests,
    handlePermissionChange,
    locationPopOpen,
    setLocationPopOpen,
    showOnline,
    onOnline,
    onOffline,
  } = useAppLayout();

  const { open, handleClose, transition, newMessage } = useSocketPeer();
  useEffect(() => {
    if (!token) {
      navigate("/");
    } else {
      getMe();
      getGifts();
      getMyCoords();
      const result = getChatsFilter();
      setChatsFilterVal(result);
      getMemberships();
      getInterests();
    }
  }, []);
  useEffect(() => {
    // if (location.pathname === "/nearby") {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "denied") {
          setLocationPopOpen(true);
        } else {
          setLocationPopOpen(false);
        }
      });
    // }
  }, [locationPopOpen, location, myData?.disable_location]);

  useEffect(() => {
    const aState = document.addEventListener(
      "visibilitychange",
      handleAppStateChange
    );

    if (token) {
      if ("permissions" in navigator) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permissionStatus) => {
            permissionStatus.addEventListener("change", handlePermissionChange);
            if (permissionStatus.state === "denied") {
              setLocationPopOpen(true);
            } else {
            }
          });
      } else if ("geolocation" in navigator) {
      } else {
        console.log("Geolocation is not supported");
      }

      // getPushNotificationToken(token);
      // Notifications.addNotificationReceivedListener((notification) => {
      //   // setNotification(notification);
      //   console.log(notification, "is incoming");
      // });
    }

    return () => {
      aState?.remove();

      if ("permissions" in navigator) {
        navigator.permissions?.removeEventListener(
          "change",
          handlePermissionChange
        );
      }
    };
  }, [token]);

  useEffect(() => {
    i18next.changeLanguage(myData?.language?.toLowerCase());
  }, [myData]);

  const handleAppStateChange = (e) => {
    if (document.visibilityState === "visible") {
      onUpdateUserStatus("online");
    } else {
      onUpdateUserStatus("away");
    }
  };

  useEffect(() => {
    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);
    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  }, []);

  const reduxDispatch = useDispatch();
  const showModal = useSelector((state) => state.global.storyModal);
  const splitPath = location?.pathname?.split("/");
  return (
    <>
      {showOnline && (
        <div className={classes.noInternet}>
          <span>No internet connection !!!</span>
        </div>
      )}
      <div
        className={classes.root}
        onClick={() => (!!showModal ? reduxDispatch(setStoryModal(false)) : "")}
        style={
          location.pathname === ("/admin/dashboard" || "/admin")
            ? { backgroundColor: "#FFEBEB" }
            : {}
        }
      >
        <LeftSideBar />
        <PopupModal />

        <Snackbar
          onClick={() => console.log("hello")}
          className=""
          open={open}
          onClose={handleClose}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close fontSize="small" />
            </IconButton>
          }
          TransitionComponent={transition}
          // autoHideDuration={6000}
          message={
            newMessage?.media
              ? fileType(newMessage?.media) == "video"
                ? `...🎥${t("video")}...`
                : fileType(newMessage?.media) == "image"
                ? `...📸${t("image")}...`
                : fileType(newMessage?.media) == "audio"
                ? `...🎞${t("audio")}...`
                : `...📎${t("file")}...`
              : newMessage?.text
          }
          key={transition ? transition.name : ""}
        />
        {splitPath[1] != "admin" && (
          <EnableLocationPop
            open={locationPopOpen}
            handleClose={() => setLocationPopOpen(false)}
          />
        )}

        <main
          className={classes.mainSection}
          style={
            location.pathname?.split("/")[1] === "admin"
              ? { marginLeft: "70px" }
              : {}
          }
        >
          {children}
        </main>
        <RightMenuBar />
      </div>
    </>
  );
};

export default AppLayout;
