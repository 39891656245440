import { connect } from "react-redux";
import { setPosts } from "../../../../redux/actions/feeds/feeds.actions";
import Posts from "./Posts";

const mapState = ({ feeds }) => ({
  posts: feeds.posts,
});

const mapStateToProps = (dispatch) => ({
  setPosts: (data) => dispatch(setPosts(data)),
});
export default connect(mapState, mapStateToProps)(Posts);
