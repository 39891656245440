import {
  SET_GETTING_POSTS,
  SET_MY_POSTS,
  SET_POSTS,
  SET_STORIES,
  SET_MY_EVENTS,
} from "./feeds.types";

export const setPosts = (payload) => ({
  type: SET_POSTS,
  payload,
});

export const setStories = (payload) => ({
  type: SET_STORIES,
  payload,
});

export const setGettingPosts = (payload) => ({
  type: SET_GETTING_POSTS,
  payload,
});

export const setMyPosts = (payload) => ({
  type: SET_MY_POSTS,
  payload,
});
export const setMyEvents = (payload) => ({
  type: SET_MY_EVENTS,
  payload,
});
