import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  storyWrapper: {
    width: "100%",
    height: 132,
    padding: 15,
    display: "flex",
    gap: 10,
    boxSizing: "border-box",
    backgroundColor: "#fff",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
  },
  yourStory: {
    padding: 5,
    boxSizing: "border-box",
  },
  stories: {
    padding: 5,
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    overflowX: "scroll",
    display: "flex",
    gap: 20,
    scrollBehavior: "smooth",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  skeleton: {
    "& :first-child": {
      display: "flex",
      gap: 10,
      alignItems: "flex-start",
    },
  },
}));

export default useStyles;
