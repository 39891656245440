import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100000,
    background: "#EA0F08",
    opacity: 1,
    transition: "all linear 1000ms",
  },

  "@keyframes splash-icon-animation": {
    "0%": {
      transform: "scale(1)",
      opacity: 0.93,
    },
    "50%": {
      transform: "scale(0.95)",
      opacity: 0.5,
    },
    "100%": {
      transform: "scale(1)",
      opacity: 0.93,
    },
  },

  iconWrapper: {
    height: 500,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animationName: "$splash-icon-animation",
    animationDuration: "3s",
    animationTimingFunction: "cubic-bezier(0.27, 0.35, 0.71, 1.68)",

    "& > img": {
      height: "100%",
    },
  },
}));

export default useStyles;
