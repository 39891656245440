import { connect } from "react-redux";
import { setStories } from "../../../../redux/actions/feeds/feeds.actions";
import Story from "./Story";

const mapState = ({ feeds }) => ({
  stories: feeds.stories,
});

const mapStateToProps = (dispatch) => ({
  setStories: (data) => dispatch(setStories(data)),
});

export default connect(mapState, mapStateToProps)(Story);
