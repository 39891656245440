import React, { useRef, useState } from "react";
import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import useStyles from "./PostCards.styles";
import LocationIcon from "../../../../../../../../../../../resources/img/location-pin.svg";
import DotsIcon from "../../../../../../../../../../../resources/img/Icon material-more-vert.svg";
import {
  fileType,
  usernameStringCheck,
} from "../../../../../../../../../../../utils/helpers";
import PostImgComponent from "../../../../../../../../../../../components/PostImgComponent";
import { useLocation } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import { useEffect } from "react";
import {
  togglePopup,
  setPopupChildren,
} from "./../../../../../../../../../../../redux/actions/global/global.actions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel";
import usePostsEvents from "../../../../hooks/usePostsEvents";

function PostCards({
  i,
  profileImg,
  profileName,
  profileLocation,
  post,
  containerStyle,
  headerStyle,
  onOpenReport,
  cardImage,
}) {
  const { t } = useTranslation();
  const { getCaption, getUser, removeUserPost } = usePostsEvents();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const splitPath = location.pathname.split("/");

  const [isExpired, setIsExpired] = useState(false);
  const reduxDispatch = useDispatch();
  const myData = useSelector((state) => state.users.myData);

  const onOpenMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const videoRef = useRef(null);
  const cVideoRef = useRef(null);

  const checkVidInViewport = (e) => {
    if (e === 1) {
      cVideoRef.current && cVideoRef.current.play();
    } else {
      cVideoRef.current && cVideoRef.current.pause();
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.play();
          } else {
            entry.target.pause();
          }
        });
      },
      { threshold: 0.5 }
    );
    if (cVideoRef.current) {
      observer.observe(cVideoRef.current);
    }

    return () => {
      if (cVideoRef?.current) {
        observer.unobserve(cVideoRef.current);
      }
    };
  }, [cVideoRef]);

  return (
    <Box key={i} className={[classes.cardRoot, containerStyle]}>
      <Box className={[classes.cardHeader, headerStyle]}>
        <Box className={classes.cardProfile}>
          <Box className={classes.cardProfileImg}>
            <Avatar
              src={profileImg}
              alt="profile Img"
              className={classes.cardAvatar}
            />
          </Box>
          <Box className={classes.cardProfileSum}>
            <Typography
              className={classes.cardProfileName}
              onClick={() => {
                getUser(post?.user?.id);
                // reduxDispatch(setPopupChildren(<UserPreview />));
                reduxDispatch(togglePopup(true));
              }}
            >
              <span style={{ color: "#ADABAB" }}>@</span>
              {usernameStringCheck(profileName)}
            </Typography>
            <Typography noWrap className={classes.cardProfileLocation}>
              {profileLocation?.length > 0 && (
                <img
                  className={classes.locationIcon}
                  src={LocationIcon}
                  alt="icon"
                />
              )}{" "}
              {profileLocation}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.cardIcons}>
          <Box className={classes.cardIcons}>
            <span
              className={classes.dropdown}
              id="demo-positioned-button"
              aria-controls={"demo-positioned-menu"}
              aria-haspopup="true"
              aria-expanded={"true"}
              onClick={(e) => onOpenMenu(e, post?.id)}
            >
              <img src={DotsIcon} alt="icon" />
            </span>
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            {/* <MenuItem
              onClick={() => {
                reduxDispatch(setPopupChildren(<MakePost isEdit={post} />));
                reduxDispatch(togglePopup(true));
                handleClose();
              }}
            >
              {t("edit")}
            </MenuItem> */}
            <MenuItem
              onClick={() => {
                removeUserPost(post?.id);
                handleClose();
              }}
            >
              {t("delete")}
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      <Box className={classes.cardImageBox}>
        {Array.isArray(post?.files) ? (
          <Carousel
            onChange={(e) => checkVidInViewport(e)}
            sx={{
              display: "flex !important",
              justifyContent: "center !important",
              alignItems: "center !important",

              // height: 400,
              width: "100%",
            }}
            className={classes.cardImage}
            indicators={false}
            navButtonsAlwaysVisible={true}
            autoPlay={false}
            animation="slide"
            navButtonsProps={{
              style: {
                backgroundColor: "#000",
                opacity: 0.1,
                display: post?.files?.length <= 1 ? "none" : undefined,
              },
            }}
          >
            {post?.files?.map((item, i) =>
              fileType(item) === "video" ? (
                <video
                  key={i}
                  src={item}
                  className={classes.cardImage}
                  ref={cVideoRef}
                  // loop={false}
                  controls
                  style={{ width: "100%" }}
                  loop={false}
                />
              ) : (
                <PostImgComponent
                  key={i}
                  className={classes.file}
                  src={item}
                  alt="card Img"
                />
              )
            )}
          </Carousel>
        ) : fileType(post?.image) === "video" ? (
          <video
            // autoPlay
            loop={false}
            src={post?.image}
            className={classes.cardImage}
            controls
            ref={cVideoRef}
          />
        ) : (
          <PostImgComponent
            className={classes.cardImage}
            src={post?.image}
            alt="card Img"
          />
        )}
      </Box>
      <Box className={classes.cardCaption}>
        <Typography className={classes.cardCaptionText}>
          {getCaption(post)}
        </Typography>
        {post?.is_event && (
          <Typography className={classes.eventDate}>
            <InfoIcon
              fontSize="small"
              className={!isExpired ? "iconGo" : "iconStop"}
            />{" "}
            <span style={{ fontWeight: "600", fontSize: 12 }}>
              {moment(post?.event_date).format("D MMM. YYYY.  h:mm a")}
            </span>
            {moment(post?.event_date).isAfter() ? null : (
              <span style={{ fontStyle: "italic", fontSize: 12, color: "red" }}>
                {" "}
                ({t("expiredEvent")})
              </span>
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default PostCards;
