import React from "react";

import "./Authentication.styles.css";
import useAuthentication from "./hooks/useAuthentication";

function Authentication() {
  const { Component } = useAuthentication();

  return <div className="authPage">{Component}</div>;
}

export default Authentication;
