import { makeStyles } from "@mui/styles";

export const AdminGlobalHeaderStyles = makeStyles(({ theme }) => ({
  greetDateRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dateRangeRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
    gap: "4px",
    padding: ".5rem",
    "& span": {
      whiteSpace: "nowrap",
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cursor: "pointer",
  },
  btnBox: {
    cursor: "pointer",
  },
}));
