import { ZIM } from "zego-zim-web";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
class CallService {
  constructor() {
    this.zp = null;
  }

  initializeCall(userId, userName) {
    if (!this.zp) {
      const TOKEN = ZegoUIKitPrebuilt.generateKitTokenForTest(
        Number(process.env.REACT_APP_CALL_ID),
        process.env.REACT_APP_CALL_SERVER_SECRET,
        null,
        userId,
        userName
      );
      this.zp = ZegoUIKitPrebuilt.create(TOKEN);
      this.zp.addPlugins({ ZIM });
      this.zp.setCallInvitationConfig({
        ringtoneConfig: {
          // incomingCallUrl: "https://xxx/xxx.mp3",
          // outgoingCallUrl: "https://xxx/xxx.mp3",
        },
        onSetRoomConfigBeforeJoining: (callType) => {
          return {
            showMyCameraToggleButton: true,
            showMyMicrophoneToggleButton: true,
            showAudioVideoSettingsButton: true,
            showScreenSharingButton: false,
            showTextChat: false,
            showUserList: false,
            maxUsers: 2,
            layout: "Auto",
            showLayoutButton: false,
            scenario: {
              mode: "OneONoneCall",
              config: {
                role: "Host",
              },
            },
          };
        },
      });
    }
  }
}

const callService = new CallService();
export default callService;
