import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  storyNode: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    justifyContent: "space-between",
    alignItems: "center",
  },
  node: {
    height: 58,
    width: 58,
    borderRadius: "50%",
    overflow: "hidden",
    outline: "2px solid #EA0F07",
    border: "4px solid #fff",
    cursor: "pointer",
  },
  seen: {
    height: 58,
    width: 58,
    borderRadius: "50%",
    overflow: "hidden",
    outline: "2px solid #d0d0d0",
    border: "4px solid #fff",
    cursor: "pointer",
  },

  nodeAvatar: {
    height: "100% !important",
    width: "100% !important",
    objectFit: "cover !important",
  },

  title: {
    fontWeight: " 500 !important",
    fontSize: "14px !important",
    color: "#000",
  },
}));

export default useStyles;
