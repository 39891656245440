import { format, subYears } from "date-fns";
import {
  SET_CALLER,
  SET_CALLING,
  SET_CONFIRM_MODAL,
  SET_GETTING_MEMBERSHIPS,
  SET_GIFTS,
  SET_INCOMING_CALL,
  SET_INTERESTS,
  SET_LOCAL_STREAM,
  SET_MEMBERSHIPS,
  SET_ON_CALL,
  SET_OUTGOING_CALL,
  SET_PEER,
  SET_PERIODS,
  SET_POPUP_CHILDREN,
  SET_REMOTE_STREAM,
  SET_RINGTONE,
  SET_SHOW_POPUP,
  SET_SOCKET,
  SET_STORY_MODAL,
  SET_FIRST_COMPARABLE,
  SET_SECOND_COMPARABLE,
  SET_THIRD_COMPARABLE,
  SET_DATES,
} from "../../actions/global/global.types";
const currentDate = new Date();

const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();

const yearAgo = currentYear - 1;
const monthAgo = currentMonth;

const date12MonthsAgo = new Date(yearAgo, monthAgo, currentDate.getDate());
const defaultState = {
  showPopup: false,
  popupChildren: null,
  showMapModal: false,
  confirmModal: false,
  onModalDismiss: () => {},
  gifts: [],
  interests: [],
  socket: null,
  peer: null,
  calling: false,
  onCall: false,
  outgoingCall: null,
  incomingCall: null,
  caller: false,
  remoteStream: null,
  localStream: null,
  gettingMemberships: false,
  memberships: [],
  storyModal: false,
  ringtone: null,
  endDate: format(currentDate, "yyyy-MM-dd"),
  startDate: format(date12MonthsAgo, "yyyy-MM-dd"),
};

export default function globalReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SHOW_POPUP:
      return { ...state, showPopup: action.payload };
    case SET_POPUP_CHILDREN:
      return {
        ...state,
        popupChildren: action.payload,
        onModalDismiss: action.onDismiss,
      };
    case SET_CONFIRM_MODAL:
      return { ...state, confirmModal: action.payload };
    case SET_GIFTS:
      return { ...state, gifts: action.payload };
    case SET_INTERESTS:
      return { ...state, interests: action.payload };
    case SET_SOCKET:
      return { ...state, socket: action.payload };
    case SET_PEER:
      return { ...state, peer: action.payload };
    case SET_CALLING:
      return { ...state, calling: action.payload };
    case SET_ON_CALL:
      return { ...state, onCall: action.payload };
    case SET_OUTGOING_CALL:
      return { ...state, outgoingCall: action.payload };
    case SET_INCOMING_CALL:
      return { ...state, incomingCall: action.payload };
    case SET_CALLER:
      return { ...state, caller: action.payload };
    case SET_REMOTE_STREAM:
      return { ...state, remoteStream: action.payload };
    case SET_LOCAL_STREAM:
      return { ...state, localStream: action.payload };
    case SET_MEMBERSHIPS:
      return { ...state, memberships: action.payload };
    case SET_GETTING_MEMBERSHIPS:
      return { ...state, gettingMemberships: action.payload };
    case SET_STORY_MODAL:
      return { ...state, storyModal: action.payload };
    case SET_RINGTONE:
      return { ...state, ringtone: action.payload };
    case SET_PERIODS:
      return { ...state, periods: action.payload };
    case SET_FIRST_COMPARABLE:
      return { ...state, firstComparable: action.payload };
    case SET_SECOND_COMPARABLE:
      return { ...state, secondComparable: action.payload };
    case SET_THIRD_COMPARABLE:
      return { ...state, thirdComparable: action.payload };
    case SET_DATES:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    default:
      return state;
  }
}
