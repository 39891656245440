import { makeStyles } from "@mui/styles";
import bgImage from "../../assets/images/bg_modal.png";

export const useIncognitoModal = makeStyles(({ theme }) => ({
  Wrapper: {
    backgroundImage: `url(${bgImage})`,
    height: "100%",
    width: "300px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#FFF",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: "2rem 2rem",
    borderRadius: "10px",
    textAlign: "center",
    gap: "1.3rem",
  },
  closeBtn: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
  },
  mainBtn: {
    background: "#FFF !important",
    color: "#EA0F08 !important",
    borderRadius: "0 !important",
    fontSize: "12px !important",
    textTransform: "initial !important",
    fontWeight: "500 !important",
  },
  IncognitoIcon: {
    // background: "#FFE7E6",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    width: "190px",
    height: "130px",
    "& #tArtboard_2-3": {
      display: "none",
    },
    margin: "auto",
  },
  title: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
  },
  content: {
    fontSize: "14px !important",
    padding: "0 2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));
