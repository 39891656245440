import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import usePaymentCheckout from "./hooks/usePaymentCheckout";
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const PaymentCheckout = ({
  clientSecret,
  isCoins,
  isMembership,
  coin,
  membership,
}) => {
  const { handleChange, handleSubmit, succeeded, error, processing, disabled } =
    usePaymentCheckout();

  useEffect(() => {
    return () => {};
  }, []);
  const { t } = useTranslation();
  return (
    <Paper style={{ width: 400, padding: 10 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography fontSize={20} fontWeight={500} textAlign="center">
          {isMembership && t("subscribe")}
          {isCoins && t("buyCredits")}
        </Typography>
        <Typography
          style={{
            color: "red",
            fontSize: 20,
            marginTop: 20,
            marginBottom: 10,
            textAlign: "center",
            fontWeight: "bolder",
          }}
        >
          {isCoins && coin?.title}
          {isMembership && membership?.name}
        </Typography>
        <Typography
          style={{ fontSize: 16, textAlign: "center", marginBottom: 10 }}
        >
          {isCoins && `${coin?.quantity} M8S ${"coins"}`}

          {isMembership && membership?.description}
        </Typography>
        {isCoins && (
          <Typography
            style={{ fontSize: 24, textAlign: "center" }}
          >{`$${coin?.amount}`}</Typography>
        )}
        {isMembership && (
          <Box
            sx={{ display: "flex" }}
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{"\u0024"}</Typography>
            <Typography
              style={{
                fontWeight: "500",
                color: "#000",
                fontSize: 40,
              }}
            >
              {membership?.price?.unit_amount}
            </Typography>
            <Typography
              style={{
                fontSize: "14px",
                marginBottom: "-15px",
              }}
            >
              /{membership?.price?.recurring?.interval}
            </Typography>
          </Box>
        )}
      </Box>
      <Grid
        container
        spacing={2}
        style={{
          padding: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} md={12} style={{ margin: "10px 0px" }}>
          <Typography style={{ margin: "10px 0px", color: "#97999D" }}>
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>{" "}
            {t("cardNumber")}
          </Typography>
          <CardNumberElement
            options={{
              style: {
                base: { backgroundColor: "#F4F5F9" },
              },
            }}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <Typography style={{ margin: "10px 0px", color: "#97999D" }}>
            <span style={{ color: "red", fontWeight: "bold" }}>*</span>{" "}
            {t("expDate")}
          </Typography>
          <CardExpiryElement
            style={{ border: "1px solid red" }}
            options={{
              style: {
                base: { backgroundColor: "#F4F5F9" },
              },
            }}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography style={{ margin: "10px 0px", color: "#97999D" }}>
            <span style={{ color: "red", fontWeight: "bold" }}>*</span> CVV
          </Typography>
          <CardCvcElement
            style={{ border: "1px solid red" }}
            options={{
              style: {
                base: { backgroundColor: "#F4F5F9" },
              },
            }}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "10px 0px",
        }}
      >
        <Button
          onClick={(e) => handleSubmit(e, clientSecret)}
          disabled={processing || succeeded}
          style={{
            backgroundColor: "#02BC3C",
            color: "#fff",
            margin: 10,
            width: "100%",
            opacity: processing || succeeded ? 0.5 : 1,
          }}
        >
          {processing ? (
            <Circles
              height="20"
              width="20"
              color="#E8E8E8"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          ) : succeeded ? (
            t("paySuccess")
          ) : isCoins ? (
            `${t("pay")?.toUpperCase()} $${coin?.amount}`
          ) : isMembership ? (
            `${t("pay")?.toUpperCase()} $${membership?.price?.unit_amount}`
          ) : null}
        </Button>
      </Box>
      {error && (
        <div
          style={{
            color: "red",
            fontSize: 12,
            fontWeight: 500,
            margin: "0px 10px",
          }}
          role="alert"
        >
          {error}
        </div>
      )}
      {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
          Payment succeeded!
        </p> */}
    </Paper>
  );
};

export default PaymentCheckout;
