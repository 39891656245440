import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import { colors } from "../../../utils/constants";
import useOnboarding from "../hooks/useOnboarding";
import useStyles from "./Interest.styles";
import { useTranslation } from "react-i18next";
import useSettingConstants from "../../../constants/data/useSettingConstants";

function Interest() {
  const classes = useStyles();

  const { interestsArray } = useSettingConstants();

  const { createInterests, state, dispatch } = useOnboarding();
  const { isLoading, interests } = state;

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const handleSelectItem = (id) => {
    const selectedItem = id;
    if (interests.length < 8 && !interests.includes(selectedItem)) {
      dispatch({ interests: [...interests, selectedItem] });
    } else {
      dispatch({ interests: interests.filter((int) => int !== selectedItem) });
    }
  };
  const { t } = useTranslation();
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageBox}>
        <Typography className={classes.header}>{t("interests")}</Typography>
        <Typography className={classes.headerSub}>
          {t("tellAboutInterests")}
        </Typography>
        <form className={classes.formBox} onSubmit={createInterests}>
          <Box className={classes.intCon}>
            {interestsArray.map((interest) => (
              <Box
                key={Interest?.id}
                className={classes.intWrap}
                style={{
                  backgroundColor: interests.includes(interest?.id)
                    ? getRandomColor()
                    : "",
                }}
                onClick={() => handleSelectItem(interest.id)}
              >
                <Typography className={classes.intText}>
                  {interest.name}
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography className={classes.count}>
            {interests?.length}/8 Selected
          </Typography>
          <CustomButton
            disabled={interests?.length < 8 ? true : false}
            type="submit"
            loading={!!isLoading}
          >
            {t("continue")}
          </CustomButton>
        </form>
      </Box>
    </Box>
  );
}

export default Interest;
