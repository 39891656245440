import React, { useState } from "react";
import defaultUserImage from ".././../assets/images/default user.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
const UserImgComponent = ({ src, style, className, alt, ...props }) => {
  const [showDefault, setShowDefault] = useState(false);
  return (
    <LazyLoadImage
      effect="blur"
      src={!showDefault ? src || defaultUserImage : defaultUserImage}
      onError={(e) => {
        setShowDefault(true);
      }}
      alt={alt}
      className={className}
      style={style}
      {...props}
    />
  );
};

export default UserImgComponent;
