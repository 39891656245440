import React, { useEffect, useState } from "react";
import adminService from "../../../../services/admin/admin.service";
import { getToken } from "../../../../utils/helpers";
import { format } from "date-fns";

const useAdminReferral = () => {
  const [referralData, setReferralData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const token = getToken();
  function formatDate(dateString) {
    const inputDate = new Date(dateString);
    const formattedDate = format(inputDate, "do MMMM yyyy");
    return formattedDate;
  }

  useEffect(() => {
    adminService
      .getAdminReferral(token)
      .then((res) => {
        setReferralData(res.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, [token]);
  //   console.log("Referrals", referralData.referrals.data);
  return { referralData, isLoading, formatDate };
};

export default useAdminReferral;
