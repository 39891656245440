import React, { useEffect } from "react";
import useEventStyles from "./Events.styles";
import EventsCard from "./components/EventsCard";
import { Box } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import usePostsEvents from "../../hooks/usePostsEvents";

export default function Events() {
  const classes = useEventStyles();
  const { events, getUserEvents, userId } = usePostsEvents();

  useEffect(() => {
    getUserEvents();
  }, [userId]);
  return !events ? (
    <div className={classes.container}>
      <Skeleton
        count={3}
        height={150}
        style={{ margin: "20px auto", width: "100%" }}
      />
    </div>
  ) : events.length > 0 ? (
    <div className={classes.container}>
      {events.map((event, index) => {
        return <EventsCard key={index} event={event} />;
      })}
    </div>
  ) : (
    <Box>No Events Available</Box>
  );
}
