import env from "../env";
import { request } from "./api";
import io from "socket.io-client";
const API_URL = `${env.API_URL}`;

const URL = {
  getGifts: `${API_URL}/gifts`,
  buyCoins: `${API_URL}/users/coin`,
  getMembership: `${API_URL}/membership`,
  selectMembership: (id) => `${API_URL}/users/membership/${id}`,
  sendGiftWithMessage: (userId, giftId) =>
    `${API_URL}/gifts/${giftId}/${userId}`,
  sendGift: (userId, giftId) => `${API_URL}/users/gift/${userId}/${giftId}`,
  createPostTag: `${API_URL}/posts/tag`,
  deletePostTag: (tagId) => `${API_URL}/posts/tag/${tagId}`,
  submitReport: (reportId, reportType) =>
    `${API_URL}/report/${reportType}/${reportId}`,
  getInterests: `${API_URL}/interest`,
};

const getGifts = (token) => {
  return request(URL.getGifts, {}, "GET", `Bearer ${token}`);
};

const buyCoins = (token, count) => {
  return request(URL.buyCoins, { count }, "POST", `Bearer ${token}`);
};

const getMembership = () => {
  return request(URL.getMembership, {}, "GET");
};

const selectMembership = (token, id) => {
  return request(URL.selectMembership(id), {}, "POST", `Bearer ${token}`);
};

const sendGiftWithMessage = (token, userId, giftId) => {
  return request(
    URL.sendGiftWithMessage(userId, giftId),
    {},
    "POST",

    `Bearer ${token}`
  );
};

const sendGift = (token, userId, giftId) => {
  return request(URL.sendGift(userId, giftId), {}, "POST", `Bearer ${token}`);
};

const createPostTag = (token, id, name) => {
  return request(URL.createPostTag, { id, name }, "POST", `Bearer ${token}`);
};

const deletePostTag = (token, tagId) => {
  return request(URL.deletePostTag(tagId), {}, "DELETE", `Bearer ${token}`);
};

const submitReport = (token, reportId, reportType, reason) => {
  return request(
    URL.submitReport(reportId, reportType),
    { reason },
    "POST",

    `Bearer ${token}`
  );
};

const getInterests = (token) => {
  return request(URL.getInterests, {}, "GET", `Bearer ${token}`);
};
const connectSocket = (token) => {
  return io("https://m8s-backend-server-c54c2b672054.herokuapp.com", {
    auth: {
      token,
    },
    transports: ["websocket"],
  });
};

const globalService = {
  getGifts,
  sendGift,
  getInterests,
  connectSocket,
  getMembership,
  selectMembership,
  submitReport,
};
export default globalService;
