import { makeStyles } from "@mui/styles";

export const MateStyle = makeStyles((theme) => ({
  userWrapper: {
    display: "flex",
    alignItems: "center !important",
    flexDirection: "column !important",
    height: 220,
    cursor: "pointer",
    padding: 10,
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -5px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
  },
  avatarBox: {
    width: 150,
    height: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    overflow: "hidden",
  },
  avatar: {
    width: 150,
    height: "auto",
  },
  userNameRow: {
    gap: 8,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  onlineDot: {
    backgroundColor: "#06C439",
    width: 10,
    height: 10,
    borderRadius: "100%",
  },
  state: {
    textAlign: "center",
    fontSize: "11px !important",
    color: "#ADABAB",
    maxWidth: 80,
  },
  country: {
    textAlign: "center",
    fontSize: "11px !important",
    color: "#ADABAB",
    maxWidth: 90,
  },
}));
