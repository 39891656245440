import { makeStyles } from "@mui/styles";
import { COLOR } from "../../../../../../../../../constants/theme";
export const styles = makeStyles((theme) => ({
  view1: {
    backgroundColor: "#fff",
    position: "relative",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "99%",
    paddingBottom: 20,
  },
  otherImagesWrap: {
    display: "grid !important",
    flexDirection: "row",
    width: "90%",
    margin: 10,
    position: "relative",
    gap: 16,
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
  },
  topImgBox: {
    position: "relative",
    display: "flex !important",
    width: 100,
    height: 125,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  topSpinner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#000",
    opacity: 0.5,
    borderRadius: 5,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  view11: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  view12: {
    marginLeft: 20,
  },
  img1: {
    objectFit: "contain",
    // width: "300px",
    // height: "400px"
  },
  img: {
    objectFit: "contain",
    // width: "300px",
    // height: "400px"
  },
  picker: {
    width: "200px",
    height: "200px",
    cursor: "pointer",
    borderWidth: "1.3px",
    borderColor: "#000",
    borderStyle: "dashed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addBtn: {
    backgroundColor: COLOR.main,
    padding: 5,
    width: "80%",
    cursor: "pointer",
  },
  addBtnText: {
    color: "#fff",
    fontWeight: "700",
    textAlign: "center",
    fontSize: "13px",
  },
  imgBox: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    display: "flex !important",
    position: "relative",
    objectFit: "contain",
    minHeight: 150,
  },
  othersImgBox: {
    width: 100,
    height: 125,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    display: "flex !important",
  },
  mainImgVidBox: {
    paddingTop: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 15,
    width: "90%",
    alignSelf: "center",
    display: "flex !important",
  },
  saveVidBtn: {
    backgroundColor: "#EA0F08",
    width: "100%",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    borderStyle: "none",
    display: "flex !important",
    cursor: "pointer",
  },
  secImage: {
    height: "50px !important",
    width: "50px !important",
    border: "1px dashed #bdbdbd",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    zIndex: 2,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.1,
    backgroundColor: "#000",
    borderRadius: 3,
  },
}));
