import { makeStyles } from "@mui/styles";

export const MatchesStyle = makeStyles(({ theme }) => ({
  matchesWrapper: {
    flexDirection: "column",
    width: "100%",
    // height: "100%",
    backgroundColor: "#F7F7F7",
    position: "relative",

    overflow: "hidden",
  },
  matesMainContainer: {
    backgroundColor: "#fff",
    marginTop: 15,
    padding: 10,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 145px)",
    overflow: "auto",
  },
  matesTopTextBox: {
    padding: "0px 15px",
  },
  matesTopText: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
  },
  matesGrid: {
    display: "grid",
    gap: 30,
    gridTemplateColumns: "repeat(auto-fill, minmax(160px, 1fr))",
    alignItems: "center",
    width: "100%",
    padding: 15,
  },
  skeletonContainer: {
    width: "calc(100vw - 400px) !important",
    display: "flex !important",
    flexDirection: "row !important",
    justifyContent: "space-around !important",
    alignItems: "center !important",
    height: "100% !important",
  },
  userWrapper: {
    display: "flex",
    alignItems: "center !important",
    flexDirection: "column !important",
    height: 170,
    cursor: "pointer",
    padding: 10,
    border: "1px solid black",
  },
  avatarBox: {
    width: 130,
    height: 130,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    boxSizing: "content-box",
    overflow: "hidden",
  },
  avatar: {
    display: "block",
    width: "100%",
    height: "auto",
  },
  userNameRow: {
    gap: 8,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  onlineDot: {
    backgroundColor: "#06C439",
    width: 10,
    height: 10,
    borderRadius: "100%",
  },
  distance: {
    textAlign: "center",
    fontSize: 11,
    color: "#ADABAB",
  },
}));
