import { makeStyles } from "@mui/styles";

export const AdminSettingsStyles = makeStyles(({ theme }) => ({
  pageWrapper: {
    flexDirection: "column",
    width: "100%",
    // height: "100%",
    backgroundColor: "#F7F7F7",
    position: "relative",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  pageMainWrap: {
    backgroundColor: "#F7F7F7",
    marginTop: 15,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
    // justifyContent: "space-between",
    height: "calc(100vh - 145px)",
    // overflow: "auto",
    gap: 24,
    dispaly: "flex !important",
    flexDirection: "row !important",
  },
  pageListWrap: {
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -5px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    width: "30%",
    backgroundColor: "#fff !important",
    padding: 10,
    overflow: "auto",
    display: "inline-block",
    height: "fit-content",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  navContainer: {
    backgroundColor: "#fff",
    width: "25%",
    padding: "20px 0px 90px 0px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -5px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    overflow: "auto",
    scrollbarWidth: 0,

    // -ms-overflow-style: none,
    // scrollbar-width: none
  },
  navItem: {
    marginBottom: "15px",
    cursor: "pointer",
  },
  navText: {
    marginLeft: 20,
    fontWeight: "500",
    fontSize: "14px !important",
  },
  navTextSel: {
    marginLeft: 20,
    fontWeight: "600",
    color: "#EA0F08",
  },
  divider: {
    background: "#00000029",
    width: "100%",
    height: "0.5px",
    marginTop: 22,
    marginBottom: 22,
  },
}));
