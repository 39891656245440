import React from "react";
import "./CustomButton.css";

function CustomButton({
  children,
  disabled,
  loading,
  handleClick,
  min,
  ...otherProps
}) {
  return (
    <button
      className={min ? "mini" : disabled ? "buttonDisabled" : "button"}
      disabled={disabled}
      type="submit"
      {...otherProps}
    >
      <div className={loading ? "button--loading" : ""}>
        <span className="button__text">{children}</span>
      </div>
    </button>
  );
}

export default CustomButton;
