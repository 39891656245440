import { Box, TextField, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { whichHour } from "../../utils/helpers";
import { AdminGlobalHeaderStyles } from "./AdminGlobalHeader.styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DateRangeSelector from "../DateRangeSelector/";
import {
  setPopupChildren,
  togglePopup,
} from "../../redux/actions/global/global.actions";

const AdminGlobalHeader = () => {
  const classes = AdminGlobalHeaderStyles();
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state) => state.global);

  function toggleCalender() {
    dispatch(setPopupChildren(<DateRangeSelector />));
    dispatch(togglePopup(true));
  }
  return (
    <Box className={classes.greetDateRow}>
      <Box>
        <Typography style={{ fontWeight: 600, fontSize: 16 }}>
          Good {whichHour()}, Admin
        </Typography>
        <Typography style={{ fontSize: 13 }}>
          {moment().format("MMMM D, YYYY, hh:mm a")}
        </Typography>
      </Box>
      <Box className={classes.dateRangeRow} onClick={toggleCalender}>
        <span>{startDate}</span> -<span>{endDate}</span>
        <CalendarTodayIcon sx={{ color: "#EA0F08", fontSize: "13px" }} />
      </Box>
    </Box>
  );
};

export default AdminGlobalHeader;
