import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import useSettingConstants from "../../constants/data/useSettingConstants";
import { LanguagesStyles } from "./Languages.styles";

const Languages = ({ onChange, value }) => {
  const classes = LanguagesStyles();
  const { languages } = useSettingConstants();

  return (
    <Box className={classes.wrapper}>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={value}
          onChange={onChange}
        >
          {languages.map((lang, i) => (
            <FormControlLabel
              key={i}
              value={lang.value}
              control={<Radio className={classes.radio} />}
              label={lang.name}
              labelPlacement="start"
              className={classes.radioWrap}
              componentsProps={{
                typography: { fontSize: "14px", fontWeight: 500 },
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default Languages;
