import { makeStyles } from "@mui/styles";

export const ModesModalStyles = makeStyles(({ theme }) => ({
  wrapper: {
    backgroundColor: "#fff",
    width: 400,
    padding: 30,
    borderRadius: 15,
    maxHeight: "80vh",
    overflow: "auto",
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
  },
  topTitleText: { fontWeight: "600 !important", fontSize: "20px !important" },
  topDescriptionText: { color: "#969696" },

  saveBtn: {
    width: "100%",
    padding: 10,
    backgroundColor: "#EA0F08",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 15,
    borderRadius: 5,
    display: "flex !important",
    borderStyle: "none",
    cursor: "pointer",
  },
}));
