import { makeStyles } from "@mui/styles";
import React from "react";
import { DetailsComp } from "../DetailsComp";
import { ImageUpload } from "../ImageUpload";

export const AccountProfile = () => {
  const classes = styles();

  return (
    <div className={classes.container}>
      <ImageUpload />
      <DetailsComp />
    </div>
  );
};

export const styles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    //   justifyContent: "space-between",
    flexDirection: "column",
  },
}));
