import React from "react";
import { useTranslation } from "react-i18next";
import { AccountProfile } from "../../pages/Settings/components/AccountProfile";

const useSettingConstants = () => {
  const { t } = useTranslation();

  const settingsRoute = "settings";

  const navData = [
    {
      value: t("accountProfile"),
      nav: `${settingsRoute}`,
      id: 1 || null,
      render: <AccountProfile />,
    },
    {
      value: t("postsEvents"),
      nav: `${settingsRoute}/posts-events`,
      id: 2,
    },
    {
      value: t("walletCoins"),
      nav: `${settingsRoute}/wallet`,
      id: 3,
    },
    {
      value: t("membership"),
      nav: `${settingsRoute}/membership`,
      id: 4,
    },
    {
      value: t("manageLocation"),
      nav: `${settingsRoute}/manage_location`,
      id: 5,
    },
    {
      value: t("referEarn"),
      nav: `${settingsRoute}/refer`,
      id: 6,
    },
    {
      value: t("contactFAQ"),
      nav: `${settingsRoute}/contact`,
      id: 7,
    },
    {
      value: t("prefSecurity"),
      nav: `${settingsRoute}/security`,
      id: 8,
    },
    {
      value: t("termsPrivacy"),
      nav: `${settingsRoute}/terms`,
      id: 9,
    },
  ];

  const sexOptions = [
    { value: "straight", title: t("straight") },
    { value: "gay", title: t("gay") },
    { value: "bi", title: t("bi") },
  ];

  const zodiacSignOptions = [
    { title: t("aries"), value: "ARIES" },
    { title: t("taurus"), value: "TAURUS" },
    { title: t("gemini"), value: "GEMINI" },
    { title: t("cancer"), value: "CANCER" },
    { title: t("leo"), value: "LEO" },
    { title: t("virgo"), value: "VIRGO" },
    { title: t("libra"), value: "LIBRA" },
    { title: t("scorpio"), value: "SCORPIO" },
    { title: t("sagittarius"), value: "SAGITTARIUS" },
    { title: t("capricon"), value: "CAPRICON" },
    { title: t("aquarius"), value: "AQUARIUS" },
    { title: t("pisces"), value: "PISCES" },
  ];

  const religionOptions = [
    { title: t("agnostic"), value: "Agnostic" },
    { title: t("atheist"), value: "ATHEIST" },
    { title: t("buddhist"), value: "BUDDHIST" },
    { title: t("catholic"), value: "CATHOLIC" },
    { title: t("christian"), value: "CHRISTIAN" },
    { title: t("hindu"), value: "HINDU" },
    { title: t("jain"), value: "JAIN" },
    { title: t("jewish"), value: "JEWISH" },
    { title: t("mormon"), value: "MORMON" },
    { title: t("muslim"), value: "MUSLIM" },
    { title: t("zoroastrian"), value: "ZOROASTRIAN" },
    { title: t("sikh"), value: "SIKH" },
    { title: t("spiritual"), value: "SPIRITUAL" },
    { title: t("other"), value: "OTHER" },
    { title: t("ratherNotSay"), value: "NOT_SAY" },
  ];

  const childrenOptions = [
    { title: t("noKids"), value: "NO, NEVER" },

    { title: t("maybeKids"), value: "SOMEDAY, MAYBE" },
    { title: t("expectingKids"), value: "EXPECTING" },
    { title: t("haveKids"), value: "I ALREADY HAVE KIDS" },
    {
      title: t("noMoreKids"),
      value: "I HAVE KIDS AND DONT WANT MORE",
    },
  ];

  const petOptions = [
    { title: t("noPets"), value: "NONE" },
    { title: t("havePets"), value: "HAVE PETS" },
  ];

  const drinkOptions = [
    { title: t("never"), value: "NEVER" },
    { title: t("drink"), value: "I DRINK SOMETIMES" },
  ];

  const smokeOptions = [
    { title: t("never"), value: "NEVER" },
    { title: t("smoke"), value: "I SMOKE SOMETIMES" },
    { title: t("chainSmoker"), value: "CHAIN SMOKER" },
  ];

  const personalityOptions = [
    { title: t("adventorous"), value: "Adventorous" },
    { title: t("careless"), value: "Careless" },
    { title: t("cheerful"), value: "Cheerful" },
    { title: t("demanding"), value: "Demanding" },
    { title: t("extroverted"), value: "Extroverted" },
    { title: t("honest"), value: "Honest" },
    { title: t("generous"), value: "Generous" },
    { title: t("liberal"), value: "liberal" },
    { title: t("introverted"), value: "Introverted" },
    { title: t("possessive"), value: "Possessive" },
    { title: t("reserved"), value: "Reserved" },
    { title: t("sensitive"), value: "Sensitive" },
    { title: t("spontaneous"), value: "Spontaneous" },
    { title: t("proud"), value: "Proud" },
    { title: t("considerate"), value: "Considerate" },
    { title: t("helpful"), value: "Helpful" },
    { title: t("optimistic"), value: "Optimistic" },
  ];

  const interestsArray = [
    { id: 1, name: t("movie") },
    { id: 2, name: t("shopping") },
    { id: 3, name: t("music") },
    { id: 4, name: t("travels") },
    { id: 5, name: t("arts") },
    { id: 6, name: t("technology") },
    { id: 7, name: t("sports") },
    { id: 8, name: t("politics") },
    { id: 9, name: t("fashion") },
    { id: 10, name: t("games") },
    { id: 11, name: t("food") },
    { id: 12, name: t("hiking") },
    { id: 13, name: t("flowers") },
    { id: 15, name: t("diy") },
    { id: 16, name: t("clubbing") },
    { id: 17, name: t("karaoke") },
    { id: 18, name: t("nature") },
    { id: 19, name: t("dancing") },
  ];

  const memberships = [
    {
      id: 1,
      name: t("lite"),
      description: t("tasteOfDating"),
      price: {
        recurring: {
          interval: t("month"),
          interval_count: 1,
        },
        unit_amount: 10,
      },
    },
    {
      id: 2,
      name: t("club"),
      description: t("enjoyM8s"),
      price: {
        recurring: {
          interval: t("month"),
          interval_count: 1,
        },
        unit_amount: 50,
      },
    },
    {
      id: 3,
      name: t("elite"),
      description: t("eliteDating"),
      price: {
        recurring: {
          interval: t("month"),
          interval_count: 1,
        },
        unit_amount: 250,
      },
    },
  ];

  const languages = [
    { value: "EN", name: "English" },
    { value: "ES", name: "Spanish" },
    { value: "IT", name: "Italian" },
    { value: "FR", name: "French" },
    { value: "PT", name: "Portugese" },
    { value: "DE", name: "German" },
  ];

  return {
    navData,
    sexOptions,
    zodiacSignOptions,
    religionOptions,
    childrenOptions,
    petOptions,
    drinkOptions,
    smokeOptions,
    personalityOptions,
    interestsArray,
    memberships,
    languages,
  };
};

export default useSettingConstants;
