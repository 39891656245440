import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGettingMates,
  setGettingPreview,
  setMates,
  setPreviewUser,
  setWhoLikesMe,
} from "../../../redux/actions/users/users.actions";
import usersService from "../../../services/users.service";
import { getToken } from "../../../utils/helpers";
import Geocode from "react-geocode";

const useMatches = () => {
  const token = getToken();
  const dispatch = useDispatch();
  const mates = useSelector((state) => state.users.mates);
  const getUsersWholikesMe = () => {
    usersService
      .getUsersWhoLikesMe(token)
      .then(({ data }) => {
        dispatch(setWhoLikesMe(data?.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const getUser = (userId) => {
    dispatch(setGettingPreview(true));
    usersService
      .getUser(token, userId)
      .then(({ data }) => {
        dispatch(setPreviewUser(data));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingPreview(false)));
  };

  const getMates = (batch = 1) => {
    dispatch(setGettingMates(true));
    usersService
      .getMates(token, batch, 20)
      .then((res) => {
        batch == 1
          ? dispatch(setMates(res.data.data))
          : dispatch(setMates([...mates, ...res.data.data]));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(setGettingMates(false));
      });
  };

  const getUserLocation = async (lat, lng) => {
    try {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

      return await Geocode.fromLatLng(lat, lng).then(
        (response) => {
          let city, state, country;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[0]) {
                case "locality":
                  city = response.results[0].address_components[i].short_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].short_name;
                  break;
                case "country":
                  country =
                    response.results[0].address_components[i].short_name;
                  break;
                default:
              }
            }
          }

          return { country, state };
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (error) {}
  };

  return { getUsersWholikesMe, getUser, getMates, getUserLocation };
};

export default useMatches;
