import React, { useEffect, useState } from "react";
import styles from "./style";
import { ReactComponent as M8Coin } from "../../../../assets/icons/coin.svg";
import { Box, Typography } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import CustomButton from "../../../../components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import useSettings from "../../hooks/useSettings";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../redux/actions/global/global.actions";
import BuyCoins from "../../../../components/BuyCoins";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

const Wallet = () => {
  const classes = styles();
  const myData = useSelector((state) => state.users.myData);
  const dispatch = useDispatch();

  const { getWalletTrans, gettingTrans, walletTrans } = useSettings();
  const { t } = useTranslation();
  useEffect(() => {
    getWalletTrans();
  }, []);

  const [selected, setSelected] = useState(0);

  return (
    <div className={classes.container}>
      {/* <div className={classes.header}>
        <div className={selected === 0 ? classes.headerItemSel : classes.headerItem}
            onClick={() => setSelected(0)}
        >
            <p className={selected === 0 ? classes.headerText1 : classes.headerText}>Wallet</p>
        </div>
        <div className={selected === 1 ? classes.headerItemSel : classes.headerItem}
            onClick={() => setSelected(1)}
        >
            <p className={selected === 1 ? classes.headerText1 : classes.headerText}>Banking</p>
        </div>
      </div> */}

      <div className={classes.rowViewS}>
        <div className={classes.rowView}>
          <M8Coin stroke={"orange"} width={45} height={45} fill={"orange"} />

          <div className={classes.colView}>
            <Typography fontSize={16} fontWeight={"600"}>
              {myData?.coins}
            </Typography>
            <Typography fontSize={12}> {t("m8sWalletBal")} </Typography>
          </div>
          <CustomButton
            onClick={() => {
              dispatch(setPopupChildren(<BuyCoins />));
              dispatch(togglePopup(true));
            }}
            style={{ marginLeft: "auto", width: 100, borderStyle: "none" }}
          >
            {t("buyMore")}
          </CustomButton>
        </div>

        {/* <CustomButton children={"Withdraw"} min={true} /> */}
      </div>
      <div className={classes.view1}>
        {gettingTrans ? (
          <Skeleton count={7} height={50} style={{ marginBottom: 10 }} />
        ) : walletTrans.length > 0 ? (
          walletTrans?.map((trans, i) => (
            <div className={classes.walletList}>
              <div
                className={classes.creditL}
                style={{
                  backgroundColor:
                    trans.type === "CREDIT" ? "#D5F7D9" : "#F7D5D5",
                }}
              >
                {trans.type === "CREDIT" ? (
                  <ArrowDownward
                    color="#000"
                    style={{ width: 15, height: 15 }}
                  />
                ) : (
                  <ArrowUpward color="#000" style={{ width: 15, height: 15 }} />
                )}
              </div>
              <Typography
                className={classes.text1}
                fontSize={12}
                marginLeft={2}
                fontStyle={"italic"}
              >
                {/* <span className={classes.boldTxt}>30 M8S</span>  */}
                {trans?.description}
                {/* You withdrew <span className={classes.boldTxt}>120 M8S</span> coins to your Bank account */}
              </Typography>
            </div>
          ))
        ) : (
          <Box
            sx={{
              margin: "25% auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No Transaction Records Found
          </Box>
        )}
      </div>
    </div>
  );
};

export default Wallet;
