import { makeStyles } from "@mui/styles";

export const ManageProfileStyles = makeStyles(({ theme }) => ({
  Wrapper: {
    backgroundColor: "#fff",
    padding: "3rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -5px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
  },
  changePassword: {
    color: "red",
    textDecoration: "underline",
    cursor: "pointer",
  },
  form: {
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  formLabel: {
    width: "100%",
    color: "#293146",
  },
  formInput: {
    padding: "1rem",
    backgroundColor: "#F4F5F9",
    border: "none",
    fontSize: "14px",
    color: "#303C47",
  },
  submitButton: {
    width: "100%",
    border: "none",
    color: "white",
    padding: "1rem",
    borderRadius: "5px",
    cursor: "pointer",
    backgroundColor: "#EA0F08",
  },
}));
