import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReportDialog from "../../../components/ReportDialog";
import useSettingConstants from "../../../constants/data/useSettingConstants";
import { COLOR } from "../../../constants/theme";
import usersService from "../../../services/users.service";
import { getToken, removeUserSession } from "../../../utils/helpers";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
export const Nav = () => {
  const token = getToken();
  const { t } = useTranslation();
  const [selected, setSelected] = useState(1);
  useEffect(() => {
    if (selected === null) {
      _id();
    }
  }, []);
  const { navData } = useSettingConstants();

  const _id = () => {
    const val = sessionStorage.getItem("$settingsId");
    if (val) {
      setSelected(val);
      // return sessionStorage.getItem("$settingsId");
    } else {
      setSelected(1);
      // return 1;
    }
  };
  const navigate = useNavigate();

  const initialState = {
    openDialogue: false,
    deleteText: "",
    deleting: false,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initialState
  );

  const { openDialogue, deleteText, deleting } = state;

  const onLogout = () => {
    usersService.removeFCMToken(token).then((res) => {
      removeUserSession();
      navigate("/");
    });
  };

  const onDeleteAccount = () => {
    dispatch({ deleting: true });
    usersService
      .deleteAccount(token)
      .then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          onLogout();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => toast.error(err?.message))
      .finally(() => {
        dispatch({ deleting: false });
      });
  };

  const onCancel = () => {
    dispatch({ openDialogue: false, deleteText: "" });
  };

  const location = useLocation();

  const classes = styles();

  const _onClick = (val, id) => {
    // e.prevent
    navigate(`/${val}`);
    setSelected(id);
    sessionStorage.setItem("$settingsId", id);
  };

  // console.log({selected});

  return (
    <div className={classes.navContainer}>
      {navData.map((item, index) => {
        return (
          <div
            className={classes.navItem}
            onClick={() => {
              item?.id === 7
                ? window.open("https://m8s.online/contact/", "__blank")
                : item?.id === 9
                ? window.open("https://m8s.online/privacy-policy")
                : _onClick(item?.nav, item.id);
            }}
          >
            {item.id === 1 ? (
              <p
                className={
                  location.pathname.includes(item.nav + "/")
                    ? classes.navText
                    : classes.navTextSel
                }
              >
                {item.value}
              </p>
            ) : (
              <p
                className={
                  location.pathname.includes(item.nav)
                    ? classes.navTextSel
                    : classes.navText
                }
              >
                {item.value}
              </p>
            )}
            <div className={classes.divider} />
          </div>
        );
      })}
      <div
        className={classes.navItem}
        onClick={() => dispatch({ openDialogue: true })}
      >
        <p className={classes.navText}>{`${t("delete")} ${t("account")}`}</p>
        <div className={classes.divider} />
      </div>

      <Dialog
        open={openDialogue}
        onClose={() => dispatch({ openDialogue: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("delete")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("allDataLost")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{t("cancel")}</Button>
          <Button disabled={deleting} onClick={onDeleteAccount}>
            {deleting ? t("wait") + "..." : t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles = makeStyles((theme) => ({
  navContainer: {
    backgroundColor: "#fff",
    width: "25%",
    padding: "20px 0px 90px 0px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -5px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    overflow: "auto",
    scrollbarWidth: 0,

    // -ms-overflow-style: none,
    // scrollbar-width: none
  },
  navItem: {
    marginBottom: "15px",
    cursor: "pointer",
  },
  navText: {
    marginLeft: 20,
    fontWeight: "500",
    fontSize: "14px !important",
  },
  navTextSel: {
    marginLeft: 20,
    fontWeight: "600",
    color: COLOR.main,
  },
  divider: {
    background: "#00000029",
    width: "100%",
    height: "0.5px",
    marginTop: 22,
    marginBottom: 22,
  },
}));
