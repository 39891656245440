import env from "../env";
import { request } from "./api";

const API_URL = `${env.API_URL}`;

const URL = {
  getWalletTrans: `${API_URL}/users/transactions`,
  switchIncognito: `${API_URL}/users/incognito`,
  updateLang: `${API_URL}/users/lang`,
  getBlockedAccts: `${API_URL}/users/block`,
  unblockUser: (userId) => `${API_URL}/users/block/${userId}`,
  toogleLocation: `${API_URL}/users/location`,
  buyCoins: (id) => `${API_URL}/coins/${id}`,
  activateBooster: `${API_URL}/users/booster`,
};

const getWalletTrans = (token) => {
  return request(
    URL.getWalletTrans,
    {},
    "GET",

    `Bearer ` + token
  );
};

const switchIncognito = (token) => {
  return request(
    URL.switchIncognito,
    {},
    "POST",

    `Bearer ` + token
  );
};

const updateLang = (token, value) => {
  return request(
    URL.updateLang,
    { lang: value },
    "POST",

    `Bearer ${token}`
  );
};

const getBlockedAccts = (token) => {
  return request(URL.getBlockedAccts, {}, "GET", `Bearer ` + token);
};

const unblockUser = (token, userId) => {
  return request(
    URL.unblockUser(userId),
    {},
    "DELETE",

    `Bearer ` + token
  );
};

const toggleEnabledLocation = (token) => {
  return request(
    URL.toogleLocation,
    {},
    "POST",

    `Bearer ` + token
  );
};

const buyCoins = (token, id) => {
  return request(URL.buyCoins(id), {}, "POST", `Bearer ${token}`);
};

const activateBooster = (token, data) => {
  console.log(data);
  return request(URL.activateBooster, data, "POST", `Bearer ` + token);
};
const otherServices = {
  getWalletTrans,
  switchIncognito,
  updateLang,
  getBlockedAccts,
  unblockUser,
  toggleEnabledLocation,
  buyCoins,
  activateBooster,
};
export default otherServices;
