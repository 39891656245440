import { connect } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../redux/actions/global/global.actions";
import ChatsHeader from "./ChatsHeader";

const mapState = ({ users }) => ({
  usersDefaultFilter: users.usersDefaultFilter,
});
const mapDispatchToProps = (dispatch) => ({
  togglePopup: (data) => dispatch(togglePopup(data)),
  setPopupChildren: (data) => dispatch(setPopupChildren(data)),
});
export default connect(mapState, mapDispatchToProps)(ChatsHeader);
