import axios from "axios";
import env from "../env";
import { request } from "./api";

const API_URL = `${env.API_URL}`;

const URL = {
  getPosts: (batch, limit) => `${API_URL}/posts?batch=${batch}&limit=${limit}`,
  likePosts: (id) => `${API_URL}/posts/like/${id}`,
  stories: `${API_URL}/stories`,
  createPost: `${API_URL}/posts`,
  seenStory: `${API_URL}/stories/`,
  acceptEventRequest: (id) => `${API_URL}/posts/${id}/request/accept`,
  eventRequest: (id) => `${API_URL}/posts/${id}/request`,
  getMyPosts: (batch, limit) =>
    `${API_URL}/posts/me?batch=${batch}&limit=${limit}`,
  getPostEvents: `${API_URL}/posts/events`,
};

const getPosts = (token, batch = 1, limit = 25) => {
  return request(URL.getPosts(batch, limit), {}, "GET", `Bearer ${token}`);
};
const likePosts = (token, postId) => {
  return request(URL.likePosts(postId), {}, "POST", `Bearer ${token}`);
};
const getStories = (token) => {
  return request(URL.stories, {}, "GET", `Bearer ${token}`);
};
const createStories = (token, formData) => {
  return axios({
    method: "POST",
    url: URL.stories,
    data: formData,
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
    },
    mode: "no-cors",
    cache: "no-cache",
  });
};

const createPost = (token, formData) => {
  return axios({
    method: "POST",
    url: URL.createPost,
    data: formData,
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
    },
    mode: "no-cors",
    cache: "no-cache",
  });
};
const updatePost = (token, id, formData) => {
  return axios({
    method: "PATCH",
    url: `${URL.createPost}/${id}`,
    data: formData,
    headers: {
      authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "multipart/form-data",
    },
    mode: "no-cors",
    cache: "no-cache",
  });
};

const viewedStory = (token, id) => {
  return request(`${URL.seenStory}${id}`, {}, "POST", `Bearer ${token}`);
};
const removeStory = (token, id) => {
  return request(`${URL.seenStory}${id}`, {}, "DELETE", `Bearer ${token}`);
};

const acceptEventRequest = (token, id, user) => {
  return request(
    `${URL.acceptEventRequest(id)}`,
    { user },
    "PATCH",

    `Bearer ${token}`
  );
};
const eventRequest = (token, id) => {
  return request(`${URL.eventRequest(id)}`, {}, "POST", `Bearer ${token}`);
};
const getMyPosts = (token, batch, limit) => {
  return request(URL.getMyPosts(batch, limit), {}, "GET", `Bearer ${token}`);
};

const getPostEvents = (token) => {
  return request(URL.getPostEvents, {}, "GET", `Bearer ${token}`);
};
const removePost = (token, id) => {
  return request(`${URL.createPost}/${id}`, {}, "DELETE", `Bearer ${token}`);
};

const feedsService = {
  getPosts,
  likePosts,
  getStories,
  createPost,
  viewedStory,
  acceptEventRequest,
  getMyPosts,
  getPostEvents,
  createStories,
  removePost,
  updatePost,
  eventRequest,
  removeStory,
};
export default feedsService;
