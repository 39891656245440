import React, { useEffect } from "react";
import StoryNode from "../StoryNode";
import useStyles from "./Story.styles";
import YourStoryNode from "../YourStoryNode";
import useFeeds from "../../hooks/useFeeds";
import { getUser } from "../../../../utils/helpers";
import Skeleton from "react-loading-skeleton";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setStoryModal } from "../../../../redux/actions/global/global.actions";

function Story({ stories }) {
  const { getStories, loadingStory } = useFeeds();
  const myData = useSelector((state) => state.users.myData);
  const classes = useStyles();

  useEffect(() => {
    getStories();
  }, []);

  const user = getUser();

  const myStories = stories.filter((story) => story?.id === myData?.id);

  return (
    <div className={classes.storyWrapper}>
      <div className={classes.yourStory}>
        {user ? (
          <YourStoryNode
            avatar={myData?.avatar}
            story={myStories[0]?.stories}
          />
        ) : (
          <Skeleton
            count={1}
            height={68}
            style={{
              margin: "0 auto",
              width: "68px",
              borderRadius: "50%",
            }}
          />
        )}
      </div>
      <div className={classes.stories}>
        {!loadingStory ? (
          stories.map((story) => {
            return <StoryNode story={story} />;
          })
        ) : (
          <Box
            className={classes.skeleton}
            sx={{ margin: "0", width: "100%", display: "flex", gap: "10px" }}
          >
            <Skeleton
              count={6}
              height={68}
              style={{
                margin: "0 auto",
                width: "68px",
                borderRadius: "50%",
              }}
            />
          </Box>
        )}
      </div>
    </div>
  );
}

export default Story;
