import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEditNavigationStyles } from "./EditNavigation.style";

export default function EditNavigation() {
  const classes = useEditNavigationStyles();
  const location = useLocation();
  const currentLocation = location.pathname.split("/");
  const defaultLocation = location.pathname.split("/").pop();
  const params = useParams();
  const navRoutes = [
    {
      text: "Edit Profile",
      route: "profile",
    },
    {
      text: "Posts & Events",
      route: "posts-events",
    },
    {
      text: "Wallet & M8S Coins",
      route: "admin-wallet",
    },
  ];
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(0);

  const handleClick = (index, route) => {
    setIsActive(index);
    navigate(`users/edit/${params.userId}/${route}`); // Adjust the path as needed
  };
  console.log(currentLocation);

  return (
    <Box className={classes.Wrapper}>
      {navRoutes.map(({ text, route }, index) => {
        if (route === "profile") {
          return (
            <div key={route}>
              <span className={classes.NavLinks}>
                <Typography
                  onClick={() => handleClick(index, route)}
                  className={` ${
                    currentLocation.includes(route) || defaultLocation === ""
                      ? "active"
                      : "inactive"
                  }`}
                >
                  {text}
                </Typography>
              </span>
              <Divider />
            </div>
          );
        }
        if (route !== "profile") {
          return (
            <div key={route}>
              <span className={classes.NavLinks}>
                <Typography
                  onClick={() => handleClick(index, route)}
                  className={` ${
                    currentLocation.includes(route) ? "active" : "inactive"
                  }`}
                >
                  {text}
                </Typography>
              </span>
              <Divider />
            </div>
          );
        }
      })}
    </Box>
  );
}
