import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  postModal: {
    paddingTop: "30px",
    background: "#0F0704",
    width: 400,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    border: "none",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    height: 450,
  },
  topHalf: {
    backgroundColor: "#0F0704",
    display: "flex",
    flexDirection: "column",
    padding: "0 15px 15px 15px",
    gap: 10,
    height: 350,
  },
  close: {
    width: 20,
    height: 20,
    borderRadius: "50%",
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  topHead: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fileContainer: {
    height: 340,
    width: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  topSpinner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#000",
    opacity: 0.5,
    borderRadius: 5,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  btnsContainer: {
    padding: "20px 10px",
    display: "grid !important",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    gap: 8,
    alignContent: "center",
  },
  setProfileBtn: {
    backgroundColor: "green !important",
    color: "#fff !important",
    borderStyle: "none",
    fontSize: 13,
    lineHeight: 1.5,
  },
  deleteBtn: {
    backgroundColor: "red !important",
    color: "#fff !important",
    borderStyle: "none",
    fontSize: 13,
    lineHeight: 1.5,
  },
  loader: {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
    zIndex: 2,
    position: "absolute !important",
    top: 0,
  },
}));

export default useStyles;
