import { useDispatch } from "react-redux";
import { setChatsFilterVal } from "../../../redux/actions/chats/chats.actions";
import { togglePopup } from "../../../redux/actions/global/global.actions";
import { setChatsFilter } from "../../../utils/helpers";

const useChatsFilter = () => {
  const dispatch = useDispatch();

  const onSelectFilterBy = (val) => {
    dispatch(setChatsFilterVal(val));
    setChatsFilter(val);
    dispatch(togglePopup(false));
  };
  return { onSelectFilterBy };
};

export default useChatsFilter;
