import { makeStyles } from "@mui/styles";

export const useMatchStyles = makeStyles(({ theme }) => ({
  Wrapper: {
    textAlign: "center",
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "1.8rem",
    position: "relative",
  },

  header: {
    "& svg": {
      width: "300px",
      height: "130px",
      objectFit: "contain",
    },
  },
  continue: {
    width: "100%",
    textAlign: "right",
    textDecoration: "underline",
    cursor: "pointer",
    position: "absolute",
    right: "1rem",
  },
  headerText: {
    color: "#EA0F08",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    gap: "2rem",
    width: "100%",
  },
  MatchFound: {
    position: "relative",
    height: "200px",
    minWidth: "43%",
    // display: "flex",
    "& svg": {
      height: "200px",
      width: "150px",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  MessageBtn: {
    background: "#FFF !important",
    color: "#EA0F08 !important",
    borderRadius: "10px",
    fontSize: "14px !important",
    textTransform: "inherit !important",
    wordWrap: "white-space",
  },
  messageContainer: {
    display: "flex",
    width: "100vw",
    gap: "1rem",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  info: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  info1: {
    fontWeight: "600 !important",
  },
  info2: {
    fontSize: "14px !important",
    textAlign: "center",
  },
  person2: {
    height: 170,
    width: 170,
    borderRadius: 170,
    padding: 7,
    borderWidth: "5px solid #fff !important",
    borderColor: "#fff",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  person1: {
    height: 170,
    width: 170,
    borderRadius: 170,
    padding: 7,
    borderWidth: "5px solid #fff !important",
    borderColor: "#fff",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    zIndex: 5,
  },
  m8s: {
    marginLeft: -95,
    marginRight: -95,
    zIndex: 5,
    height: 200,
    // position: "absolute",
    // zIndex: "20",
    // left: "0",
    // right: "15px",
    // top: "2rem",
    // "& svg": {
    //   objectFit: "contain",
    //   height: "150px",
    //   width: "150px",
    // },
  },
}));
