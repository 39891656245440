import React from "react";
import { getToken } from "../../../../../../utils/helpers";
import adminService from "../../../../../../services/admin/admin.service";
import usersService from "../../../../../../services/users.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setMyData } from "../../../../../../redux/actions/users/users.actions";

export default function useManageProfile() {
  const token = getToken();
  const dispatch = useDispatch();
  function getMe() {
    usersService
      .getMe(token)
      .then((res) => {
        dispatch(setMyData(res?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const editProfileInfo = async (data) => {
    try {
      await adminService.editProfile(token, data);
      getMe();
      console.log(data);
      toast.success("Profile Edited Successfully");
    } catch (err) {
      console.log(err);
      toast.error("Profile Failed to Edit");
    }
  };
  return { editProfileInfo };
}
