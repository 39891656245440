import { makeStyles } from "@mui/styles";

export const MessageBodyStyle = makeStyles(({ theme }) => ({
  messagesWrapper: {
    marginTop: 5,
    backgroundColor: "#fff !important",
    height: "calc(100vh - 320px)",
    padding: 10,
    overflow: "auto",
  },
  messageRow: {
    flexDirection: "row",
    margin: "5px 0px !important",
    display: "flex !important",
  },
  messageContainer: {
    // borderWidth: 1,
    // borderColor: 'red',
    maxWidth: "85%",
    padding: 10,
    backgroundColor: "#F4F5F9",
    borderBottomRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  messageContainerSender: {
    // borderWidth: 1,
    // borderColor: 'red',
    maxWidth: "85%",
    padding: 10,
    backgroundColor: "#E00E08",
    borderTopRightRadius: 15,
    borderBottomLeftRadius: 15,
  },
  mediaStyle: { width: 150, height: 150, objectFit: "contain" },
  messageInfoBox: {
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
    display: "flex !important",
  },
  audioMessageWrapper: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  audioMessageInner: { display: "flex !important", justifyContent: "center" },
  audioDurationText: {
    fontSize: "10px !important",
    paddingRight: 10,
    textAlign: "right",
  },
  audioMessageCntrlWrapper: {
    width: 25,
    height: 25,
    // marginBottom: -15,
    borderRadius: 100,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "1px solid black",
    zIndex: 2,
    display: "flex !important",
  },
}));
