import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(({ theme }) => ({
  modeWrap: {
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    flexDirection: "row",
    marginVertical: 10,
    display: "flex !important",
    gap: 16,
    cursor: "pointer",
  },
  modeInfo: {},
  titleText: {
    fontSize: "15px !important",
    fontWeight: "600 !important",
  },
  descriptionText: {
    fontSize: "13px !important",
  },
}));
