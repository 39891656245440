import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import PropTypes from "prop-types";
import { defaultStaticRanges } from "./constants";

// import "./DateRangeSelector.css";
import { useDispatch, useSelector } from "react-redux";
import { setDates } from "../../redux/actions/global/global.actions";

const DateRangeSelector = ({
  ranges,
  onChange,
  onSubmit,
  section,
  ...rest
}) => {
  const { startDate, endDate } = useSelector((state) => state.global);
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const yearAgo = currentYear - 1;
  const monthAgo = currentMonth;

  const date12MonthsAgo = new Date(yearAgo, monthAgo, currentDate.getDate());
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: date12MonthsAgo,
    endDate: currentDate,
    key: "selection",
  });

  const dispatch = useDispatch();
  const [show, setShow] = useState();

  function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "MM/DD/yyyy");
  }

  const handleSelect = (ranges) => {
    setSelectedDateRange(ranges.selection);

    const dateObj = {
      endDate: format(ranges.selection.endDate, "yyyy-MM-dd"),
      startDate: format(ranges.selection.startDate, "yyyy-MM-dd"),
      label: `${format(ranges.selection.startDate, "MMM, yyyy")} - ${format(
        ranges.selection.endDate,
        "MMM, yyyy"
      )}`,
      value: `${format(ranges.selection.startDate, "YYY-MM-dd")},${format(
        ranges.selection.endDate,
        "YYY-MM-dd"
      )}`,
    };
    console.log(dateObj);
    // const { startDate, endDate } = selectedDateRange;
    const { startDate, endDate } = dateObj;
    if (startDate || endDate) dispatch(setDates({ startDate, endDate }));
  };

  return (
    <React.Fragment>
      <div className="shadow d-inline-block">
        <DateRangePicker
          onChange={handleSelect}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={true}
          months={1}
          ranges={[selectedDateRange]}
          direction="horizontal"
          maxDate={new Date()}
          displayMode="dateRange"
          startDatePlaceholder="From"
          endDatePlaceholder="To"
        />
        {/* <div className="text-right position-relative rdr-buttons-position mt-2 mr-3">
          <button
            className="btn btn-transparent text-primary rounded-0 px-4 mr-2"
            onClick={() => setShow(true)}
          >
            Done
          </button>
          <button
            className="btn btn-transparent text-danger rounded-0 px-4"
            onClick={onClickClear}
          >
            Clear
          </button>
        </div> */}
      </div>

      {show && (
        <div className="h-100 mt-3 alert alert-transparent">
          <p className="my-auto d-inline">
            Start Date : {formatDateDisplay(selectedDateRange.startDate)}
            {" | "}
            End Date : {formatDateDisplay(selectedDateRange.endDate)}
          </p>
          <button
            className="mb-1 btn btn-transparent text-danger"
            onClick={() => setShow(false)}
            variant="outline-success"
          >
            {" "}
            Close
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

DateRangeSelector.defaultProps = {
  ranges: defaultStaticRanges,
};

DateRangeSelector.propTypes = {
  /**
   * On Submit
   */
  onSubmit: PropTypes.func,
};

export default DateRangeSelector;
