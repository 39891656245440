import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { COLOR } from "../../../constants/theme";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { Avatar, Box, IconButton, } from "@mui/material";
import UserImgComponent from "../../../components/UserImgComponent";
import useAccountProfile from "./AccountProfile/hooks/useAccountProfile";
import {  RotatingLines } from "react-loader-spinner";
import { PlayCircle, VideoCall } from "@mui/icons-material";
import {
  setPopupChildren,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import MediaPreview from "../../../components/MediaPreview";

export const ImageUpload = (props) => {
  const classes = styles();
  const myData = useSelector((state) => state.users.myData);
  const {
    fileToAccept,
    setFileToAccept,
    setImgType,
    open,
    loading,
    dispatch,
  } = useAccountProfile();

  useEffect(() => {
    fileToAccept && open();
    return () => {
      setFileToAccept(null);
      setImgType("");
    };
  }, [fileToAccept]);

  return (
    <div className={classes.view1}>
      <Box className={classes.otherImagesWrap}>
        {loading && (
          <Box className={classes.loaderContainer}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </Box>
        )}

        {myData?.images?.filter((image, i) => image?.isMain).length > 0 ? (
          <Box
            className={classes.imgBox}
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                setPopupChildren(
                  <MediaPreview
                    file={myData?.images?.filter((image) => image?.isMain)[0]}
                    fileType="image"
                  />
                )
              );
              dispatch(togglePopup(true));
            }}
          >
            <UserImgComponent
              src={myData?.images?.filter((image) => image?.isMain)[0]?.url}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: 5,
                border: "2px solid red",
              }}
            />
          </Box>
        ) : (
          <IconButton
            onClick={() => {
              setFileToAccept("image");
              setImgType("main");
            }}
            className={classes.imgBox}
            sx={{
              border: "1px dashed",
              borderRadius: 1,
            }}
          >
            <Avatar src="" className={classes.secImage} />
          </IconButton>
        )}
        {myData?.images
          ?.filter((image) => !image?.isMain)
          ?.map((item, i) => (
            <Box
              key={i}
              className={classes.imgBox}
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(
                  setPopupChildren(
                    <MediaPreview file={item} fileType="image" />
                  )
                );
                dispatch(togglePopup(true));
              }}
            >
              <UserImgComponent
                src={item?.url}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 5,
                }}
              />
            </Box>
          ))}
        {Array(
          4 - (myData?.images?.filter((image) => !image?.isMain)?.length || 0)
        )
          ?.fill(null)
          ?.map((item, i) => (
            <IconButton
              key={i}
              className={classes.imgBox}
              sx={{
                borderWidth: 1,
                borderStyle: "dashed",
                borderRadius: 1,
              }}
              onClick={() => {
                setFileToAccept("image");
                setImgType("others");
              }}
            >
              <AddIcon size={16} />
            </IconButton>
          ))}

        {myData?.vid ? (
          <Box
            className={classes.imgBox}
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(
                setPopupChildren(
                  <MediaPreview file={{ url: myData?.vid }} fileType="video" />
                )
              );
              dispatch(togglePopup(true));
            }}
          >
            <PlayCircle
              style={{
                position: "absolute",
                top: "40%",
                bottom: "40%",
                left: " 40%",
                right: "40%",
                width: 50,
                height: 50,
                opacity: 0.7,
              }}
            />
            <video
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: 5,
              }}
              controls={false}
              onClick={() => console.log("clicked")}
            >
              <source src={myData?.vid} />
            </video>
          </Box>
        ) : (
          <IconButton
            onClick={() => {
              setFileToAccept("video");
            }}
            className={classes.imgBox}
            sx={{
              border: "1px dashed",
              borderRadius: 1,
            }}
          >
            <VideoCall
              sx={{
                width: "50px",
                height: "50px",
                transform: "translateY(10px)",
                // color: "#bdbdbd",
                color: "#707070",
                opacity: 0.7,
              }}
            />
          </IconButton>
        )}
      </Box>
    </div>
  );
};

export const styles = makeStyles((theme) => ({
  view1: {
    backgroundColor: "#fff",
    position: "relative",
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "99%",
    paddingBottom: 20,
  },
  otherImagesWrap: {
    display: "grid !important",
    flexDirection: "row",
    width: "90%",
    margin: 10,
    position: "relative",
    gap: 16,
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
  },
  topImgBox: {
    position: "relative",
    display: "flex !important",
    width: 100,
    height: 125,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  topSpinner: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#000",
    opacity: 0.5,
    borderRadius: 5,
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
  },
  view11: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  view12: {
    marginLeft: 20,
  },
  img1: {
    objectFit: "contain",
    // width: "300px",
    // height: "400px"
  },
  img: {
    objectFit: "contain",
    // width: "300px",
    // height: "400px"
  },
  picker: {
    width: "200px",
    height: "200px",
    cursor: "pointer",
    borderWidth: "1.3px",
    borderColor: "#000",
    borderStyle: "dashed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addBtn: {
    backgroundColor: COLOR.main,
    padding: 5,
    width: "80%",
    cursor: "pointer",
  },
  addBtnText: {
    color: "#fff",
    fontWeight: "700",
    textAlign: "center",
    fontSize: "13px",
  },
  imgBox: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    display: "flex !important",
    position: "relative",
    objectFit: "contain",
    minHeight: 150,
  },
  othersImgBox: {
    width: 100,
    height: 125,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    display: "flex !important",
  },
  mainImgVidBox: {
    paddingTop: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 15,
    width: "90%",
    alignSelf: "center",
    display: "flex !important",
  },
  saveVidBtn: {
    backgroundColor: "#EA0F08",
    width: "100%",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    borderStyle: "none",
    display: "flex !important",
    cursor: "pointer",
  },
  secImage: {
    height: "50px !important",
    width: "50px !important",
    border: "1px dashed #bdbdbd",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    zIndex: 2,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.1,
    backgroundColor: "#000",
    borderRadius: 3,
  },
}));
