import React, { useEffect, useState } from "react";
import {
  debounceHandler,
  getToken,
} from "../../../../../../../../../utils/helpers";
import usersService from "../../../../../../../../../services/users.service";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setPreviewUser } from "../../../../../../../../../redux/actions/users/users.actions";
import { useTranslation } from "react-i18next";
import useSettingConstants from "../../../../../../../../../constants/data/useSettingConstants";
import useConstants from "../../../../../../../../../utils/useConstants";
import adminService from "../../../../../../../../../services/admin/admin.service";
import { useParams } from "react-router-dom";

export default function useUserDetails() {
  const { previewUser } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [userInterests, setUserInterests] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [isExist, setIsExist] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const moment = require("moment");
  const date = moment(userData?.birthday);
  const formatedDate = date.format("DD/MM/YYYY");
  const { t } = useTranslation();
  const { sexOptions, interestsArray } = useSettingConstants();
  const {
    childrenOptions,
    drinkOptions,
    personalityOptions,
    petOptions,
    religionOptions,
    smokeOptions,
    zodiacSignOptions,
  } = useConstants();

  const date_components = formatedDate.split("/");
  const day = date_components[0];
  const month = date_components[1];
  const year = date_components[2];

  const onOpenMenu = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(menu);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMenu("");
  };
  const token = getToken();
  const { userId } = useParams();
  async function updateProfileInfo(e) {
    e.preventDefault();
    setIsUpdatingProfile(true);
    const {
      full_name,
      gender,
      sexuality,
      username,
      height,
      zodiac_sign,
      pet,
      children,
      drink,
      smoke,
      religion,
      personality,
      occupation,
      bio,
      birthday,
    } = userData;
    const first_name = full_name.split(" ")[0];
    const last_name = full_name.split(" ")[1];
    const data = {
      first_name,
      last_name,
      gender,
      sexuality,
      username,
      height,
      zodaic_sign: zodiac_sign,
      pet,
      children,
      drink,
      smoke,
      religion,
      personality,
      occupation,
      bio,
      birthday,
    };
    if (
      !first_name ||
      !last_name ||
      !gender ||
      !sexuality ||
      !username ||
      !height ||
      !zodiac_sign ||
      !pet ||
      !children ||
      !drink ||
      !smoke ||
      !religion ||
      !personality ||
      !occupation ||
      !birthday
    ) {
      setIsUpdatingProfile(false);
      toast.error("Please fill in all fields");
      return;
    }

    await adminService
      .updateUserProfile(token, userId, data)
      .then(() => {
        setIsUpdatingProfile(false);
        dispatch(setPreviewUser({ ...previewUser, ...data }));
        toast.success("Profile Updated");
      })
      .catch((err) => {
        setIsUpdatingProfile(false);
        console.log(err);
      });
  }
  const onValidateUsername = (val) => {
    debounceHandler(() => {
      setIsChecking(true);
      usersService
        .checkUserName(token, val)
        .then(({ data }) => {
          // console.log(data.status);
          setIsExist(data.status);
        })
        .finally(() => setIsChecking(false));
    }, 800);
  };
  function submitInterest(e) {
    e.preventDefault();
    setIsSubmitting(true);
    const ids = userInterests.map((item) => item.id);
    console.log("Interests", userInterests, ids);
    adminService
      .updateUserInterests(token, userId, ids)
      .then(() => {
        dispatch(setPreviewUser({ ...previewUser, interests: userInterests }));
        toast.success("Interests Updated");
        setIsSubmitting(false);
      })
      .catch((err) => toast.error(err.message));
    // console.log(userInterests);
  }
  const onChangeUsername = (val) => {
    if (val?.charAt(0) === "@") {
      setUserData({
        ...userData,
        username: val?.toLowerCase()?.trim().slice(1),
      });
      onValidateUsername(val?.toLowerCase()?.trim().slice(1));
    } else {
      setUserData({ ...userData, username: `${val?.toLowerCase()?.trim()}` });
      onValidateUsername(`${val?.toLowerCase()?.trim()}`);
    }
    // console.log(val);
  };
  function handleChange(e) {
    const { name, value } = e.target;
    console.log(name, value);
    if (name === "username") {
      onChangeUsername(value);
    }
    // console.log(name, value);
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  const onPickInterest = (interest) => {
    if (userInterests?.map((i) => i?.id).includes(interest?.id)) {
      setUserInterests(userInterests?.filter((i) => i?.id !== interest?.id));
    } else {
      if (userInterests.length < 8) {
        setUserInterests([...userInterests, interest]);
      }
    }
  };

  const handleDatePickerClose = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    setUserData({
      full_name:
        String(previewUser.first_name) + " " + String(previewUser.last_name),
      gender: previewUser?.gender,
      sexuality: previewUser?.sexuality,
      username: previewUser?.username,
      height: Number(previewUser?.height || 0),
      zodiac_sign: previewUser.zodaic_sign,
      pet: previewUser?.pet,
      children: previewUser?.children,
      drink: previewUser?.drink,
      smoke: previewUser?.smoke,
      religion: previewUser?.religion,
      personality: previewUser?.personality,
      interests: previewUser?.interests,
      occupation: previewUser?.occupation,
      bio: previewUser?.bio,
      birthday: previewUser?.birthday,
    });
    setUserInterests(previewUser?.interests);
    console.log(previewUser.sexuality, userData.sexuality);
  }, [previewUser]);
  return {
    userData,
    setUserData,
    previewUser,
    userInterests,
    setUserInterests,
    updateProfileInfo,
    isSubmitting,
    isUpdatingProfile,
    submitInterest,
    handleChange,
    isExist,
    isChecking,
    onPickInterest,
    handleDatePickerClose,
    selectedMenu,
    anchorEl,
    isOpen,
    setSelectedMenu,
    setAnchorEl,
    setIsOpen,
    handleClose,
    onOpenMenu,
    day,
    month,
    year,
    t,
    sexOptions,
    interestsArray,
    childrenOptions,
    drinkOptions,
    personalityOptions,
    petOptions,
    religionOptions,
    smokeOptions,
    zodiacSignOptions,
  };
}
