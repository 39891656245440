import { Box} from "@mui/material";
import React, { useEffect, useState } from "react";
import PostCards from "./components/PostsCards/PostsCards";
import styles from "./Posts.style";
import Skeleton from "react-loading-skeleton";
import usePostsEvents from "../../hooks/usePostsEvents";

const Posts = () => {
  const classes = styles();
  const { getUserPosts, posts, userId } = usePostsEvents();
  useEffect(() => {
    getUserPosts();
  }, [userId]);

  const [selected, setSelected] = useState(0);

  return (
    <Box className={classes.container}>
      {posts ? (
        posts.length > 0 ? (
          posts
            ?.filter((post) => post.is_event === false)
            ?.map((post, i) => {
              return (
                <PostCards
                  key={i}
                  i={i}
                  profileName={post.user.username}
                  profileLocation={post.post_location}
                  cardCaption={post.caption}
                  profileImg={post.user.avatar}
                  cardImage={post.image}
                  post={post}
                  containerStyle={{
                    border: "1px solid red",
                    backgroundColor: "red !important",
                    width: 400,
                  }}
                />
              );
            })
        ) : (
          <Box>No Post Available</Box>
        )
      ) : (
        <Box sx={{ margin: "0 auto" }}>
          <Skeleton
            count={3}
            height={400}
            style={{ margin: "20px auto", width: "600px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Posts;
