import { Box, Typography } from "@mui/material";
import React from "react";
import { MembershipsStyles } from "./MembershipCard.styles";
import clubBadge from "../../../../assets/images/club.png";
import eliteBadge from "../../../../assets/images/elite.png";
import liteBadge from "../../../../assets/images/lite.png";
import { useSelector } from "react-redux";

const MembershipCard = ({
  membership,
  i,
  onClick,
  currentPlan,
  width,
  selected,
}) => {
  const classes = MembershipsStyles();
  const myData = useSelector((state) => state.users.myData);
  return (
    <Box
      key={i}
      className={classes.memberWrap}
      style={{
        backgroundColor: selected === membership.id ? "#F8D588" : "#fff",
        width: `${width}`,
      }}
      onClick={onClick}
    >
      <Box className={classes.memberInfo}>
        <Box className={classes.planNameRow}>
          <Typography fontSize={13} fontWeight={600}>
            {membership?.name}
          </Typography>{" "}
          {myData?.active_subscription && currentPlan == membership?.id && (
            <Box className={classes.activePlan}>active</Box>
          )}
        </Box>

        <Box
          sx={{ display: "flex" }}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Typography>{"\u0024"}</Typography>
          <Typography className={classes.amountText}>
            {membership?.price?.unit_amount}
          </Typography>
          <Typography className={classes.durationText}>
            /{membership?.price?.recurring?.interval}
          </Typography>
        </Box>
        <Typography fontSize={13} style={{ width: "100%" }}>
          {membership?.description}
        </Typography>
      </Box>

      <Box
        sx={{ display: "flex" }}
        style={{
          width: 50,
          height: 50,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {membership?.id === 3 && (
          <img
            alt={"elite"}
            src={eliteBadge}
            style={{ width: 65, height: 80 }}
          />
        )}
        {membership?.id === 2 && (
          <img alt="club" src={clubBadge} style={{ width: 65, height: 80 }} />
        )}
        {membership?.id === 1 && (
          <img alt="lite" src={liteBadge} style={{ width: 65, height: 80 }} />
        )}
      </Box>
    </Box>
  );
};

export default MembershipCard;
