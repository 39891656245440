import { audioExts, imageExts, videoExts } from "./constants";

export const shuffledArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const capitalizeFirst = (string) => {
  const lowercase = string.toLowerCase();
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export function getToken() {
  const token = sessionStorage.getItem("token");

  if (token) return token;
  else return null;
}

export function getLocalLang() {
  const lang = sessionStorage.getItem("lang");

  if (lang) return lang;
  else return null;
}

export function getUser() {
  const userStr = sessionStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const setToken = (token) => {
  sessionStorage.removeItem("token");
  sessionStorage.setItem("token", token);
};

export const setLocalLang = (lang) => {
  sessionStorage.removeItem("lang");
  sessionStorage.setItem("lang", lang);
};

export const setUserSession = (token, user) => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
};

export const setUser = (user) => {
  sessionStorage.removeItem("user");
  sessionStorage.setItem("user", JSON.stringify(user));
};

export const setOnboarding = (detail) => {
  sessionStorage.setItem("onboard", JSON.stringify(detail));
};
export const getOnboarding = () => {
  const onboard = sessionStorage.getItem("onboard");

  if (onboard) return onboard;
  else return null;
};

export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

export const filteredChat = (chatsArr, chatsFilterBy) => {
  if (chatsFilterBy === "unread") {
    const read = chatsArr?.filter((chat) => chat?.chat?.unseen_messages === 0);
    const unread = chatsArr?.filter((chat) => chat?.chat?.unseen_messages > 0);
    return unread;
  } else if (chatsFilterBy === "online") {
    const onlineUsers = chatsArr?.filter(
      (chat) => chat?.user?.status === "online"
    );

    return onlineUsers;
  } else if (chatsFilterBy === "favourites") {
    const favouites = chatsArr?.filter((chat) => chat?.is_favorite);

    return favouites;
  } else {
    const arr1 = chatsArr?.map((chat) => ({
      ...chat,
      last_message: {
        ...chat?.last_message,
        createdAt: new Date(chat?.last_message?.createdAt).toISOString(),
      },
    }));

    return arr1.sort(
      (objA, objB) =>
        Number(objA.last_message.createdAt) -
        Number(objB.last_message.createdAt)
    );
  }
};

export const usernameStringCheck = (str) => {
  //   let onlyLetters = /^[a-zA-Z]+$/;
  // if (str.length > 4 && str.length < 25) {
  //   if (onlyLetters.test(str.charAt(0))) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   return false;
  // }

  if (str?.charAt(0) === "@") {
    return str?.slice(1).toLowerCase();
  } else {
    return str?.toLowerCase();
  }
};

export const getAge = (date) => {
  const year = date?.slice(0, 4);
  let d = new Date().getFullYear();
  const userAge = Number(d) - Number(year);
  return userAge;
};

function toRad(Value) {
  return (Value * Math.PI) / 180;
}
export function distanceBetweenCoords(originLat, originLng, destLat, destLng) {
  const R = 6371; // Radius of the earth in km
  let dLat = toRad(destLat - originLat);
  let dLon = toRad(destLng - originLng);
  let originLatitude = toRad(originLat);
  let destLatitude = toRad(destLat);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(originLatitude) *
      Math.cos(destLatitude);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;
  return d;
}

export const fileType = (fileUrl) => {
  if (fileUrl === undefined || !fileUrl) {
    return "none";
  } else {
    const imageSource = `^https://firebasestorage.googleapis.com/`;
    const regexp = new RegExp(imageSource);

    const isValid = regexp.test(fileUrl);

    if (isValid) {
      const m = fileUrl?.match("(?=\\.).*(?=\\?alt=media&token=)")[0];
      const urlExtension = /\.(\w+)$/.exec(m);

      if (urlExtension && Object.values(imageExts).includes(urlExtension[0])) {
        return "image";
      } else if (
        urlExtension &&
        Object.values(videoExts).includes(urlExtension[0])
      ) {
        return "video";
      } else if (
        urlExtension &&
        Object.values(audioExts).includes(urlExtension[0])
      ) {
        return "audio";
      } else {
        return "none";
      }
    } else {
      return "none";
    }
  }
};

export const setChatsFilter = (val) => {
  sessionStorage.removeItem("chats_filter_val");
  sessionStorage.setItem("chats_filter_val", val);
};

export function getChatsFilter() {
  const result = sessionStorage.getItem("chats_filter_val");

  if (result) return result;
  else return "most_recent";
}

export function elementInViewport2(el) {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
}

export const isElementInViewport = function (el, percentVisible) {
  let rect = el.getBoundingClientRect(),
    windowHeight = window.innerHeight || document.documentElement.clientHeight;

  return !(
    Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100) <
      percentVisible ||
    Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
      percentVisible
  );
};

export const getFileExtFromBase64 = (base64Data) => {
  return base64Data.substring(
    "data:image/".length,
    base64Data.indexOf(";base64")
  );
};
export const base64ToFile = (base64, filename) => {
  // var arr = base64.split(","),
  //   mime = arr[0].match(/:(.*?);/)[1],
  //   bstr = window.atob(arr[1]),
  //   n = bstr.length,
  //   u8arr = new Uint8Array(n);

  // while (n--) {
  //   u8arr[n] = bstr.charCodeAt(n);
  // }

  // return new File([u8arr], filename, { type: mime });

  const contentType = base64.split(";")[0].split(":")[1];
  const byteCharacters = window.atob(base64.split(",")[1]);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const blob = new Blob([new Uint8Array(byteArrays)], { type: contentType });

  return new File([blob], filename, { type: contentType });
};

let timeoutId;
function timeoutFunc(func, delay) {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  timeoutId = setTimeout(func, delay);
}

export const debounceHandler = (func, delay) => timeoutFunc(func, delay);

export const toFeetInch = (val) => {
  var inches = (val * 0.393700787).toFixed(0);

  var feet = Math.floor(inches / 12);
  inches %= 12;

  return feet + "ft " + inches + "in";
};

export function whichHour() {
  const hours = new Date().getHours();
  const min = new Date().getMinutes();
  return hours < 12
    ? "Morning"
    : hours >= 12 && hours < 17
    ? "Afternoon"
    : "Evening";
}
