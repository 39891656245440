import { Box, Typography } from "@mui/material";
import React from "react";
import { EditHeaderStyle } from "./EditHeader.style";
import { useSelector } from "react-redux";
import {
  getAge,
  usernameStringCheck,
} from "../../../../../../../utils/helpers";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const EditHeader = ({ togglePopup, setPopupChildren }) => {
  const classes = EditHeaderStyle();
  const myData = useSelector((state) => state.users.myData);
  const navigate = useNavigate();
  return (
    <Box className={classes.chatsHeaderWrapper} sx={{ display: "flex" }}>
      <Box className={classes.ArrowBack} onClick={() => navigate(-1)}>
        <ArrowBack />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "70%",
          justifyContent: "flex-end",
        }}
      >
        <Typography
          fontSize={14}
          fontWeight={"500"}
          className={classes.headerTxt}
        >
          {myData?.first_name || myData?.last_name || myData?.username},{" "}
          {myData?.birthday && getAge(myData?.birthday)}
        </Typography>
        {myData?.status && myData?.status === "online" && (
          <>
            <div className={classes.dividerV} />

            <Typography
              fontSize={14}
              fontWeight={"500"}
              className={classes.headerTxt}
            >
              Online
            </Typography>
            <div className={classes.online} />
          </>
        )}

        <div className={classes.dividerV} />

        <Typography
          fontSize={14}
          fontWeight={"500"}
          className={classes.headerTxt}
        >
          @{usernameStringCheck(myData?.username)}
        </Typography>
      </Box>
    </Box>
  );
};

export default EditHeader;
