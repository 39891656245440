import { Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { colors } from "../../utils/constants";
import {
  capitalizeFirst,
  getUser,
  toFeetInch,
  usernameStringCheck,
} from "../../utils/helpers";
import useDiscover from "./hooks/useDiscover";
import PetsIcon from "@mui/icons-material/Pets";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import HeightIcon from "@mui/icons-material/Height";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import LiquorIcon from "@mui/icons-material/Liquor";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import { ReactComponent as TimesIcon } from "../../assets/icons/close.svg";
import { ReactComponent as GiftIcon } from "../../assets/icons/gift.svg";
import { ReactComponent as LikeUserIcon } from "../../assets/icons/like_white.svg";

import { DiscoverStyle } from "./Discover.style";
import TinderCard from "react-tinder-card";
import UserImgComponent from "../../components/UserImgComponent";
import Carousel from "react-material-ui-carousel";
import Skeleton from "react-loading-skeleton";
import MappedHeader from "../../components/UsersFilter/components/MappedHeader";
import useConstants from "../../utils/useConstants";
import { FaStarOfDavid, FaBrain, FaPrayingHands } from "react-icons/fa";
import { ReactComponent as ReligionIcon } from "../../assets/icons/religion.svg";
import clubBadge from "../../assets/images/club.png";
import eliteBadge from "../../assets/images/elite.png";
import liteBadge from "../../assets/images/lite.png";
import useSettingConstants from "../../constants/data/useSettingConstants";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import lovePop from "../../assets/animations/lovePop.json";
import { ReactComponent as RollbackIcon } from "../../assets/icons/rollback.svg";
import { Match, Plans } from "./components/";

const Discover = ({ discoverUsers, myData, showPopup, gettingDiscover }) => {
  const swipeCardRef = useRef(null);
  const classes = DiscoverStyle();
  const vidRef = useRef(null);
  const { t } = useTranslation();
  const [isPremium, setIsPremium] = useState(false);
  const [current, setCurrent] = useState(0);
  const {
    modes,
    memberships,
    sexOptions,
    childrenOptions,
    drinkOptions,
    smokeOptions,
    religionOptions,
    personalityOptions,
    zodiacSignOptions,
    petOptions,
  } = useConstants();
  const { interestsArray } = useSettingConstants();
  const {
    getDiscoverUsers,
    currentUser,
    setCurrentUser,
    onSwipeOut,
    onOpenGifts,
    setCurrentUserGallery,
    currentUserGallery,
    handleBacktrack,
    showPremiumBoost,
    showUsersMatch,
    setShowPremiumBoost,
    setShowUsersMatch,
  } = useDiscover();

  useEffect(() => {
    getDiscoverUsers();
  }, []);

  useEffect(() => {
    if (discoverUsers?.length > 0) {
      setCurrentUser(discoverUsers[0]);
      let images = [];
      if (discoverUsers[0]?.images?.length) {
        images = discoverUsers[0]?.images
          ?.filter((img) => !img?.isMain && img?.url)
          .map((i) => i?.url);
      }
      setCurrentUserGallery([
        discoverUsers[0]?.avatar && discoverUsers[0]?.avatar,
        discoverUsers[0]?.vid && discoverUsers[0]?.vid,
        ...images,
      ]);
    }
  }, [discoverUsers]);

  const checkVidInViewport = (e) => {
    if (e === 1) {
      vidRef.current && vidRef.current.play();
    } else {
      vidRef.current && vidRef.current.pause();
    }
  };

  // function handleRollback() {
  //   if (isPremium) {
  //     return;
  //   }
  //   setCurrent(1);
  // }

  return (
    <Box className={classes.discoverWrapper}>
      <MappedHeader />
      {showUsersMatch ? (
        <Box className={classes.discoverMatchWrap}>
          <Match match={currentUser} setShowUsersMatch={setShowUsersMatch} />
        </Box>
      ) : showPremiumBoost ? (
        <Box className={classes.discoverPlanWrap}>
          <Plans setShowPremiumBoost={setShowPremiumBoost} />
        </Box>
      ) : (
        <Box className={classes.discoverMainWrap}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              {gettingDiscover ? (
                <Skeleton
                  style={{
                    borderRadius: 10,
                    height: "calc(100vh - 175px)",
                  }}
                />
              ) : discoverUsers?.length > 0 ? (
                <Box className={classes.swiperWrapper}>
                  <Box
                    className={classes.rollback}
                    onClick={async () =>
                      await handleBacktrack(swipeCardRef, currentUser?.id)
                    }
                  >
                    <RollbackIcon />
                  </Box>
                  <TinderCard
                    ref={(ref) => (swipeCardRef.current = ref)}
                    className="swipe"
                    key={currentUser.id}
                    onCardLeftScreen={(dir) => onSwipeOut(dir, currentUser)}
                  >
                    <Box className={classes.imgContainer}>
                      <Carousel
                        onChange={(e) => checkVidInViewport(e)}
                        sx={{
                          display: "flex",
                          justifyContent: "center !important",
                          alignItems: "center !important",
                          height: "calc(100vh - 175px)",
                          width: 360,
                          objectFit: "contain",
                        }}
                        className={classes.carousel}
                        indicators={false}
                        navButtonsAlwaysVisible={true}
                        autoPlay={false}
                        animation="slide"
                        navButtonsProps={{
                          style: { backgroundColor: "#000", opacity: 0.1 },
                        }}
                      >
                        {currentUserGallery.map((item, i) =>
                          i === 1 && item ? (
                            <video
                              src={item}
                              ref={vidRef}
                              className={classes.img}
                              muted={true}
                            />
                          ) : (
                            <UserImgComponent
                              key={i}
                              className={classes.img}
                              alt="user_image"
                              src={item}
                              height="calc(100vh - 175px)"
                              width="100%"
                            />
                          )
                        )}
                      </Carousel>
                    </Box>
                  </TinderCard>

                  <Box className={classes.buttonsContainer}>
                    <IconButton
                      className={classes.actionButtons}
                      style={{ backgroundColor: "#fff" }}
                      onClick={async () => {
                        await swipeCardRef.current.swipe("left");
                      }}
                    >
                      <TimesIcon
                        stroke={"#000"}
                        width={18}
                        height={18}
                        fill={"#000"}
                      />
                    </IconButton>
                    <IconButton
                      className={classes.actionButtons}
                      style={{ backgroundColor: "#fff" }}
                      onClick={() => onOpenGifts(currentUser)}
                    >
                      <GiftIcon
                        stroke={"#000"}
                        width={18}
                        height={18}
                        fill={"#000"}
                      />
                    </IconButton>
                    <IconButton
                      className={classes.actionButtons}
                      style={{ backgroundColor: "red" }}
                      onClick={async () =>
                        await swipeCardRef.current.swipe("right")
                      }
                    >
                      <LikeUserIcon
                        stroke={"#fff"}
                        width={18}
                        height={18}
                        fill={"#fff"}
                      />
                    </IconButton>
                  </Box>
                  {/* <Box
                  style={{
                    position: "absolute",
                    width: "70%",
                  }}
                >
                  <Lottie animationData={lovePop} loop={true} />
                </Box> */}
                </Box>
              ) : (
                <Box className={classes.swiperWrapper}>
                  <Typography>{t("noUser")}</Typography>
                </Box>
              )}
            </Grid>
            <Grid item md={6} className={classes.rightSideContainer}>
              {gettingDiscover ? (
                <Skeleton count={10} height={40} style={{ marginBottom: 15 }} />
              ) : discoverUsers?.length > 0 ? (
                <Grid container className={classes.userDetailsContainer}>
                  <Grid>
                    <Typography fontSize={32}>
                      <span style={{ color: "#ADABAB" }}>@</span>
                      {usernameStringCheck(currentUser?.username)}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography fontSize={13}>
                      {currentUser?.first_name ||
                        currentUser?.first_name ||
                        currentUser?.username ||
                        "--/--"}{" "}
                      {` ${t("isHere")} `}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
                      {currentUser?.mode && (
                        <Box className={classes.modeBox}>
                          {
                            modes.filter(
                              (mode) => mode?.value === currentUser?.mode
                            )[0]?.logo
                          }
                          <Typography style={{ marginLeft: 5 }}>
                            {
                              modes.filter(
                                (mode) => mode?.value === currentUser?.mode
                              )[0]?.title
                            }
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  {currentUser?.bio && (
                    <Grid>
                      <Typography className={classes.detailTitle}>
                        {t("aboutMe")}
                      </Typography>
                      <Typography fontSize={13}>{currentUser?.bio}</Typography>
                    </Grid>
                  )}
                  <Grid>
                    <Typography className={classes.detailTitle}>
                      {t("moreInfo")}
                    </Typography>
                    <Box>
                      <Box className={classes.infoInterestGrid}>
                        {currentUser?.sexuality && (
                          <Box className={classes.infoBox}>
                            <Typography className={classes.infoText}>
                              {
                                sexOptions.filter(
                                  (s) =>
                                    s.value ===
                                    currentUser?.sexuality?.toLowerCase()
                                )[0]?.title
                              }
                            </Typography>
                          </Box>
                        )}

                        {currentUser?.children && (
                          <Box className={classes.infoBox}>
                            <ChildCareIcon className={classes.infoIcon} />
                            <Typography className={classes.infoText}>
                              {
                                childrenOptions.filter(
                                  (s) => s.value === currentUser?.children
                                )[0]?.title
                              }
                            </Typography>
                          </Box>
                        )}
                        {currentUser?.height && (
                          <Box className={classes.infoBox}>
                            <HeightIcon className={classes.infoIcon} />
                            <Typography className={classes.infoText}>
                              {`${toFeetInch(currentUser?.height)} (${
                                (currentUser?.height || 0) / 100
                              }m)`}
                            </Typography>
                          </Box>
                        )}
                        {currentUser?.personality && (
                          <Box className={classes.infoBox}>
                            <FaBrain className={classes.infoIcon} />
                            <Typography className={classes.infoText}>
                              {
                                personalityOptions.filter(
                                  (s) =>
                                    s.value?.toUpperCase() ===
                                    currentUser?.personality?.toUpperCase()
                                )[0]?.title
                              }
                            </Typography>
                          </Box>
                        )}
                        {currentUser?.pet && (
                          <Box className={classes.infoBox}>
                            <PetsIcon className={classes.infoIcon} />
                            <Typography className={classes.infoText}>
                              {
                                petOptions.filter(
                                  (s) => s.value === currentUser?.pet
                                )[0]?.title
                              }
                            </Typography>
                          </Box>
                        )}
                        {currentUser?.religion && (
                          <Box className={classes.infoBox}>
                            <ReligionIcon
                              style={{ width: 20, height: 20 }}
                              className={classes.infoIcon}
                            />
                            <Typography className={classes.infoText}>
                              {
                                religionOptions.filter(
                                  (s) => s.value === currentUser?.religion
                                )[0]?.title
                              }
                            </Typography>
                          </Box>
                        )}
                        {currentUser?.drink && (
                          <Box className={classes.infoBox}>
                            <LiquorIcon className={classes.infoIcon} />
                            <Typography className={classes.infoText}>
                              {
                                drinkOptions.filter(
                                  (s) => s.value === currentUser?.drink
                                )[0]?.title
                              }
                            </Typography>
                          </Box>
                        )}
                        {currentUser?.smoke && (
                          <Box className={classes.infoBox}>
                            <SmokingRoomsIcon className={classes.infoIcon} />
                            <Typography className={classes.infoText}>
                              {
                                smokeOptions.filter(
                                  (s) => s.value === currentUser?.smoke
                                )[0]?.title
                              }
                            </Typography>
                          </Box>
                        )}
                        {currentUser?.zodiac_sign && (
                          <Box className={classes.infoBox}>
                            <FaStarOfDavid className={classes.infoIcon} />
                            <Typography className={classes.infoText}>
                              {
                                zodiacSignOptions.filter(
                                  (s) => s.value === currentUser?.zodiac_sign
                                )[0]?.title
                              }
                            </Typography>
                          </Box>
                        )}
                        {/* <Box>{capitalizeFirst(d)}</Box> */}
                      </Box>
                    </Box>
                  </Grid>
                  {currentUser?.interests?.length > 0 &&
                    currentUser?.interests !== null && (
                      <Grid>
                        <Typography className={classes.detailTitle}>
                          {t("interests")}
                        </Typography>
                        <Box>
                          <Box className={classes.infoInterestGrid}>
                            {currentUser?.interests.map((item, i) => {
                              return (
                                <Box
                                  key={i}
                                  className={classes.infoBox}
                                  style={{
                                    backgroundColor:
                                      colors[
                                        Math.floor(
                                          Math.random() * colors.length
                                        )
                                      ],
                                  }}
                                >
                                  <Typography className={classes.infoText}>
                                    #
                                    {
                                      interestsArray?.filter(
                                        (int) => int?.id === item?.id
                                      )[0]?.name
                                    }
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      </Grid>
                    )}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Discover;
