import { Box, Divider, IconButton, Typography } from "@mui/material";
import React from "react";
import { MappedHeaderStyle } from "./MappedHeader.style";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import { ReactComponent as BoosterIcon } from "../../../../assets/icons/boosters.svg";
import countries from "../../../../constants/countries/countries+states.json";
import UsersFilter from "../../UsersFilter";
import { useLocation } from "react-router-dom";
import CoinsAndMore from "../../../CoinsAndMore";
import { useTranslation } from "react-i18next";
import useConstants from "../../../../utils/useConstants";
import useSettingConstants from "../../../../constants/data/useSettingConstants";
import json from "country-region-data/data.json";
const MappedHeader = ({
  usersDefaultFilter,
  togglePopup,
  setPopupChildren,
}) => {
  const classes = MappedHeaderStyle();
  const location = useLocation();
  const page = location?.pathname?.split("/")[1];
  const { t } = useTranslation();
  const { modes, usersCategoryOptions, genderOptions } = useConstants();
  const { interestsArray } = useSettingConstants();

  return (
    <Box className={classes.wrapper} sx={{ display: "flex" }}>
      <Box sx={{ display: "flex" }}>
        {usersDefaultFilter?.country != "" && (
          <Typography className={classes.text}>
            {
              json.filter(
                (item) => item?.countryShortCode == usersDefaultFilter?.country
              )[0]?.countryName
            }
          </Typography>
        )}
        {usersDefaultFilter?.state && (
          <>
            , &nbsp;
            {/* <Divider
              color="#000"
              style={{ margin: "7px 15px" }}
              orientation="vertical"
              variant="middle"
              size="small"
              flexItem
            /> */}
            <Typography className={classes.text}>
              {
                json
                  .filter(
                    (item) =>
                      item?.countryShortCode == usersDefaultFilter?.country
                  )[0]
                  .regions.filter((item) =>
                    [item?.name, item?.shortCode].some((item) =>
                      usersDefaultFilter?.state.split(",").includes(item)
                    )
                  )[0]?.name
              }
            </Typography>
          </>
        )}
        {usersDefaultFilter?.mode && (
          <>
            <Divider
              color="#000"
              style={{ margin: "7px 15px" }}
              orientation="vertical"
              variant="middle"
              size="small"
              flexItem
            />
            <Typography className={classes.text}>
              {
                modes.filter(
                  (item) => item?.value === usersDefaultFilter?.mode
                )[0].title
              }
            </Typography>
          </>
        )}
        {usersDefaultFilter?.gender && (
          <>
            <Divider
              color="#000"
              style={{ margin: "7px 15px" }}
              orientation="vertical"
              variant="middle"
              size="small"
              flexItem
            />
            <Typography className={classes.text}>
              {genderOptions.filter(
                (item) => item?.value === usersDefaultFilter?.gender
              )[0].value === "both"
                ? t("maleFemale")
                : genderOptions.filter(
                    (item) => item?.value === usersDefaultFilter?.gender
                  )[0].label}
            </Typography>
          </>
        )}
        {usersDefaultFilter?.category && (
          <>
            <Divider
              color="#000"
              style={{ margin: "7px 15px" }}
              orientation="vertical"
              variant="middle"
              size="small"
              flexItem
            />
            <Typography className={classes.text}>
              {
                usersCategoryOptions.filter(
                  (item) => item?.value === usersDefaultFilter?.category
                )[0].label
              }
            </Typography>
          </>
        )}
        {usersDefaultFilter?.interests.length > 0 && (
          <>
            <Divider
              color="#000"
              style={{ margin: "7px 15px" }}
              orientation="vertical"
              variant="middle"
              size="small"
              flexItem
            />

            <Typography className={classes.text}>
              {String(
                interestsArray
                  .filter((val) =>
                    usersDefaultFilter.interests
                      .map((selected) => selected?.id)
                      .includes(val?.id)
                  )
                  .map((item) => item?.name)
              )}
            </Typography>
          </>
        )}
      </Box>
      <Box style={{ gap: 12, display: "flex" }}>
        <IconButton
          onClick={() => {
            setPopupChildren(<UsersFilter page={page} />);
            togglePopup(true);
          }}
        >
          <FilterIcon
            style={{
              padding: 5,
              borderRadius: 3,
              backgroundColor: "#F7F7F7",
              width: 15,
              height: 15,
            }}
          />
        </IconButton>
        <IconButton
          onClick={() => {
            setPopupChildren(<CoinsAndMore />);
            togglePopup(true);
          }}
        >
          <BoosterIcon width={20} height={20} stroke="#000" fill="#000" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default MappedHeader;
