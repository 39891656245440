import React from "react";
import { useMatchStyles } from "./Match.style";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as MatchedFoundImg } from "../../../../assets/icons/matchedImg.svg";
import { ReactComponent as Person } from "../../../../assets/icons/person1.svg";
import { ReactComponent as M8SIcon } from "../../../../assets/icons/m8s logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  filteredChat,
  getToken,
  setUser,
  usernameStringCheck,
} from "../../../../utils/helpers";
import socketService from "../../../../services/socket.service";
import { toast } from "react-toastify";
import UserImgComponent from "../../../../components/UserImgComponent";
import chatsService from "../../../../services/chats.service";
import {
  setChats,
  setMessagesInView,
  setMessagingUser,
} from "../../../../redux/actions/chats/chats.actions";
import { togglePopup } from "../../../../redux/actions/global/global.actions";
import usersService from "../../../../services/users.service";
import { setMyData } from "../../../../redux/actions/users/users.actions";
import { useNavigate } from "react-router-dom";

export default function Match({ match, setShowUsersMatch }) {
  const classes = useMatchStyles();
  const { discoverUsers, myData } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const index = discoverUsers?.findIndex((user) => user?.id === match?.id);
  const token = getToken();
  const newlyMatchedUser = discoverUsers[index - 1];
  const chatsFilterVal = useSelector((state) => state.chats.chatsFilterVal);

  const navigate = useNavigate();
  const greetings = [
    t("howdy"),
    t("heyThere"),
    `${t("hello")} ${usernameStringCheck(newlyMatchedUser?.username)}, ${t(
      "hopeGreat"
    )}`,

    `${t("hi")} ${usernameStringCheck(newlyMatchedUser?.username)}, ${t(
      "howEverything"
    )}`,

    `${t("hello")}, ${t("goodDay")}`,
    t("hopeSmoothly"),
  ];

  const handleSelectSuggestion = (receiver, textMessage) => {
    socketService.emit(
      "message",
      {
        text: textMessage,
        receiver: receiver?.id,
        type: "text",
      },
      (res) => {
        if (res.status) {
          getUser(receiver?.id);

          getMe();
          dispatch(togglePopup(false));
          getMessagesWithUser(receiver?.id);
        }
      }
    );
  };

  const getMe = () => {
    usersService.getMe(token).then(({ data }) => {
      dispatch(setMyData(data));
      setUser(data);
    });
  };

  const getUser = (id) => {
    usersService
      .getUser(token, id)
      .then((res) => {
        if (res?.success === true) {
          dispatch(setMessagingUser(res?.data));
          navigate("/chats");
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => toast.error(err))
      .finally(() => {});
  };

  const getMessagesWithUser = (userId) => {
    dispatch(setMessagesInView([]));
    chatsService
      .getMessagesWithUser(token, userId, 1, 25)
      .then((res) => {
        if (res?.success === true) {
          dispatch(setMessagesInView(res?.data?.data));
          getChats();
        }
      })
      .catch((err) => toast.error(err));
  };

  const getChats = () => {
    chatsService
      .getChats(token)
      .then((res) => {
        if (res?.success === true) {
          dispatch(setChats(filteredChat(res?.data?.data, chatsFilterVal)));
        }
      })
      .catch((err) => console.log("err are ", err))
      .finally(() => {});
  };

  return (
    <Box className={classes.Wrapper}>
      <Typography
        onClick={() => setShowUsersMatch(false)}
        className={classes.continue}
      >
        {t("continueSwipe")}
      </Typography>
      <Box>
        <Box className={classes.header}>
          <MatchedFoundImg />
        </Box>
      </Box>
      <Box className={classes.MatchFound}>
        <Box className={classes.person1}>
          <UserImgComponent
            style={{
              width: 170,
              height: "auto",
              display: "block",
              borderRadius: 170,
            }}
            src={newlyMatchedUser?.avatar}
          />
        </Box>
        <Box className={classes.m8s}>
          <M8SIcon />
        </Box>
        <Box className={classes.person2}>
          <UserImgComponent
            src={myData?.avatar}
            style={{
              width: 170,
              height: "auto",
              display: "block",
              borderRadius: 170,
            }}
          />
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.info}>
          <Typography className={classes.info1}>{t("have24hrs")}</Typography>
          <Typography className={classes.info2}>
            {`${t("itsMatch")} `}{" "}
            <span style={{ fontSize: 15, color: "#C7C7C7" }}>@</span>
            <span
              style={{ fontWeight: 700, fontSize: 15 }}
            >{`${usernameStringCheck(newlyMatchedUser?.username)}`}</span>
            {` ${t("likeBack")}`}
          </Typography>
          <Typography className={classes.info2}>
            {`${t("getSparks")} `}
          </Typography>
        </Box>
        <Box style={{ width: "100%", overflow: "auto" }}>
          <Box className={classes.messageContainer}>
            {greetings?.map((item, index) => (
              <Button
                key={index}
                className={classes.MessageBtn}

                onClick={() =>
                  handleSelectSuggestion(newlyMatchedUser, item)
                }
              >
                {item}
              </Button>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
