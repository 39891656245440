import { makeStyles } from '@mui/styles';
import React from 'react'

export const InfoRenderComp = ({icon, title, content}) => {
    const classes = styles();

  return (
    <div className={classes.container}>
        <div className={classes.rowView}>
            <img src={require("../../../assets/icons/chat-red.png")} className={classes.img} />
            <p className={classes.txt}>{title}</p>
        </div>
        <p className={classes.txt}>{content}</p>
    </div>
  )
}

const styles = makeStyles((theme) => ({
    container: {
        display: "flex",
        width: "90%",
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    rowView: {
        display: "flex",
        alignItems: 'center',
    },
    img: {
       width: 15,
       height: 15,
       objectFit: "contain",
       marginRight: 10,
    },
    txt: {
        fontWeight: "500",
        fontSize: 13
    },
}));