import { connect } from "react-redux";
import {
  setChats,
  setMessagingUser,
} from "../../redux/actions/chats/chats.actions";
import Chats from "./Chats";

const mapState = ({ chats, global }) => ({
  chats: chats.chats,
  messagingUser: chats.messagingUser,
  socket: global.socket,
  chatsFilterVal: chats.chatsFilterVal,
});
const mapDispatchToProps = (dispatch) => ({
  setChats: (data) => dispatch(setChats(data)),
  setMessagingUser: (data) => dispatch(setMessagingUser(data)),
});
export default connect(mapState, mapDispatchToProps)(Chats);
