import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
  Wrapper: {
    background: "#F4F5F9",
  },
  select: {
    "& .MuiInputBase-root, .MuiOutlinedInput-input": {
      padding: "8px 4px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));
