import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PaymentCheckout from "../../../pages/PaymentCheckout";
import {
  setGettingMemberships,
  setMemberships,
  setPopupChildren,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import globalService from "../../../services/global.service";
import { getToken } from "../../../utils/helpers";
import useConstants from "../../../utils/useConstants";

const useMemberships = () => {
  const [subscribing, setSubscribing] = useState(false);
  const dispatch = useDispatch();
  const token = getToken();
  const { memberships } = useConstants();
  const myData = useSelector((state) => state.users.myData);
  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    setCurrentPlan(myData?.membership?.id);
  }, [myData]);
  const getMembership = () => {
    dispatch(setGettingMemberships(true));

    globalService
      .getMembership()
      .then(({ data }) => {
        dispatch(setMemberships(data));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingMemberships(false)));
  };

  const onSelectMembership = (id, screen) => {
    setSubscribing(true);
    globalService
      .selectMembership(token, id)
      .then(({ data, success, message, errors }) => {
        if (success === true) {
          dispatch(
            setPopupChildren(
              <PaymentCheckout
                clientSecret={data?.client_secret}
                isMembership={true}
                membership={memberships.filter((coin) => coin?.id === id)[0]}
              />
            )
          );
          dispatch(togglePopup(true));
        } else {
          toast.error(message || errors || null);
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        setSubscribing(false);
      });
  };
  return { onSelectMembership, subscribing, getMembership, currentPlan };
};

export default useMemberships;
