import { connect } from "react-redux";
import {
  setChats,
  setMessagesInView,
} from "../../../../redux/actions/chats/chats.actions";
import MessageSection from "./MessageSection";

const mapState = ({ chats, global }) => ({
  messagingUser: chats.messagingUser,
  messagesInView: chats.messagesInView,
  socket: global.socket,
  chats: chats.chats,
});

const mapDispatchToProps = (dispatch) => ({
  setMessagesInView: (data) => dispatch(setMessagesInView(data)),
  setChats: (data) => dispatch(setChats(data)),
});
export default connect(mapState, mapDispatchToProps)(MessageSection);
