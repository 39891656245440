import React, { useState } from "react";
import { CustomTableStyles } from "./CustomTable.style";
// import LineLoader from "../LineLoader";
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";

function CustomTable({
  stickyHeader,
  appTheme,
  data,
  style,
  onSortBy,
  noSortByIndexs,
  indexOfMoney,
  noSort,
  isTable2,
  isLoading,
}) {
  const classes = CustomTableStyles({ isTable2 });
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [sortByIndex, setSortByIndex] = useState(0);
  const [isDesending, setIsDesending] = useState(false);

  const icon = (index) =>
    isDesending && index === sortByIndex ? (
      <ExpandMoreOutlined style={{ fontSize: 16 }} />
    ) : (
      <ExpandLessOutlined style={{ fontSize: 16 }} />
    );

  const onSort = (index) => {
    setSortByIndex(index);
    setIsDesending(!isDesending);
    onSortBy?.(index, isDesending);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      data?.rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [data?.rows, page, rowsPerPage]
  );

  return (
    <>
      <TableContainer className={classes.tableWrapper} style={style}>
        <Table
          stickyHeader={stickyHeader}
          className={classes.table}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {data?.th?.map((item, index) => (
                <TableCell
                  className={classes.tableHeadCell}
                  style={{
                    borderBottom: "none",
                    backgroundColor: "#EA0F08",
                  }}
                  align="left"
                  key={`table-header-${index}`}
                >
                  <div
                    className={`${classes.tableHeadName} ${
                      !indexOfMoney || !indexOfMoney[index.toString()]
                        ? ""
                        : "money-type"
                    }`}
                  >
                    {item}{" "}
                    {(!noSortByIndexs || !noSortByIndexs[index.toString()]) &&
                      !noSort && (
                        <ButtonBase
                          className="sortBtn"
                          onClick={() => onSort(index)}
                          style={{
                            opacity: sortByIndex === index ? 1 : undefined,
                            fontWeight:
                              sortByIndex === index ? "800" : undefined,
                          }}
                        >
                          {icon(index)}
                        </ButtonBase>
                      )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {visibleRows?.map((row, index) => (
              <>
                <tr className={classes.divider} />
                <TableRow
                  style={{}}
                  hover
                  key={`table-row-${index}`}
                  classes={{
                    hover: classes.tableRowHover,
                  }}
                >
                  {row?.map((item, i) => (
                    <TableCell
                      component="th"
                      scope="row"
                      key={`table-cell-${i}`}
                      className={classes.tableCell}
                    >
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
        {/* <LineLoader
        style={{ position: "absolute", top: 45 }}
        isLoading={isLoading}
      /> */}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={data?.rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default CustomTable;
