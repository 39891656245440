import React from "react";
import { Box } from "@mui/system";
import FormField from "../../../../components/FormField";
import { Typography } from "@mui/material";
import useStyles from "../Login/Login.styles";
import Logo from "../../../../resources/img/M8s social white background.png";
import CustomButton from "../../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../../../../pages/Authentication/hooks/useAuthentication";

function ChangePassword() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { state, dispatch, handleChangePassword } = useAuthentication();

  const { password, cPassword, isLoading } = state;

  const isDisabled = (password && cPassword) === "";

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageBox}>
        <Box className={classes.logo}>
          <img src={Logo} alt="m8t logo" />
        </Box>
        <Box marginTop="-20px" marginBottom="20px">
          <Typography className={classes.headerRed}>Forgot Password</Typography>
        </Box>

        <form className={classes.formBox} onSubmit={handleChangePassword}>
          <Box>
            <FormField
              label="Password"
              isPassword
              value={password}
              handleChange={(e) => dispatch({ password: e.target.value })}
            />
          </Box>
          <Box>
            <FormField
              label="Confirm Password"
              isPassword
              value={cPassword}
              handleChange={(e) => dispatch({ cPassword: e.target.value })}
            />
          </Box>
          <Box marginTop="15px">
            <CustomButton
              type="submit"
              loading={!!isLoading}
              disabled={!!isDisabled}
            >
              Reset Password
            </CustomButton>
          </Box>

          <Box marginBottom="20px">
            <Typography className={classes.formText}>
              Already a member?{" "}
              <span
                onClick={() => navigate("/auth/login")}
                className={classes.formLink}
              >
                Sign in
              </span>
            </Typography>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default ChangePassword;
