import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Gifts from "../../../../../../../components/Gifts";
import {
  setChats,
  setMessagesInView,
} from "../../../../../../../redux/actions/chats/chats.actions";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../../../../redux/actions/global/global.actions";
import chatsService from "../../../../../../../services/chats.service";
import { videoExts, imageExts } from "../../../../../../../utils/constants";
import {
  fileType,
  filteredChat,
  getToken,
  usernameStringCheck,
} from "../../../../../../../utils/helpers";
import MicRecorder from "mic-recorder-to-mp3";
import { useStopwatch } from "react-timer-hook";
import socketService from "../../../../../../../services/socket.service";
import { useTranslation } from "react-i18next";

const useMessageFooter = ({ messageRef, setMessageRef }) => {
  const dispatch = useDispatch();
  const [textMessage, setTextMessage] = useState("");
  const [sendingText, setSendingText] = useState(false);

  const [recording, setRecording] = useState(false);
  const { t } = useTranslation();
  const messagesInView = useSelector((state) => state.chats.messagesInView);
  const messagingUser = useSelector((state) => state.chats.messagingUser);
  const chats = useSelector((state) => state.chats.chats);
  const chatsFilterVal = useSelector((state) => state.chats.chatsFilterVal);
  const [fileToAccept, setFileToAccept] = useState(null);
  const chattedUsers = chats.map((chat) => chat.user.id);
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaMessage, setMediaMessage] = useState(null);
  const [sendingMedia, setSendingMedia] = useState(false);
  const [fileExt, setFileExt] = useState("");
  const [recorded, setRecorded] = useState(null);
  const myData = useSelector((state) => state.users.myData);
  const greetings = [
    `${t("hello")} ${usernameStringCheck(messagingUser?.username)}, ${t(
      "hopeGreat"
    )}`,
    t("howdy"),
    `${t("hi")} ${usernameStringCheck(messagingUser?.username)}, ${t(
      "howEverything"
    )}`,
    t("heyThere"),
    `${t("hello")}, ${t("goodDay")}`,
    t("hopeSmoothly"),
  ];

  const [Mp3Recorder] = useState(new MicRecorder({ bitRate: 128 }));

  const token = getToken();

  const { seconds, minutes, hours, start, pause } = useStopwatch({
    autoStart: false,
  });
  const onOpenGifts = (user) => {
    dispatch(setPopupChildren(<Gifts reciever={user} goTo="messages" />));
    dispatch(togglePopup(true));
  };
  const handleOnDrop = (acceptedFiles) => {
    const filteredFiles = acceptedFiles.filter((file) => {
      const extension = file.name
        .substring(file.name.lastIndexOf("."))
        .toLowerCase();
      if (file.type.startsWith("video/")) {
        return videoExts.includes(extension);
      } else if (file.type.startsWith("image/")) {
        return imageExts.includes(extension);
      }
      return false;
    });
    setFileExt(`.${filteredFiles[0].name.split(".").pop()}`);
    const file = URL.createObjectURL(filteredFiles[0]);
    getBase64(filteredFiles[0]);
    setMediaFile(file);
  };
  const { open } = useDropzone({
    accept: [...videoExts, ...imageExts],
    multiple: false,
    maxSize: 15728640,
    minSize: 0,
    onDrop: handleOnDrop,
    onFileDialogOpen: () => setFileToAccept(null),
    onError: () => setFileToAccept(null),
  });

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setMediaMessage(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  const getChats = () => {
    chatsService
      .getChats(token)
      .then((res) => {
        if (res?.success === true) {
          dispatch(setChats(filteredChat(res?.data?.data, chatsFilterVal)));
        }
      })
      .catch((err) => console.log("err are ", err))
      .finally(() => {});
  };
  const onEmitTypingAction = (receiverId, status) => {
    socketService.emit(
      "typing",
      {
        receiver: receiverId,
        status: status,
      },
      (res) => {}
    );
  };
  const onTyping = (e) => {
    setTextMessage(e);
  };

  const handleKeyDown = (e, receiverId) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      mediaMessage ? onSendMedia() : onSubmitMessage(receiverId);
    }
  };
  const onSubmitMessage = (receiverId) => {
    if (textMessage.trim().length === 0) return false;
    let ref;
    const sender = messageRef?.sender ? myData?.id : receiverId;
    if (messageRef) {
      if (messageRef?.text?.length) {
        ref = `${messageRef?.id}|${messageRef?.text?.substring(
          0,
          25
        )}|${sender}`;
      } else if (messageRef?.media) {
        const file =
          fileType(messageRef?.media) == "video"
            ? `...🎥${t("video")}...`
            : fileType(messageRef?.media) == "image"
            ? `...📸${t("image")}...`
            : fileType(messageRef?.media) == "audio"
            ? `...🎞${t("audio")}...`
            : `...📎${t("file")}...`;
        ref = `${messageRef?.id}|${file}|${sender}`;
      } else {
        ref = `${messageRef?.id}|...|${sender}`;
      }
    }
    setSendingText(true);
    socketService.emit(
      "message",
      {
        text: textMessage,
        receiver: receiverId,
        type: "text",
        ...(ref && { ref }),
      },
      (res) => {
        if (res.status) {
          setSendingText(false);
          setTextMessage("");
          setMessageRef(null);
          dispatch(setMessagesInView([res?.data, ...messagesInView]));
          const newChats = chats?.map((chat) =>
            chat?.user?.id === messagingUser?.id
              ? {
                  ...chat,
                  last_message: res?.data,
                }
              : chat
          );
          const chatToMoveTop = newChats?.filter(
            (chat) => chat?.user?.id === messagingUser?.id
          );

          const otherChats = newChats?.filter(
            (chat) => chat?.user?.id !== messagingUser?.id
          );

          dispatch(setChats([...chatToMoveTop, ...otherChats]));

          if (!chattedUsers.includes(messagingUser?.id)) {
            getChats();
          }
        } else {
          setSendingText(false);
          toast.error("Unable to send message");
        }
      }
    );
  };

  const onSendMedia = () => {
    if (!mediaMessage || fileExt.trim().length < 1)
      return toast.error("Attach a valid media file");

    try {
      setSendingMedia(true);

      socketService.emit(
        "message",
        {
          content: mediaMessage,
          receiver: messagingUser?.id,
          type: "media",
          ext: fileExt,
          ...(textMessage.trim().length > 0 && { text: textMessage }),
        },
        (res) => {
          if (res.status === true) {
            onRemoveMedia();
            setTextMessage("");
            dispatch(setMessagesInView([res?.data, ...messagesInView]));

            const newChats = chats?.map((chat) =>
              chat?.user?.id === messagingUser?.id
                ? {
                    ...chat,
                    last_message: res?.data,
                  }
                : chat
            );
            const chatToMoveTop = newChats?.filter(
              (chat) => chat?.user?.id === messagingUser?.id
            );

            const otherChats = newChats?.filter(
              (chat) => chat?.user?.id !== messagingUser?.id
            );

            dispatch(setChats([...chatToMoveTop, ...otherChats]));
            if (!chattedUsers.includes(messagingUser?.id)) {
              getChats();
            }
            // if (route?.name != "message-screen") {
            //   navigation.goBack();
            // }
            // setRecordedAudio({});
            onRemoveMedia();
          } else {
            toast.error("Unable to send media");
            setSendingMedia(false);
          }
          setSendingMedia(false);
        }
      );
    } catch (error) {
      toast.error(error.message);
      setSendingMedia(false);
    }
  };
  const onRemoveMedia = () => {
    setMediaFile(null);
    setMediaMessage(null);
    setFileExt("");
    setFileToAccept(null);
    setRecorded(null);
    // reset();
  };

  const startRecording = () => {
    // setRecording(true);
    navigator.getUserMedia(
      { audio: true },
      () => {
        Mp3Recorder.start()
          .then(() => {
            setRecording(true);
            start();
          })
          .catch((e) => console.error(e));
      },
      () => {
        console.log("Permission Denied");

        return false;
      }
    );
  };

  const stopRecording = () => {
    // setRecording(false);
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setFileExt(`.${blob?.type.split("/").pop()}`);
        getBase64(blob);
        const blobUrl = URL.createObjectURL(blob);
        setRecorded(blobUrl);
        pause();
        setRecording(false);
      })
      .catch((e) => console.log(e));
  };

  return {
    onOpenGifts,
    onEmitTypingAction,
    onTyping,
    textMessage,
    sendingText,
    onSubmitMessage,
    setTextMessage,
    open,
    setFileToAccept,
    fileToAccept,
    mediaFile,

    onSendMedia,
    mediaMessage,
    sendingMedia,
    fileExt,
    onRemoveMedia,
    startRecording,
    stopRecording,

    recording,
    recorded,
    seconds,
    minutes,
    hours,
    handleKeyDown,
    greetings,
  };
};

export default useMessageFooter;
