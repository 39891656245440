import React, { Component, useReducer } from "react";
import Login from "../../../layout/AuthLayout/components/Login";
import SignUp from "../../../layout/AuthLayout/components/SignUp";
import ForgotPassword from "../../../layout/AuthLayout/components/ForgotPassword";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import authService from "../../../services/auth.service";
import { toast } from "react-toastify";
import VerifyEmail from "../../../layout/AuthLayout/components/VerifyEmail";
import ChangePassword from "../../../layout/AuthLayout/components/ChangePassword";
import usersService from "../../../services/users.service";
import {
  getToken,
  setLocalLang,
  setToken,
  setUser,
} from "../../../utils/helpers";
import { useDispatch } from "react-redux";
import { setMyData } from "../../../redux/actions/users/users.actions";
import { requestForToken } from "./../../../firebase";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function useAuthentication() {
  const { page } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const splitLocation = location.pathname?.split("/");
  const reduxDispatcher = useDispatch();
  const { t } = useTranslation();
  const initState = {
    email: "",
    password: "",
    cPassword: "",
    lang: "",
    resetEmail: "",
    verifyPin: "",
    ref: "",
    isLoading: false,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initState
  );

  const { email, password, cPassword, lang, resetEmail, verifyPin, ref } =
    state;

  switch (page) {
    case "login":
      Component = <Login />;
      break;
    case "signup":
      Component = <SignUp />;
      break;
    case "forgot":
      Component = <ForgotPassword />;
      break;
    case "verify":
      Component = <VerifyEmail />;
      break;
    case "reset":
      Component = <VerifyEmail isReset />;
      break;
    case "changePassword":
      Component = <ChangePassword />;
      break;
    default:
      break;
  }

  function handleLogin(e) {
    e.preventDefault();
    if (password.length < 8) {
      toast.error(t("passwordChar"));
    } else {
      dispatch({ isLoading: true });
      authService
        .login(email, password)
        .then((response) => {
          toast.success(t("successfulLogin"));
          getMe(response?.data?.token);
          setToken(response?.data?.token);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.errors || error?.message);
          if (error?.errors.includes("Please verify your email to continue")) {
            navigate("/auth/verify", { state: { email } });
          }
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  }
  function handleCreate(e) {
    e.preventDefault();
    if (password.length < 8) {
      toast.error(t("passwordChar"));
    } else if (password !== cPassword) {
      toast.error(t("passwordMismatch"));
    } else {
      dispatch({ isLoading: true });
      authService
        .create(email, password, cPassword, lang, ref)
        .then((response) => {
          toast.success(t("regSuc"));
          setLocalLang(lang.toLowerCase());
          i18next.changeLanguage(lang.toLowerCase());
          navigate("/auth/verify", { state: { email } });
        })
        .catch((error) => {
          toast.error(error.errors);
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  }
  function handleVerify(e, location) {
    e.preventDefault();
    dispatch({ isLoading: true });
    authService
      .verifyOtp(location, verifyPin)
      .then((response) => {
        toast.success("Email Verified");
        dispatch({ email: " ", password: " " });
        setToken(response.data.token);
        navigate("/onboarding/personal");
      })
      .catch((error) => {
        toast.error("Invalid OTP");
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }

  function handleResendOtp(e, location) {
    e.preventDefault();
    dispatch({ isLoading: true });
    authService
      .resendOtp(location)
      .then((response) => {
        toast.success("OTP Sent");
      })
      .catch((error) => {
        toast.error("Please Retry");
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }

  function handleForgotPassword(e) {
    e.preventDefault();
    dispatch({ isLoading: true });
    authService
      .forgotPassword(resetEmail)
      .then((response) => {
        toast.success("Otp Sent");
        navigate("/auth/reset", { state: { resetEmail } });
      })
      .catch((error) => {
        toast.error(error.errors);
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }
  function handleForgotResendOtp(e, location) {
    e.preventDefault();
    dispatch({ isLoading: true });
    authService
      .forgotPassword(location)
      .then((response) => {
        toast.success("OTP Sent");
      })
      .catch((error) => {
        toast.error("Please Retry");
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }

  function handleResetPassword(e, location) {
    e.preventDefault();
    dispatch({ isLoading: true });
    authService
      .resetPassword(location, verifyPin)
      .then((response) => {
        toast.success("Email Verified");
        setToken(response.data.token);
        navigate("/auth/changePassword");
      })
      .catch((error) => {
        toast.error("Invalid OTP");
      })
      .finally(() => {
        dispatch({ isLoading: false });
      });
  }
  function handleChangePassword(e) {
    e.preventDefault();
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters");
    } else if (password !== cPassword) {
      toast.error("Passwords don't match");
    } else {
      dispatch({ isLoading: true });
      const token = getToken();
      const type = "FORGET";
      authService
        .changePassword(type, password, token)
        .then((response) => {
          toast.success("Password Changed");
          dispatch({ password: "", cPassword: "" });
          navigate("/auth/login");
        })
        .catch((error) => {
          toast.error(error);
        })
        .finally(() => {
          dispatch({ isLoading: false });
        });
    }
  }

  const getMe = (token) => {
    usersService.getMe(token).then(({ data }) => {
      const email = data?.email;
      try {
        setLocalLang(data?.language?.toLowerCase());
        i18next.changeLanguage(data?.language?.toLowerCase());
        if (
          (splitLocation[1] =
            "admin" && data?.role === ("superadmin" || "admin"))
        ) {
          if (!data.verifiedEmail) {
            handleResendOtp(null, data?.email);
            navigate("/auth/verify", { state: { email } });
          } else {
            navigate("/admin/dashboard");
          }
        } else {
          if (!data.verifiedEmail) {
            handleResendOtp(null, data?.email);
            navigate("/auth/verify", { state: { email } });
          } else if (data.first_name === null) {
            navigate("/onboarding/personal");
            // }
            // else if (!data.active_subscription) {
            //   navigate("/onboarding/plans");
          } else if (data?.interests?.length === 0) {
            navigate("/onboarding/interest");
          } else if (data?.avatar === null || !data?.avatar) {
            navigate("/onboarding/verification");
          } else {
            setToken(token);
            setUser(data);
            reduxDispatcher(setMyData(data));

            navigate("/feeds");
            requestForToken().then((res) => {
              if (res.length > 0) {
                usersService
                  .setFCMToken(token, res)
                  .then((res) => console.log(res));
              }
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  return {
    Component,
    state,
    dispatch,
    handleLogin,
    handleCreate,
    handleVerify,
    handleResendOtp,
    handleForgotPassword,
    handleResetPassword,
    handleForgotResendOtp,
    handleChangePassword,
  };
}

export default useAuthentication;
