import React from "react";
import { togglePopup } from "../../redux/actions/global/global.actions";
import useMediaPreview from "./hooks/useMediaPreview";
import useStyles from "./MediaPreviewStyle";
import { Box, Button, IconButton } from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import useAccountProfile from "../../pages/Settings/components/AccountProfile/hooks/useAccountProfile";
import { useTranslation } from "react-i18next";
import { Circles } from "react-loader-spinner";

const MediaPreview = ({ file, fileType }) => {
  const { reduxDispatch, state } = useMediaPreview();
  const { onDeleteImage, loading, onSetAsMain, onDeleteVid } =
    useAccountProfile();
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.postModal}>
      <div className={classes.topHalf}>
        <div className={classes.topHead}>
          <IconButton
            className={classes.close}
            onClick={() => reduxDispatch(togglePopup(false))}
            style={{ backgroundColor: "#fff" }}
          >
            <HighlightOff color="#fff" />
          </IconButton>
        </div>

        <div className={classes.fileContainer}>
          {loading && (
            <Box className={classes.loader}>
              <Circles
                color="red"
                ariaLabel="circles-loading"
                wrapperClass={classes.topSpinner}
                visible={true}
              />
            </Box>
          )}
          {fileType === "video" ? (
            <video
              autoPlay={true}
              controls
              onTouchStart={true}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            >
              <source src={file?.url} />
            </video>
          ) : (
            <img
              src={file?.url}
              alt={""}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </div>
      </div>
      <Box className={classes.btnsContainer}>
        <Button
          onClick={() => onSetAsMain(file?.id)}
          disabled={file?.isMain ? true : fileType === "video" ? true : loading}
          className={classes.setProfileBtn}
          style={{
            opacity: file?.isMain ? 0.5 : fileType === "video" ? 0.5 : 1,
          }}
        >
          {loading ? (
            <Circles
              height={15}
              width={15}
              color="#fff"
              ariaLabel="circles-loading"
              visible={true}
            />
          ) : (
            t("setPhoto")
          )}
        </Button>

        <Button
          disabled={loading}
          onClick={() =>
            fileType === "video" ? onDeleteVid() : onDeleteImage(file?.id)
          }
          className={classes.deleteBtn}
        >
          {loading ? (
            <Circles
              height={15}
              width={15}
              color="#fff"
              ariaLabel="circles-loading"
              visible={true}
            />
          ) : (
            t("delete")
          )}
        </Button>
      </Box>
    </div>
  );
};

export default MediaPreview;
