import React from "react";
import { Box } from "@mui/system";
import CustomButton from "../../../components/CustomButton";
import useStyles from "../PersonalDetails/PersonalDetails.styles";
import MembershipCard from "../../../components/Memberships/components/MembershipCard";
import { useSelector } from "react-redux";
import useOnboarding from "./../hooks/useOnboarding";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from "react-i18next";
import useSettingConstants from "../../../constants/data/useSettingConstants";

function Plans() {
  const classes = useStyles();

  const { createMembership, state, dispatch, navigate } = useOnboarding();
  const { membership, isLoading } = state;
  const { t } = useTranslation();
  const { memberships } = useSettingConstants();
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageBox}>
        <form
          className={classes.formBox}
          onSubmit={() =>
            membership == 0 ? navigate("/onboarding/mode") : createMembership()
          }
        >
          <Box className={classes.headClass}>
            <Box className={classes.headerText}>
              <span className={classes.header}>{t("welcomeToM8s")}</span>
              <span className={classes.sub}>{t("knowMore")}</span>
            </Box>
            <Box
              sx={{
                width: 52,
                height: 52,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CircularProgressbar
                value={40}
                text={`40%`}
                strokeWidth={15}
                styles={buildStyles({
                  textColor: "#0A0A12",
                  pathColor: "#EA0F08",
                  trailColor: "#f4f5f9",
                  textSize: "25px",
                })}
              />
            </Box>
          </Box>

          {[
            {
              id: 0,
              name: t("free"),
              description: t("getToSee"),
              price: {
                recurring: {
                  interval: t("month"),
                  interval_count: 1,
                },
                unit_amount: 0,
              },
            },
            ...memberships,
          ].map((member, i) => (
            <Box sx={{ width: "350px" }}>
              <MembershipCard
                membership={member}
                i={i}
                key={i}
                selected={membership}
                width="100%"
                onClick={() => {
                  dispatch({ membership: member?.id });
                }}
              />
            </Box>
          ))}
          <Box className={classes.continueBtn}>
            <CustomButton type="submit" loading={!!isLoading}>
              {membership == 0 ? t("proceed") : t("confirmPayment")}
            </CustomButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default Plans;
