import { makeStyles } from "@mui/styles";
import bgImage from "../../../assets/images/bg_modal.png";

export const useUpgradeModal = makeStyles(({ theme }) => ({
  Wrapper: {
    backgroundImage: `url(${bgImage})`,
    height: "100%",
    width: "300px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "#FFF",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: "2rem 2rem",
    gap: "1rem",
    borderRadius: "10px",
  },
  closeBtn: {
    position: "absolute",
    top: "20px",
    right: "20px",
    cursor: "pointer",
  },
  mainBtn: {
    background: "#FFF !important",
    color: "#EA0F08 !important",
    borderRadius: "0 !important",
    fontSize: "12px !important",
    textTransform: "initial !important",
    fontWeight: "500 !important",
  },
  IconContainer: {
    background: "#FFE7E6",
    display: "grid",
    placeItems: "center",
    width: "20px",
    height: "20px",
    borderRadius: "100%",
    "& svg": {
      width: "10px",
      height: "10px",
      objectFit: "contain",
    },
  },
  title: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  list: {
    display: "flex",
    alignItems: "center",
    gap: ".8rem",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
}));
