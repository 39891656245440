import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setGettingMates,
  setMates,
  setPreviewUser,
  setWhoLikesMe,
} from "../../../redux/actions/users/users.actions";
import {
  setMessagingUser,
  setMessagesInView,
  setChats,
} from "../../../redux/actions/chats/chats.actions";
import usersService from "../../../services/users.service";
import chatsService from "../../../services/chats.service";

import { getToken } from "../../../utils/helpers";
import {
  setPopupChildren,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import Gifts from "../../Gifts";
import { useLocation } from "react-router-dom";

const useUserPreview = () => {
  const dispatch = useDispatch();
  const previewUser = useSelector((state) => state.users.previewUser);
  const chats = useSelector((state) => state.chats.chats);
  const token = getToken();
  const location = useLocation();
  const toggleUserLike = (id) => {
    dispatch(
      setPreviewUser({
        ...previewUser,
        liked: !previewUser.liked,
        mate: previewUser?.likes_you && !previewUser.liked,
      })
    );
    usersService
      .toggleUserLike(token, id)
      .then((res) => {
        if (location?.pathname?.split("/")[1] == "matches") {
          getMates();
          getUsersWholikesMe();
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const getMessagesWithUser = (userId) => {
    dispatch(setMessagesInView());

    chatsService
      .getMessagesWithUser(token, userId, 1, 25)
      .then((res) => {
        console.log(res, "here");
        if (res?.success === true) {
          dispatch(setMessagesInView(res?.data?.data));
          dispatch(
            setChats(
              chats?.map((chat) =>
                chat?.user?.id == userId
                  ? {
                      ...chat,
                      chat: {
                        ...chat?.chat,
                        unseen_messages: 0,
                      },
                    }
                  : chat
              )
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onOpenGifts = (user) => {
    dispatch(setPopupChildren(<Gifts reciever={user} goTo="messages" />));
    dispatch(togglePopup(true));
  };

  const getMates = (batch = 1) => {
    dispatch(setGettingMates(true));
    usersService
      .getMates(token, batch, 20)
      .then((res) => {
        dispatch(setMates(res.data.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        dispatch(setGettingMates(false));
      });
  };

  const getUsersWholikesMe = () => {
    usersService
      .getUsersWhoLikesMe(token)
      .then(({ data }) => {
        dispatch(setWhoLikesMe(data?.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  return { toggleUserLike, getMessagesWithUser, onOpenGifts };
};

export default useUserPreview;
