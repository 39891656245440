import { connect } from "react-redux";
import Gifts from "./Gifts";
import { togglePopup } from "../../redux/actions/global/global.actions";

const mapState = ({ global, users }) => ({
  gifts: global.gifts,
  myData: users.myData,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopup: (data) => dispatch(togglePopup(data)),
});

export default connect(mapState, mapDispatchToProps)(Gifts);
