import React from "react";
import adminService from "../../../../../../../../services/admin/admin.service";
import { getToken } from "../../../../../../../../utils/helpers";
import { toast } from "react-toastify";

export default function useAddTeamMember() {
  const token = getToken();
  const createUser = async (data) => {
    try {
      await adminService.createAdminUser(token, data);
      toast.success("User Created");
    } catch (err) {
      toast.error(String(err.errors));
    }
  };
  const updateTeamMember = async (userId, data) => {
    await adminService
      .editUserAdminProfile(token, userId, data)
      .then((res) => {
        toast.success("User Updated");
      })
      .catch((err) => console.log(err));
  };
  return { createUser, updateTeamMember };
}
