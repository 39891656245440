import { makeStyles } from "@mui/styles";

export const EditHeaderStyle = makeStyles(({ theme }) => ({
  chatsHeaderWrapper: {
    backgroundColor: "#fff",
    marginTop: 40,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
    display: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row !important",
    padding: "10px 20px",
  },

  ArrowBack: {
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "#EA0F08",
    color: "#FFF",
    padding: ".2rem",
    "& svg": {
      width: "17px !important",
      height: "17px !important",
    },
    cursor: "pointer",
  },

  text: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  headerTxt: {
    margin: "5px",
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dividerV: {
    backgroundColor: "#000",
    width: "1px",
    height: "15px",
    margin: "5px 10px",
  },
  online: {
    width: "8px",
    height: "8px",
    backgroundColor: "#06C439",
    borderRadius: 100,
    margin: "auto 5px",
  },
}));
