import React, { useEffect, useState } from "react";
import adminService from "../../../../services/admin/admin.service";
import { getToken } from "../../../../utils/helpers";
import { useSelector } from "react-redux";

export default function useAdminRevenue() {
  const [revenueData, setRevenueData] = useState({});
  const [revenues, setRevenues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [batch, setBatch] = useState(1);
  const [limit, setLimit] = useState(1000);
  const { startDate, endDate } = useSelector((state) => state.global);

  const filterData = [
    { name: "All", value: "ALL" },
    { name: "Coins", value: "COINS" },
    { name: "Subscription", value: "SUBSCRIPTIONS" },
  ];
  const [filterValue, setFilterValue] = useState({
    name: "All",
    value: "ALL",
  });

  const handleChangeFilter = (val) => {
    setFilterValue(val.target);
  };
  const token = getToken();

  useEffect(() => {
    getAdminRevenueData();
  }, [token, startDate, endDate, batch, limit]);

  useEffect(() => {
    getAdminRevenues();
  }, [filterValue]);
  const getAdminRevenueData = () => {
    adminService
      .getAdminRevenueData(token)
      .then((res) => {
        setRevenueData(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const getAdminRevenues = () => {
    const date = { startDate, endDate };
    adminService
      .getAdminRevenue(token, {
        batch,
        limit,
        ...(date && date),
        ...(filterValue.value !== "ALL" && { type: filterValue.value }),
      })
      .then((res) => {
        console.log(res);
        setRevenues(res?.data?.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  return {
    revenueData,
    isLoading,
    revenues,
    filterValue,
    filterData,
    handleChangeFilter,
  };
}
