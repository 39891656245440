import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageContainer: {
    width: 350,
    padding: 25,
    backgroundColor: "#fff",
    borderRadius: 15,
  },
  pageContainer2: {
    width: 520,
    padding: 25,
    backgroundColor: "#fff",
    borderRadius: 15,
  },
  pageBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    height: 100,
    width: 300,
    margin: "auto",
    overflow: "hidden",

    "& img": {
      transform: "translate(20px, -100px)",
      height: 270,
      width: 270,
      objectFit: "contain",
    },
  },
  header: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    color: "#000000",
  },
  headerRed: {
    fontSize: "20px !important",
    fontWeight: "500 !important",
    color: "#ea0f08",
    textAlign: "center",
  },
  headerSub: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    color: "#000000",
    textAlign: "center",
  },
  headerSubRed: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
    color: "#ea0f08",
    textAlign: "center",
  },
  formBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  boxFlex: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
  },
  formText: {
    textAlign: "center",
    fontSize: "12px !important",
    fontWeight: "500 !important",
  },
  formForgot: {
    textAlign: "right",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    color: "#EA0F08",
    cursor: "pointer",
  },
  formLink: {
    textAlign: "right",
    fontSize: 12,
    fontWeight: 500,
    color: "#EA0F08",
    cursor: "pointer",
  },
  footing: {
    width: "100%",
    height: 150,
    overflow: "hidden",
    transform: "translateY(26px)",

    "& img": {
      transform: "translate(0, -53px)",
      height: 270,
      width: "100%",
      objectFit: "contain",
      zIndex: -1,
    },
  },
  otpInput: {
    width: "50px !important",
    height: 50,
    fontSize: 20,
  },
  otpContainer: {
    backgroundColor: "#E6F1FB",
    width: "100%",
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  target: {
    marginTop: 0,
    width: "100%",
  },
  targetText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    paddingLeft: 10,
    color: "#293146",
    marginBottom: 5,
  },

  dropDown: {
    height: 45,
    width: "100%",
    borderRadius: 0,
    color: "#293146",
    fontSize: 14,
    fontWeight: "500 !important",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& .Dropdown-arrow": {
      top: 18,
    },
    "& .Dropdown-placeholder": {
      fontSize: "14px important",
      color: "#293146",
    },

    "&:focus": {
      border: "1px solid #2f3138",
    },
  },
}));

export default useStyles;
