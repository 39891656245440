import { makeStyles } from "@mui/styles";

export const BuyCoinsStyles = makeStyles(({ theme }) => ({
  wrapper: {
    backgroundColor: "#fff",
    width: 400,
    padding: 30,
    borderRadius: 15,
    maxHeight: "80vh",
    overflow: "auto",
  },
  top: {
    display: "flex !important",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "15px 0px",
  },
  topTitleText: { fontWeight: "500 !important", fontSize: "20px !important" },
  topDescriptionText: { color: "#969696" },
  saveBtn: {
    width: "100%",
    padding: 10,
    backgroundColor: "#EA0F08",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 25,
    borderRadius: 5,
    display: "flex !important",
    borderStyle: "none",
    cursor: "pointer",
  },
  coinBox: {
    height: 180,
    width: "37%",
    padding: 20,
    borderRadius: 10,
    boxShadow: "5px 5px #EA0F07",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #707070",
    display: "flex !important",
    flexDirection: "column",
    gap: 16,
    cursor: "pointer",
  },
  coinsTitle: {
    fontWeight: "500 !important",
    fontSize: 12,
    marginBottom: 30,
    textAlign: "center",
  },
  coinQty: {
    fontWeight: "500 !important",
    fontSize: 12,
    marginTop: 8,
  },
  coinPrice: {
    fontWeight: "500 !important",
    marginTop: 8,

    fontSize: 9,
    color: "red",
    textDecorationLine: "underline",
  },
}));
