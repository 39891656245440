import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./i18n";

// eslint-disable-next-line no-extend-native
String.prototype.toSentenceCase = function () {
  const value = String(this);
  return value?.toLowerCase()?.replace(/^./g, this[0]?.toUpperCase());
};
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
