import React from "react";
import { ImageSection } from "./components/ImageSection/ImageSection";
import UserDetails from "./components/UserDetails/UserDetails";
import {style} from "./Profile.style"

export default function Profile() {
  const classes = style();

  return (
    <div className={classes.container}>
      <ImageSection />
      <UserDetails />
    </div>
  );
}
