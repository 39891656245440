import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CustomButton from "../CustomButton";
import { Circles } from "react-loader-spinner";
import { BuyCoinsStyles } from "./BuyCoins.style";
import useBuyCoins from "./hooks/useBuyCoins";

import coinImage from "../../assets/images/coin.png";
import useCoinsConstants from "./useCoinsConstants";
import { useTranslation } from "react-i18next";
const BuyCoins = ({ togglePopup, myData, memberships }) => {
  const classes = BuyCoinsStyles();
  const { coins } = useCoinsConstants();
  const { buying, buyCoins, setSelectedCoins, selectedCoins } = useBuyCoins({
    coins,
  });
  const { t } = useTranslation();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.top}>
        <Box></Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography className={classes.topTitleText}>
            {t("buyCredits")}
          </Typography>
        </Box>

        <IconButton onClick={() => togglePopup(false)}>
          <HighlightOffIcon color="#000" fontSize="small" />
        </IconButton>
      </Box>
      <Box
        style={{
          marginVertical: 10,
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          gap: 16,
        }}
        sx={{ display: "flex" }}
        flexWrap="wrap"
      >
        {coins.map((coin, i) => (
          <Box
            onClick={() => setSelectedCoins(coin.id)}
            key={i}
            className={classes.coinBox}
            style={{
              backgroundColor: selectedCoins === coin.id ? "#FFE7E6" : "#fff",
            }}
          >
            <Typography className={classes.coinsTitle}>{coin.title}</Typography>

            <img
              src={coinImage}
              style={{ width: 35, height: 35, marginVertical: 13 }}
              alt={"coin"}
            />
            <Typography className={classes.coinQty}>
              {`${coin.quantity} M8S Coins`}
            </Typography>
            <Typography className={classes.coinPrice}>
              {`$${coin.amount}`}
            </Typography>
          </Box>
        ))}
      </Box>
      <CustomButton
        disabled={buying}
        onClick={buyCoins}
        className={classes.saveBtn}
      >
        {buying ? (
          <Circles
            height="20"
            width="20"
            color="#E8E8E8"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <Typography style={{ color: "#fff", fontWeight: "700" }}>
            {t("continue")}
          </Typography>
        )}
      </CustomButton>
    </Box>
  );
};

export default BuyCoins;
