import { connect } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../redux/actions/global/global.actions";
import { setPreviewUser } from "../../../../redux/actions/users/users.actions";
import Mate from "./Mate";

const mapState = ({ users }) => ({
  mates: users.mates,
  gettingMates: users.gettingMates,
  myCoords: users.myCoords,
});
const mapDispatchToProps = (dispatch) => ({
  setPreviewUser: (data) => dispatch(setPreviewUser(data)),
  setPopupChildren: (data) => dispatch(setPopupChildren(data)),
  togglePopup: (data) => dispatch(togglePopup(data)),
});
export default connect(mapState, mapDispatchToProps)(Mate);
