import { makeStyles } from "@mui/styles";

export const RevenueStyles = makeStyles(({ theme }) => ({
  pageWrapper: {
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "relative",
    overflow: "auto",
    margin: "30px 0px",
    gap: "1rem",
    display: "flex",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  cardsRow: {
    display: "grid",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    gap: "1.5em",
    gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
  },
  tableSection: {
    padding: 20,
    backgroundColor: "#fff",
    borderRadius: 15,
    marginBottom:"4rem"
  },
  tableRow: {
    padding: "18px",
    fontSize: "13px",
    fontWeight: 200,
  },
  formControl: {
    "& fieldset": {
      border: "none",
    },
    "& label": {
      fontSize: "0.9vw",
    },
  },
  ddSelect: {
    borderRadius: "2px",
    height: 30,
    width: "100%",
    color: "red",
    boxShadow: "none",
    fontSize: "13px",
    paddingLeft: 2,
    paddingTop: 5,
    border: "none",
    overflow: "hidden",
    backgroundColor: "#F4F5F9",

    "&::after, &::before": {
      display: "none !important",
    },

    "& > div:first-child": {
      zIndex: 5,
      width: "100%",
      fontWeight: 400,
      paddingLeft: 7,
      paddingTop: "17px",
    },
    "&.Mui-focused > div:first-child": {
      backgroundColor: "transparent",
    },
    "&.avatar-wrapper": {
      color: "#011B60",
    },
    "&.avatar-wrapper img": {
      backgroundColor: "blue",
      color: "pink",
      width: "100%",
      height: "100%",
      paddingTop: 12,
      paddingBottom: 12,
      justifyContent: "flex-start",
    },
    "& path": {
      stroke: "#F4F5F9",
    },
  },
  dropDownValue: {
    fontSize: "13px",
    fontWeight: 400,
    padding: "12px 15px",
    color: "#011B60",
  },
  revenueTableHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "15px 0",
    justifyContent: "space-between",
    "& .MuiPaper-root": {
      boxShadow: "none",
    },
  },
  dropdownIcon: {
    marginLeft: 10,
  },
  userCell: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
  greyBg: {
    height: 20,
    width: 20,
    borderRadius: 20,
    backgroundColor: "#BFBFBF",
    marginRight: 10,
  },
}));
