import env from "../../env";
import { request } from "../api";

const API_URL = `${env.API_URL}`;

const URL = {
  getDashboardData: `${API_URL}/users/admin/statistics/dashboard`,
  getRevenueData: `${API_URL}/users/admin/statistics/revenue`,
  getRevenue: `${API_URL}/users/admin/revenues`,
  getReferralData: `${API_URL}/users/admin/statistics/referrals`,
  getUsersData: `${API_URL}/users/admin/statistics/users`,
  getAdminUsersData: `${API_URL}/users/admin/team?limit=40&batch=1`,
  adminGetUsers: `${API_URL}/users/admin`,
  createAdmin: `${API_URL}/users/admin?limit=10&batch=1`,
  adminStatus: `${API_URL}}/users/admin/toggle`,
  patchChangePassword: `${API_URL}/users/password`,
  patchEditProfile: `${API_URL}/users/profile`,
  patchUserProfile: (userId) => `${API_URL}/users/${userId}/edit-profile`,
  postUserInterests: (userId) => `${API_URL}/users/${userId}/interest`,
  getUserPosts: (userId) =>
    `${API_URL}/users/${userId}/posts?limit=1&batch=1000`,
  getUserEvents: (userId) => `${API_URL}/users/${userId}/events?limit=&batch=`,
  getUserTransactions: (userId) => `${API_URL}/users/${userId}/transactions`,
  toggleActiveUser: `${API_URL}/users/admin/toggle`,
  patchEditAdminUserProfile: (userId) => `${API_URL}/users/admin/${userId}`,
  removeUserPost: (userId, postId) =>
    `${API_URL}/users/${userId}/post/${postId}`,
};

const getAdminDashboardData = (token, startDate, endDate) => {
  return request(
    URL.getDashboardData,
    {
      startDate,
      endDate,
    },
    "GET",
    `Bearer ${token}`
  );
};
const getAdminRevenueData = (token) => {
  return request(URL.getRevenueData, {}, "GET", `Bearer ${token}`);
};

const getAdminRevenue = (token, queryParams) => {
  return request(URL.getRevenue, queryParams, "GET", `Bearer ${token}`);
};
const getAdminReferral = (token) => {
  return request(URL.getReferralData, {}, "GET", `Bearer ${token}`);
};
const getUsersData = (token) => {
  return request(URL.getUsersData, {}, "GET", `Bearer ${token}`);
};
const getAdminUsers = (token) => {
  return request(URL.getAdminUsersData, {}, "GET", `Bearer ${token}`);
};
const adminGetUsers = (token, queryParams) => {
  return request(URL.adminGetUsers, queryParams, "GET", `Bearer ${token}`);
};
const createAdminUser = (token, data) => {
  return request(URL.createAdmin, { ...data }, "POST", `Bearer ${token}`);
};
const setAdminStatus = (token, data) => {
  return request(URL.adminStatus, { ...data }, "PATCH", `Bearer ${token}`);
};

const changePassword = (token, data) => {
  return request(
    URL.patchChangePassword,
    { ...data },
    "PATCH",
    `Bearer ${token}`
  );
};

const editProfile = (token, data) => {
  return request(URL.patchEditProfile, { ...data }, "PATCH", `Bearer ${token}`);
};

const getUserPosts = (token, userId) => {
  return request(URL.getUserPosts(userId), {}, "GET", `Bearer ${token}`);
};
const getUserEvents = (token, userId) => {
  return request(URL.getUserEvents(userId), {}, "GET", `Bearer ${token}`);
};
const updateUserProfile = (token, userId, data) => {
  return request(
    URL.patchUserProfile(userId),
    { ...data },
    "PATCH",
    `Bearer ${token}`
  );
};
const updateUserInterests = (token, userId, data) => {
  return request(
    URL.postUserInterests(userId),
    { interests: data },
    "POST",
    `Bearer ${token}`
  );
};
const getTransactions = (token, userId) => {
  return request(URL.getUserTransactions(userId), {}, "GET", `Bearer ${token}`);
};

const toggleActiveUser = (token, user_id, is_active) => {
  return request(
    URL.toggleActiveUser,
    { user_id, is_active },
    "PATCH",
    `Bearer ${token}`
  );
};

const editUserAdminProfile = (token, userId, data) => {
  return request(
    URL.patchEditAdminUserProfile(userId),
    data,
    "PATCH",
    `Bearer ${token}`
  );
};

const removeUserPost = (token, userId, postId) => {
  return request(
    URL.removeUserPost(userId, postId),
    {},
    "DELETE",
    `Bearer ${token}`
  );
};

const adminService = {
  getAdminDashboardData,
  getAdminRevenueData,
  getAdminReferral,
  getUsersData,
  getAdminUsers,
  createAdminUser,
  setAdminStatus,
  adminGetUsers,
  changePassword,
  editProfile,
  getUserPosts,
  getUserEvents,
  updateUserProfile,
  updateUserInterests,
  getTransactions,
  getAdminRevenue,
  toggleActiveUser,
  editUserAdminProfile,
  removeUserPost,
};
export default adminService;
