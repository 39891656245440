import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  pageContainer: {
    width: 380,
    padding: 25,
    backgroundColor: "#fff",
    borderRadius: 15,
    maxHeight: "90vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  pageBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  formBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  target: {
    marginTop: 0,
    width: "100%",
  },
  targetText: {
    fontSize: "12px !important",
    fontWeight: "500 !important",
    paddingLeft: 10,
    color: "#293146",
    marginBottom: 5,
  },

  dropDown: {
    height: 45,
    width: "100%",
    borderRadius: 0,
    color: "#293146",
    fontSize: 14,
    fontWeight: "400 !important",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& .Dropdown-arrow": {
      top: 18,
    },
    "& .Dropdown-placeholder": {
      fontSize: "14px important",
      color: "#293146",
    },

    "&:focus": {
      border: "1px solid #2f3138",
    },
  },
  btnBox: {
    display: "flex",
    gap: 20,
  },
  btn: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    border: "1px solid #00000029",
    background: "#FFFFFF",
    width: "100%",
    textAlign: "center",
    fontSize: "14px !important",
  },
  btnActive: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "100%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  day: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "20%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  month: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "30%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  year: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "50%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  continueBtn: {
    marginTop: 30,
  },
  header: {
    color: "#EA0F08",
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  headerBlack: {
    color: "#0A0707",
    fontSize: "16px !important",
    fontWeight: "600 !important",
  },
  sub: {
    color: "#2F3C47",
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  subSub: {
    color: "#969696",
    fontSize: "12px !important",
    fontWeight: "500 !important",
    width: "80%",
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    marginBottom: 20,
  },
  headClass: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default useStyles;
