import React from "react";
import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/material";
import ReactDropdown from "react-dropdown";
import CustomButton from "../../../components/CustomButton";
import useStyles from "./Verify.styles";
import Logo from "../../../resources/img/M8s social white background.png";
import ModeCard from "../../../components/ModesModal/ModeCard";
import useVerification from "../hooks/useVerification";
import Poster from "../../../assets/images/VideoPoster.jpg";
import { useEffect } from "react";
import useConstants from "../../../utils/useConstants";
import { useTranslation } from "react-i18next";
import { VideoCall } from "@mui/icons-material";
import useSettingConstants from "../../../constants/data/useSettingConstants";

function Verify() {
  const classes = useStyles();
  const { modes, sexOptions } = useConstants();

  const {
    open,
    fileToAccept,
    setImgType,
    setFileToAccept,
    main,
    others,
    vid,
    state,
    uploadAll,
    getMe,
    myData,
    getRandomColor,
    imgIndex,
    setImgIndex,
  } = useVerification();
  const { isLoading } = state;
  const { t } = useTranslation();
  const moment = require("moment");
  const date = moment(myData?.birthday);
  const formatedDate = date.format("DD/MM/YYYY");

  const date_components = formatedDate.split("/");
  const day = date_components[0];
  const month = date_components[1];
  const year = date_components[2];

  const mode = modes.findIndex((m) => m.value === myData?.mode);
  const { interestsArray } = useSettingConstants();

  const interestList = myData?.interests;
  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    fileToAccept && open();
    return () => {
      setFileToAccept(null);
      setImgType("");
      setImgIndex(0);
    };
  }, [fileToAccept]);

  return (
    <form onSubmit={uploadAll} style={{ width: "100%", overflow: "auto" }}>
      <Grid container padding={0} spacing={10}>
        <Grid item md={6} display="flex" justifyContent="center">
          <Box className={classes.pageContainer2}>
            <Box
              sx={{
                position: "relative",
                width: "180px",
                height: "180px",
                borderRadius: "100%",
              }}
            >
              {others[0] ? (
                <img
                  alt="profile pic"
                  src={URL.createObjectURL(others[0])}
                  className={classes.mainImage}
                />
              ) : (
                <Avatar className={classes.mainImage} />
              )}
            </Box>
            <Box className={classes.subImages}>
              {!!vid ? (
                <video poster={Poster} className={classes.secImage}>
                  <source src={URL.createObjectURL(vid)} />
                </video>
              ) : (
                <span
                  className={classes.secImage}
                  onClick={() => {
                    setFileToAccept("video");
                  }}
                >
                  <VideoCall
                    sx={{
                      width: "80px",
                      height: "60px",
                      transform: "translateY(10px)",
                      // color: "#bdbdbd",
                      color: "#707070",
                      opacity: 0.7,
                    }}
                  />
                </span>
              )}
              {/* </Box> */}

              {others?.map((item, index) => {
                return !!item ? (
                  <img
                    src={URL.createObjectURL(item)}
                    className={classes.secImage}
                    alt="pic"
                    onClick={() => {
                      setFileToAccept("image");
                      setImgType("others");
                      setImgIndex(index);
                    }}
                  />
                ) : index === 0 ? (
                  <Avatar
                    src=""
                    className={classes.secImage}
                    onClick={() => {
                      setFileToAccept("image");
                      setImgType("others");
                      setImgIndex(index);
                    }}
                  />
                ) : (
                  <span
                    className={classes.secImage}
                    onClick={() => {
                      setFileToAccept("image");
                      setImgType("others");
                      setImgIndex(index);
                    }}
                  >
                    &#x2b;
                  </span>
                );
              })}
            </Box>
            <Box>
              <Typography>{t("uploadPhotos")}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box className={classes.pageContainer}>
            <Box className={classes.pageBox}>
              <Box className={classes.formBox}>
                <Box className={classes.headClass}>
                  <Box className={classes.headerText}>
                    <span className={classes.header}>{t("welcomeToM8s")}</span>
                    {"\n"}
                    <span className={classes.sub}>{t("knowMore")}</span>
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      height: 150,
                      display: "inline-flex",
                      transform: "translate(30px, -60px)",
                    }}
                  >
                    <img src={Logo} alt="m8t logo" />
                  </Box>
                </Box>
                <Box className={classes.target}>
                  <Typography className={classes.targetText}>
                    {t("whatyourname")}
                  </Typography>
                  <Box className={classes.newBox}>
                    {myData
                      ? `${myData?.first_name || ""} ${myData?.last_name || ""}`
                      : ""}
                  </Box>
                </Box>
                <Box className={classes.target}>
                  <Typography className={classes.targetText}>
                    {t("username")}
                  </Typography>
                  <Box className={classes.newBox}>
                    @{myData ? myData.username : ""}
                  </Box>
                </Box>
                <Box className={classes.target}>
                  <Typography className={classes.targetText}>
                    {" "}
                    {t("gender")}
                  </Typography>

                  <Box className={classes.btnBox}>
                    <span
                      className={
                        myData?.gender === "male"
                          ? classes.btnActive
                          : classes.btn
                      }
                    >
                      {t("male")}
                    </span>
                    <span
                      // onClick={() => dispatch({ gender: "female" })}
                      className={
                        myData?.gender === "female"
                          ? classes.btnActive
                          : classes.btn
                      }
                    >
                      {t("female")}
                    </span>
                  </Box>
                </Box>
                <Box className={classes.target}>
                  <Typography className={classes.targetText}>
                    {t("sex")}
                  </Typography>
                  <ReactDropdown
                    controlClassName={classes.dropDown}
                    arrowClassName={classes.dropDownArrow}
                    options={sexOptions}
                    disabled
                    value={myData?.sexuality?.toLowerCase()}
                    placeholder={myData?.sexuality?.toLowerCase()}
                  />
                </Box>
                <Box className={classes.target}>
                  <Typography className={classes.targetText}>
                    {t("dob")}
                  </Typography>
                  <Box className={classes.btnBox}>
                    <span
                      className={classes.day}
                      // onClick={() => setIsOpen(!isOpen)}
                    >
                      {day === "Invalid date" ? "DD" : day}
                    </span>
                    <span
                      className={classes.month}
                      // onClick={() => setIsOpen(!isOpen)}
                    >
                      {month || "MM"}
                    </span>
                    <span
                      className={classes.year}
                      // onClick={() => setIsOpen(!isOpen)}
                    >
                      {year || "YYYY"}
                    </span>
                  </Box>
                </Box>

                <Box className={classes.target}>
                  <Typography className={classes.targetText}>
                    {t("desiredMode")}
                  </Typography>
                  <ModeCard
                    mode={modes[mode]}
                    selectedMode={myData?.mode}
                    pad="5px 10px"
                  />
                </Box>
                <Box className={classes.target}>
                  <Typography className={classes.targetText}>
                    {t("interests")}
                  </Typography>
                  <Box className={classes.intCon}>
                    {!!interestList &&
                      interestList.map((i) => (
                        <Box
                          key={i}
                          className={classes.intWrap}
                          style={{
                            backgroundColor: getRandomColor(),
                          }}
                        >
                          <Typography className={classes.intText}>
                            #
                            {
                              interestsArray?.filter(
                                (int) => int?.id === i?.id
                              )[0]?.name
                            }
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                </Box>
                <Box className={classes.continueBtn} sx={{ marginTop: "15px" }}>
                  <CustomButton
                    disabled={!others[0] ? true : false}
                    type="submit"
                    loading={!!isLoading}
                  >
                    {t("continue")}
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default Verify;
