import React, { useState } from "react";
import { useIncognitoModal } from "./IncognitoModal.style";
import { Box, Button, Typography } from "@mui/material";
import { togglePopup } from "../../redux/actions/global/global.actions";
import { useDispatch } from "react-redux";
import { CloseOutlined } from "@mui/icons-material";
import { ReactComponent as IncognitoIcon } from "../../assets/icons/IncognitoIcon1.svg";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/helpers";
import otherServices from "../../services/others.service";
import { toast } from "react-toastify";
import usersService from "../../services/users.service";
import { setMyData } from "../../redux/actions/users/users.actions";

export default function IncognitoModal() {
  const classes = useIncognitoModal();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [switchingIncognito, setSwitchingIncognito] = useState(false);
  const token = getToken();
  const onSwitchIncognito = () => {
    setSwitchingIncognito(true);
    otherServices
      .switchIncognito(token)
      .then((res) => {
        if (res?.success) {
          toast.success(t("successIncognito"));
          getMe();
          dispatch(togglePopup(false));
        } else {
          toast.error(`${t("unableSwitch")} ${t("incognito")}`);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setSwitchingIncognito(false);
      });
  };

  const getMe = () => {
    usersService
      .getMe(token)
      .then(async ({ data }) => {
        dispatch(setMyData(data));
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box className={classes.Wrapper}>
      <CloseOutlined
        onClick={() => dispatch(togglePopup(false))}
        className={classes.closeBtn}
      />
      <IncognitoIcon className={classes.IncognitoIcon} />
      <Typography className={classes.title}>{t("noSeen")}</Typography>
      <Box className={classes.content}>
        <Typography>{t("takeControl")}</Typography>
        <Typography>{t("useIncognoto")}</Typography>
      </Box>
      <Button
        disabled={switchingIncognito}
        onClick={onSwitchIncognito}
        className={classes.mainBtn}
      >
        {switchingIncognito ? t("loading") : t("goIncognito")}
      </Button>
    </Box>
  );
}
