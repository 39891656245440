import React, { useEffect } from "react";
import { Box, Modal } from "@mui/material";

const PopupModal = ({
  showPopup,
  popupChildren,
  confirmModal,
  onModalDismiss,
  togglePopup,
  setPopupChildren,
  children,
  calling,
}) => {
  useEffect(() => {
    if (showPopup === false) {
      setPopupChildren(null);
    }
  }, [showPopup, setPopupChildren]);

  return (
    <Modal
      disableAutoFocus={true}
      open={showPopup}
      onClose={() => !calling && togglePopup(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100vh",
      }}
    >
      <Box>{popupChildren}</Box>
    </Modal>
  );
};

export default PopupModal;
