import { AccountCircle, MoreHoriz, Report } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import AdminGlobalHeader from "../../../components/AdminGlobalHeader";
import ChartCard from "../../../components/ChartCard";
import CustomTable from "../../../components/CustomTable";
import { referralsData } from "./constants";
import { ReferralsStyles } from "./Referrals.style";
import useAdminReferral from "./hooks/useAdminReferral";
import MoreMenu from "../../../components/MoreMenu/MoreMenu";

const Referrals = () => {
  const classes = ReferralsStyles();
  const { referralData, isLoading, formatDate } = useAdminReferral();
  if (isLoading) return <div>Loading...</div>;
  const { coins, referrals, referralRecords } = referralData;
  console.log("referrals", referralData);
  const referralList = referralRecords.data;
  return (
    <Box className={classes.pageWrapper}>
      <AdminGlobalHeader />
      <Box className={classes.cardsRow}>
        <ChartCard
          icon={<AccountCircle style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Total Referrals"
          description="General"
          figure={referrals.totalCount}
          trend="positive"
          data={[...referrals.groupedByDateRange]}
          trendValue="21"
        />

        <ChartCard
          icon={<Report style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Total Points"
          description="Male Users"
          figure={coins.totalCoins}
          trend="positive"
          trendValue="86.4"
          dataKey="total_coins"
          data={[...coins.groupedByDateRange]}
        />
      </Box>
      <Box className={classes.tableSection} sx={classes.tableSection}>
        <Typography fontWeight={500}>Referral Summary</Typography>

        <CustomTable
          noSort
          data={{
            th: [
              <span key={1}>User</span>,
              <span key={2}>Date</span>,
              <span key={3}>Point Given</span>,
              <span key={4}>Actions</span>,
            ],
            rows: referralList?.map(
              ({ id, coins, createdAt, referrer }, index) => {
                const date = formatDate(createdAt);
                return [
                  <Box key={id} className={classes.tableRow}>
                    <Box className={classes.userCell}>
                      <Box className={classes.greyBg} />
                      {`${
                        referrer?.first_name + " " + referrer?.last_name || "--"
                      }`}
                    </Box>
                  </Box>,
                  <Box key={id} className={classes.tableRow}>
                    {date || "--"}
                  </Box>,
                  <Box key={id} className={classes.tableRow}>
                    {coins}
                  </Box>,
                  <Box key={index} className={classes.tableRow}>
                    <MoreMenu className={classes.dropdownIcon} />
                  </Box>,
                ];
              }
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Referrals;
