import React from 'react';
import { RightMenuBarStyle } from './RightMenuBar.style';
import moment from 'moment';

const RightMenuBar = () => {
  const classes = RightMenuBarStyle();

  return (
    <div className={classes.menubarWrapper}>
      <div className={classes.menubarInnerContainer}>
        <div className={classes.copyrightText}>
          &#169; {moment().year()} Copyright. All Rights Reserved. M8S SRL.
        </div>
      </div>
    </div>
  );
};

export default RightMenuBar;
