import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import FormField from "../../../../components/FormField";
import { Typography } from "@mui/material";
import useStyles from "../Login/Login.styles";
import Logo from "../../../../resources/img/M8s social white background.png";
import Footing from "../../../../resources/img/footerImg.png";
import CustomButton from "../../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import useAuthentication from "../../../../pages/Authentication/hooks/useAuthentication";
import { languageGroup } from "../constants";
import { useTranslation } from "react-i18next";

function SignUp() {
  const classes = useStyles();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const referal = queryParams.get("ref");
  const [hasRef, setHasRef] = useState(false);
  const { state, dispatch, handleCreate } = useAuthentication();
  const { email, password, lang, cPassword, isLoading, ref } = state;

  useEffect(() => {
    if (referal) {
      dispatch({ ref: referal });
      setHasRef(true);
    }
  }, [referal]);
  const isDisabled = (email && password && lang && cPassword) === "";

  const { t } = useTranslation();

  return (
    <Box className={classes.pageContainer2}>
      <Box className={classes.pageBox}>
        <Box className={classes.logo} marginLeft="0" height="75px">
          <img
            src={Logo}
            alt="m8t logo"
            style={{ transform: "translate(-64px, -100px)" }}
          />
        </Box>

        <Box className={classes.boxFlex} marginBottom="15px">
          <Box>
            <Typography className={classes.header}>
              {t("createAccount")}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.formText}>
              {t("gotAccount")}{" "}
              <span
                onClick={() => navigate("/auth/login")}
                className={classes.formLink}
              >
                {t("signIn")}
              </span>
            </Typography>
          </Box>
        </Box>

        <form onSubmit={handleCreate} className={classes.formBox}>
          <Box className={classes.boxFlex}>
            <Box width="100%" sx={{ position: "relative" }}>
              <FormField
                value={email}
                handleChange={(e) => dispatch({ email: e.target.value })}
                label={t("email")}
                type="email"
              />
              <span
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  color: "#EA0F08",
                }}
              >
                *
              </span>
            </Box>
            <Box className={classes.target} sx={{ position: "relative" }}>
              <span
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  color: "#EA0F08",
                }}
              >
                *
              </span>
              <Typography className={classes.targetText}>
                {t("defaultLanguage")}
              </Typography>
              <Dropdown
                controlClassName={classes.dropDown}
                arrowClassName={classes.dropDownArrow}
                options={languageGroup}
                onChange={(e) => dispatch({ lang: e.value })}
                value={lang}
                placeholder={t("selectDefaultLang")}
              />
            </Box>
          </Box>
          <Box className={classes.boxFlex}>
            <Box width="100%" sx={{ position: "relative" }}>
              <FormField
                label={t("password")}
                isPassword
                value={password}
                handleChange={(e) => dispatch({ password: e.target.value })}
              />
              <span
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  color: "#EA0F08",
                }}
              >
                *
              </span>
            </Box>
            <Box width="100%" sx={{ position: "relative" }}>
              <FormField
                label={t("confirmPass")}
                isPassword
                value={cPassword}
                handleChange={(e) => dispatch({ cPassword: e.target.value })}
              />
              <span
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  color: "#EA0F08",
                }}
              >
                *
              </span>
            </Box>
          </Box>
          <Box sx={{ position: "relative" }}>
            <FormField
              label={t("gotCode")}
              value={ref}
              handleChange={(e) => dispatch({ ref: e.target.value })}
              disabled={hasRef}
              required={false}
            />
          </Box>
          <Box marginTop="15px" marginBottom="20px">
            <CustomButton
              type="submit"
              disabled={!!isDisabled}
              loading={!!isLoading}
              style={{ borderStyle: "none" }}
            >
              {t("continue")}
            </CustomButton>
          </Box>
        </form>
        <Box className={classes.footing}>
          <img src={Footing} alt="m8t footing" />
        </Box>
      </Box>
    </Box>
  );
}

export default SignUp;
