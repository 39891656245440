import { makeStyles } from "@mui/styles";
import { padding } from "@mui/system";

export const useStyles = makeStyles((theme) => ({
  pageWrap: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  pageHeader: {
    background: "#ffffff",
    display: "flex",
    flexDirection: "column",
    padding: 20,
    gap: 20,
  },
  headerTop: {
    display: "flex",
    gap: 20,
  },
  headerIcon: {
    height: "200px",
    width: "55%",

    "& img": {
      height: "100%",
      width: "auto",
      transform: "translateX(0px)",
    },
  },
  summary: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  total: {
    fontSize: "16px !important",
    fontWeight: "500 !important",
  },
  count: {
    fontSize: "38px !important",
    fontWeight: "600 !important",
    color: "#ea0f08",
  },
  linkField: {
    background: "#F4F5F9 !important",
    minHeight: "45px !important",
    width: "100%",
    border: "none",
    wordBreak: "break-word",
    position: "relative",
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
  },
  getLink: {
    display: "flex",
    alignItems: "center",
    background: "#ea0f08",
    color: "#fff",
    padding: "5px 10px",
    position: "absolute",
    right: 10,
    borderRadius: 5,
    cursor: "pointer",

    "&:active": {
      background: "#c20807",
      color: "#f2f2f2",
      boxShadow: "0 3px rgba(0, 0, 0, 0.3)",
      transform: "translateY(2px)",
    },
  },

  default: {
    color: "#ea0f08",
    fontWeight: 500,
  },
  pageDetails: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  pageLine: {
    background: "#fff",
    padding: "8px 15px",
    display: "flex",
    alignItems: "center",
    gap: 40,

    "& .upward": {
      background: "rgb(213, 247, 217)",
      height: 30,
      width: 30,
      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .downward": {
      background: "#FFC9D8",
      height: 30,
      width: 30,
      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));
