import React, { useEffect, useState } from "react";
import adminService from "../../../../services/admin/admin.service";
import { getToken } from "../../../../utils/helpers";
import moment from "moment";
import { useSelector } from "react-redux";

const useAdminDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { startDate, endDate } = useSelector((state) => state.global);
  const [hasDays, setHasDays] = useState(false);
  const [date, setDate] = useState({
    startDate,
    endDate,
  });
  const token = getToken();

  useEffect(() => {
    setDate({
      startDate,
      endDate,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    adminService
      .getAdminDashboardData(token, date.startDate, date.endDate)
      .then((res) => {
        setDashboardData(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, [token, date]);
  return { dashboardData, isLoading };
};

export default useAdminDashboard;
