import { makeStyles } from "@mui/styles";

export const CoinsAndMoreStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex !important",
    backgroundColor: "#fff",
    width: 350,
    flexDirection: "column",
    alignItems: "center",
    padding: 30,
    borderRadius: 10,
    gap: 8,
  },
  titleText: { margin: "10px !important" },
  descriptionText: {
    fontSize: "13px !important",
    margin: 10,
    width: 300,
  },
  box: {
    border: "1px solid #707070",
    padding: "15px",
    boxShadow: "5px 5px #EA0F07",
    borderRadius: 5,
    margin: "15px",
    height: 150,
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  },
  coinLogo: {
    height: 50,
    width: 50,
  },
  membershipLogo: {
    height: 50,
    width: 40,
  },
  boxContent: {
    display: "flex !important",
    flexDirection: "column",
    gap: 20,
    margin: "20px auto",
    alignItems: "center",
  },
  boxText: {
    fontSize: "13px !important",
    textAlign: "center",
  },
}));
