import React, { useState } from "react";
import defaultImage from ".././../assets/images/default_image.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PostImgComponent = ({ src, style, className, alt, ...props }) => {
  const [showDefault, setShowDefault] = useState(false);
  return (
    <LazyLoadImage
      effect="blur"
      src={!showDefault ? src || defaultImage : defaultImage}
      onError={(e) => {
        setShowDefault(true);
      }}
      alt={alt}
      className={className}
      style={style}
      {...props}
    />
  );
};

export default PostImgComponent;
