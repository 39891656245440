import { makeStyles } from "@mui/styles";

export const usePremiumStyle = makeStyles(({ style }) => ({
  Wrapper: {
    backgroundColor: "#EA0F08",
    color: "#FFF",
    height: "100%",
    padding: ".5rem 2.5rem 0",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  UpgradeBtn: {
    background: "#FFF !important",
    color: "#EA0F08 !important",
    borderRadius: "0 !important",
    textTransform: "capitalize !important",
    whiteSpace: "nowrap",
    width: "150px",
    height: "fit-content",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "150px",
  },
  headerImg: {
    width: "fit-content",
    height: "fit-content",
    "& svg": {
      width: "100px",
      objectFit: "contain",
    },
  },
  headerContent: {
    height: "100%",
  },
  headerBody: { fontSize: "14px !important" },
  headerTitle: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
  },
  pricing: {
    background: "#F4F5F9",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "2rem 1.5rem",
    color: "#000",
    height: "100px",
    gap: 12,
    overflow: "auto",
  },
  active: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    background: "#F8D588",
    border: "none",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "2.5rem 1rem",
    "& svg": {
      width: "65px",
      height: "40px",
    },
    height: "50px",
    width: "200px",
  },
  inactive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    background: "#FFF",
    border: "none",
    cursor: "pointer",
    borderRadius: "10px",
    padding: "2.5rem 1rem",
    "& svg": {
      width: "65px",
      height: "40px",
    },
    height: "50px",
    width: "200px",
  },
  period: {
    fontSize: "28px !important",
    fontWeight: "600 !important",
  },
  price: {
    fontSize: "12px !important",
    fontWeight: "400 !important",
  },
  benefits: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem 2rem",
  },
  benefit: {
    display: "flex",
    alignItems: "center",

    "& span": {
      whiteSpace: "nowrap",
      fontSize: "16px",
      fontWeight: "500",
    },
    "& svg": {
      width: "10px",
      height: "10px",
      color: "#FFF",
    },
  },
  Footer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  FooterTitle: {
    fontSize: " 18px !important",
    fontWeight: "600 !important",
  },
}));
