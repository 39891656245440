import React from "react";
import { Avatar, Typography } from "@mui/material";
import useStyles from "./StoryNode.styles";
import useFeeds from "../../hooks/useFeeds";
import { usernameStringCheck } from "../../../../utils/helpers";

function StoryNode({ story }) {
  const classes = useStyles();
  const { onShowStory } = useFeeds();

  function checkSeen(array) {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].seen) {
        return false;
      }
    }
    return true;
  }

  const seen = checkSeen(story?.stories);

  return (
    <div className={classes.storyNode}>
      <div
        className={seen ? classes.seen : classes.node}
        onClick={() => onShowStory(story?.stories, story)}
      >
        <Avatar
          src={story?.avatar}
          alt="story"
          className={classes.nodeAvatar}
        />
      </div>
      <Typography className={classes.title}>
        <span style={{ color: "#ADABAB" }}>@</span>
        {usernameStringCheck(story?.username)}
      </Typography>
    </div>
  );
}

export default StoryNode;
