import { connect } from "react-redux";
import {
  setPopupChildren,
  togglePopup,
} from "../../redux/actions/global/global.actions";
import { setPreviewUser } from "../../redux/actions/users/users.actions";
import Nearby from "./Nearby";

const mapState = ({ users }) => ({
  nearbyUsers: users.nearbyUsers,
  gettingNearby: users.gettingNearby,
  myCoords: users.myCoords,
});

const mapDispatchToProps = (dispatch) => ({
  setPreviewUser: (data) => dispatch(setPreviewUser(data)),
  setPopupChildren: (data) => dispatch(setPopupChildren(data)),
  togglePopup: (data) => dispatch(togglePopup(data)),
});
export default connect(mapState, mapDispatchToProps)(Nearby);
