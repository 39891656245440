import { connect } from "react-redux";
import Discover from "./Discover";

const mapState = ({ users, global }) => ({
  discoverUsers: users.discoverUsers,
  myData: users.myData,
  showPopup: global.showPopup,
  gettingDiscover: users.gettingDiscover,
});
export default connect(mapState)(Discover);
