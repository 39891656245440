import React from "react";
import { Box } from "@mui/system";
import { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import ModeCard from "../../../components/ModesModal/ModeCard";
import useStyles from "../PersonalDetails/PersonalDetails.styles";
import useOnboarding from "./../hooks/useOnboarding";
import { useEffect } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import useConstants from "../../../utils/useConstants";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function Mode() {
  const classes = useStyles();
  const [selectedMode, setSelectedMode] = useState("");
  const { createMode, state, dispatch } = useOnboarding();
  const { mode, isLoading } = state;
  const { modes } = useConstants();
  useEffect(() => {
    dispatch({ mode: selectedMode });
  }, [selectedMode]);
  const { t } = useTranslation();
  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageBox}>
        <form className={classes.formBox} onSubmit={createMode}>
          <Box className={classes.headClass}>
            <Box className={classes.headerText}>
              <span className={classes.headerBlack}>{t("selectMode")}</span>
              <span className={classes.subSub}>
                {`${t("chooseDesireInfo1")} ${t("chooseDesireInfo2")}`}
              </span>
            </Box>
            <Box
              sx={{
                width: 80,
                height: 52,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <CircularProgressbar
                value={55}
                text={`55%`}
                strokeWidth={15}
                styles={buildStyles({
                  textColor: "#0A0A12",
                  pathColor: "#EA0F08",
                  trailColor: "#f4f5f9",
                  textSize: "25px",
                })}
              />
            </Box>
          </Box>
          {modes.map((mde, i) => (
            <ModeCard
              key={i}
              mode={mde}
              selectedMode={mode}
              setSelectedMode={setSelectedMode}
            />
          ))}

          <Box className={classes.continueBtn}>
            <CustomButton
              disabled={!selectedMode?.length ? true : false}
              type="submit"
              loading={!!isLoading}
            >
              {t("continue")}
            </CustomButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default Mode;
