import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  postRoot: {
    width: "100%",
    height: "calc(100vh - 142px)",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    gap: 30,
    backgroundColor: "#fff",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    scrollBehavior: "smooth",
    padding: "20px 0",
    boxSizing: "border-box",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  "@keyframes addStory-icon-animation": {
    "0%": {
      transform: "scale(1)",
    },
    "50%": {
      transform: "scale(0.95)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  outer: {
    width: 70,
    height: 70,
    borderRadius: "50%",
    backgroundColor: "#FFD112",
    position: "absolute",
    top: "85vh",
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "all linear 1000ms",
    animationName: "$addStory-icon-animation",
    animationDuration: "2s",
    animationTimingFunction: "cubic-bezier(0.27, 0.35, 0.71, 1.68)",
    animationIterationCount: "infinite",
  },
  inner: {
    width: 35,
    height: 35,
    borderRadius: "50%",
    backgroundColor: "#FFA000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& span": {
      color: "#fff",
      fontSize: 30,
      fontWeight: 500,
      transform: "translateY(-2px)",
    },
  },

  dropdownContent: {
    position: "absolute",
    top: "calc(85vh - 150px)",
    right: 0,
    width: 130,
    overflow: "auto",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    backgroundColor: "#fff",
    zIndex: 100,
    display: "flex",
    flexDirection: "column",
  },
  dropContent: {
    fontWeight: " 500 !important",
    fontSize: "12px !important",
    cursor: "pointer",
    padding: 15,

    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",

    "&: hover": {
      backgroundColor: "#dadde1",
    },
  },
  noDisplay: {
    display: "none",
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
