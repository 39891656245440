import React, { useState } from "react";
import useStyles from "./MakePost.styles";
import CloseModal from "../../../../resources/img/close_modal.svg";
import { Icon, InputAdornment, TextField, Typography } from "@mui/material";
import { ReactComponent as LocationIcon } from "../../../../resources/img/location-pin.svg";
import { togglePopup } from "../../../../redux/actions/global/global.actions";
import { useDispatch } from "react-redux";
import useFeeds from "../../hooks/useFeeds";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { imageExts, videoExts } from "../../../../utils/constants";
import { getFileExtFromBase64 } from "./../../../../utils/helpers";
import PlacesAutocomplete from "react-places-autocomplete";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useTranslation } from "react-i18next";
import { Circles } from "react-loader-spinner";

function MakePost({ story, isEdit }) {
  const classes = useStyles();
  const reduxDispatch = useDispatch();
  const { t } = useTranslation();
  const {
    state,
    dispatch,
    getGeoLocation,
    createPost,
    createStory,
    updatePost,
    handleChange,
    handleSelect,

    handleCropChange,
    handleCropComplete,
    getCroppedImage,
    crop,
    imgPreviewRef,

    fileToPreview,
    fileToUpload,
    setFileToPreview,
    setFileToUpload,
    setFileName,
    setFiles,
    files,
  } = useFeeds();
  const { caption, location, isLoading } = state;
  const [fileType, setfileType] = useState("");

  useEffect(() => {
    getGeoLocation();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": imageExts, "video/*": videoExts },
    multiple: true,
    maxSize: 15728640,
    minSize: 0,
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      setFileName(acceptedFiles[0].name);

      const fileReader = new FileReader();
      fileReader.readAsDataURL(acceptedFiles[0]);
      console.log(fileReader);
      fileReader.addEventListener("load", () => {
        if (videoExts.includes(`.${getFileExtFromBase64(fileReader.result)}`)) {
          setfileType("video");
          setFileToUpload(acceptedFiles[0]);
          const fil = URL.createObjectURL(acceptedFiles[0]);

          setFileToPreview(fil);
        } else {
          setfileType("image");
          setFileToPreview(fileReader.result);
        }
      });
    },
  });

  useEffect(() => {
    if (isEdit) {
      dispatch({
        caption: isEdit?.caption,
        location: isEdit?.post_location,
      });
    }
  }, []);

  return (
    <div className={classes.postModal}>
      <div className={classes.topHalf}>
        <div className={classes.topHead}>
          <div
            className={classes.close}
            onClick={() => reduxDispatch(togglePopup(false))}
          >
            <img src={CloseModal} alt="close modal" />
          </div>
          <Typography className={classes.headerText}>
            {story
              ? `${t("new")} ${t("story")}`
              : isEdit
              ? `${t("edit")} ${t("post")}`
              : `${t("new")} ${t("post")}`}
          </Typography>
          {fileType === "image" ? (
            fileToUpload ? (
              <button
                onClick={
                  !!isEdit
                    ? (e) => updatePost(e, isEdit.id)
                    : story
                    ? createStory
                    : createPost
                }
                type="submit"
                className={classes.saveBtn}
                disabled={fileToUpload ? false : isLoading}
              >
                {isEdit ? t("save") : t("post")}
              </button>
            ) : (
              <button onClick={getCroppedImage} className={classes.cropBtn}>
                {t("crop")}
              </button>
            )
          ) : (
            <button
              onClick={
                !!isEdit
                  ? (e) => updatePost(e, isEdit.id)
                  : story
                  ? createStory
                  : createPost
              }
              type="submit"
              className={classes.saveBtn}
              disabled={fileToUpload ? false : true}
            >
              {isEdit ? t("save") : t("post")}
            </button>
          )}
        </div>

        <div className={classes.postImage}>
          {isLoading && (
            <Circles
              color="red"
              ariaLabel="circles-loading"
              wrapperClass={classes.topSpinner}
              visible={true}
            />
          )}
          <canvas
            style={{
              height: 100,
              objectFit: "contain",
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 2,
            }}
            ref={imgPreviewRef}
          ></canvas>

          {fileType.length > 0 ? (
            fileType === "video" ? (
              <div style={{ width: "100%", height: "100%" }}>
                <video controls onTouchStart={true}>
                  <source src={fileToPreview} />
                </video>
              </div>
            ) : (
              <ReactCrop
                crop={crop}
                onChange={handleCropChange}
                onComplete={handleCropComplete}
                style={{
                  height: 250,
                }}
                aspect={4 / 5}
              >
                <img
                  src={fileToPreview}
                  alt={""}
                  style={{
                    height: 250,
                  }}
                />
              </ReactCrop>
            )
          ) : (
            <div className={classes.uploaderIcon} {...getRootProps()}>
              <input {...getInputProps()} />
              <span style={{ textAlign: "center" }}>{t("dragDrop")}</span>
            </div>
          )}
        </div>
      </div>
      <div className={classes.lowerHalf}>
        <div>
          <Typography className={classes.captionHead}>
            {t("writeCaption")}
          </Typography>
          <TextField
            multiline
            value={caption}
            onChange={(e) => dispatch({ caption: e.target.value })}
            className={classes.captionField}
          />
        </div>
        {!story && (
          <div>
            <Typography className={classes.captionHead}>
              {t("addLocation")}
            </Typography>
            <PlacesAutocomplete
              value={location}
              onChange={handleChange}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className={classes.wowo}>
                  <TextField
                    {...getInputProps({
                      placeholder: "",
                      className: classes.captionField2,
                      // value: location,
                      multiline: true,
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon>
                              <LocationIcon />
                            </Icon>
                          </InputAdornment>
                        ),
                      },
                    })}
                    value={location}
                  />

                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#d0d0d0", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span
                            onClick={() =>
                              dispatch({ location: suggestion.description })
                            }
                          >
                            {suggestion.description}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        )}
      </div>
    </div>
  );
}

export default MakePost;
