import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useMemo, useState } from "react";
import UserImgComponent from "../../../../components/UserImgComponent";
import UserPreview from "../../../../components/UserPreview";
import { getAge, usernameStringCheck } from "../../../../utils/helpers";
import useMatches from "../../hooks/useMatches";
import { MateStyle } from "./Mate.styles";

const Mate = ({
  setPopupChildren,
  setPreviewUser,
  togglePopup,
  user,
  index,
}) => {
  const classes = MateStyle();

  const [location, setLocation] = useState({});
  const { getUserLocation } = useMatches();

  useMemo(() => {
    user?.location_coords &&
      getUserLocation(
        user?.location_coords?.coordinates[1],
        user?.location_coords?.coordinates[0]
      ).then((res) => setLocation(res));
  }, []);
  return (
    <Grid
      onClick={() => {
        setPreviewUser(user);
        setPopupChildren(<UserPreview />);
        togglePopup(true);
      }}
      className={classes.userWrapper}
      key={index}
    >
      <Box className={classes.avatarBox}>
        <UserImgComponent
          className={classes.avatar}
          alt="u_img"
          src={user?.avatar}
        />
      </Box>
      <Box className={classes.userNameRow}>
        <Typography style={{ maxWidth: 200 }} textOverflow="ellipsis" noWrap>
          <span style={{ color: "#ADABAB" }}>@</span>
          {usernameStringCheck(user.username)},
        </Typography>

        {user.birthday !== null && (
          <Typography>{getAge(user?.birthday)}</Typography>
        )}

        {user?.status === "online" && <Box className={classes.onlineDot} />}
      </Box>
      {user?.location_coords && (
        <Box sx={{ display: "flex" }}>
          <Typography className={classes.state} noWrap>
            {location?.state}
          </Typography>
          {Object.keys(location).length && (
            <Typography color="#ADABAB">, </Typography>
          )}
          <Typography className={classes.country} noWrap>
            {location?.country}
          </Typography>
        </Box>
      )}
    </Grid>
  );
};

export default Mate;
