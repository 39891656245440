import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { PostsEventsStyles } from "./PostEvents.styles";

export default function PostsEvents() {
  const classes = PostsEventsStyles();
  const location = useLocation();
  const currentPath = location.pathname.split("/").pop();
  const basePath = location.pathname.split("/");
  const { userId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  function navigateTo(path) {
    navigate(`/admin/users/edit/${userId}/posts-events/${path}`);
  }
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div
          className={
            currentPath === "posts" || currentPath === "posts-events"
              ? classes.headerItemSel
              : classes.headerItem
          }
          onClick={() => navigateTo("posts")}
        >
          <p
            className={
              currentPath === "posts" || currentPath === "posts-events"
                ? classes.headerText1
                : classes.headerText
            }
          >
            {t("posts")}
          </p>
        </div>
        <div
          className={
            currentPath === "events"
              ? classes.headerItemSel
              : classes.headerItem
          }
          onClick={() => navigateTo("events")}
        >
          <p
            className={
              currentPath === "events"
                ? classes.headerText1
                : classes.headerText
            }
          >
            {t("events")}
          </p>
        </div>
      </div>

      <div className={classes.postCont}>
        <Outlet />
      </div>
    </div>
  );
}
