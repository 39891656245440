import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { togglePopup } from "../../../../../redux/actions/global/global.actions";
import { setMyData } from "../../../../../redux/actions/users/users.actions";
import usersService from "../../../../../services/users.service";
import { imageExts, videoExts } from "../../../../../utils/constants";
import { getToken } from "../../../../../utils/helpers";

const useAccountProfile = () => {
  const [loading, setLoading] = useState(false);
  const [fileToAccept, setFileToAccept] = useState(null);
  const [imgType, setImgType] = useState("");
  const token = getToken();
  const dispatch = useDispatch();
  const myData = useSelector((state) => state.users.myData);
  const { t } = useTranslation();
  const getMe = () => {
    usersService
      .getMe(token)
      .then(({ data }) => {
        dispatch(setMyData(data));
      })
      .catch((err) => {});
  };

  const { open } = useDropzone({
    accept: {
      ...(fileToAccept === "video"
        ? { "video/*": videoExts }
        : { "image/*": imageExts }),
    },
    multiple: false,
    maxSize: 15728640,
    minSize: 0,
    onDrop: (acceptedFiles) => {
      const file = URL.createObjectURL(acceptedFiles[0]);

      if (fileToAccept === "video") {
        onSaveVid(acceptedFiles[0]);
      }

      if (fileToAccept === "image") {
        if (myData?.images?.length > 5) {
          setFileToAccept(null);

          return toast.error(t("cantHave5"));
        }

        if (imgType === "main") {
          onUploadMain(acceptedFiles[0]);
        }

        if (imgType === "others") {
          onUploadOthers(acceptedFiles[0]);
        }
      }
    },

    onFileDialogOpen: () => {
      setImgType("");
      setFileToAccept(null);
    },
    onError: () => {
      setImgType("");
      setFileToAccept(null);
    },
  });

  const onUploadMain = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    usersService
      .uploadMainImage(token, formData)
      .then(({ data }) => {
        if (data?.success) {
          getMe();
          toast.success(data?.message);
        } else {
          toast.error(data?.errors || data?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onUploadOthers = (file) => {
    let formData = new FormData();

    formData.append("file", file);
    setLoading(true);
    usersService
      .addImage(token, formData)
      .then(({ data }) => {
        if (data?.success === true) {
          getMe();
          toast.success(t("successAddImage"));
        } else {
          toast.error(data?.errors || data?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteImage = (imageId) => {
    setLoading(true);

    usersService
      .deleteImage(token, imageId)
      .then((res) => {
        if (res?.success) {
          getMe();
          toast.success(res?.message);
          dispatch(togglePopup(false));
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSaveVid = (video) => {
    if (Object.values(video).length < 1) {
      return toast.error(t("attatchVid"));
    }

    let formData = new FormData();
    formData.append("file", video);
    setLoading(true);
    usersService
      .uploadVidProfile(token, formData)
      .then(({ data }) => {
        if (data?.success) {
          getMe();
          toast.success(data?.message);
        } else {
          toast.error(data?.errors || data?.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteVid = () => {
    setLoading(true);
    usersService
      .delVidProfile(token)
      .then((res) => {
        if (res.success) {
          getMe();
          toast.success(res?.message);
          dispatch(togglePopup(false));
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const onSetAsMain = (imageId) => {
    setLoading(true);

    usersService
      .setAsMain(token, imageId)
      .then((res) => {
        if (res?.success) {
          getMe();
          toast.success(res?.message);
          dispatch(togglePopup(false));
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    onDeleteImage,
    loading,
    fileToAccept,
    setFileToAccept,
    open,
    setImgType,
    onSaveVid,
    onDeleteVid,
    dispatch,
    onSetAsMain,
  };
};

export default useAccountProfile;
