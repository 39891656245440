import {
  Box,
  CircularProgress,
  InputAdornment,
  InputBase,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COLOR } from "../../../constants/theme";
import { colors } from "../../../utils/constants";
import { InfoRenderComp } from "./InfoRenderComp";
import PetsIcon from "@mui/icons-material/Pets";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import LiquorIcon from "@mui/icons-material/Liquor";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import { ReactComponent as ReligionIcon } from "../../../assets/icons/religion.svg";
import CustomButton from "../../../components/CustomButton";
import useSettings from "../hooks/useSettings";
import { Circles } from "react-loader-spinner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import usersService from "../../../services/users.service";
import { debounceHandler, getToken, toFeetInch } from "../../../utils/helpers";
import useSettingConstants from "../../../constants/data/useSettingConstants";
import useConstants from "../../../utils/useConstants";
import { FaStarOfDavid, FaBrain, FaPrayingHands } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Height } from "@mui/icons-material";
import { MobileDatePicker } from "@mui/x-date-pickers";

export const DetailsComp = () => {
  const classes = styles();
  const myData = useSelector((state) => state.users.myData);

  const [gender, setGender] = useState("male");
  const [sexuality, setSexuality] = useState("Straight");

  const [userData, setUserData] = useState({});
  const [selectedMenu, setSelectedMenu] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [isExist, setIsExist] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const token = getToken();
  const [isOpen, setIsOpen] = useState(false);
  const { sexOptions, interestsArray } = useSettingConstants();
  const {
    childrenOptions,
    drinkOptions,
    personalityOptions,
    petOptions,
    religionOptions,
    smokeOptions,
    zodiacSignOptions,
  } = useConstants();
  const { t } = useTranslation();

  const moment = require("moment");
  const date = moment(userData?.birthday || myData?.birthday);
  const formatedDate = date.format("DD/MM/YYYY");

  const date_components = formatedDate.split("/");
  const day = date_components[0];
  const month = date_components[1];
  const year = date_components[2];

  const onValidateUsername = (val) => {
    debounceHandler(() => {
      setIsChecking(true);
      usersService
        .checkUserName(token, val)
        .then(({ data }) => {
          console.log(data.status);
          setIsExist(data.status);
        })
        .finally(() => setIsChecking(false));
    }, 800);
  };

  const onOpenMenu = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(menu);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMenu("");
  };
  const open = Boolean(anchorEl);

  const {
    updateProfileInfo,
    updatingProfile,
    submittingInt,
    onSubmitInterests,
  } = useSettings();
  useEffect(() => {
    setUserData({
      gender: myData?.gender,
      sexuality: myData?.sexuality,
      username: myData?.username,
      height: Number(myData?.height || 0),
      zodaic_sign: myData?.zodaic_sign,
      pet: myData?.pet,
      children: myData?.children,
      drink: myData?.drink,
      smoke: myData?.smoke,
      religion: myData?.religion,
      personality: myData?.personality,
    });
    setSelectedInterests(myData?.interests);
  }, [myData]);

  const selectionChangeHandler = (e) => {
    setUserData({ ...userData, sexuality: e.target.value });
  };

  const onChangeName = (v) => {
    let value = v.trim();

    const first_name = value.split(" ")[0];
    const last_name = value.split(" ")[1];

    setUserData({ ...userData, first_name, last_name });
  };

  const onChangeUsername = (val) => {
    if (val?.charAt(0) === "@") {
      setUserData({
        ...userData,
        username: val?.toLowerCase()?.trim().slice(1),
      });
      onValidateUsername(val?.toLowerCase()?.trim().slice(1));
    } else {
      setUserData({ ...userData, username: `${val?.toLowerCase()?.trim()}` });
      onValidateUsername(`${val?.toLowerCase()?.trim()}`);
    }
  };

  const onPickInterest = (interest) => {
    if (selectedInterests?.map((i) => i?.id).includes(interest?.id)) {
      setSelectedInterests(
        selectedInterests?.filter((i) => i?.id !== interest?.id)
      );
    } else {
      if (selectedInterests.length < 8) {
        setSelectedInterests([...selectedInterests, interest]);
      }
    }
  };
  const onSlideHeight = (event, newValue) => {
    setUserData({
      ...userData,
      height: newValue,
    });
  };

  const handleDatePickerClose = () => {
    setIsOpen(false);
  };
  return (
    <div className={classes.view2}>
      <div>
        <p className={classes.title}>
          {t("bio")} ({t("aboutYou")})
        </p>

        <InputBase
          sx={{ flex: 1, padding: 1 }}
          value={userData?.bio || myData?.bio}
          onChange={({ target }) =>
            setUserData({ ...userData, bio: target.value })
          }
          className={classes.input}
          multiline
          rows={3}
        />
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("fullName")}</p>

        <OutlinedInput
          size="medium"
          placeholder={`${myData?.first_name} ${myData?.last_name}`}
          className={classes.input1}
          onChange={({ target }) => onChangeName(target.value)}
          fullWidth
        />
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("username")}</p>

        <OutlinedInput
          size="medium"
          startAdornment={<InputAdornment position="start">@</InputAdornment>}
          placeholder="username"
          value={userData?.username}
          onChange={({ target }) => onChangeUsername(target.value)}
          className={classes.input1}
          fullWidth
        />
        <Box
          sx={{
            position: "absolute",
            right: 10,
            bottom: 17,
            display: "flex",
            width: 20,
            height: 20,
          }}
        >
          {isChecking ? (
            <CircularProgress size={23} />
          ) : userData?.username?.length > 0 ? (
            isExist === null ? (
              ""
            ) : !isExist ? (
              <ErrorIcon sx={{ color: "red" }} />
            ) : (
              <CheckCircleIcon color="success" />
            )
          ) : (
            ""
          )}
        </Box>
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("dob")}</p>

        <Box className={classes.btnBox}>
          <span className={classes.day} onClick={() => setIsOpen(!isOpen)}>
            {day === "Invalid date" ? "DD" : day}
          </span>
          <span className={classes.month} onClick={() => setIsOpen(!isOpen)}>
            {month || "MM"}
          </span>
          <span className={classes.year} onClick={() => setIsOpen(!isOpen)}>
            {year || "YYYY"}
          </span>
        </Box>
        <Box sx={{ display: "none" }}>
          <MobileDatePicker
            open={isOpen}
            onClose={handleDatePickerClose}
            label="Date mobile"
            inputFormat="MM/DD/YYYY"
            value={
              userData?.birthday ||
              myData?.birthday ||
              new Date(new Date().setFullYear(new Date().getFullYear() - 18))
            }
            onChange={(val) => setUserData({ ...userData, birthday: val })}
            renderInput={(params) => <TextField {...params} />}
            maxDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 18))
            }
          />
        </Box>
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("occupation")}</p>

        <OutlinedInput
          size="medium"
          placeholder={t("enterOccupation")}
          value={userData?.occupation || myData?.occupation}
          className={classes.input1}
          onChange={({ target }) =>
            setUserData({ ...userData, occupation: target.value })
          }
          fullWidth
        />
      </div>

      <div className={classes.rowView}>
        <div
          className={
            userData?.gender === "male" ? classes.sexBtn : classes.sexBtnNone
          }
          onClick={() => {
            setUserData({ ...userData, gender: "male" });
            setGender("male");
          }}
        >
          <p className={classes.sexText}>{t("male")}</p>
        </div>

        <div
          className={
            userData?.gender === "female" ? classes.sexBtn : classes.sexBtnNone
          }
          onClick={() => {
            setUserData({ ...userData, gender: "female" });
            setGender("female");
          }}
        >
          <p className={classes.sexText}>{t("female")}</p>
        </div>
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("sex")}</p>
        <TextField
          value={userData?.sexuality || "..."}
          onChange={selectionChangeHandler}
          style={{
            backgroundColor: "#fff",
            width: "100%",
            paddingLeft: 20,
            fontSize: 13,
          }}
          name={"sexuality"}
          className={classes.sexuality}
          labelId="sexuality"
          id="select"
          select
        >
          {sexOptions.map((item, index) => (
            <MenuItem key={index} value={item.value.toLowerCase()}>
              {item.title}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <div className={classes.inputView}>
        <p className={classes.title1}>{t("moreInfo")}</p>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box className={classes.infoTitle}>
              <Height className={classes.infoIcon} />
              <Typography fontSize={13}>{t("height")}</Typography>
            </Box>
            <Typography fontSize={13}>
              {`${toFeetInch(userData?.height)} (${
                (userData?.height || 0) / 100
              }m)`}
            </Typography>
          </Box>

          <Slider
            getAriaLabel={() => t("height")}
            value={Number(userData?.height || myData?.height)}
            onChange={onSlideHeight}
            valueLabelDisplay="off"
            color="error"
            max={244}
            min={0}
          />
        </Box>
        <Box
          id="zodaic_sign"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "zodaic_sign")}
        >
          <Box className={classes.infoTitle}>
            <FaStarOfDavid className={classes.infoIcon} />

            <Typography fontSize={13}>{t("zodiacSign")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              zodiacSignOptions.filter(
                (item) => item?.value === userData?.zodaic_sign
              )[0]?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="zodaic_sign"
          open={selectedMenu === "zodaic_sign" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {zodiacSignOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, zodaic_sign: item.value });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>
        <Box
          id="pet"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "pet")}
        >
          <Box className={classes.infoTitle}>
            <PetsIcon className={classes.infoIcon} />
            <Typography fontSize={13}>Pet</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              petOptions.filter((item) => item?.value === userData?.pet)[0]
                ?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="pet"
          open={selectedMenu === "pet" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {petOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, pet: item.value });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="children"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "children")}
        >
          <Box className={classes.infoTitle}>
            <ChildCareIcon className={classes.infoIcon} />
            <Typography fontSize={13}>{t("children")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              childrenOptions.filter(
                (item) => item?.value === userData?.children
              )[0]?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="children"
          open={selectedMenu === "children" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {childrenOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, children: item.value });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="drink"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "drink")}
        >
          <Box className={classes.infoTitle}>
            <LiquorIcon className={classes.infoIcon} />
            <Typography fontSize={13}>{t("drinkT")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              drinkOptions.filter((item) => item?.value === userData?.drink)[0]
                ?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="drink"
          open={selectedMenu === "drink" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {drinkOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, drink: item.value });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="smoke"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "smoke")}
        >
          <Box className={classes.infoTitle}>
            <SmokingRoomsIcon className={classes.infoIcon} />
            <Typography fontSize={13}>{t("smokeT")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              smokeOptions.filter((item) => item?.value === userData?.smoke)[0]
                ?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="smoke"
          open={selectedMenu === "smoke" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {smokeOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, smoke: item.value });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="religion"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "religion")}
        >
          <Box className={classes.infoTitle}>
            <ReligionIcon className={classes.infoIcon} />
            <Typography fontSize={13}>{t("religion")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              religionOptions.filter(
                (item) => item?.value === userData?.religion
              )[0]?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="religion"
          open={selectedMenu === "religion" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {religionOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, religion: item.value });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="personality"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "personality")}
        >
          <Box className={classes.infoTitle}>
            <FaBrain className={classes.infoIcon} />
            <Typography fontSize={13}>{t("personCharacter")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              personalityOptions.filter(
                (item) => item?.value === userData?.personality
              )[0]?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="personality"
          open={selectedMenu === "personality" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {personalityOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, personality: item.value });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <CustomButton
          disable={updatingProfile}
          className={classes.saveProfileBtn}
          onClick={() => updateProfileInfo(userData)}
        >
          {updatingProfile ? (
            <Circles color="#fff" width={15} height={15} />
          ) : (
            t("saveProfile")
          )}
        </CustomButton>
        {/* <InfoRenderComp title={"Religion"} content={"Christian"} />
        <InfoRenderComp title={"Height"} content={"106cm"} />
        <InfoRenderComp title={"Weight"} content={"100 pounds"} /> */}
      </div>

      <div className={classes.inputView}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className={classes.title1}>{t("interests")}</p>
          <Typography fontWeight={500}>{`${
            selectedInterests?.length || 0
          }/8`}</Typography>
        </Box>

        <div className={classes.wrapView}>
          {interestsArray?.map((item, i) => (
            <Box
              onClick={() => onPickInterest(item)}
              key={i}
              className={classes.interestBox}
              style={{
                backgroundColor:
                  colors[Math.floor(Math.random() * colors.length)],
                border: selectedInterests?.map((i) => i?.id)?.includes(item?.id)
                  ? "2px solid #EA0F08"
                  : "none",
              }}
            >
              <p className={classes.content}>#{item.name}</p>
            </Box>
          ))}
        </div>
      </div>
      <CustomButton
        disable={submittingInt}
        className={classes.saveProfileBtn}
        onClick={() => onSubmitInterests(selectedInterests.map((i) => i?.id))}
      >
        {submittingInt ? (
          <Circles color="#fff" width={15} height={15} />
        ) : (
          `${t("submit")} ${t("interests")}`
        )}
      </CustomButton>
    </div>
  );
};

export const styles = makeStyles((theme) => ({
  view2: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    alignSelf: "center",
    // alignItems: "flex-start",
    // backgroundColor: "blue"
  },
  title: {
    fontWeight: "700",
    fontSize: 13,
  },
  title1: {
    fontWeight: "600",
    fontSize: 15,
  },
  input: {
    width: "100%",
    backgroundColor: "#fff",
    padding: 20,
    borderStyle: "none !important",
    fontSize: 12,
    borderWidth: "0px",
    borderColor: "transparent !important",
    outline: "none",
  },
  inputView: {
    marginTop: 20,
    position: "relative",
  },
  wrapView: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "90%",
  },
  input1: {
    width: "90%",
    backgroundColor: "#fff",
    fontSize: 14,
    alignSelf: "center",
    borderWidth: "0.5px",
    borderColor: "#293146",
    outline: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  sexuality: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    alignSelf: "flex-start",
    marginTop: 20,
    // backgroundColor: "blue"
  },
  sexBtn: {
    // border: "1px solid #707070",
    backgroundColor: COLOR.light,
    width: "45%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 18,
    cursor: "pointer",
    border: "none",
  },

  sexText: {
    fontWeight: "500",
    fontSize: 13,
  },
  sexBtnNone: {
    // border: "1px solid #707070",
    backgroundColor: "#fff",
    width: "40%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    cursor: "pointer",
    border: "none",
  },
  content: {
    fontWeight: "400",
    fontSize: 13,
    textAlign: "center",
  },
  closeBtn: {
    borderRadius: 100,
    padding: 5,
    width: 15,
    height: 15,
    backgroundColor: "#fff",
    border: "1px solid",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    cursor: "pointer",
    position: "absolute",
    right: -12,
    top: -12,
  },
  moreInfoRow: {
    display: "flex !important",
    justifyContent: "space-between",
    marginBottom: 10,
    padding: "10px 0px",
    borderBottom: "1px solid #70707014",
    cursor: "pointer",
  },
  infoTitle: {
    display: "flex !important",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  infoIcon: {
    color: "#EA0F08",
    height: "20px !important",
    width: "20px !important",
  },
  saveProfileBtn: {
    borderStyle: "none",
    backgroundColor: "#EA0F08",
    padding: 15,
    width: "100%",
    color: "#fff",
    borderRadius: 3,
    cursor: "pointer",
    fontWeight: "600",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "center",
  },
  interestBox: {
    padding: "0px 10px",
    borderRadius: 5,
    position: "relative",
    margin: 10,
    cursor: "pointer",
  },
  day: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "20%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  month: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "30%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  year: {
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid transparent",
    background: "#FFE7E6",
    width: "50%",
    textAlign: "center",
    fontSize: "14px !important",
    cursor: "pointer",
  },
  btnBox: {
    display: "flex",
    gap: 20,
  },
}));
