import { Box, Menu, MenuItem, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ManageLocationStyles } from "./ManageLocation.styles";
import { ReactComponent as LocationIcon } from "../../../../resources/img/location-pin.svg";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import json from "country-region-data/data.json";
const ManageLocation = () => {
  const classes = ManageLocationStyles();
  const myData = useSelector((state) => state.users.myData);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const {
    onSwitchIncognito,
    locationEnabled,
    openLocationSettings,
    handleSelectClubCountry,
  } = useSettings();
  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  return (
    <Box className={classes.locationsWrapper}>
      <Typography fontWeight={600}>{t("manageLocation")}</Typography>
      <Box className={classes.row}>
        <Box>
          <Typography className={classes.text} fontWeight={500}>
            {t("enableLocation")}
          </Typography>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <LocationIcon style={{ color: "#000" }} />
            <Typography className={classes.text} fontSize={13}>
              {myData?.state &&
                json
                  .filter(
                    (item) => item?.countryShortCode == myData?.country
                  )[0]
                  .regions.filter((item) =>
                    [item?.name, item?.shortCode].some((item) =>
                      myData?.state.split(",").includes(item)
                    )
                  )[0]?.name}
              ,{" "}
              {myData?.country &&
                json.filter(
                  (item) => item?.countryShortCode == myData?.country
                )[0]?.countryName}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Switch
            sx={{ color: "#EA0F08" }}
            checked={locationEnabled}
            onChange={() => openLocationSettings(locationEnabled)}
          />
        </Box>
      </Box>

      {/*Incognito*/}
      <Box className={classes.row} gap={12}>
        <Box>
          <Typography className={classes.text} fontWeight={500}>
            {t("incognito")}
          </Typography>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography className={classes.text} fontSize={12}>
              {t("incognito1")}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Switch
            sx={{ color: "#EA0F08" }}
            checked={myData?.incognito}
            onChange={onSwitchIncognito}
          />
        </Box>
      </Box>

      {/*Incognito*/}
      <Box className={classes.row} gap={12}>
        <Box
          id="club_country"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          onClick={(e) => (myData?.club_country ? null : onOpenMenu(e))}
          style={{ cursor: "pointer", opacity: myData?.club_country ? 0.5 : 1 }}
          disabled={myData?.club_country ? true : false}
        >
          <Typography className={classes.text} fontWeight={500}>
            <span style={{ color: "red", fontWeight: 600 }}>* </span>{" "}
            {t("selectCountry")} (
            <span style={{ color: "red" }}>{t("clubCountryOnly")}</span>)
          </Typography>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography className={classes.text} fontSize={12}>
              {t("chooseCountryOption")}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            fontSize={16}
            fontWeight={500}
            color="red"
            style={{ marginRight: 10 }}
          >
            {myData?.club_country &&
              json.filter(
                (item) => item?.countryShortCode == myData?.club_country
              )[0]?.countryName}
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="club_country"
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {json.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                handleSelectClubCountry(item.countryShortCode);
              }}
            >
              {item?.countryName}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};

export default ManageLocation;
