import React from "react";
import Posts from "./components/Posts";
import Story from "./components/Story";
import { FeedsStyle } from "./Feeds.style";

const Feeds = () => {
  const classes = FeedsStyle();

  return (
    <div className={classes.feedsWrapper}>
      <Story />
      <Posts />
    </div>
  );
};

export default Feeds;
