import {
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ChangePasswordStyles } from "./ChangePassword.styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useDispatch } from "react-redux";
import { togglePopup } from "../../redux/actions/global/global.actions";
import CustomButton from "../CustomButton";
import useChangePassword from "./hooks/useChangePassword";
import { Circles } from "react-loader-spinner";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ChangePassword = () => {
  const classes = ChangePasswordStyles();
  const dispatch = useDispatch();

  const {
    pass,
    setPass,
    newPass,
    setNewPass,
    cNewPass,
    setCNewPass,
    submitting,
    onSubmitPass,
    showPass,
    setShowPass,
    showNewPass,
    setShowNewPass,
    showCNewPass,
    setShowCNewPass,
  } = useChangePassword();
  return (
    <Box className={classes.changePassContainer}>
      <Box className={classes.top}>
        <Typography variant="h6" className={classes.topText}>Change Password</Typography>

        <IconButton onClick={() => dispatch(togglePopup(false))}>
          <HighlightOffIcon color="#000" fontSize="small" />
        </IconButton>
      </Box>
      <Box className={classes.textFieldsContainer}>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Current Password</Typography>
          <OutlinedInput
            type={showPass ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPass(!showPass)}
                >
                  {showPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            size="small"
            className={classes.inputField}
            value={pass}
            onChange={({ target }) => setPass(target.value)}
          />
        </Box>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Change Password</Typography>
          <OutlinedInput
            type={showNewPass ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowNewPass(!showNewPass)}
                >
                  {showNewPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            size="small"
            className={classes.inputField}
            value={newPass}
            onChange={({ target }) => setNewPass(target.value)}
          />
        </Box>
        <Box className={classes.textFieldsWrapper}>
          <Typography className={classes.label}>Confirm Password</Typography>
          <OutlinedInput
            type={showCNewPass ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowCNewPass(!showCNewPass)}
                >
                  {showCNewPass ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            size="small"
            className={classes.inputField}
            value={cNewPass}
            onChange={({ target }) => setCNewPass(target.value)}
          />
        </Box>

        <CustomButton
          onClick={onSubmitPass}
          disabled={submitting}
          className={classes.confirmBtn}
        >
          {submitting ? (
            <Circles width={15} height={15} color="#fff" />
          ) : (
            " Confirm Password"
          )}
        </CustomButton>
      </Box>
    </Box>
  );
};

export default ChangePassword;
