import { makeStyles } from "@mui/styles";
const useStyle = makeStyles(({ theme }) => ({
  Wrapper: {
    position: "absolute",
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
    right: "1rem",
    zIndex: "10",
    padding: ".5rem",
    top: "2rem",
    gap: ".5rem",
    "& span": {
      width: "100%",
      whiteSpace: "nowrap",
      cursor: "pointer",
    },
  },
}));

export default useStyle;
