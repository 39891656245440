import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  setGettingNearby,
  setNearbyUsers,
} from "../../../redux/actions/users/users.actions";
import usersService from "../../../services/users.service";
import { getToken, getUser, shuffledArray } from "../../../utils/helpers";

const useNearby = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const myData = useSelector((state) => state.users.myData);
  const nearbyUsers = useSelector((state) => state.users.nearbyUsers);
  const gettingDiscover = useSelector((state) => state?.users.gettingDiscover);
  const [gettingMoreNearby, setGettingMoreNearby] = useState(false);
  const dispatch = useDispatch();

  const token = getToken();
  const usersDefaultFilter = useSelector(
    (state) => state.users.usersDefaultFilter
  );

  const getNearbyUsers = (batch = 1) => {
    const usersIntString = String(
      usersDefaultFilter.interests.map((selected) => selected?.id)
    );
    dispatch(setGettingNearby(true));

    usersService
      .getUsers(
        token,
        1000,
        batch,
        usersIntString,
        usersDefaultFilter?.gender,
        usersDefaultFilter?.state || "",
        usersDefaultFilter?.country || "",
        usersDefaultFilter?.min_age,
        usersDefaultFilter?.max_age,
        usersDefaultFilter?.category,
        usersDefaultFilter?.mode
      )
      .then((res) => {
        if (res.success === true) {
          batch === 1
            ? dispatch(setNearbyUsers(shuffledArray(res.data.data)))
            : dispatch(
                setNearbyUsers([
                  ...nearbyUsers,
                  ...shuffledArray(res.data.data),
                ])
              );
        } else {
        }
      })
      .catch((error) => toast.error(error?.message))
      .finally(() => {
        setIsRefreshing(false);
        setGettingMoreNearby(false);
        dispatch(setGettingNearby(false));
      });
  };

  const onGetMoreNearby = (batch) => {
    setGettingMoreNearby(true);
    getNearbyUsers(batch);
  };

  return { getNearbyUsers };
};

export default useNearby;
