import { makeStyles } from "@mui/styles";

export const FeedsStyle = makeStyles((theme) => ({
  feedsWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));
