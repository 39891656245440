import { useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setDiscoverUsers,
  setGettingDiscover,
} from "../../../redux/actions/users/users.actions";
import {
  setPopupChildren,
  togglePopup,
} from "../../../redux/actions/global/global.actions";
import usersService from "../../../services/users.service";
import { getToken, shuffledArray } from "../../../utils/helpers";
import Gifts from "../../../components/Gifts";
import IncognitoModal from "../../../components/IncognitoModal/IncognitoModal";
import Upgrade from "../../../components/PremiumModals/Upgrade/Upgrade";

const useDiscover = () => {
  const swipeCardRef = useRef(null);
  const firstUserRef = useRef(null);
  const discoverUsers = useSelector((state) => state.users.discoverUsers);
  const myData = useSelector((state) => state.users.myData);
  const dispatch = useDispatch();
  const [currentUser, setCurrentUser] = useState({});
  const [currentUserGallery, setCurrentUserGallery] = useState([]);
  const [showPremiumBoost, setShowPremiumBoost] = useState(false);
  const [showUsersMatch, setShowUsersMatch] = useState(false);
  const [currentUserIndex, setCurrentUserIndex] = useState(null);
  const usersDefaultFilter = useSelector(
    (state) => state.users.usersDefaultFilter
  );
  const token = getToken();

  const getDiscoverUsers = (batch = 1) => {
    const usersIntString = String(
      usersDefaultFilter.interests.map((selected) => selected?.id)
    );
    dispatch(setGettingDiscover(true));

    usersService
      .getUsers(
        token,
        1000,
        batch,
        usersIntString,
        usersDefaultFilter?.gender,
        usersDefaultFilter?.state || "",
        usersDefaultFilter?.country || "",
        usersDefaultFilter?.min_age,
        usersDefaultFilter?.max_age,
        usersDefaultFilter?.category,
        usersDefaultFilter?.mode
      )
      .then((res) => {
        if (res.success === true) {
          batch === 1
            ? dispatch(setDiscoverUsers(shuffledArray(res.data.data)))
            : dispatch(
                setDiscoverUsers([
                  ...discoverUsers,
                  ...shuffledArray(res.data.data),
                ])
              );
        } else {
          console.log(res);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(setGettingDiscover(false));
      });
  };

  const toggleUserLike = (user) => {
    if (!user?.liked) {
      usersService
        .toggleUserLike(token, user?.id)
        .then((res) => console.log("Like toggled success :: ", res))
        .catch((err) => console.log(err));
    }
    if (user?.likes_you) {
      setShowUsersMatch(true);
    }
  };

  const onSwipeOut = (dir, user) => {
    const index = discoverUsers?.findIndex((u) => u?.id === user?.id);

    if (index > -1 && discoverUsers?.length > index + 1) {
      setCurrentUser(discoverUsers[index + 1]);
      let images = [];
      if (discoverUsers[index + 1]?.images?.length) {
        images = discoverUsers[index + 1]?.images
          .filter((img) => !img?.isMain && img?.url)
          .map((i) => i?.url);
      }
      setCurrentUserGallery([
        discoverUsers[index + 1]?.avatar && discoverUsers[index + 1]?.avatar,
        discoverUsers[index + 1]?.vid && discoverUsers[index + 1]?.vid,
        ...images,
      ]);

      if (dir === "right") {
        toggleUserLike(user);
      }
      onSwipeUser(user?.id);
    } else {
      getDiscoverUsers(Math.ceil(discoverUsers?.length / 1000) + 1);
    }

    if (index == 15) {
      dispatch(togglePopup(true));
      dispatch(setPopupChildren(<IncognitoModal />));
    }

    if (index == 10) {
      dispatch(togglePopup(true));
      dispatch(setPopupChildren(<Upgrade />));
    }
  };

  const onSwipeUser = (userId) => {
    usersService
      .swipeUser(token, userId)
      .then((res) => console.log(res, "on swipe"))
      .catch((err) => console.log(err, "on swipe"));
  };

  const onResetSwipes = () => {
    usersService
      .resetSwipes(token)
      .then((res) => console.log(res, "on reset swipe"))
      .catch((err) => console.log(err, "on reset swipe"));
  };

  const onOpenGifts = (user) => {
    dispatch(setPopupChildren(<Gifts reciever={user} goTo="messages" />));
    dispatch(togglePopup(true));
  };

  const handleBacktrack = async (cardRef, userId) => {
    if (myData?.active_subscription) {
      const index = discoverUsers?.findIndex((user) => user?.id === userId);
      if (index > 0 && discoverUsers?.length > index + 1) {
        setCurrentUser(discoverUsers[index - 1]);
        let images = [];
        if (discoverUsers[index - 1]?.images?.length) {
          images = discoverUsers[index - 1]?.images
            .filter((img) => !img?.isMain && img?.url)
            .map((i) => i?.url);
        }
        setCurrentUserGallery([
          discoverUsers[index - 1]?.avatar && discoverUsers[index - 1]?.avatar,
          discoverUsers[index - 1]?.vid && discoverUsers[index - 1]?.vid,
          ...images,
        ]);
      }
      console.log(cardRef.current);
      await cardRef.current.restoreCard();
    } else {
      setShowPremiumBoost(true);
    }
  };

  return {
    getDiscoverUsers,
    currentUser,
    setCurrentUser,
    currentUserIndex,
    setCurrentUserIndex,
    swipeCardRef,
    onSwipeOut,
    firstUserRef,
    onOpenGifts,
    setCurrentUserGallery,
    currentUserGallery,
    handleBacktrack,
    showPremiumBoost,
    showUsersMatch,
    setShowPremiumBoost,
    setShowUsersMatch,
  };
};

export default useDiscover;
