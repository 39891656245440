import { makeStyles } from "@mui/styles";

export const MyEventsStyles = makeStyles(() => ({
  container: {
    width: "100%",
    height: "80vh",
    overflowY: "scroll",
    display: "flex !important",
    flexDirection: "column",
    gap: 20,
    backgroundColor: "#F7F7F7",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    scrollBehavior: "smooth",
    boxSizing: "border-box",

    "&::-webkit-scrollbar": {
      display: "none",
    },
    paddingBottom: 20,
  },
}));
