import { makeStyles } from "@mui/styles";

export const MatchesHeaderStyle = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "#fff",
    marginTop: 20,
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -5px",
    dispaly: "flex !important",
    alignItems: "center",
    flexDirection: "row !important",
    padding: "10px 20px",
    overFlow: "auto",
  },
  text: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
  },
  userNode: {
    width: 50,
    height: 50,
    borderRadius: "100%",
    // overflow: "hidden",
    position: "relative",
    margin: "0px 8px",
    cursor: "pointer",
    "& .MuiAvatar-root": {
      width: "100%",
      height: "100%",
    },
  },
  likeCountWrap: {
    flexDirection: "row",
    backgroundColor: "#FFCECC",
    padding: 3,
    borderRadius: 100,
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 2,
    position: "absolute",
    bottom: 1,
    right: -5,
    gap: 2,
    display: "flex !important",
  },
  img: {
    height: 50,
    width: 50,
    borderRadius: "100%",
  },
}));
