import {
  AccountCircle,
  KeyboardArrowDown,
  PriceChange,
  Report,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import AdminGlobalHeader from "../../../components/AdminGlobalHeader";
import ChartCard from "../../../components/ChartCard";
import CustomTable from "../../../components/CustomTable";
import { RevenueStyles } from "./Revenue.style";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import useAdminRevenue from "./hooks/useAdminRevenue";
import { useState } from "react";
import MoreMenu from "../../../components/MoreMenu/MoreMenu";
import CustomDropDown from "../../../components/CustomDropDown/CustomDropDown";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

function DefaultDropdownIcon() {
  const classes = RevenueStyles();
  return <KeyboardArrowDown className={classes.arrowDownIcon} />;
}

const Revenue = () => {
  const classes = RevenueStyles();
  const {
    revenueData,
    isLoading,
    revenues,
    filterValue,
    filterData,
    handleChangeFilter,
  } = useAdminRevenue();
  const { purchasedCoins, revenue, subscriptions } = revenueData;

  return isLoading ? (
    <Skeleton className={classes.pageWrapper}></Skeleton>
  ) : (
    <Box className={classes.pageWrapper}>
      <AdminGlobalHeader />
      <Box className={classes.cardsRow}>
        <ChartCard
          icon={<AccountCircle style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Total Revenue"
          description=""
          figure={revenue?.totalRevenue}
          trend="positive"
          data={revenue?.groupedByDateRange}
          trendValue="18.6"
        />

        <ChartCard
          icon={<Report style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Total Subscriptions"
          description=""
          figure={subscriptions?.totalSubscriptions}
          trend="positive"
          data={subscriptions?.groupedByDateRange}
        />
        <ChartCard
          icon={<PriceChange style={{ color: "#EA0F08", fontSize: 30 }} />}
          title="Total Coins Purchase"
          description=""
          figure={purchasedCoins?.totalPurchasedCoins}
          trend="positive"
          trendValue="78"
          data={purchasedCoins?.groupedByDateRange}
        />
      </Box>
      <Box className={classes.tableSection} sx={classes.tableSection}>
        <Typography fontWeight={500}>Revenue Summary</Typography>
        <Box className={classes.revenueTableHeader}>
          <CustomDropDown
            data={filterData}
            value={filterValue}
            handleChange={handleChangeFilter}
          />
        </Box>
        <CustomTable
          noSort
          data={{
            th: [
              <span key={1}>Username</span>,
              <span key={2}>Type</span>,
              <span key={3}>Date</span>,
              <span key={4}>Amount</span>,
              <span key={4}>Actions</span>,
            ],
            rows: revenues?.map(
              ({ user, type, date, amount, id, source, createdAt }, index) => [
                <Box key={id} className={classes.tableRow}>
                  <Box className={classes.userCell}>
                    {/* <Box className={classes.greyBg} /> */}
                    {`${user?.username || "--"}`}
                  </Box>
                </Box>,
                <Box key={id} className={classes.tableRow}>
                  {source || "--"}
                </Box>,
                <Box key={id} className={classes.tableRow}>
                  {moment(createdAt).format("DD MMM YYYY")}
                </Box>,
                <Box key={id} className={classes.tableRow}>
                  {amount}
                </Box>,
                <Box key={index} className={classes.tableRow}>
                  <MoreMenu
                    firstText={"View Receipt"}
                    // secondText={"Make inactive"}
                    className={classes.dropdownIcon}
                  />
                </Box>,
              ]
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default Revenue;
