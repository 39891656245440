import { Box } from "@mui/material";
import React from "react";
import EditHeader from "./components/EditHeader/EditHeader";
import EditNavigation from "./components/EditNavigation/EditNavigation";
import { useEditStyles } from "./Edit.style";
import useEditUser from "./hooks/useEditUser";
import { useSelector } from "react-redux";

export default function Edit({ children }) {
  const classes = useEditStyles();
  const { isLoading } = useEditUser();
  return (
    <Box className={classes.Wrapper}>
      <EditHeader />
      <Box className={classes.body}>
        <Box className={classes.navigation}>
          <EditNavigation />
        </Box>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Box>
  );
}
