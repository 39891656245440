import {
  SET_DISCOVER_USERS,
  SET_GETTING_DISCOVER,
  SET_GETTING_MATES,
  SET_GETTING_NEARBY,
  SET_GETTING_PREVIEW,
  SET_MATES,
  SET_MY_ACTIVITIES,
  SET_MY_COORDS,
  SET_MY_DATA,
  SET_MY_REFERRALS,
  SET_NEARBY_USERS,
  SET_PREVIEW_USER,
  SET_USERS_DEFAULT_FILTER,
  SET_WHO_I_LIKE,
  SET_WHO_LIKES_ME,
} from "./users.types";

export const setMyData = (payload) => ({
  type: SET_MY_DATA,
  payload,
});

export const setDiscoverUsers = (payload) => ({
  type: SET_DISCOVER_USERS,
  payload,
});

export const setGettingDiscover = (payload) => ({
  type: SET_GETTING_DISCOVER,
  payload,
});

export const setNearbyUsers = (payload) => ({
  type: SET_NEARBY_USERS,
  payload,
});

export const setGettingNearby = (payload) => ({
  type: SET_GETTING_NEARBY,
  payload,
});

export const setMyCoords = (payload) => ({
  type: SET_MY_COORDS,
  payload,
});

export const setPreviewUser = (payload) => ({
  type: SET_PREVIEW_USER,
  payload,
});

export const setGettingPreview = (payload) => ({
  type: SET_GETTING_PREVIEW,
  payload,
});

export const setUsersDefaultFilter = (payload) => ({
  type: SET_USERS_DEFAULT_FILTER,
  payload,
});

export const setWhoLikesMe = (payload) => ({
  type: SET_WHO_LIKES_ME,
  payload,
});

export const setMates = (payload) => ({
  type: SET_MATES,
  payload,
});

export const setGettingMates = (payload) => ({
  type: SET_GETTING_MATES,
  payload,
});

export const setWhoILike = (payload) => ({
  type: SET_WHO_I_LIKE,
  payload,
});
export const setMyActivities = (payload) => ({
  type: SET_MY_ACTIVITIES,
  payload,
});
export const setMyReferrals = (payload) => ({
  type: SET_MY_REFERRALS,
  payload,
});
