import { makeStyles } from "@mui/styles";

const drawerWidth = 280;

export const LeftSideBarStyle = makeStyles((theme) => ({
  // navDrawerWrapper: {
  //   "& .MuiButtonBase-root:hover": {
  //     backgroundColor: "#F4F5F9",
  //   },
  // },
  drawerStyle: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    width: drawerWidth,
    overflowX: "hidden",
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      overflowX: "hidden",
      borderRight: "none",
      paddingLeft: 20,
      boxShadow:
        "rgba(0, 0, 0, 0.1) 0px 4px 10px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
    },
  },
  topLeftDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: "64px",
  },
  imgWrapper: {
    padding: 0,
    marginLeft: -35,
    marginTop: -50,
    marginBottom: -70,
    marginRight: -40,
  },
  img: { width: 200, height: 200, margin: 0 },
  menuHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    paddingLeft: "15px",
    marginBottom: "20px",
  },
  borderlessText: {
    width: 150,
    fontSize: 12,
    fontWeight: 500,
  },
  logoDiv: {},
  logoStyle: {},
  listItemWrapper: {
    border: "1px solid blue",
  },
  active: {
    backgroundColor: "#F4F5F9",
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
    "& .MuiButtonBase-root": {
      backgroundColor: "#fff",
    },
  },
  inActive: {
    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
    "& .MuiButtonBase-root": {
      backgroundColor: "#fff",
    },
  },
  filterBtn: {
    "& .MuiTouchRipple": {
      backgroundColor: "red",
    },
  },
  avatarWrapper: {
    width: 70,
    height: 70,
    padding: "2px !important",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& .MuiAvatar-root": {
      width: "100%",
      height: "100%",
    },
  },
  avatar: { width: 70, height: 70, border: "1px solid #fff" },
  userNameText: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "5px 0px 5px 10px",
  },
  settingsBtn: {
    padding: 5,
    marginLeft: 4,
    borderRadius: "3px !important",
    backgroundColor: "#F7F7F7 !important",
    opacity: 0.5,
    marginRight: 4,
  },
  m8sIcon: {
    width: 25,
    height: 25,
    padding: 0,
  },
  coinRow: {
    display: "flex !important",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userIconWrap: {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex !important",
    marginTop: 5,
  },
  buyMore: {
    textDecoration: "underline",
    fontSize: "13px !important",
    color: "red",
    fontWeight: "600 !important",
    cursor: "pointer",
  },
  filterIcon: {
    padding: 5,
    borderRadius: 3,
    backgroundColor: "#F7F7F7",
    opacity: 0.5,
  },
  bottomBoxWrapper: {
    width: 240,
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
  },
  bottomBox: {
    width: 180,
    height: 140,
    backgroundColor: "#EEF2F6",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    padding: 20,
  },
  newMessageDot: {
    height: 10,
    width: 10,
    borderRadius: 10,
    backgroundColor: "#EA0F08",
    position: "absolute",
    top: 10,
    left: 30,
    animation: `$blink 1s ${theme.transitions.easing.easeInOut} infinite`,
  },
  "@keyframes blink": {
    "0%": {
      backgroundColor: "#EA0F08",
    },

    "50%": {
      backgroundColor: "#f4bcb9",
    },
    "100%": {
      backgroundColor: "#EA0F08",
    },
  },
}));
