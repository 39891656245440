import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CustomButton from "../CustomButton";
import { Circles } from "react-loader-spinner";
import { MembershipsStyles } from "./Memberships.style";
import { ReactComponent as BoosterIcon } from "../../assets/icons/boosters.svg";
import useMemberships from "./hooks/useMemberships";
import MembershipCard from "./components/MembershipCard";
import { useTranslation } from "react-i18next";
import useConstants from "../../utils/useConstants";
const Memberships = ({ togglePopup, myData }) => {
  const classes = MembershipsStyles();
  const [selected, setSelected] = useState(null);

  const { subscribing, getMembership, onSelectMembership, currentPlan } =
    useMemberships();

  const { t } = useTranslation();
  const { memberships } = useConstants();

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.top}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <BoosterIcon width={50} height={50} stroke="#000" fill="#000" />{" "}
          <Typography className={classes.topTitleText}>
            {t("upgradeMember")}
          </Typography>
        </Box>

        <IconButton onClick={() => togglePopup(false)}>
          <HighlightOffIcon color="#000" fontSize="small" />
        </IconButton>
      </Box>
      <Box
        style={{
          gap: 16,
          flexDirection: "column",
          margin: "20px 0px",
          width: "100%",
        }}
        sx={{ display: "flex" }}
      >
        {memberships.map((membership, i) => (
          <MembershipCard
            membership={membership}
            i={i}
            selected={selected}
            onClick={() => setSelected(membership?.id)}
            currentPlan={currentPlan}
          />
        ))}
      </Box>
      <CustomButton
        disabled={subscribing}
        onClick={() => onSelectMembership(selected)}
        className={classes.saveBtn}
      >
        {subscribing ? (
          <Circles
            height="20"
            width="20"
            color="#E8E8E8"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <Typography style={{ color: "#fff", fontWeight: "700" }}>
            {t("subscribe")}
          </Typography>
        )}
      </CustomButton>
    </Box>
  );
};

export default Memberships;
