import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ModesModalStyles } from "./ModesModal.styles";
import useModesModal from "./hooks/useModesModal";
import CustomButton from "../CustomButton";
import { Circles } from "react-loader-spinner";
import ModeCard from "./ModeCard";
import useConstants from "../../utils/useConstants";

const ModesModal = ({ togglePopup, myData }) => {
  const classes = ModesModalStyles();
  const [selectedMode, setSelectedMode] = useState(myData?.mode || "");

  const { modes } = useConstants();

  const { onSaveMode, savingMode } = useModesModal();
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.top}>
        <Typography className={classes.topTitleText}>
          Why are you here
        </Typography>

        <IconButton onClick={() => togglePopup(false)}>
          <HighlightOffIcon color="#000" fontSize="small" />
        </IconButton>
      </Box>
      <Typography className={classes.topDescriptionText}>
        Tell your audience why you are here. You can change this whenever you
        like.
      </Typography>
      <Box
        style={{ gap: 16, flexDirection: "column", margin: "20px 0px" }}
        sx={{ display: "flex" }}
      >
        {modes.map((mode, i) => (
          <ModeCard
            key={i}
            mode={mode}
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
          />
        ))}
      </Box>
      <CustomButton
        disabled={savingMode}
        onClick={() => onSaveMode(selectedMode)}
        className={classes.saveBtn}
      >
        {savingMode ? (
          <Circles
            height="20"
            width="20"
            color="#E8E8E8"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <Typography style={{ color: "#fff", fontWeight: "700" }}>
            Continue
          </Typography>
        )}
      </CustomButton>
    </Box>
  );
};

export default ModesModal;
