import {
  Box,
  CircularProgress,
  InputAdornment,
  InputBase,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { colors } from "../../../../../../../../../utils/constants";
import PetsIcon from "@mui/icons-material/Pets";
import ChildCareIcon from "@mui/icons-material/ChildCare";
import LiquorIcon from "@mui/icons-material/Liquor";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import { ReactComponent as ReligionIcon } from "../../../../../../../../../assets/icons/religion.svg";
import CustomButton from "../../../../../../../../../components/CustomButton/CustomButton";
import { Circles } from "react-loader-spinner";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { toFeetInch } from "../../../../../../../../../utils/helpers";
import useSettingConstants from "../../../../../../../../../constants/data/useSettingConstants";
import useConstants from "../../../../../../../../../utils/useConstants";
import { FaStarOfDavid, FaBrain } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Height } from "@mui/icons-material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { styles } from "./UserDetails.style";
import useUserDetails from "./useUserDetails";

export default function UserDetails() {
  const classes = styles();
  const {
    userData,
    setUserData,
    userInterests,
    updateProfileInfo,
    isSubmitting,
    isUpdatingProfile,
    submitInterest,
    handleChange,
    isExist,
    isChecking,
    onPickInterest,
    handleDatePickerClose,
    selectedMenu,
    anchorEl,
    isOpen,
    setIsOpen,
    handleClose,
    onOpenMenu,
    day,
    month,
    year,
    t,
    sexOptions,
    interestsArray,
    childrenOptions,
    drinkOptions,
    personalityOptions,
    petOptions,
    religionOptions,
    smokeOptions,
    zodiacSignOptions,
    previewUser,
  } = useUserDetails();

  return !userData ? (
    <div>Loading...</div>
  ) : (
    <Box
      style={{ marginBottom: "75rem", height: "100%" }}
      className={classes.view2}
    >
      <div>
        <p className={classes.title}>
          {t("bio")} ({t("aboutYou")})
        </p>

        <InputBase
          sx={{ flex: 1, padding: 1 }}
          name="bio"
          value={userData?.bio}
          onChange={handleChange}
          className={classes.input}
          multiline
          rows={3}
        />
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("fullName")}</p>

        <OutlinedInput
          size="medium"
          placeholder={`firstname lastname`}
          name="full_name"
          value={userData?.full_name}
          className={classes.input1}
          onChange={handleChange}
          fullWidth
        />
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("username")}</p>

        <OutlinedInput
          size="medium"
          startAdornment={<InputAdornment position="start">@</InputAdornment>}
          placeholder="username"
          name="username"
          value={userData?.username}
          onChange={handleChange}
          className={classes.input1}
          fullWidth
        />
        <Box
          sx={{
            position: "absolute",
            right: 10,
            bottom: 17,
            display: "flex",
            width: 20,
            height: 20,
          }}
        >
          {isChecking ? (
            <CircularProgress size={23} />
          ) : userData?.username?.length > 0 ? (
            isExist === null ? (
              ""
            ) : !isExist ? (
              <ErrorIcon sx={{ color: "red" }} />
            ) : (
              <CheckCircleIcon color="success" />
            )
          ) : (
            ""
          )}
        </Box>
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("dob")}</p>

        <Box className={classes.btnBox}>
          <span className={classes.day} onClick={() => setIsOpen(!isOpen)}>
            {day === "Invalid date" ? "DD" : day}
          </span>
          <span className={classes.month} onClick={() => setIsOpen(!isOpen)}>
            {month || "MM"}
          </span>
          <span className={classes.year} onClick={() => setIsOpen(!isOpen)}>
            {year || "YYYY"}
          </span>
        </Box>
        <Box sx={{ display: "none" }}>
          <MobileDatePicker
            open={isOpen}
            onClose={handleDatePickerClose}
            label="Date mobile"
            inputFormat="MM/DD/YYYY"
            value={userData?.birthday}
            onChange={(val) => setUserData({ ...userData, birthday: val._d })}
            renderInput={(params) => <TextField {...params} />}
            maxDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 18))
            }
          />
        </Box>
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("occupation")}</p>

        <OutlinedInput
          size="medium"
          placeholder={t("enterOccupation")}
          value={userData?.occupation}
          className={classes.input1}
          name="occupation"
          onChange={handleChange}
          fullWidth
        />
      </div>

      <div className={classes.rowView}>
        <div
          className={
            userData?.gender === "male" ? classes.sexBtn : classes.sexBtnNone
          }
          onClick={() => {
            setUserData({ ...userData, gender: "male" });
          }}
        >
          <p className={classes.sexText}>{t("male")}</p>
        </div>

        <div
          className={
            userData?.gender === "female" ? classes.sexBtn : classes.sexBtnNone
          }
          onClick={() => {
            setUserData({ ...userData, gender: "female" });
          }}
        >
          <p className={classes.sexText}>{t("female")}</p>
        </div>
      </div>

      <div className={classes.inputView}>
        <p className={classes.title}>{t("sex")}</p>
        <TextField
          value={userData?.sexuality || "..."}
          onChange={handleChange}
          style={{
            backgroundColor: "#fff",
            width: "100%",
            paddingLeft: 20,
            fontSize: 13,
          }}
          name={"sexuality"}
          className={classes.sexuality}
          labelId="sexuality"
          id="select"
          select
        >
          {sexOptions.map((item) => (
            <MenuItem key={item.value} value={item.value.toLowerCase()}>
              {item.title}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <div className={classes.inputView}>
        <p className={classes.title1}>{t("moreInfo")}</p>
        <Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box className={classes.infoTitle}>
              <Height className={classes.infoIcon} />
              <Typography fontSize={13}>{t("height")}</Typography>
            </Box>
            <Typography fontSize={13}>
              {`${toFeetInch(userData?.height)} (${
                (userData?.height || 0) / 100
              }m)`}
            </Typography>
          </Box>

          <Slider
            getAriaLabel={() => t("height")}
            value={Number(userData?.height)}
            name="height"
            onChange={handleChange}
            valueLabelDisplay="off"
            color="error"
            max={244}
            min={0}
          />
        </Box>
        <Box
          id="zodaic_sign"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "zodaic_sign")}
        >
          <Box className={classes.infoTitle}>
            <FaStarOfDavid className={classes.infoIcon} />

            <Typography fontSize={13}>{t("zodiacSign")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              zodiacSignOptions.filter(
                (item) => item?.value === userData?.zodiac_sign
              )[0]?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="zodaic_sign"
          open={selectedMenu === "zodaic_sign" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {zodiacSignOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({
                  ...userData,
                  zodiac_sign: item.title,
                });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>
        <Box
          id="pet"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "pet")}
        >
          <Box className={classes.infoTitle}>
            <PetsIcon className={classes.infoIcon} />
            <Typography fontSize={13}>Pet</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              petOptions.filter((item) => item?.value === userData?.pet)[0]
                ?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="pet"
          open={selectedMenu === "pet" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {petOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, pet: item.title });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="children"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "children")}
        >
          <Box className={classes.infoTitle}>
            <ChildCareIcon className={classes.infoIcon} />
            <Typography fontSize={13}>{t("children")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              childrenOptions.filter(
                (item) => item?.value === userData?.children
              )[0]?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="children"
          open={selectedMenu === "children" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {childrenOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, children: item.title });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="drink"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "drink")}
        >
          <Box className={classes.infoTitle}>
            <LiquorIcon className={classes.infoIcon} />
            <Typography fontSize={13}>{t("drinkT")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              drinkOptions.filter((item) => item?.value === userData?.drink)[0]
                ?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="drink"
          open={selectedMenu === "drink" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {drinkOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, drink: item.title });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="smoke"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "smoke")}
        >
          <Box className={classes.infoTitle}>
            <SmokingRoomsIcon className={classes.infoIcon} />
            <Typography fontSize={13}>{t("smokeT")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              smokeOptions.filter((item) => item?.value === userData?.smoke)[0]
                ?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="smoke"
          open={selectedMenu === "smoke" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {smokeOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, smoke: item.title });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="religion"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "religion")}
        >
          <Box className={classes.infoTitle}>
            <ReligionIcon className={classes.infoIcon} />
            <Typography fontSize={13}>{t("religion")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              religionOptions.filter(
                (item) => item?.value === userData?.religion
              )[0]?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="religion"
          open={selectedMenu === "religion" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {religionOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, religion: item.title });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <Box
          id="personality"
          aria-controls={"demo-positioned-menu"}
          aria-haspopup="true"
          aria-expanded={"true"}
          className={classes.moreInfoRow}
          onClick={(e) => onOpenMenu(e, "personality")}
        >
          <Box className={classes.infoTitle}>
            <FaBrain className={classes.infoIcon} />
            <Typography fontSize={13}>{t("personCharacter")}</Typography>
          </Box>
          <Typography fontSize={13}>
            {
              personalityOptions.filter(
                (item) => item?.value === userData?.personality
              )[0]?.title
            }
          </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="demo-positioned-menu"
          aria-labelledby="personality"
          open={selectedMenu === "personality" ? true : false}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          {personalityOptions.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                setUserData({ ...userData, personality: item.title });
                handleClose();
              }}
            >
              {item?.title}
            </MenuItem>
          ))}
        </Menu>

        <CustomButton
          disable={isUpdatingProfile}
          className={classes.saveProfileBtn}
          onClick={updateProfileInfo}
        >
          {isUpdatingProfile ? (
            <Circles color="#fff" width={15} height={15} />
          ) : (
            t("saveProfile")
          )}
        </CustomButton>
        {/* <InfoRenderComp title={"Religion"} content={"Christian"} />
        <InfoRenderComp title={"Height"} content={"106cm"} />
        <InfoRenderComp title={"Weight"} content={"100 pounds"} /> */}
      </div>

      <div className={classes.inputView}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className={classes.title1}>{t("interests")}</p>
          <Typography fontWeight={500}>{`${
            userInterests?.length || 0
          }/${8}`}</Typography>
        </Box>

        <div className={classes.wrapView}>
          {interestsArray?.map((item, i) => {
            // console.log(item);
            return (
              <Box
                onClick={() => {
                  onPickInterest(item);
                }}
                key={i}
                className={classes.interestBox}
                style={{
                  backgroundColor:
                    colors[Math.floor(Math.random() * colors.length)],
                  border: userInterests?.map((i) => i?.id)?.includes(item?.id)
                    ? "2px solid #EA0F08"
                    : "none",
                }}
              >
                <p className={classes.content}>#{item.name}</p>
              </Box>
            );
          })}
        </div>
      </div>
      <CustomButton
        disable={isSubmitting}
        className={classes.saveProfileBtn}
        onClick={submitInterest}
      >
        {isSubmitting ? (
          <Circles color="#fff" width={15} height={15} />
        ) : (
          `${t("submit")} ${t("interests")}`
        )}
      </CustomButton>
    </Box>
  );
}
