import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../../../../../redux/actions/global/global.actions";
import usersService from "../../../../../../../../services/users.service";
import UserPreview from "../../../../../../../../components/UserPreview";
import { getToken } from "../../../../../../../../utils/helpers";
import adminService from "../../../../../../../../services/admin/admin.service";
import {
  setGettingPreview,
  setPreviewUser,
} from "../../../../../../../../redux/actions/users/users.actions";

function usePostsEvents() {
  const reduxDispatch = useDispatch();
  const { userId } = useParams();

  const token = getToken();
  const [posts, setPosts] = useState([]);

  const [events, setEvents] = useState([]);

  function getUserPosts() {
    adminService
      .getUserPosts(token, userId)
      .then((res) => {
        setPosts(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  function getUserEvents() {
    adminService
      .getUserEvents(token, userId)
      .then((res) => {
        setEvents(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  const [isLoading, setIsLoading] = useState(false);
  const [sendingRequest, setSendingRequest] = useState(false);
  const { t } = useTranslation();

  const getUser = (userId) => {
    reduxDispatch(setGettingPreview(true));
    usersService
      .getUser(token, userId)
      .then(({ data }) => {
        reduxDispatch(setPreviewUser(data));
      })
      .catch((err) => console.log(err))
      .finally(() => reduxDispatch(setGettingPreview(false)));
  };
  const getCaption = (post) => {
    const splitString = post?.caption?.split(" ");
    let newWord = [];

    for (var i = 0; i < splitString?.length; i++) {
      if (post?.tags?.map((tag) => tag?.name)?.includes(splitString[i])) {
        const tagIndex = post?.tags?.findIndex(
          (tag) => tag?.name == splitString[i]
        );
        newWord?.push(
          <span
            key={i}
            onClick={() => {
              getUser(post?.tags[tagIndex]?.user?.id);
              reduxDispatch(setPopupChildren(<UserPreview />));
              reduxDispatch(togglePopup(true));
            }}
            style={{
              color: "#EA0F07",
              fontFamily: "Avenir",
              fontWeight: "bold",
              fontSize: 14,
              cursor: "pointer",
            }}
          >
            <span
              style={{
                fontSize: 12,
                color: "#EA0F07",
                fontFamily: "Avenir",
              }}
            >
              @
            </span>
            {post?.tags[tagIndex]?.user?.username}{" "}
          </span>
        );
      } else {
        newWord?.push(`${splitString[i]} `);
      }
    }

    return newWord;
  };

  const removeUserPost = (postId) => {
    adminService
      .removeUserPost(token, userId, postId)
      .then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          getUserPosts();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {});
  };

  const removeUserEvent = (postId) => {
    adminService
      .removeUserPost(token, userId, postId)
      .then((res) => {
        if (res?.success) {
          toast.success(res?.message);
          getUserEvents();
        } else {
          toast.error(res?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {});
  };

  return {
    getCaption,
    getUser,
    getUserPosts,
    removeUserPost,
    isLoading,
    posts,
    removeUserEvent,
    events,
    getUserEvents,
    userId,
  };
}

export default usePostsEvents;
