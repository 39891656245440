import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setGettingMemberships,
  setGifts,
  setInterests,
  setMemberships,
} from "../../../redux/actions/global/global.actions";
import {
  setMyCoords,
  setMyData,
  setUsersDefaultFilter,
} from "../../../redux/actions/users/users.actions";
import globalService from "../../../services/global.service";
import usersService from "../../../services/users.service";
import { getToken, removeUserSession, setUser } from "../../../utils/helpers";
import Geocode from "react-geocode";
import { useLocation, useNavigate } from "react-router-dom";
import authService from "../../../services/auth.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import otherServices from "../../../services/others.service";
import socketService from "../../../services/socket.service";
import { Slide } from "@mui/material";
import callService from "../../../services/call.service";

const useAppLayout = () => {
  const token = getToken();
  const navigate = useNavigate();
  const usersDefaultFilter = useSelector(
    (state) => state.users.usersDefaultFilter
  );
  const myData = useSelector((state) => state.users.myData);
  const dispatch = useDispatch();
  const [locationPopOpen, setLocationPopOpen] = useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const splitLocation = location.pathname?.split("/");
  const getGifts = () => {
    globalService
      .getGifts(token)
      .then(({ data }) => {
        dispatch(setGifts(data));
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const getMe = () => {
    usersService.getMe(token).then((res) => {
      setUser(res.data);

      dispatch(
        setUsersDefaultFilter({
          ...usersDefaultFilter,
          // mode: res.data.mode,
          state: res.data.state,
          country: res.data.country,
        })
      );
      dispatch(setMyData(res.data));
      callService.initializeCall(`${res?.data?.id}`, `${res?.data?.username}`);
      if (!res?.data.verifiedEmail) {
        handleResendOtp(res?.data?.email);
        navigate("/auth/verify", { state: { email: res?.data?.email } });
      } else if (res?.data.first_name === null) {
        navigate("/onboarding/personal");
        // }
        // else if (!res?.data.active_subscription) {
        //   navigate("/onboarding/plans");
      } else if (res?.data?.interests?.length === 0) {
        navigate("/onboarding/interest");
      }
    });
  };

  function handleResendOtp(location) {
    authService
      .resendOtp(location)
      .then((response) => {
        toast.success("OTP Sent");
      })
      .catch((error) => {})
      .finally(() => {});
  }

  const getMyCoords = () => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        dispatch(
          setMyCoords({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
        );

        getCoordsInfo(position.coords.latitude, position.coords.longitude);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      },
      { enableHighAccuracy: true }
    );
  };
  const getCoordsInfo = (lat, lng) => {
    try {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);

      Geocode.fromLatLng(lat, lng).then(
        (response) => {
          let city, state, country;
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[0]) {
                case "locality":
                  city = response.results[0].address_components[i].short_name;
                  break;
                case "administrative_area_level_1":
                  state = response.results[0].address_components[i].short_name;
                  break;
                case "country":
                  country =
                    response.results[0].address_components[i].short_name;
                  break;
                default:
              }
            }
          }

          updateMyCoords(country, state, lat, lng);
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (error) {}
  };
  const updateMyCoords = (country, state, lat, lng) => {
    usersService
      .updateMyLocation(token, country, state, Number(lat), Number(lng))
      .then(({ success }) => {
        if (success === true) {
          getMe();
        }
      })
      .catch((err) => console.log("Err res :: ", err));
  };

  const onUpdateUserStatus = (status) => {
    usersService
      .updateStatus(token, status)
      .then((res) => {})
      .catch((err) => console.log(err, " is err "));
  };

  const getMemberships = () => {
    dispatch(setGettingMemberships(true));

    globalService
      .getMembership()
      .then(({ data }) => {
        dispatch(setMemberships(data));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setGettingMemberships(false)));
  };
  const getInterests = () => {
    globalService
      .getInterests(token)
      .then((res) => {
        if (res?.success) {
          dispatch(setInterests(res?.data));
        }
      })
      .catch((err) => console.log(err));
  };

  const onLogout = () => {
    usersService.removeFCMToken(token).then((res) => {
      removeUserSession();
      if (splitLocation[1] == "admin") {
        navigate("/admin");
      } else {
        navigate("/");
      }
    });
  };

  const handlePermissionChange = () => {
    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "denied") {
            if (!myData?.disable_location) {
              onToggleEnabledLocation();
            }
          } else {
            if (!myData?.disable_location) {
              onToggleEnabledLocation();
            }
            getMyCoords();
          }
        });
    }
  };

  const onToggleEnabledLocation = () => {
    otherServices
      .toggleEnabledLocation(token)
      .then((res) => {
        if (res?.success) {
          getMe();
        }
      })
      .catch((err) => console.log(err, "is err"))
      .finally(() => {});
  };

  const [showOnline, setShowOffline] = useState(false);

  const onOnline = useCallback(() => {
    setShowOffline(false);
  }, []);

  const onOffline = useCallback(() => {
    setShowOffline(true);
  }, []);
  return {
    getGifts,
    getMe,
    getMyCoords,
    onUpdateUserStatus,
    getMemberships,
    getInterests,
    onLogout,
    handlePermissionChange,
    locationPopOpen,
    setLocationPopOpen,
    showOnline,
    onOnline,
    onOffline,
  };
};

export default useAppLayout;
