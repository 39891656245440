import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { togglePopup } from "../../../redux/actions/global/global.actions";
import { setMyData } from "../../../redux/actions/users/users.actions";
import usersService from "../../../services/users.service";
import { getToken } from "../../../utils/helpers";

const useModesModal = () => {
  const [savingMode, setSavingMode] = useState(false);
  const token = getToken();
  const dispatch = useDispatch();
  const onSaveMode = (mode) => {
    if (mode === "") {
      return toast.error("Please select a mode");
    }
    setSavingMode(true);
    usersService
      .saveMode(token, mode)
      .then((res) => {
        getMe();
        toast.success("Successfully changed mode");
        dispatch(togglePopup(false));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSavingMode(false);
      });
  };

  const getMe = () => {
    usersService
      .getMe(token)
      .then(({ data }) => {
        dispatch(setMyData(data));
      })
      .catch((err) => console.log(err));
  };

  return { onSaveMode, savingMode };
};

export default useModesModal;
