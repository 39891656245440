import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useStyle } from "./CustomDropDown.styles";

export default function CustomDropDown({ data, value, handleChange }) {
  const classes = useStyle();

  return (
    <div>
      <FormControl
        sx={{
          m: 0,
          minHeight: "20px",
          minWidth: "100px",
          backgroundColor: "#F4F5F9",
        }}
      >
        {/* <InputLabel id="demo-simple-select-helper-label">All</InputLabel> */}
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={value.value}
          onChange={handleChange}
          sx={{ color: "#0A0707", fontSize: "14px" }}
          className={classes.select}
        >
          {data?.map((item) => (
            <MenuItem value={item?.value}>{item?.name}</MenuItem>
          ))}
        </Select>
        {/* <FormHelperText>With label + helper text</FormHelperText> */}
      </FormControl>
    </div>
  );
}
