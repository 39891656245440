import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import UserImgComponent from "../../components/UserImgComponent";
import UserPreview from "../../components/UserPreview";
import MappedHeader from "../../components/UsersFilter/components/MappedHeader";
import {
  distanceBetweenCoords,
  getAge,
  usernameStringCheck,
} from "../../utils/helpers";
import useNearby from "./hooks/useNearby";
import { NearbyStyle } from "./Nearby.style";
const Nearby = ({
  nearbyUsers,
  gettingNearby,
  myCoords,
  setPopupChildren,
  togglePopup,
  setPreviewUser,
}) => {
  const classes = NearbyStyle();
  const { t } = useTranslation();
  const { getNearbyUsers } = useNearby();
  useEffect(() => {
    getNearbyUsers();
  }, [myCoords]);
  return (
    <Box className={classes.nearbyWrapper}>
      <MappedHeader />
      <Box className={classes.nearbyMainWrap}>
        <Grid container className={classes.nearbyGrid}>
          {gettingNearby ? (
            <Box className={classes.skeletonContainer}>
              <Skeleton
                count={20}
                circle={true}
                width={120}
                height={120}
                inline={true}
                style={{ margin: "10px 15px" }}
              />
            </Box>
          ) : nearbyUsers?.length > 0 ? (
            nearbyUsers?.map((user, i) => (
              <Grid
                onClick={() => {
                  setPreviewUser(user);
                  setPopupChildren(<UserPreview />);
                  togglePopup(true);
                }}
                className={classes.userWrapper}
                key={i}
              >
                <Box className={classes.avatarBox}>
                  <UserImgComponent
                    className={classes.avatar}
                    alt="u_img"
                    src={user?.avatar}
                  />
                </Box>
                <Box className={classes.userNameRow}>
                  <Typography
                    style={{ maxWidth: 180 }}
                    textOverflow="ellipsis"
                    noWrap
                  >
                    <span style={{ color: "#ADABAB" }}>@</span>
                    {usernameStringCheck(user.username)},
                  </Typography>

                  {user.birthday !== null && (
                    <Typography>{getAge(user?.birthday)}</Typography>
                  )}

                  {user?.status === "online" && (
                    <Box className={classes.onlineDot} />
                  )}
                </Box>
                {user?.location_coords && myCoords && (
                  <Box>
                    <Typography className={classes.distance}>
                      {`${distanceBetweenCoords(
                        myCoords.lat,
                        myCoords.lng,
                        user?.location_coords?.coordinates[1],
                        user?.location_coords?.coordinates[0]
                      ).toFixed(1)} km`}
                    </Typography>
                  </Box>
                )}
              </Grid>
            ))
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "75vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>{t("noUser")}</Typography>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Nearby;
