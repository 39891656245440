import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setMyEvents } from "../../../../../redux/actions/feeds/feeds.actions";
import feedsService from "../../../../../services/feeds.service";
import { getToken } from "../../../../../utils/helpers";

const useMyPostsEvents = () => {
  const token = getToken();
  const [gettingPostEvents, setGettingPostEvents] = useState(false);

  const reduxDispatch = useDispatch();

  const getPostEvents = () => {
    setGettingPostEvents(true);
    feedsService
      .getPostEvents(token)
      .then((res) => {
        reduxDispatch(setMyEvents(res?.data.data));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setGettingPostEvents(false);
      });
  };

  const removeEvent = (postId) => {
    feedsService
      .removePost(token, postId)
      .then((res) => {
        toast.success("Event Deleted");
        getPostEvents();
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  return { getPostEvents, gettingPostEvents, removeEvent };
};

export default useMyPostsEvents;
