import { makeStyles } from "@mui/styles";

export const style = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100vh",
    overflowX: "hidden",
    overflowY: "auto",
    scrollbarWidth: "none" /* Firefox */,
    "-ms-overflow-style": "none" /* IE and Edge */,
    "&::-webkit-scrollbar": {
      width: "0.25rem", // Adjust the width if needed
      display: "none", // Hide the scrollbar
    },
  },
}));
