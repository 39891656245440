import { makeStyles } from "@mui/styles";

export const useAdminNavigationStyles = makeStyles((theme) => ({
  Wrapper: {
    background: "#FFF",
    width: "250px",
  },
  NavLinks: {
    "& .active": {
      color: "#EA0F08 !important",
      padding: "1.5rem 2rem",
      cursor: "pointer",
      fontSize: "16px !important",
      fontWeight: "600",
    },
    "& .inactive": {
      color: "#0A0707 !important",
      padding: "1.5rem 2rem",
      cursor: "pointer",
      fontSize: "14px !important",
      fontWeight: "600",
    },
  },
}));
