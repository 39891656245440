import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import useStyles from "../Login/Login.styles";
import Logo from "../../../../resources/img/M8s social white background.png";
import CustomButton from "../../../../components/CustomButton";
import OtpInput from "react18-input-otp";
import useAuthentication from "../../../../pages/Authentication/hooks/useAuthentication";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function VerifyEmail({ isReset }) {
  const classes = useStyles();
  const location = useLocation();
  const locationState = location?.state?.email;
  const locationResetState = location?.state?.resetEmail;
  const {
    state,
    dispatch,
    handleResendOtp,
    handleVerify,
    handleResetPassword,
    handleForgotResendOtp,
  } = useAuthentication();
  const { verifyPin, isLoading } = state;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleChange = (enteredOtp) => {
    dispatch({ verifyPin: enteredOtp });
  };

  const isDisabled = verifyPin.length < 4;

  return (
    <Box className={classes.pageContainer}>
      <Box className={classes.pageBox}>
        <Box className={classes.logo}>
          <img src={Logo} alt="m8t logo" />
        </Box>
        <Box marginTop="-20px" marginBottom="20px">
          <Typography className={classes.headerRed}>
            {t("enterCode")}
          </Typography>
          <Typography className={classes.headerSub}>
            {t("codeToEmail")}{" "}
            <span className={classes.headerSubRed}>
              {" "}
              {isReset ? locationResetState : locationState}
            </span>
          </Typography>
        </Box>

        <form
          onSubmit={
            !!isReset
              ? (e) => handleResetPassword(e, locationResetState)
              : (e) => handleVerify(e, locationState)
          }
          className={classes.formBox}
        >
          <Box>
            <OtpInput
              value={verifyPin}
              onChange={handleChange}
              numInputs={4}
              inputStyle={classes.otpInput}
              containerStyle={classes.otpContainer}
            />
          </Box>
          <Box marginTop="15px">
            <CustomButton
              type="submit"
              loading={isLoading}
              disabled={isDisabled}
              style={{ borderStyle: "none" }}
            >
              {t("continue")}
            </CustomButton>
          </Box>

          <Box marginBottom="20px" marginTop="10px">
            <Typography className={classes.formText}>
              {t("didntReceive")}?{" "}
              <span
                className={classes.formLink}
                onClick={
                  !!isReset
                    ? (e) => handleForgotResendOtp(e, locationResetState)
                    : (e) => handleResendOtp(e, locationState)
                }
              >
                {t("resendCode")}
              </span>
            </Typography>
          </Box>
          <Box marginBottom="20px" marginTop="10px">
            <Typography
              onClick={() => navigate("/auth/login")}
              className={classes.formText}
              style={{ color: "red", cursor: "pointer" }}
            >
              {t("backLogin")}
            </Typography>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default VerifyEmail;
