import React from "react";
import { useTranslation } from "react-i18next";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import MyEvents from "./components/MyEvents";
import MyPosts from "./components/MyPosts/MyPosts";
import { MyPostsEventsStyles } from "./MyPostsEvents.styles";

const MyPostsEvents = () => {
  const classes = MyPostsEventsStyles();
  const location = useLocation();
  const splitPath = location.pathname.split("/");
  const settingsPath = splitPath.pop();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div
          className={
            settingsPath === "posts" || settingsPath === "posts-events"
              ? classes.headerItemSel
              : classes.headerItem
          }
          onClick={() => navigate("/settings/posts-events/posts")}
        >
          <p
            className={
              settingsPath === "posts" || settingsPath === "posts-events"
                ? classes.headerText1
                : classes.headerText
            }
          >
            {t("posts")}
          </p>
        </div>
        <div
          className={
            settingsPath === "events"
              ? classes.headerItemSel
              : classes.headerItem
          }
          onClick={() => navigate("/settings/posts-events/events")}
        >
          <p
            className={
              settingsPath === "events"
                ? classes.headerText1
                : classes.headerText
            }
          >
            {t("events")}
          </p>
        </div>
      </div>

      <div className={classes.postCont}>
        <Outlet />
      </div>
    </div>
  );
};

export default MyPostsEvents;
