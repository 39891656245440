import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../../../../components/ChangePassword";
import Languages from "../../../../components/Languages";
import useSettingConstants from "../../../../constants/data/useSettingConstants";
import {
  setPopupChildren,
  togglePopup,
} from "../../../../redux/actions/global/global.actions";
import useSettings from "../../hooks/useSettings";
import { SecurityStyles } from "./Security.styles";

const Security = () => {
  const classes = SecurityStyles();
  const myData = useSelector((state) => state.users.myData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleLangChange } = useSettings();
  const { languages } = useSettingConstants();
  const { t } = useTranslation();
  return (
    <Box className={classes.pageWrap}>
      <Box
        onClick={() => navigate("/settings/security/blocked_accounts")}
        className={classes.section}
      >
        <Typography fontSize={14}>{t("blockedAccts")}</Typography>
        <Typography className={classes.subText}>{t("beenBlocked")}</Typography>
      </Box>
      <Box
        onClick={() => {
          dispatch(
            setPopupChildren(
              <Languages value={myData?.language} onChange={handleLangChange} />
            )
          );
          dispatch(togglePopup(true));
        }}
        className={classes.section}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={14}>{t("changeLang")}</Typography>
        <Typography color="#EA0F08" fontWeight={500}>
          {
            languages?.filter((lang) => lang?.value === myData?.language)[0]
              ?.name
          }
        </Typography>
      </Box>

      <Box
        onClick={() => {
          dispatch(setPopupChildren(<ChangePassword />));
          dispatch(togglePopup(true));
        }}
        className={classes.section}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={14}>{t("changePassword")}</Typography>
      </Box>
    </Box>
  );
};

export default Security;
