import {
  SET_CALLER,
  SET_CALLING,
  SET_CONFIRM_MODAL,
  SET_GETTING_MEMBERSHIPS,
  SET_GIFTS,
  SET_INCOMING_CALL,
  SET_INTERESTS,
  SET_LOCAL_STREAM,
  SET_MEMBERSHIPS,
  SET_ON_CALL,
  SET_OUTGOING_CALL,
  SET_PEER,
  SET_POPUP_CHILDREN,
  SET_REMOTE_STREAM,
  SET_RINGTONE,
  SET_SHOW_POPUP,
  SET_SOCKET,
  SET_STORY_MODAL,
  SET_FIRST_COMPARABLE,
  SET_SECOND_COMPARABLE,
  SET_THIRD_COMPARABLE,
  SET_PERIODS,
  SET_DATES,
} from "./global.types";

export const setDates = (payload) => ({
  type: SET_DATES,
  payload: { ...payload },
});

export const setPeriods = (payload) => ({
  type: SET_PERIODS,
  payload,
});

export const setFirstComparable = (payload) => ({
  type: SET_FIRST_COMPARABLE,
  payload,
});

export const setSecondComparable = (payload) => ({
  type: SET_SECOND_COMPARABLE,
  payload,
});

export const setThirdComparable = (payload) => ({
  type: SET_THIRD_COMPARABLE,
  payload,
});

export const togglePopup = (payload) => ({
  type: SET_SHOW_POPUP,
  payload,
});

export const setPopupChildren = (payload, onDismiss) => ({
  type: SET_POPUP_CHILDREN,
  payload,
  onDismiss: onDismiss || (() => {}),
});

export const setConfirmModal = (payload) => ({
  type: SET_CONFIRM_MODAL,
  payload,
});

export const setGifts = (payload) => ({
  type: SET_GIFTS,
  payload,
});

export const setInterests = (payload) => ({
  type: SET_INTERESTS,
  payload,
});

export const setSocket = (payload) => ({
  type: SET_SOCKET,
  payload,
});

export const setPeer = (payload) => ({
  type: SET_PEER,
  payload,
});

export const setCalling = (payload) => ({
  type: SET_CALLING,
  payload,
});

export const setOnCall = (payload) => ({
  type: SET_ON_CALL,
  payload,
});

export const setOutgoingCall = (payload) => ({
  type: SET_OUTGOING_CALL,
  payload,
});

export const setIncomingCall = (payload) => ({
  type: SET_INCOMING_CALL,
  payload,
});

export const setCaller = (payload) => ({
  type: SET_CALLER,
  payload,
});

export const setRemoteStream = (payload) => ({
  type: SET_REMOTE_STREAM,
  payload,
});

export const setLocalStream = (payload) => ({
  type: SET_LOCAL_STREAM,
  payload,
});
export const setMemberships = (payload) => ({
  type: SET_MEMBERSHIPS,
  payload,
});
export const setGettingMemberships = (payload) => ({
  type: SET_GETTING_MEMBERSHIPS,
  payload,
});
export const setStoryModal = (payload) => ({
  type: SET_STORY_MODAL,
  payload,
});
export const setRingtone = (payload) => ({
  type: SET_RINGTONE,
  payload,
});
