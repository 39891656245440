import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  fileType,
  getToken,
  usernameStringCheck,
} from "../../../../../../utils/helpers";
import { MessageBodyStyle } from "./MessageBody.style";
import moment from "moment";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MoreVertOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import socketService from "../../../../../../services/socket.service";
import { setMessagesInView } from "../../../../../../redux/actions/chats/chats.actions";
import { useTranslation } from "react-i18next";
import ReportDialog from "../../../../../../components/ReportDialog";
import globalService from "../../../../../../services/global.service";
const MessageBody = ({
  messagesInView,
  isTyping,
  setMessageRef,
  messageInputRef,
  messagingUser,
  myData,
}) => {
  const classes = MessageBodyStyle();
  const messageBodyRef = useRef(null);
  const [selectedMessage, setSelectedMessage] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openReport, setOpenReport] = useState(false);
  const [reasonForReport, setReasonForReport] = useState("");
  const token = getToken();
  useEffect(() => {
    const element = messageBodyRef.current;
    element.scrollTop = element.scrollHeight;
  }, [messagesInView]);

  const onOpenReport = () => {
    setOpenReport(true);
  };

  const onCancelReport = () => {
    setReasonForReport("");
    setOpenReport(false);
  };

  const onSubmitReport = () => {
    if (reasonForReport.trim().length == 0) {
      toast.error(t("stateReport"));
      return;
    }

    globalService
      .submitReport(token, selectedMessage?.id, "message", reasonForReport)
      .then((res) => {
        if (res?.success) {
          toast.success(t("reportSubmitSuccess"));
          setOpenReport(false);
          setReasonForReport("");
          setSelectedMessage(null);
        } else {
          toast.error(res?.errors || res?.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const onOpenMenu = (event, message) => {
    setSelectedMessage(message);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSelectedMessage(null);
    setAnchorEl(null);
  };

  const handleDeleteMessage = () => {
    socketService.emit("delete_message", { id: selectedMessage?.id }, (res) => {
      if (res.status) {
        dispatch(
          setMessagesInView(
            messagesInView?.filter((message) => message?.id != res?.data?.id)
          )
        );
        toast.success(res?.data?.message);
        handleClose();
      } else {
      }
    });
  };

  return (
    <Box className={classes.messagesWrapper} ref={messageBodyRef}>
      <>
        {messagesInView
          ?.slice(0)
          ?.reverse()
          ?.map((message, i) =>
            message?.sender &&
            message?.sender_deleted ? null : !message?.sender &&
              message?.receiver_deleted ? null : (
              <Box
                key={i}
                className={classes.messageRow}
                style={{
                  justifyContent: message?.sender ? "flex-end" : "flex-start",
                }}
              >
                {message?.sender && (
                  <IconButton
                    id="demo-positioned-button"
                    aria-controls={"demo-positioned-menu"}
                    aria-haspopup="true"
                    aria-expanded={"true"}
                    onClick={(e) => onOpenMenu(e, message)}
                    style={{ height: 25, width: 20 }}
                  >
                    <MoreVertOutlined
                      style={{
                        color: "#00000045",
                        fontSize: 16,
                        opacity: 0.5,
                      }}
                    />
                  </IconButton>
                )}
                <Menu
                  anchorEl={anchorEl}
                  id="demo-positioned-menu"
                  aria-labelledby="demo-positioned-button"
                  open={selectedMessage?.id === message?.id ? true : false}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <MenuItem onClick={() => handleDeleteMessage()}>
                    {selectedMessage?.sender && !selectedMessage?.seen
                      ? t("delete4Every")
                      : t("deleteForMe")}
                  </MenuItem>
                  <MenuItem onClick={() => onOpenReport()}>
                    {t("reportMessage")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setMessageRef(selectedMessage);
                      messageInputRef.current.focus();
                      setSelectedMessage(null);
                    }}
                  >
                    {t("reply")}
                  </MenuItem>
                </Menu>

                <Box
                  className={
                    message?.sender
                      ? classes.messageContainerSender
                      : classes.messageContainer
                  }
                  style={
                    message?.gift &&
                    message?.gift !== null && {
                      backgroundColor: "transparent",
                      alignItems: "center",
                    }
                  }
                >
                  {message?.ref && (
                    <Box
                      style={{
                        opacity: 0.7,
                        backgroundColor:
                          Number(
                            message?.ref?.split("|")[
                              message?.ref?.split("|").length - 1
                            ]
                          ) == myData?.id
                            ? "#E00E08"
                            : "#F4F5F9",
                        borderRadius: 3,
                        padding: 3,
                        borderLeftWidth: 3,
                        borderColor:
                          Number(
                            message?.ref?.split("|")[
                              message?.ref?.split("|").length - 1
                            ]
                          ) == myData?.id
                            ? "#fff"
                            : "#000",
                        cursor: "pointer",
                      }}
                    >
                      {Number(
                        message?.ref?.split("|")[
                          message?.ref?.split("|").length - 1
                        ]
                      ) == myData?.id ? (
                        <Typography
                          style={{
                            color: "#fff",
                            fontWeight: "700",
                            opacity: 0.5,
                            textDecorationLine: "underline",
                          }}
                        >
                          {t("you")}
                        </Typography>
                      ) : (
                        <Typography
                          style={{
                            color: "#000",
                            fontWeight: "700",
                            opacity: 0.5,
                            textDecorationLine: "underline",
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            style={{
                              color: "#ADABAB",
                            }}
                          >
                            @
                          </Typography>
                          {usernameStringCheck(messagingUser?.username)}
                        </Typography>
                      )}

                      <Typography
                        style={{
                          color:
                            Number(
                              message?.ref?.split("|")[
                                message?.ref?.split("|").length - 1
                              ]
                            ) == myData?.id
                              ? "#fff"
                              : "#000",
                        }}
                        numberOfLines={1}
                      >
                        {message?.ref?.split("|")[1]}
                      </Typography>
                    </Box>
                  )}
                  <Typography
                    style={{
                      fontSize: 9,
                      color: "#C0C0C0",
                      opacity: 0.8,
                      padding: 0,
                    }}
                  >
                    {moment(message?.createdAt).format("DD/MM/YY")}
                  </Typography>
                  {message?.gift != null && (
                    <>
                      <img
                        alt="gift"
                        src={message?.gift?.icon}
                        style={{ width: 50, height: 50 }}
                      />
                      <Typography style={{ fontSize: 10 }}>
                        {message?.sender
                          ? "You sent a gift"
                          : "You received a gift"}
                      </Typography>
                    </>
                  )}
                  {message?.media != null ? (
                    fileType(message?.media) === "image" ? (
                      <>
                        <Box
                        // activeOpacity={1}
                        // onPress={() => setIsVisible(true)}
                        >
                          <LazyLoadImage
                            effect="blur"
                            alt="img"
                            src={message?.media}
                            className={classes.mediaStyle}
                          />
                        </Box>
                        {/* <ImageView
                images={[{ uri: message?.media }]}
                imageIndex={0}
                visible={visible}
                onRequestClose={() => setIsVisible(false)}
              /> */}
                      </>
                    ) : fileType(message?.media) === "video" ? (
                      <video
                        //   ref={vidRef}
                        className={classes.mediaStyle}
                        src={message?.media}
                        controls

                        // onPlaybackStatusUpdate={(status) => setStatus(() => status)}
                      />
                    ) : fileType(message?.media) === "audio" ? (
                      <Box className={classes.audioMessageWrapper}>
                        <audio controls style={{ maxWidth: 250 }}>
                          <source src={message?.media} />
                        </audio>
                      </Box>
                    ) : null
                  ) : null}

                  {message?.text !== "" &&
                    message?.text != null &&
                    message?.gift == null && (
                      <Typography
                        style={{
                          color: message?.sender ? "#fff" : "#000",
                          fontSize: 13,
                        }}
                      >
                        {message?.text}
                      </Typography>
                    )}
                  <Box className={classes.messageInfoBox}>
                    <Typography
                      style={{
                        fontSize: 10,
                        color: "#C0C0C0",
                        marginRight: 5,
                      }}
                    >
                      {moment(message?.createdAt).format("h:mm a")}
                    </Typography>
                    {message?.sender ? (
                      message?.seen ? (
                        <DoneAllOutlinedIcon
                          style={{ color: "#5ced73", width: 10, height: 10 }}
                        />
                      ) : (
                        <DoneAllOutlinedIcon
                          style={{ color: "#C0C0C0", width: 10, height: 10 }}
                        />
                      )
                    ) : null}
                  </Box>
                </Box>
                {!message?.sender && (
                  <IconButton
                    id="demo-positioned-button"
                    aria-controls={"demo-positioned-menu"}
                    aria-haspopup="true"
                    aria-expanded={"true"}
                    onClick={(e) => onOpenMenu(e, message)}
                    style={{ height: 25, width: 20 }}
                  >
                    <MoreVertOutlined
                      style={{ color: "#00000045", fontSize: 16, opacity: 0.5 }}
                    />
                  </IconButton>
                )}
              </Box>
            )
          )}
        {isTyping && (
          <Box
            className={classes.messageRow}
            style={{
              justifyContent: "flex-start",
            }}
          >
            <Box className={classes.messageContainer}>
              <Typography style={{ color: "#C0C0C0", fontSize: 9 }}>
                {`${t("typing")}...`}
              </Typography>
            </Box>
          </Box>
        )}
      </>
      <ReportDialog
        open={openReport}
        handleClose={() => setOpenReport(false)}
        title={t("report")}
        description={t("whyReporting")}
        value={reasonForReport}
        onChange={({ target }) => setReasonForReport(target.value)}
        onCancel={onCancelReport}
        onSubmit={onSubmitReport}
      />
    </Box>
  );
};

export default MessageBody;
